// Author: Pukhraj Dhamu
// Created Date: Thuesday, July 13th 2023 at 4:18 PM
// Last Modified Date: Thuesday, July 13th 2023 at 4:18 PM
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/store";
import Layout from "../../components/Layout/Layout"
import { useQuery } from "@tanstack/react-query";
import { getMarketingTemplates } from "../../http/marketing.http";
import { SelectField } from "../../tailwind";
import { Loader } from "../../components/smallComponents/loader";
import { useDispatch } from 'react-redux'
import { setBulkAction, setBulkActionCall } from '../../redux/slice​/blogSlice'
import { BiRefresh } from 'react-icons/bi'
import RouteStrings from '../../localData/routes.json'
import { HiOutlinePlusSm } from 'react-icons/hi'
import { counter } from '../../utils/Utils'
import qs from 'qs'
import MarketingTemplateTable from "../../tailwind/Table/MarketingTemplates";
import { useQueryParamsForPaginationNew } from "../../hooks/useQueryParamsForPaginationNew";

const MarketingTemplates = () => {
    const dispatch = useDispatch()
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const [search, setSearch] = React.useState<string>('')
    // get query params
    const queryParams = useQueryParamsForPaginationNew()
    const [value] = useDebounce(search, 1000);
    const queryString: string = qs.stringify(queryParams.query);
    // get all email templates query
    const getAllMarketingTemplatesQuery = useQuery<{ data: IMarketingTemplate }>({
        queryKey: ["getAllTemplates"],
        queryFn: () => getMarketingTemplates(queryString),
        enabled: queryParams.query.page !== undefined || queryParams.query.limit !== undefined || queryParams.query.order !== undefined,
    });

    useEffect(() => {
        if (value) queryParams.setQuery && queryParams.setQuery({ page: 1, limit: queryParams.query.limit, order: queryParams.query.order, q: value });
        if (!value) {
            delete queryParams.query.q
            queryParams.setQuery && queryParams.setQuery({ ...queryParams.query })
        }
        getAllMarketingTemplatesQuery.refetch();
    }, [value]);

    useEffect(() => {
        getAllMarketingTemplatesQuery.refetch();
    }, [queryParams.query]);

    const headlePrev = () => {
        if (queryParams.query.page > 1) {
            queryParams.setQuery && queryParams.setQuery({
                ...queryParams.query,
                page: queryParams.query.page - 1,
            });
        } else {
            toast.error("You are on first page");
        }
    };

    const handleNext = () => {
        if (!getAllMarketingTemplatesQuery.data?.data.meta.pagination.page) return
        if (queryParams.query.page < getAllMarketingTemplatesQuery.data.data.meta.pagination.pageCount) {
            queryParams.setQuery && queryParams.setQuery({
                ...queryParams.query,
                page: queryParams.query.page + 1,
            });
        } else {
            toast.error("No more pages");
        }
    };

    const headleFirst = () => {
        if (queryParams.query.page === 1) {
            toast.error("You are on first page");
        } else {
            queryParams.setQuery && queryParams.setQuery({
                ...queryParams.query,
                page: 1,
            });
        }
    }

    const headleLast = () => {
        if (!getAllMarketingTemplatesQuery.data?.data.meta.pagination.pageCount) return
        const lastPage = getAllMarketingTemplatesQuery.data?.data.meta.pagination.pageCount
        if (queryParams.query.page === lastPage) {
            toast.error("You are on last page");
        } else {
            queryParams.setQuery && queryParams.setQuery({
                ...queryParams.query,
                page: lastPage
            });
        }
    }

    return (
        <Layout>
            <div className="flex justify-between items-center pr-6">
                <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                    Marketing Templates
                </h1>
                <div className='flex gap-4 items-center'>
                    <div
                        onClick={() => { getAllMarketingTemplatesQuery.refetch() }}
                        className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                        <BiRefresh className={`text-black text-lg bg-white rounded-full ${getAllMarketingTemplatesQuery.isLoading || getAllMarketingTemplatesQuery.isFetching && "animate-spin"}`} />
                        <h1 className='text-sm text-white select-none'>
                            Refresh
                        </h1>
                    </div>
                    <Link to={`${RouteStrings.Marketing.Children.MarketingTemplates.Children.CreateMarketingTemplate.Link}`}>
                        <div className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                            <HiOutlinePlusSm className='text-black text-lg bg-white rounded-full' />
                            <h1 className='text-sm text-white'>
                                Add New
                            </h1>
                        </div>
                    </Link>
                    <div className='w-52'>
                        <SelectField
                            onChange={(e) => {
                                dispatch(setBulkAction(e.value))
                            }}
                            options={[
                                { value: "none", label: "Bulk Actions" },
                                { value: "public", label: "Public" },
                                { value: "private", label: "Private" },
                                { value: "delete", label: "Delete Forever" },
                            ]}
                            getOptionLabel={(option) => option.label}
                            placeholder='Bulk Actions'
                        />
                    </div>
                    <div
                        onClick={() => dispatch(setBulkActionCall(true))}
                        className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                        <BiRefresh className={`text-black text-lg bg-white rounded-full ${getAllMarketingTemplatesQuery.isLoading || getAllMarketingTemplatesQuery.isFetching && "animate-spin"}`} />
                        <h1 className='text-sm text-white select-none'>
                            Apply
                        </h1>
                    </div>
                    <input
                        type="text"
                        placeholder="Search Content by name, tags, slug, etc..."
                        className={`input input-bordered input-md w-80 bg-transparent focus:outline-none h-[38px] rounded ${darkMode ? "border-gray-700" : "border-gray-300"}`}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                </div>
            </div>
            <div className="mt-5 w-full">
                {
                    getAllMarketingTemplatesQuery.isLoading && <div className='w-full flex justify-center items-center min-h-[47rem]'>
                        <Loader smail />
                    </div>
                }
            </div>
            <div className="mt-5 w-full">
                {
                    getAllMarketingTemplatesQuery.isError && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            Server Error. Please try again
                        </h1>
                        <div
                            onClick={() => getAllMarketingTemplatesQuery.refetch()}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                            <h1 className='text-sm text-white'>
                                Retry
                            </h1>
                        </div>
                    </div>
                }
            </div>
            <div className="mt-5 w-full">
                {getAllMarketingTemplatesQuery.isSuccess ? getAllMarketingTemplatesQuery.data?.data?.data.length > 0 ? <>
                    <MarketingTemplateTable query={getAllMarketingTemplatesQuery} />
                    <div className="flex justify-between items-center my-5 pr-6 mb-8">
                        <div className="flex gap-8 items-center">
                            <div className="flex gap-3.5">
                                <p>Total</p>
                                <p>{counter(getAllMarketingTemplatesQuery.data.data.meta.pagination.total || 0)}</p>
                            </div>
                            <div className="flex gap-3.5">
                                <p>Public</p>
                                <p>{counter(getAllMarketingTemplatesQuery.data.data.meta.report?.public || 0)}</p>
                            </div>
                            <div className="flex gap-3.5">
                                <p>Private</p>
                                <p>{counter(getAllMarketingTemplatesQuery.data.data.meta.report?.private || 0)}</p>
                            </div>
                            <div className="flex gap-3.5">
                                <p>Pages</p>
                                <p>{counter(getAllMarketingTemplatesQuery.data.data.meta.pagination.pageCount || 0)}</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-2.5 mr-6">
                                <div
                                    onClick={headleFirst}
                                    className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 py-2 flex gap-2 items-center cursor-pointer`}>
                                    <h1 className='text-base text-white select-none'>
                                        First
                                    </h1>
                                </div>
                                <div
                                    onClick={headlePrev}
                                    className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 py-2 flex gap-2 items-center cursor-pointer`}>
                                    <h1 className='text-base text-white select-none'>
                                        Previous
                                    </h1>
                                </div>
                                <div className={`w-28 justify-center rounded px-5 flex gap-2 items-center cursor-pointer`}>
                                    <h1 className='text-base select-none'>
                                        Page {getAllMarketingTemplatesQuery.data.data.meta.pagination.page || "N/A"}
                                    </h1>
                                </div>
                                <div
                                    onClick={handleNext}
                                    className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 flex gap-2 items-center cursor-pointer`}>
                                    <h1 className='text-base text-white select-none'>
                                        Next
                                    </h1>
                                </div>
                                <div
                                    onClick={headleLast}
                                    className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 py-2 flex gap-2 items-center cursor-pointer`}>
                                    <h1 className='text-base text-white select-none'>
                                        Last
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <>
                    <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            No Data Found
                        </h1>
                        <div
                            onClick={() => getAllMarketingTemplatesQuery.refetch()}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                            <h1 className='text-sm text-white'>
                                Retry
                            </h1>
                        </div>
                    </div>
                </> : null}
            </div>
        </Layout>
    );
};

export default MarketingTemplates;
