import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getBlogs } from '../../http/blogs.http'

export type IApiStatus = 'idle' | 'success' | 'failed' | 'loading'
export interface IBlogs {
    _id: string
    title: string,
    slug: string,
    blog_content: string,
    tags: string[],
    thumbnail: string | null,
    status: boolean,
    trash: boolean,
    createdAt: string,
    updatedAt: string,
    category: {
        _id: string,
        name: string,
        slug: string,
    }
}
export interface IBlogsState {
    blogs: IBlogs[]
    meta: {
        pagination: {
            page: number,
            pageSize: number,
            pageCount: number,
            total: number,
        },
        report: {
            total: number,
            public: number,
            private: number,
            trash: number,
        }
    }
    status: IApiStatus
    bulkAction: string
    bulkActionCall: boolean
    singleBlog: IBlogs
}

interface ISetBlogs {
    blogs: IBlogs[]
    meta: IBlogsState['meta']
}

const initialState: IBlogsState = {
    blogs: [],
    meta: {} as IBlogsState['meta'],
    status: 'idle',
    bulkAction: 'none',
    bulkActionCall: false,
    singleBlog: {} as IBlogs,
}

export const BlogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        setBlogs: (state, actions: PayloadAction<ISetBlogs>) => {
            const { blogs, meta } = actions.payload
            state.blogs = blogs
            state.meta = meta
        },
        setStatus: (state, actions: PayloadAction<IApiStatus>) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingleBlog: (state, actions: PayloadAction<IBlogs>) => {
            state.singleBlog = actions.payload
        },
        setBlogReport: (state, actions: PayloadAction<IBlogsState['meta']['report']>) => {
            state.meta.report = actions.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setBlogs,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingleBlog,
    setBlogReport
} = BlogSlice.actions

export default BlogSlice.reducer

type IFetchBlogsType = 'single' | 'all'
// Thunks
export function fetchBlogs(query: string, type?: IFetchBlogsType) {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const { data, status } = await getBlogs(query);
            dispatch(
                type === 'single' ? setSingleBlog(data.data[0]) : setBlogs({
                    blogs: data.data,
                    meta: data.meta
                }))
            dispatch(setStatus('success'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}






