import React, { useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { Title } from '../../tailwind'
import toast from 'react-hot-toast'
import { getSliderEndPoint, updateSliderEndPoint } from '../../http/Apis'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from "../../components/Droppable/StrictModeDroppable";
import { useDebounce } from 'use-debounce'
import qs from 'qs'
import { fetchAllContant, setStatus as setStatusContent } from '../../redux/slice​/contentSlice'
import { RootState } from '../../redux/store'
import { TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { Loader } from '../../components/smallComponents/loader'
import SearchBar from '../../components/SearchBar'

const Sliders = () => {
    // query client
    const queryClient = useQueryClient()
    // get slider
    const { data: slider, isLoading: sliderisLoading, isError: sliderisError } = useQuery<IGetSlider>({
        queryKey: ['slider'],
        queryFn: () => getSliderEndPoint(),
    })

    // update slider
    const { mutateAsync: updateSlider, isLoading: isSliderUpdateLoading } = useMutation((data: any) => updateSliderEndPoint(data.id, data.data), {
        onSuccess: () => {
            queryClient.invalidateQueries()
            toast.success('Slider updated successfully')
        },
        onError: () => {
            toast.error('Something went wrong')
        }
    })

    const dispatch = useDispatch()
    const { content, status } = useSelector((state: RootState) => state.content)

    const [loading, setLoading] = React.useState(false)
    const [sliderContentListDrag, setSliderContentListDrag] = useState<IContent[]>([]);
    const [sliderListDrag, setSliderListDrag] = useState<any[]>([]);

    interface ISectionData {
        name: string;
        content: string[];
        status: 'active' | 'inactive';
    }

    const [sliderData, setSliderData] = React.useState<ISectionData>({
        name: '',
        content: [],
        status: 'active'
    })
    const [contentType, setContentType] = React.useState('movie')
    const [loadingContent, setLoadingContent] = React.useState(false)
    const [search, setSearch] = useState<string>();
    const [value] = useDebounce(search, 1000);


    const queryString = qs.stringify({
        page: 1,
        limit: 25,
        sort: "desc",
    });


    useEffect(() => {
        if (value) {
            dispatch(fetchAllContant(`page=1&limit=25&sort=desc&keyword=${value}`, null) as any);
        }
    }, [value]);

    useEffect(() => {
        dispatch(fetchAllContant(queryString, null) as any);
    }, [contentType])

    useEffect(() => {
        if (slider) {
            if (slider.data) {
                setSliderData({
                    name: slider.data.name,
                    content: slider.data.content,
                    status: slider.data.status
                })
                setSliderContentListDrag(slider.data.content as never[])
                setSliderListDrag(slider.data.content as never[])
                if (slider.data.content && slider.data.content.length > 0) {
                    const contentList = slider.data.content.map((item: any) => item._id)
                    setSliderContentListDrag(contentList as never[])

                }
            }
        }
    }, [slider?.data])


    const handleSubmit = async () => {
        const data = {
            id: slider?.data._id,
            data: {
                name: sliderData.name,
                content: sliderListDrag,
                status: sliderData.status
            }
        }

        // update slider
        await updateSlider(data)
    }

    function handleOnDragEnd(result: any) {
        //console.log("🚀 ~ file: AddNewSection.jsx ~ line 90 ~ handleOnDragEnd ~ result", result)
        if (!result.destination) return;

        const { source, destination } = result;
        // result push to setSectionList if result.destination.droppableId === 'sectionList'
        if (destination.droppableId === 'sectionList') {
            // if result.draggableId includes already in sectionList then return
            if (sliderContentListDrag && sliderContentListDrag.includes(result.draggableId as never)) {
                toast.error('Already added in content list')
                return;
            }
            // draggableId more then 25 character then return
            if (result.draggableId.length > 25) {
                // now reodering sectionList
                const items = Array.from(sliderContentListDrag as never[]);
                const [reorderedItem] = items.splice(source.index, 1);
                items.splice(destination.index, 0, reorderedItem);
                setSliderContentListDrag(items);
                // now reodering sectionListDrag
                const itemsDrag = Array.from(sliderListDrag as never[]);
                const [reorderedItemDrag] = itemsDrag.splice(source.index, 1);
                itemsDrag.splice(destination.index, 0, reorderedItemDrag);
                setSliderListDrag(itemsDrag);
                return;
            }
            // else unshift result.draggableId in sectionList
            setSliderContentListDrag([result.draggableId, ...sliderContentListDrag] as any)

            // find sectionDataDrag by result.draggableId and push to sectionListDrag 
            const findSectionDataDrag = content.find((item) => {
                //console.log("🚀 ~ file: AddNewSection.jsx ~ line 96 ~ findSectionDataDrag ~ item", item)
                return item._id === result.draggableId
            })
            // push new sectionListDrag in first index
            setSliderListDrag([findSectionDataDrag, ...sliderListDrag])


            //setSectionListDrag([...sectionListDrag, findSectionDataDrag])

        }
        // if result.destination.droppableId === 'sectionContent' then remove from sectionList
        if (destination.droppableId === 'sectionContent') {
            // remove from sectionList
            const newSectionList = sliderListDrag.filter((item) => {
                return item.thumbnail !== result.draggableId
            })
            setSliderContentListDrag(newSectionList)
            // remove from sectionListDrag
            const newSectionListDrag = sliderListDrag.filter((item) => {
                return item.thumbnail !== result.draggableId
            })
            setSliderListDrag(newSectionListDrag)
            console.log("🚀 ~ file: Slider.tsx:169 ~ handleOnDragEnd ~ newSectionListDrag", newSectionListDrag)
        }

        // const items = Array.from(sectionDataDrag);
        // const [reorderedItem] = items.splice(result.source.index, 1);
        // items.splice(result.destination.index, 0, reorderedItem);
        // updateSectionDataDrag(items);
    }

    return (
        <Layout>
            <Title className="text-white font-medium text-xl mb-5 pr-5">
                Slider
            </Title>
            <div>
                {
                    sliderisLoading && <Loader className="mt-96" />
                }
            </div>
            <div>
                {
                    slider && slider.data && <div>
                        <div className='grid grid-cols-2 gap-4'>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <StrictModeDroppable droppableId="sectionList" type="CONTENT">
                                    {(provided: any) => (
                                        <div>
                                            <div className='flex flex-col gap-4'>
                                                <TextField
                                                    id="slider-title"
                                                    label="Slider Title"
                                                    variant="outlined"
                                                    placeholder='Enter slider title'
                                                    value={sliderData.name}
                                                    sx={{ mt: 2 }}
                                                    onChange={(e: any) => setSliderData({ ...sliderData, name: e.target.value })}
                                                />
                                                <div className='w-full h-[500px] overflow-scroll rounded'>
                                                    <div className="w-full" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        {sliderListDrag.map((item, index) => (
                                                            <Draggable key={item.thumbnail} draggableId={item.thumbnail} index={index}>
                                                                {(provided) => (
                                                                    <div className="w-full" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                        <div className='flex gap-4 my-2'>
                                                                            <img
                                                                                src={`${item.thumbnail}?w=248&fit=crop&auto=format`}
                                                                                srcSet={`${item.thumbnail}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                                                alt={item.title || item.name}
                                                                                loading="lazy"
                                                                                className='w-36 aspect-video rounded'
                                                                            />
                                                                            <div>
                                                                                <p className='line-clamp-1'>{item.title || item.name}</p>
                                                                                <p className='text-gray-500 line-clamp-2'>{
                                                                                    item.description.length > 80 ? item.description.substring(0, 80) + '...' : item.description
                                                                                }</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {
                                                            sliderContentListDrag.length === 0 && <div className='flex justify-center items-center h-[500px]'>
                                                                <p className='w-[80%] text-center'>
                                                                    Right side is the section content and left side is the content list to add in the section content. You can drag and drop the content from left side to right side.
                                                                </p>
                                                            </div>
                                                        }
                                                        {provided.placeholder}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="flex justify-end mt-5">
                                                <LoadingButton
                                                    onClick={() => handleSubmit()}
                                                    loading={loading}
                                                    disabled={loading}
                                                    loadingPosition="start"
                                                    startIcon={<SaveIcon />}
                                                    variant='contained'>
                                                    Save
                                                </LoadingButton>

                                            </div>
                                        </div>
                                    )}
                                </StrictModeDroppable>
                                <StrictModeDroppable droppableId="sectionContent" type="CONTENT">
                                    {(provided: any) => (
                                        <div className='pr-5'>
                                            <div className='w-full min-h-screen overflow-auto'>
                                                <div className='flex justify-between border items-center'>
                                                    <SearchBar
                                                        onChange={(e) => {
                                                            setSearch(e.target.value)
                                                            dispatch(setStatusContent("loading"))
                                                        }}
                                                        placeholder={'Search Content by name, description, tags, slug, etc...'}
                                                        className={'w-96 text-sm h-12'}
                                                        value={search}
                                                    />
                                                    <div className='mr-5'>
                                                        {status === 'loading' && <Loader smail />}
                                                    </div>
                                                </div>


                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {content && content.map((item, index) => (
                                                        <Draggable key={index} draggableId={item._id} index={index}>
                                                            {(provided) => (
                                                                <div className="w-full" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <div className='flex gap-4 my-2'>
                                                                        <img
                                                                            src={`${item.thumbnail}?w=248&fit=crop&auto=format`}
                                                                            srcSet={`${item.thumbnail}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                                            alt={item.name}
                                                                            loading="lazy"
                                                                            className='w-36 aspect-video rounded'
                                                                        />
                                                                        <div>
                                                                            <p className='line-clamp-1'>{item.name}</p>
                                                                            <p className='text-gray-500 line-clamp-2'>{
                                                                                item.description.length > 80 ? item.description.substring(0, 80) + '...' : item.description
                                                                            }</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </StrictModeDroppable>

                            </DragDropContext>
                        </div>
                    </div>
                }
            </div>
            <div>
                {
                    sliderisError && <div className='text-red-500'>{sliderisError}</div>
                }
            </div>
        </Layout>
    )
}

export default Sliders