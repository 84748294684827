// Author: Pukhraj Dhamu
// Created Date: Saturday 8 July 2023 at 11:03 AM
// Last Modified Date: Saturday 8 July 2023 at 11:03 AM
import { useState } from "react";
import Layout from "../../../components/Layout/Layout";
import { InputField } from "../../../tailwind";
import toast from "react-hot-toast";
import { Hr } from "../../../components";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { getSettingsApi, updateTranscodeSettingApi } from "../../../http/settingshttp";
import { Switch } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Loader } from "../../../components/smallComponents/loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface ITranscoderSettingState {
    id: string;
    hostname: string;
    username: string;
    password: string;
    provider: "zezo";
    status: boolean;
}

interface ITranscoderSettingPayload {
    hostname: string;
    username: string;
    password: string;
    provider: "zezo";
    status: boolean;
}

const TranscoderSettings = () => {
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [settings, setSettings] = useState<ITranscoderSettingState>({
        id: "",
        hostname: "",
        username: "",
        password: "",
        provider: 'zezo',
        status: false,
    });
    // useQuery for get transcoder settings
    const getTranscoderQuery = useQuery<{ data: ITranscoderSetting[] }>({
        queryKey: ["getTranscoderQuery"],
        queryFn: () => getSettingsApi("transcoder"),
        onSuccess: (data) => {
            if (data && data.data.length > 0) {
                setSettings({
                    id: data.data[0].id,
                    hostname: data.data[0].hostname,
                    username: data.data[0].username,
                    password: data.data[0].password,
                    provider: data.data[0].provider,
                    status: data.data[0].status,
                });
            }
        },
        onError: (error) => {
            toast.error("Failed to fetch transcoder settings")
        }
    });
    // useMutation for update transcoder settings
    const updateTranscoderMutation = useMutation({
        mutationKey: ["updateTranscoderMutation"],
        mutationFn: (data: ITranscoderSettingPayload) => updateTranscodeSettingApi(settings.id, data),
        onSuccess: (data) => {
            if (data.status === 200) {
                getTranscoderQuery.refetch();
                toast.success("Settings updated successfully");
            }
        },
        onError: (error) => {
            toast.error("Failed to update transcoder settings")
        }
    });
    // useMutation for update transcoder settings
    const triggerUpdateFunction = async () => {
        const { hostname, username, password, provider, status, id } = settings;
        if (!id) {
            toast.error("Transcoder id not found");
            return;
        }
        if (!hostname || !username || !password || !provider) {
            toast.error("Please fill all the fields");
            return;
        }
        updateTranscoderMutation.mutate({
            hostname,
            username,
            password,
            provider,
            status,
        });
    }

    return (
        <Layout>
            <div className="pr-6">
                <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                    Transcoder Configuration
                </h1>
                <p className="mt-2 opacity-70">
                    Edit the configuration of your transcoder.
                </p>
            </div>
            <div className="my-5 pr-6">
                <Hr />
            </div>
            <h6 className="opacity-70 pr-5">
                Update the configuration of your transcoder.
            </h6>
            <div className="mt-5 w-full">
                {
                    getTranscoderQuery.isLoading && <div className='w-full flex justify-center items-center min-h-[47rem]'>
                        <Loader smail />
                    </div>
                }
            </div>
            <div className="mt-5 w-full">
                {
                    getTranscoderQuery.isError && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            Server Error. Please try again
                        </h1>
                        <div
                            onClick={() => getTranscoderQuery.refetch()}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                            <h1 className='text-sm text-white'>
                                Retry
                            </h1>
                        </div>
                    </div>
                }
            </div>
            {getTranscoderQuery.isSuccess && <>
                <div className="my-5 flex flex-col gap-5 pr-6">
                    <InputField
                        title="Hostname"
                        fieldName="hostname"
                        type="text"
                        placeholder="Enter hostname"
                        value={settings.hostname}
                        onChange={(e) => setSettings({ ...settings, hostname: e.target.value })}
                    />
                    <InputField
                        title="Username"
                        fieldName="username"
                        type="text"
                        placeholder="Enter username"
                        value={settings.username}
                        onChange={(e) => setSettings({ ...settings, username: e.target.value })}
                    />
                    <InputField
                        title="Password"
                        fieldName="password"
                        type="text"
                        placeholder="Enter password"
                        value={settings.password}
                        onChange={(e) => setSettings({ ...settings, password: e.target.value })}
                    />
                    <InputField
                        title="Provider"
                        fieldName="provider"
                        type="text"
                        placeholder="Enter provider"
                        value={settings.provider}
                        onChange={(e) => setSettings({ ...settings, provider: e.target.value } as any)}
                    />
                    <div className="flex items-center gap-4">
                        <h6 className="opacity-70">Status</h6>
                        <Switch
                            checked={settings.status}
                            onChange={(e) => setSettings({ ...settings, status: e.target.checked })}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                </div>
                <div className="my-5 pr-6 flex flex-col gap-8">
                    <div className="flex justify-end">
                        <LoadingButton
                            loading={updateTranscoderMutation.isLoading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            onClick={triggerUpdateFunction}>
                            Save
                        </LoadingButton>
                    </div>
                </div>
            </>}
        </Layout>
    );
}

export default TranscoderSettings;