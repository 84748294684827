import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import toast from "react-hot-toast"
import { Menu } from "react-contexify";

export interface ISingleBreadItem {
    name: string,
    key: string
}

export interface ISingleFile {
    id: string;
    name: string;
    key: string;
    type: string;
    size: number;
}

interface IContextMenu {
    MENU_ID: string,
    singleFile: ISingleFile,
    setSingleFile: any,
    handleClickDetailsOpen: () => void,
    handleDeleteOpenDialog: () => void,
    setCurrDir: any,
    breadArr: ISingleBreadItem[],
    setBreadArr: any,
    setIsRename: any,
    setFolderName: any,
    handleClickCreateFolderOpen: any,
    checkFileExtension: any,
    copyFileUrl: (singleFile: ISingleFile) => void,
    downloadFile: (singleFile: ISingleFile) => void,
    startTranscode: (singleFile: ISingleFile) => void,
}

function ContextMenu({ MENU_ID, singleFile, setSingleFile, handleClickDetailsOpen, handleDeleteOpenDialog, setCurrDir, breadArr,
    setBreadArr,
    setIsRename,
    setFolderName,
    handleClickCreateFolderOpen,
    checkFileExtension,
    copyFileUrl,
    downloadFile,
    startTranscode
}: IContextMenu) {
    return (
        <>
            <Menu
                style={{
                    width: 250,
                    backgroundColor: "transparent",
                }}
                id={MENU_ID}>
                <Paper sx={{ width: 320, maxWidth: "100%" }}>
                    <MenuList>
                        <MenuItem
                            onClick={() => {
                                if (singleFile.type === "file") {
                                    handleClickDetailsOpen();
                                    setSingleFile(singleFile);
                                    return;
                                }
                                setCurrDir(singleFile.key);
                                breadArr.push({
                                    name: singleFile.name,
                                    key: singleFile.key,
                                });
                                setBreadArr([...breadArr]);
                            }}>
                            <ListItemIcon>
                                <FolderOpenIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Open</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleDeleteOpenDialog();
                            }}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setIsRename(true);
                                setFolderName(singleFile.name);
                                handleClickCreateFolderOpen();
                            }}>
                            <ListItemIcon>
                                <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Rename</ListItemText>
                        </MenuItem>
                        {checkFileExtension(singleFile, ["jpg", "jpeg", "png", "gif", "mp4", "mov"]) && (
                            <>
                                <MenuItem onClick={() => copyFileUrl(singleFile)}>
                                    <ListItemIcon>
                                        <LinkOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Copy URL <span className="text-xs">(Signed URL)</span>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => downloadFile(singleFile)}>
                                    <ListItemIcon>
                                        <FileDownloadOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Download</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    const CloudfrontUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}${singleFile?.key || ""}`;
                                    navigator.clipboard.writeText(CloudfrontUrl);
                                    toast.success("Link copied successfully");
                                }}>
                                    <ListItemIcon>
                                        <LinkOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Copy Link <span className="text-xs">(Cloudfront)</span>
                                    </ListItemText>
                                </MenuItem>
                            </>
                        )}
                        {checkFileExtension(singleFile, ["mp4"]) && (
                            <>
                                <MenuItem onClick={() => {
                                    startTranscode(singleFile)
                                }}>
                                    <ListItemIcon>
                                        <VideoSettingsIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Video Transcoding</ListItemText>
                                </MenuItem>
                            </>
                        )}
                        <MenuItem
                            onClick={() => {
                                handleClickDetailsOpen();
                            }}>
                            <ListItemIcon>
                                <InfoOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Details</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Menu>
        </>
    )
}

export default ContextMenu