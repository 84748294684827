import React, { useEffect } from 'react'
import { HiOutlinePlusSm } from 'react-icons/hi'
import { FaEdit } from 'react-icons/fa'
import { BiRefresh } from 'react-icons/bi'
import { SelectField } from '../../../tailwind'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useQuery } from '@tanstack/react-query'
import { categorySearch, getCategories, getSections, updateSectionOrder } from '../../../http/Apis'
import toast from 'react-hot-toast'
import { Loader } from '../../../components/smallComponents/loader'
import { SectionStatus, SectionStatusModal, SectionDelete, SectionDeleteModal } from './index'
import { Link } from 'react-router-dom'
import RouteStrings from "../../../localData/routes.json";
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { CustomLocalstorege } from '../../../localstorege'
import { getSectionApi, updateOrderSectionApi } from '../../../http/sections.http'
import qs from 'qs'
import { CgArrowsExchangeAltV } from 'react-icons/cg'

function SectionTable() {
    const [sectionData, setSectionData] = React.useState<IGetSectionData[]>([])
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const [selectedCategory, setSelectedCategory] = React.useState<IGetCategoriesData | undefined>(undefined)
    const queryString = qs.stringify({
        limit: 100,
        category: selectedCategory?.slug || 'none',
        populate: {
            category: {
                from: "categories",
                fields: ['name']
            }
        }
    })
    // fetch all sections from server useQuery
    const { data, isLoading, refetch, isFetching, isError, isRefetching } = useQuery<IGetSectionResponse>({
        queryKey: ['new_sections'],
        queryFn: () => getSectionApi(queryString),
    });

    // get categories from server
    const { data: categories, isLoading: categoryIsLoading, refetch: categoryRefetch } = useQuery<IGetCategoriesForAxios>({
        queryKey: ['categories'],
        queryFn: () => getCategories(),
    });

    // now update the index in database
    const updateSectionOrderFunc = async (newData: any) => {
        const formData = new FormData();
        for (let i = 0; i < newData.length; i++) {
            formData.append("id", newData[i]._id);
            // order must be string
            formData.append("order", i as any);
        }
        const res = await updateOrderSectionApi({ order: newData });
        if (res.status === 200) {
            refetch();
            toast.success("Order updated successfully");
        }
    };

    function handleOnDragEnd(result: any) {
        if (!result.destination) return;
        const items = Array.from(sectionData);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setSectionData(items);
        // get item index
        const newData = items.map((item, index) => {
            return {
                ...(item as any),
                index: index + 1,
            };
        });
        updateSectionOrderFunc(newData);
    }

    useEffect(() => {
        if (data) setSectionData(data.data);
    }, [data, isLoading]);

    useEffect(() => {
        refetch();
    }, [selectedCategory]);

    useEffect(() => {
        // get selected category from local storage
        const selectedCategoryKey = CustomLocalstorege.getItem("section_category_filter")
        if (selectedCategoryKey) setSelectedCategory(selectedCategoryKey)
        setTimeout(() => {
            refetch();
        }, 500);
    }, [])

    const onCategorySearch = async (search: string) => {
        const { data } = await categorySearch(search);
        return data.data;
    };

    const categoryPromiseOptions = (inputValue: string) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(onCategorySearch(inputValue));
            }, 1000);
        });

    return (
        <>
            <div className='terounded-xl overflow-hidden'>
                <div className='px-1 py-4 flex justify-between items-center'>
                    <h1 className='text-xl font-medium opacity-90'>
                        Sections
                    </h1>
                    <div className='flex gap-4 items-center'>
                        <div
                            onClick={() => {
                                setSelectedCategory(undefined)
                                categoryRefetch()
                                refetch()
                            }}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                            <BiRefresh className={`text-black text-lg bg-white rounded-full`} />
                            <h1 className='text-sm text-white'>
                                Refresh
                            </h1>
                        </div>
                        <Link to={`${RouteStrings.OldSections.Link}`}>
                            <div className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                                <CgArrowsExchangeAltV className='text-black text-lg bg-white rounded-full' />
                                <h1 className='text-sm text-white'>
                                    Old Sections
                                </h1>
                            </div>
                        </Link>
                        <Link to={`${RouteStrings.Sections.Children.CreateSection.Link}`}>
                            <div className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                                <HiOutlinePlusSm className='text-black text-lg bg-white rounded-full' />
                                <h1 className='text-sm text-white'>
                                    Add Section
                                </h1>
                            </div>
                        </Link>
                        <div className='w-56'>
                            <SelectField
                                defaultValue={selectedCategory || [] as any}
                                onChange={(e) => {
                                    // store selected category in local storage
                                    CustomLocalstorege.setItem("section_category_filter", e)
                                    setSelectedCategory(e)
                                }}
                                options={categories?.data.data}
                                promiseOptions={categoryPromiseOptions}
                                getOptionLabel={(option) => option.name}
                                placeholder='Filter by category'
                            />
                        </div>
                    </div>
                </div>
                <div className='w-full min-h-[35rem]'>
                    {
                        isLoading ? <div className='h-[47rem] justify-center items-center flex'>
                            <Loader smail />
                        </div> : <>
                            {
                                isError ? <div className='min-h-[35rem] justify-center flex flex-col items-center'>
                                    <h1 className='text-white text-xl font-medium opacity-90'>
                                        Server Error. Please try again
                                    </h1>
                                    <div
                                        onClick={() => refetch()}
                                        className='bg-zinc-500 w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4'>
                                        <h1 className='text-sm'>
                                            Retry
                                        </h1>
                                    </div>
                                </div> : <div className="overflow-auto min-h-[35rem]">
                                    <table
                                        style={{
                                            borderCollapse: "separate",
                                            borderSpacing: "0 10px"
                                        }}
                                        className="table w-full bg-transparent">
                                        <thead>
                                            <tr>
                                                <th className={`bg-transparent ${darkMode && "text-white"}`}>
                                                    Position
                                                </th>
                                                <th className={`bg-transparent ${darkMode && "text-white"}`}>Name</th>
                                                <th className={`bg-transparent ${darkMode && "text-white"}`}>
                                                    Label
                                                </th>
                                                <th className={`bg-transparent ${darkMode && "text-white"}`}>
                                                    Category
                                                </th>
                                                <th className={`bg-transparent ${darkMode && "text-white"}`}>Status</th>
                                                <th className={`bg-transparent ${darkMode && "text-white"}`}>
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>

                                        <DragDropContext onDragEnd={handleOnDragEnd}>
                                            <Droppable droppableId="sectionsdsjdhkshkj">
                                                {(provided: any) => (
                                                    <tbody
                                                        className='w-full'
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}>
                                                        {
                                                            isRefetching ? <tr>
                                                                <th className='bg-transparent'></th>
                                                                <td className='bg-transparent'></td>
                                                                <td className='bg-transparent'></td>
                                                                <td className='bg-transparent'>
                                                                    <div className='h-[35rem] justify-center flex'>
                                                                        <Loader />
                                                                    </div>
                                                                </td>
                                                                <td className='bg-transparent'></td>
                                                                <td className='bg-transparent'></td>
                                                            </tr> :
                                                                sectionData.length > 0 ? sectionData.map((item, index) => {
                                                                    return (
                                                                        <Draggable key={item._id} draggableId={item._id} index={index}>
                                                                            {(provided) => (
                                                                                <tr
                                                                                    className="rounded w-full"
                                                                                    key={index}
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}>
                                                                                    <th className={`${darkMode ? 'bg-dark-color-box' : 'bg-[#dedede]'} rounded-l border-none`}>{index + 1}</th>
                                                                                    <td className={`${darkMode ? 'bg-dark-color-box' : 'bg-[#dedede]'} border-none`}>{item.name}</td>
                                                                                    <td className={`${darkMode ? 'bg-dark-color-box' : 'bg-[#dedede]'} border-none`}>{item.label}</td>
                                                                                    <td className={`${darkMode ? 'bg-dark-color-box' : 'bg-[#dedede]'} border-none`}>{item.category?.name || "None"}</td>
                                                                                    <td className={`${darkMode ? 'bg-dark-color-box' : 'bg-[#dedede]'} border-none`}>
                                                                                        <SectionStatus data={item} />
                                                                                    </td>
                                                                                    <td className={`${darkMode ? 'bg-dark-color-box' : 'bg-[#dedede]'} rounded-r border-none`}>
                                                                                        <div className='flex gap-5 items-center'>
                                                                                            <SectionDelete data={item} />
                                                                                            <Link to={RouteStrings.Sections.Children.EditSection.dynamicLink + item._id}>
                                                                                                <FaEdit className='text-blue-500 text-xl cursor-pointer' />
                                                                                            </Link>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </Draggable>
                                                                    )
                                                                }) : <tr>
                                                                    <th className='bg-transparent'></th>
                                                                    <td className='bg-transparent'></td>
                                                                    <td className='bg-transparent'>
                                                                        <div className='h-[35rem] justify-center flex flex-col items-center'>
                                                                            <h1 className='text-white text-xl font-medium opacity-90'>
                                                                                No Sections Found
                                                                            </h1>
                                                                            <div
                                                                                onClick={() => refetch()}
                                                                                className='bg-zinc-500 w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4'>
                                                                                <h1 className='text-sm'>
                                                                                    Retry
                                                                                </h1>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className='bg-transparent'></td>
                                                                    <td className='bg-transparent'></td>
                                                                </tr>
                                                        }
                                                        {provided.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </table>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>

            <SectionStatusModal />
            <SectionDeleteModal />
        </>

    )
}

export default SectionTable