// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import { UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react'
import { InputField } from '../../../tailwind';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { changePassword } from '../../../http/Apis';

interface IProps {
    id: string;
    user: IUsersResponse['data'][0]
    userQuery: UseQueryResult
}

function UserPasswordChange({ id, user, userQuery }: IProps): JSX.Element {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const handleChangedPassword = async () => {
        try {
            if (newPassword.length < 5 || currentPassword.length < 5) {
                return toast.error("password must be greater than 6 character");
            }

            if (confirmPassword !== newPassword) {
                return toast.error("new password and confirm password must be same");
            }

            let userObj = {
                currentPassword,
                newPassword,
            };

            let res = await changePassword(userObj);
            //   console.log(res);

            if (res.status === 200) {
                toast.success(res.data.message);
                navigate("/admin/dashboard");
                setNewPassword("");
                setCurrentPassword("");
                setConfirmPassword("");
            }
        } catch (error: any) {
            if (error.response.status) {
                return toast.error(error.response.data.message);
            }
            return console.log(error);
        }
    };

    return (
        <div>
            <div className="mt-5">
                <div className="mx-2 my-2">
                    <InputField
                        fieldName="current password"
                        type={show ? "text" : "password"}
                        placeholder="Enter Your Currnent Password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                </div>
                <div className="mx-2 my-2">
                    <InputField
                        fieldName="new password"
                        type={show ? "text" : "password"}
                        placeholder="Enter Your New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <div className="mx-2 my-2">
                    <InputField
                        fieldName="confirm password"
                        type={show ? "text" : "password"}
                        placeholder="Confirm Your Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <div className="w-full flex mx-2 my-2 justify-end items-center">
                    <div className="flex items-center mx-2">
                        <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onChange={() => setShow(!show)}
                        />
                        <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Show Password
                        </label>
                    </div>
                    <button
                        type="button"
                        className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2  dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        onClick={handleChangedPassword}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UserPasswordChange