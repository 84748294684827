import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IThemeInitialState {
    darkMode: boolean
}

// initial state
const initialState: IThemeInitialState = {
    darkMode: false
}

// slice
export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleTheme: (state, action: PayloadAction<IThemeInitialState['darkMode']>) => {
            state.darkMode = action.payload
        }
    }
})

// actions
export const { toggleTheme } = themeSlice.actions
// reducer
export default themeSlice.reducer

