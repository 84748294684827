import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getSection, getSections, sectionReport, sectionSearch } from '../../http/Apis'
import { ISectionDataType } from '../../Pages/Sections/AddNewSection'


export interface ISecctionInitialState {
    data: {
        _id: string,
        title: string,
        contant: [],
        isPublished: boolean,
        createdAt: string,
        updatedAt: string,
        order: number
    }[],
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    bulkAction: string,
    bulkActionCall: boolean,
    single: {
        _id: string,
        title: string,
        type: ISectionDataType
        category: string,
        content: {
            _id: string,
            name: string,
            description: string,
            thumbnail: string,
            poster: string,
        }[],
        isPublished: boolean,
        createdAt: string,
        updatedAt: string,
        order: number
    },
    report: {
        total: number,
        Active: number,
        Deactivate: number,
    },
    activeSection: string | undefined | "home" | "none";
    selectedSection: string[];
    selectedSectionStatus: string | boolean;
}

const initialState: ISecctionInitialState = {
    data: [],
    status: 'idle',
    bulkAction: 'none',
    bulkActionCall: false,
    single: {
        _id: '',
        title: '',
        type: {} as ISectionDataType,
        category: '',
        content: [],
        isPublished: false,
        createdAt: '',
        updatedAt: '',
        order: 0
    },
    report: {
        total: 0,
        Active: 0,
        Deactivate: 0,
    },
    activeSection: "none",
    selectedSection: [],
    selectedSectionStatus: false
}

export const sectionSlice = createSlice({
    name: 'section',
    initialState,
    reducers: {
        setSections: (state, actions) => {
            const { data } = actions.payload
            state.data = data
        },
        setStatus: (state, actions: PayloadAction<ISecctionInitialState['status']>) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingle: (state, actions) => {
            const { data } = actions.payload
            state.single = data
        },
        setReport: (state, actions) => {
            const { data } = actions.payload
            state.report = data
        },
        setActiveSection: (state, actions: PayloadAction<ISecctionInitialState['activeSection']>) => {
            state.activeSection = actions.payload
            console.log("🚀 ~ file: sectionSlice.ts:96 ~ actions.payload", actions.payload)
        },
        setSelectedSection: (state, actions: PayloadAction<ISecctionInitialState['selectedSection']>) => {
            state.selectedSection = actions.payload
        },
        setSelectedSectionStatus: (state, actions: PayloadAction<ISecctionInitialState['selectedSectionStatus']>) => {
            state.selectedSectionStatus = actions.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setSections,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingle,
    setReport,
    setActiveSection,
    setSelectedSection,
    setSelectedSectionStatus
} = sectionSlice.actions

export default sectionSlice.reducer

// Thunks
export function fetchSections(query?: string) {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const res = await getSections(query || '');
            dispatch(setSections(res.data))
            dispatch(setStatus('succeeded'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}
// fetch Reports
export function fetchSectionReport() {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const res = await sectionReport();
            dispatch(setReport(res.data))
            dispatch(setStatus('succeeded'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

export function fetchSearchSection(search: string) {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const res = await sectionSearch(search);
            dispatch(setSections(res.data))
            dispatch(setStatus('succeeded'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

export function fetchSection(id: string) {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const res = await getSection(id);
            dispatch(setSingle(res.data))
            dispatch(setStatus('succeeded'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

