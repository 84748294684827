// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { InputField, SelectField } from "../../tailwind";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import qs from "qs";
import { setBulkAction, setBulkActionCall } from "../../redux/slice​/userSlice";
import { RootState } from "../../redux/store";
import { useQueryParamsForPaginationNew } from "../../hooks/useQueryParamsForPaginationNew";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createUser, getUsers } from "../../http/users.http";
import { BiRefresh } from 'react-icons/bi'
import { HiOutlinePlusSm } from 'react-icons/hi'
import { Loader } from "../../components/smallComponents/loader";
import { counter } from "../../utils/Utils";
import UsersTable from "../../tailwind/Table/users";

const UsersPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const [search, setSearch] = useState<string>();
  const [filters, setFilters] = useState<string[]>([]);
  const [createUserData, setCreateUserData] = useState<IUserCreate>({} as IUserCreate);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const queryParams = useQueryParamsForPaginationNew()
  const [value] = useDebounce(search, 1000);
  const queryString: string = qs.stringify({
    ...queryParams.query,
    ...filters,
    fields: ["name", "email", "phone", "avatar", "isPrimium", "isActivated", "trash"],
    populate: ["subscriptionId"],
  }, { encodeValuesOnly: true });

  // fetch users query
  const usersQuery = useQuery<IUsersResponse>({
    queryKey: ["users-list", queryString],
    queryFn: () => getUsers(queryString),
    enabled: queryParams.query.page !== undefined || queryParams.query.limit !== undefined || queryParams.query.order !== undefined,
  })

  useEffect(() => {
    if (value) queryParams.setQuery && queryParams.setQuery({ ...queryParams.query, q: value });
    if (!value) {
      const valueData = JSON.parse(JSON.stringify(queryParams.query));
      delete valueData.q;
      queryParams.setQuery && queryParams.setQuery({ ...valueData })
    }
    usersQuery.refetch();
  }, [value]);

  useEffect(() => {
    usersQuery.refetch();
    // set max limit
    if (queryParams.query.limit > 100) {
      queryParams.setQuery && queryParams.setQuery({ ...queryParams.query, limit: 100 });
    }
  }, [queryParams.query]);

  const headlePrev = () => {
    if (queryParams.query.page > 1) {
      queryParams.setQuery && queryParams.setQuery({
        ...queryParams.query,
        page: queryParams.query.page - 1,
      });
    } else {
      toast.error("You are on first page");
    }
  };

  const handleNext = () => {
    if (!usersQuery.data?.meta.pagination.page) return
    if (queryParams.query.page < usersQuery.data.meta.pagination.pageCount) {
      queryParams.setQuery && queryParams.setQuery({
        ...queryParams.query,
        page: queryParams.query.page + 1,
      });
    } else {
      toast.error("No more pages");
    }
  };

  const headleFirst = () => {
    if (queryParams.query.page === 1) {
      toast.error("You are on first page");
    } else {
      queryParams.setQuery && queryParams.setQuery({
        ...queryParams.query,
        page: 1,
      });
    }
  }

  const headleLast = () => {
    if (!usersQuery.data?.meta.pagination.pageCount) return
    const lastPage = usersQuery.data?.meta.pagination.pageCount
    if (queryParams.query.page === lastPage) {
      toast.error("You are on last page");
    } else {
      queryParams.setQuery && queryParams.setQuery({
        ...queryParams.query,
        page: lastPage
      });
    }
  }

  // create user mutation
  const createUserMutation = useMutation({
    mutationKey: ["create-user"],
    mutationFn: (data: IUserCreate) => createUser(data),
    onSuccess: () => {
      setCreateUserData({
        name: "",
        email: "",
        password: "",
        role: "user"
      });
      toast.success("User created successfully");
      usersQuery.refetch();
      document.getElementById("create_user_close")?.click();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
  })

  // trigger create user mutation
  const handleCreateUser = () => {
    if (!createUserData.name) return toast.error("Name is required");
    if (!createUserData.email) return toast.error("Email is required");
    if (!createUserData.password) return toast.error("Password is required");
    if (!createUserData.role) return toast.error("Role is required");
    createUserMutation.mutate(createUserData);
  }


  return (
    <Layout>
      <div className="flex justify-between items-center pr-6">
        <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
          Users
        </h1>
        <div className='flex gap-4 items-center'>
          <div
            onClick={() => { usersQuery.refetch() }}
            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
            <BiRefresh className={`text-black text-lg bg-white rounded-full ${usersQuery.isLoading || usersQuery.isFetching && "animate-spin"}`} />
            <h1 className='text-sm text-white select-none'>
              Refresh
            </h1>
          </div>
          <div
            onClick={() => window.create_user_modal.showModal()}
            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
            <HiOutlinePlusSm className='text-black text-lg bg-white rounded-full' />
            <h1 className='text-sm text-white'>
              Create
            </h1>
          </div>
          <div className='w-32'>
            <SelectField
              onChange={(e) => setFilters(e.value)}
              options={[
                {
                  value: '', label: "None"
                },
                {
                  value: {
                    filters: {
                      role: {
                        $eq: "admin"
                      }
                    }
                  }, label: "Admin"
                },
                {
                  value: {
                    filters: {
                      role: {
                        $eq: "partner"
                      }
                    }
                  }, label: "Partner"
                },
                {
                  value: {
                    filters: {
                      role: {
                        $eq: "user"
                      }
                    }
                  }, label: "User"
                },
                {
                  value: {
                    filters: {
                      'isPrimium.status': {
                        $eq: true
                      }
                    }
                  }, label: "Primium"
                },
                {
                  value: {
                    filters: {
                      'isPrimium.status': {
                        $eq: false
                      }
                    }
                  }, label: "Free"
                },
              ]}
              getOptionLabel={(option) => option.label}
              placeholder='Filters'
            />
          </div>
          <div className='w-48'>
            <SelectField
              onChange={(e) => queryParams.setQuery && queryParams.setQuery({ ...queryParams.query, limit: e.value })}
              options={[
                { value: 25, label: "Limit 25" },
                { value: 50, label: "Limit 50" },
                { value: 75, label: "Limit 75" },
                { value: 100, label: "Limit 100" }
              ]}
              getOptionLabel={(option) => option.label}
              placeholder='Number of Users'
            />
          </div>
          <div className='w-44'>
            <SelectField
              onChange={(e) => {
                dispatch(setBulkAction(e.value))
              }}
              options={[
                { value: "none", label: "Bulk Actions" },
                { value: "activate", label: "Active" },
                { value: "deactivate", label: "Deactive" },
                { value: "restore", label: "Restore" },
                { value: "trash", label: "Move to Trash" },
                { value: "delete", label: "Delete Forever" },
              ]}
              getOptionLabel={(option) => option.label}
              placeholder='Bulk Actions'
            />
          </div>
          <div
            onClick={() => dispatch(setBulkActionCall(true))}
            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
            <BiRefresh className={`text-black text-lg bg-white rounded-full ${usersQuery.isLoading || usersQuery.isFetching && "animate-spin"}`} />
            <h1 className='text-sm text-white select-none'>
              Apply
            </h1>
          </div>
          <input
            type="text"
            placeholder="Search Content by name, tags, slug, etc..."
            className={`input input-bordered input-md w-72 bg-transparent focus:outline-none h-[38px] rounded ${darkMode ? "border-gray-700" : "border-gray-300"}`}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>
      <div className="mt-5 w-full">
        {
          usersQuery.isLoading && <div className='w-full flex justify-center items-center min-h-[47rem]'>
            <Loader smail />
          </div>
        }
      </div>
      <div className="mt-5 w-full">
        {
          usersQuery.isError && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
            <h1 className='text-xl font-medium opacity-90'>
              Server Error. Please try again
            </h1>
            <div
              onClick={() => usersQuery.refetch()}
              className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
              <h1 className='text-sm text-white'>
                Retry
              </h1>
            </div>
          </div>
        }
      </div>
      <div className="mt-5 w-full">
        {usersQuery.isSuccess ? usersQuery.data?.data?.length > 0 ? <>
          <UsersTable usersQuery={usersQuery} />
          <div className="flex justify-between items-center my-5 pr-6 mb-8">
            <div className="flex gap-8 items-center">
              <div className="flex gap-3.5">
                <p>Total</p>
                <p>{counter(usersQuery.data.meta.pagination.total || 0)}</p>
              </div>
              <div className="flex gap-3.5">
                <p>Fetched</p>
                <p>{counter(usersQuery?.data?.meta?.pagination?.fetched || 0)}</p>
              </div>
              <div className="flex gap-3.5">
                <p>Pages</p>
                <p>{counter(usersQuery.data.meta.pagination.pageCount || 0)}</p>
              </div>
            </div>
            <div>
              <div className="flex gap-2.5 mr-6">
                <div
                  onClick={headleFirst}
                  className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 py-2 flex gap-2 items-center cursor-pointer`}>
                  <h1 className='text-base text-white select-none'>
                    First
                  </h1>
                </div>
                <div
                  onClick={headlePrev}
                  className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 py-2 flex gap-2 items-center cursor-pointer`}>
                  <h1 className='text-base text-white select-none'>
                    Previous
                  </h1>
                </div>
                <div className={`w-28 justify-center rounded px-5 flex gap-2 items-center cursor-pointer`}>
                  <h1 className='text-base select-none'>
                    Page {usersQuery.data.meta.pagination.page || "N/A"}
                  </h1>
                </div>
                <div
                  onClick={handleNext}
                  className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 flex gap-2 items-center cursor-pointer`}>
                  <h1 className='text-base text-white select-none'>
                    Next
                  </h1>
                </div>
                <div
                  onClick={headleLast}
                  className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 py-2 flex gap-2 items-center cursor-pointer`}>
                  <h1 className='text-base text-white select-none'>
                    Last
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </> : <>
          <div className='min-h-[47rem] justify-center flex flex-col items-center'>
            <h1 className='text-xl font-medium opacity-90'>
              No users Found
            </h1>
            <div
              onClick={() => usersQuery.refetch()}
              className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
              <h1 className='text-sm text-white'>
                Retry
              </h1>
            </div>
          </div>
        </> : null}
      </div>

      <dialog id="create_user_modal" className="modal">
        <form method="dialog" className="modal-box">
          <h3 className="font-bold text-lg">Create User</h3>
          <div className="flex flex-col gap-2.5 my-3.5">
            <InputField
              fieldName="name"
              value={createUserData.name}
              label="Name"
              placeholder="Enter Name"
              type="text"
              required
              onChange={(e) => setCreateUserData({ ...createUserData, name: e.target.value })}
            />
            <InputField
              fieldName="email"
              value={createUserData.email}
              label="Email"
              placeholder="Enter Email"
              type="email"
              required
              onChange={(e) => setCreateUserData({ ...createUserData, email: e.target.value })}
            />
            <SelectField
              onChange={(e) => setCreateUserData({ ...createUserData, role: e.value })}
              options={[
                { value: "partner", label: "Partner" },
                { value: "user", label: "User" },
              ]}
              getOptionLabel={(option) => option.label}
              placeholder='Select Role'
            />
            <InputField
              fieldName="password"
              value={createUserData.password}
              label="Password"
              inputType="password"
              placeholder="Enter Password"
              type={showPassword ? "text" : "password"}
              onPasswordVisibilityClick={() => setShowPassword(!showPassword)}
              onChange={(e) => setCreateUserData({ ...createUserData, password: e.target.value })}
              required
            />

          </div>
          <div className="modal-action">
            {/* if there is a button in form, it will close the modal */}
            <button id="create_user_close" className="btn">Close</button>
            <div
              onClick={() => createUserMutation.isLoading ? {} : handleCreateUser()}
              className="btn">
              {createUserMutation.isLoading ? "Creating..." : "Create"}
            </div>
          </div>
        </form>
      </dialog>
    </Layout>
  );
};

export default UsersPage;
