// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import { useSelector } from "react-redux"
import _ from "lodash";
import Chart from 'react-apexcharts'
import moment from "moment";
import { TailwindBox } from "../../../../../tailwind";
import { useQuery } from "@tanstack/react-query";
import { userAnalytics } from "../../../../../http/Apis";
import { RootState } from "../../../../../redux/store";
import { Loader } from "../../../../../components/smallComponents/loader";
import { BiRefresh } from 'react-icons/bi'
import { counter } from "../../../../../utils/Utils";

export interface IUserAnalytics {
    message: string
    data: {
        totalUsers: number
        users: any[]
        subscriber: number
    }
}

const UserAnalytics = () => {
    const { darkMode } = useSelector((state: RootState) => state.theme)

    const UserAnalyticsQuery = useQuery<{ data: IUserAnalytics }>({
        queryKey: ['UserAnalyticsQuery'],
        queryFn: () => userAnalytics()
    })
    // userAnalyticsData have users array and users array have users.count 
    const totalUsersInLastMonth = UserAnalyticsQuery.data?.data.data?.users && _.reduce(UserAnalyticsQuery.data?.data.data?.users, (sum, user) => sum + user.count, 0);


    return (
        <TailwindBox title="User Analytics">
            {
                UserAnalyticsQuery.isLoading && <>
                    <p className="text-xs opacity-80 mt-2">
                        Last 30 days:
                    </p>
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full -mt-4">
                            <div className="aspect-video w-full overflow-hidden rounded my-4 relative">
                                <div className="flex justify-center items-center h-full">
                                    <Loader smail />
                                </div>
                            </div>
                            <div className="">
                                <div className="flex justify-between items-center mt-5 text-sm">
                                    <h6>
                                        Total Users
                                    </h6>
                                    <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
                                </div>
                                <div className="flex justify-between items-center mt-4 text-sm">
                                    <h6>
                                        Total Users in last 30 days
                                    </h6>
                                    <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                UserAnalyticsQuery.isError && <div>
                    <div className="w-full h-full flex justify-center items-center mb-3">
                        <div className='min-h-[15rem] justify-center flex flex-col items-center'>
                            <h1 className='text-sm font-medium opacity-90'>
                                Server Error. Please try again
                            </h1>
                            <div
                                onClick={() => UserAnalyticsQuery.refetch()}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                <BiRefresh className={`text-black text-lg bg-white rounded-full ${UserAnalyticsQuery.isLoading || UserAnalyticsQuery.isFetching && "animate-spin"}`} />
                                <h1 className='text-sm text-white'>
                                    Refresh
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                UserAnalyticsQuery.isSuccess && <>
                    <p className="text-xs opacity-80 mt-2">
                        Last 30 days:
                    </p>
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full -mt-4">
                            <div className="text-black">
                                {
                                    UserAnalyticsQuery.data?.data.data?.users.length === 0 ? <div className="flex justify-center items-center h-full">
                                        <div className="aspect-video w-full overflow-hidden rounded my-4 relative">
                                            <div className="flex justify-center items-center h-full">
                                                <p className={`${darkMode ? "text-white" : "text-black"}`}>
                                                    No data found
                                                </p>
                                            </div>
                                        </div>
                                    </div> : <Chart
                                        options={{
                                            // hide top and right border
                                            chart: {
                                                toolbar: {
                                                    show: false,
                                                },
                                                sparkline: {
                                                    enabled: true,
                                                },
                                            },
                                            stroke: {
                                                curve: 'smooth'
                                            },
                                            xaxis: {
                                                categories: UserAnalyticsQuery.data?.data.data?.users.map(user => moment
                                                    (user._id).format("DD MMM")),
                                            }
                                        }}
                                        series={[{
                                            name: 'total users',
                                            data: UserAnalyticsQuery.data?.data.data?.users.map(user => user.count)
                                        }]}
                                        type="area"
                                        width={'100%'}
                                    />
                                }

                            </div>
                            <div className="">
                                <div className="flex justify-between items-center mt-5 text-sm">
                                    <h6>
                                        Total Users
                                    </h6>
                                    <p>
                                        {
                                            counter(UserAnalyticsQuery.data?.data.data?.totalUsers) || 0
                                        }
                                    </p>
                                </div>
                                <div className="flex justify-between items-center mt-4 text-sm">
                                    <h6>
                                        Total Users in last 30 days
                                    </h6>
                                    <p>
                                        {
                                            counter(totalUsersInLastMonth || 0)
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </TailwindBox>
    )
}

export default UserAnalytics