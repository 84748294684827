import React from 'react'
import { toggleTheme } from '../../redux/slice​/themeSlice'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { IoMdSearch } from 'react-icons/io';
import CustomizedInputBase from '../Mui/CustomizedInputBase/CustomizedInputBase';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { handleFullScreen } from '../../redux/slice​/toolSlice';
import { Profile } from '../../tailwind';
import CustomizedSwitches from '../Mui/Switch/MuiSwitch';
import { setBeta } from '../../redux/slice​/betaSlice';
import OnlineUser from '../smallComponents/onlineUser';

const Header = () => {
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const { isBeta } = useSelector((state: RootState) => state.beta);
  const { isFullScreen } = useSelector((state: RootState) => state.tool);

  function onFullScreen() {
    // if user exit full screen escape key then we need to update the state
    if (document.fullscreenElement === null) {
      document.body.requestFullscreen();
      return;

    }
    document.exitFullscreen();
  }

  return (
    <div className='px-5 py-4 flex justify-between'>
      <div>
        <CustomizedInputBase />
      </div>
      <div className='flex gap-1 items-center'>
        <OnlineUser />
        <IconButton sx={{ ml: 1 }} color="inherit" onClick={onFullScreen}>
          {isFullScreen ? <FullscreenExitIcon sx={{ fontSize: 30 }} /> : <FullscreenIcon sx={{ fontSize: 30 }} />}
        </IconButton>
        <IconButton sx={{ ml: 1 }} onClick={() => {
          dispatch(toggleTheme(!darkMode))
          const theme = !darkMode;
          window.localStorage.setItem("theme", theme + "");
        }} color="inherit">
          {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        <div className='ml-2.5'>
          <Profile />
        </div>
      </div>
    </div>
  )
}

export default Header