import React from 'react'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { sectionStatus } from '../../../http/Apis';
import { RootState } from '../../../redux/store';
import { Loader } from '../../../components/smallComponents/loader';
import { useQueryClient } from '@tanstack/react-query'

const SectionStatusModal = () => {
    const { selectedSectionStatus, selectedSection } = useSelector((state: RootState) => state.section);
    const [loading, setLoading] = React.useState<boolean>(false);
    const queryClient = useQueryClient();
    // handleStatus
    const handleStatus = async () => {
        const data = {
            isPublished: selectedSectionStatus,
            id: selectedSection,
        };
        try {
            setLoading(true);
            const response = await sectionStatus(data);
            if (response.status === 200) {
                // now refecth the data
                queryClient.invalidateQueries(["sections"]);
                setLoading(false);
                toast.success("Status updated successfully");
                // click sectionStatusModal id to close modal
                document.getElementById("sectionStatusModal")?.click();
            }
        } catch (error) {
            setLoading(false);
            toast.error("Status update failed");
        }
    };
    return (
        <div>
            <input type="checkbox" id="sectionStatusModal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box text-black">
                    <h3 className="font-bold text-lg">
                        Section Status
                    </h3>
                    <p className="py-4">
                        Are you sure? you want to change the status of this section?
                    </p>
                    <div className="modal-action">
                        <label onClick={handleStatus} className="btn">
                            {loading ? <Loader smail /> : "Yes"}
                        </label>
                        <label htmlFor="sectionStatusModal" className="btn">
                            Cancel
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionStatusModal