// Author: Pukhraj Dhamu
// Date Created: Thuesday 13 Jul 2023 at 12:24 PM
// Date Modified: Thuesday 13 Jul 2023 at 12:24 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const AuthProvider = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
    }
});

// list all endpoints
const initiate = () => AuthProvider.get('/service/auth-provider/initiate');
const getAuthMethods = (queryString?: string): Promise<IAuthProviderGetAuthMethodsResponse> => AuthProvider.get(`/service/auth-provider?${queryString || ''}`).then(res => res.data);
const updateAuthMethods = (data: IAuthProviderUpdatePayload) => AuthProvider.put('/service/auth-provider', data);


AuthProvider.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

const authProviderHttp = {
    initiate,
    getAuthMethods,
    updateAuthMethods,
}

export default authProviderHttp;

export interface IAuthProviderUpdatePayload {
    status: boolean;
    methods: Array<"email" | "phone" | "qrcode">
}

export interface IAuthProviderGetAuthMethodsResponse {
    success: boolean;
    message: string;
    data: {
        _id: string;
        methods_enabled: {
            email: boolean;
            phone: boolean;
            qrcode: boolean;
        },
        methods: Array<"email" | "phone" | "qrcode">;
        createdAt: string;
        updatedAt: string;
    };
}