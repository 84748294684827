import React from 'react'
import toast from 'react-hot-toast';
import { sectionStatus } from '../../../http/Apis';
import { useDispatch } from 'react-redux';
import { setSelectedSection, setSelectedSectionStatus } from '../../../redux/slice​/sectionSlice';

interface ISectionsStatusProps {
    data: IGetSectionData
}

function SectionsStatus({ data }: ISectionsStatusProps): JSX.Element {
    const dispatch = useDispatch();
    return <label
        htmlFor="sectionStatusModal"
        onClick={() => {
            dispatch(setSelectedSection([data._id]))
            dispatch(setSelectedSectionStatus(!data.status))
        }}>
        <div className={`flex gap-2 items-center ${data.status ? "bg-green-500" : "bg-red-500"} bg-opacity-20 w-fit px-2 py-0.5 rounded-full cursor-pointer`}>
            <div className={`w-2 h-2 rounded-full ${data.status ? "bg-green-600" : "bg-red-600"}`}></div>
            <p className={`text-sm ${data.status ? "text-green-600" : "text-red-600"}`}>
                {data.status ? 'Active' : 'Inactive'}
            </p>
        </div>
    </label>
}

export default SectionsStatus