// Author: Pukhraj Dhamu
// Date Created: 24 June 2022 (Friday)
// Date Modified: Sunday 14 May 2023 at 7:26 PM
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react'
import { InputField, SelectField } from '../../../../tailwind'
import SaveIcon from "@mui/icons-material/Save";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/store';
import toast from 'react-hot-toast';
import { updateBuyOrRentSettings } from '../../../../http/Apis';
import { fetchAllContant } from '../../../../redux/slice​/contentSlice';

interface IBuyOrRentProps {
    content_id: string | undefined
}

const contentRentType = [
    { name: "Buy", _id: "BUY" },
    { name: "Rent", _id: "RENT" },
];

const BuyOrRent = ({ content_id }: IBuyOrRentProps): JSX.Element => {
    const dispatch = useDispatch();
    const { single } = useSelector((state: RootState) => state.content)
    const [loading, setLoading] = useState(false)
    const [buyOrRentData, setBuyOrRentData] = useState<{
        price: number | string | undefined,
        rent_duration: number | string | undefined,
        is_buy_or_rent: any,
    }>({
        price: undefined,
        rent_duration: undefined,
        is_buy_or_rent: ""
    })

    useEffect(() => {
        if (content_id || single) {
            setBuyOrRentData({
                price: single.price,
                rent_duration: single.rent_duration,
                is_buy_or_rent: contentRentType.find(r => r._id === single?.is_buy_or_rent) 
            })
        }
    }, [single])

    // save buy or rent settings
    const saveAndUpdateBuyAndRentSettings = async () => {
        try {
            // price must be number
            if(!buyOrRentData?.price) return toast.error("Price invaild")
            if (isNaN(buyOrRentData?.price as number)) return toast.error("Price must be a number")
            const formData: any = {
                price: parseInt(buyOrRentData?.price as string),
                is_buy_or_rent: buyOrRentData?.is_buy_or_rent?._id
            }
            if(buyOrRentData.is_buy_or_rent._id === "RENT"){
                if (isNaN(buyOrRentData?.price as number) || isNaN(buyOrRentData?.rent_duration as number)) return toast.error("Price or Rent Duration must be a number")
                formData.rent_duration = parseInt(buyOrRentData?.rent_duration as string)
            }
            setLoading(true)
            const { data, status } = await updateBuyOrRentSettings(content_id, formData)
            setLoading(false)
            if (status === 200) {
                toast.success(data.message)
                dispatch(fetchAllContant(`id=${content_id}`, "single") as any);
            } else {
                toast.error(data.message)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.response?.data?.error?.message || "Something went wrong");
        }
    }
    return (
        <div className="pr-8">
            <div className='text my-3'>
                Menage Buy or Rent Settings
            </div>
            <div className='flex flex-col gap-2'>
                <InputField
                    fieldName='price'
                    placeholder='Rent Price'
                    title='Price'
                    onChange={(e) => setBuyOrRentData({ ...buyOrRentData, price: e.target.value })}
                    value={buyOrRentData?.price}
                    type="number"
                />

                {
                    buyOrRentData?.is_buy_or_rent?._id === "RENT" &&
                    <InputField
                        fieldName='rent-duration'
                        placeholder='Type rent duration in days'
                        title='Rent Duration'
                        onChange={(e) => setBuyOrRentData({ ...buyOrRentData, rent_duration: e.target.value })}
                        value={buyOrRentData.rent_duration}
                        type="number"
                    />
                }

                <SelectField

                    options={contentRentType}
                    onChange={(e) => setBuyOrRentData({ ...buyOrRentData, is_buy_or_rent: e })}
                    defaultValue={buyOrRentData?.is_buy_or_rent}
                    //onBlur={handleBlur}
                    placeholder={"Select Type"}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) =>
                        option.id ? option.id : option._id
                    }
                />

                <div className="flex justify-end my-2">
                    <LoadingButton
                        onClick={saveAndUpdateBuyAndRentSettings}
                        loading={loading}
                        disabled={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                    >
                        Save
                    </LoadingButton>
                </div>
            </div>
        </div>
    )
}

export default BuyOrRent