// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import Layout from "../../components/Layout/Layout";
import { TailwindBox, Title } from "../../tailwind";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { RootState } from "../../redux/store";
import { UserAvatar, UserHistory, UserInfo, UserPasswordChange, UserPermisions, UserSessions, UserSubscriptions } from "./components";
import UserTvodContent from "./components/UserPurchase";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { getUsers } from "../../http/users.http";
import { Loader } from "../../components/smallComponents/loader";
import { Tab, Tabs } from "@mui/material";
interface IUserTabs {
  label: string;
  value: "user_info" | "subscription" | "sessions" | "purchase" | "history" | "permissions" | "change_password";
}

const userTabs: IUserTabs[] = [
  {
    label: "User Info",
    value: "user_info"
  },
  {
    label: "Subscription",
    value: "subscription"
  },
  {
    label: "sessions",
    value: "sessions"
  },
  {
    label: "Purchase",
    value: "purchase"
  },
  {
    label: "History",
    value: "history"
  },
  {
    label: "Permissions",
    value: "permissions"
  },
  {
    label: "Change Password",
    value: "change_password"
  },
]

const EditUser = () => {
  const { id } = useParams();
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const queryString = qs.stringify({ id: id, populate: ["subscriptionId"], }, { encodeValuesOnly: true });

  const userQuery = useQuery<IUsersResponse>({
    queryKey: ["user_data", queryString],
    queryFn: () => getUsers(queryString),
  })

  const singleUser: IUsersResponse['data'][0] | null = userQuery.data?.data && userQuery.data?.data.length > 0 ? userQuery.data?.data[0] : null;
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

  const CustomTabPanel = (value: string) => {
    switch (value) {
      case 'user_info':
        return <UserInfo id={id as string} user={singleUser as any} userQuery={userQuery} />;
      case 'subscription':
        return <UserSubscriptions user={singleUser as any} id={id as string} userQuery={userQuery} />
      case 'sessions':
        return <UserSessions user={singleUser as any} id={id as string} userQuery={userQuery} />
      case 'purchase':
        return <UserTvodContent user={singleUser as any} id={id as string} userQuery={userQuery} />
      case 'history':
        return <UserHistory user={singleUser as any} id={id as string} userQuery={userQuery} />
      case 'permissions':
        return <UserPermisions user={singleUser as any} id={id as string} userQuery={userQuery} />
      case 'change_password':
        return <UserPasswordChange user={singleUser as any} id={id as string} userQuery={userQuery} />
    }
  }

  return (
    <Layout>
      <div className="mt-5 w-full">
        {
          userQuery.isLoading && <div className='w-full flex justify-center items-center min-h-[47rem]'>
            <Loader smail />
          </div>
        }
      </div>
      <div className="mt-5 w-full">
        {
          userQuery.isError && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
            <h1 className='text-xl font-medium opacity-90'>
              Server Error. Please try again
            </h1>
            <div
              onClick={() => userQuery.refetch()}
              className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
              <h1 className='text-sm text-white'>
                Retry
              </h1>
            </div>
          </div>
        }
      </div>
      <div className="pr-6">
        {userQuery.isSuccess ? singleUser ? <>
          <Title className="text-xl mb-4 font-semibold opacity-80">
            User Profile
          </Title>
          <div>
            <div className="flex gap-5">
              <TailwindBox>
                <UserAvatar id={id as string} user={singleUser as any} userQuery={userQuery} />
              </TailwindBox>
              <div className="overflow-hidden rounded w-full">
                <TailwindBox>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example">
                    {userTabs.map((tab, index) => (
                      <Tab key={index} label={tab.label} />
                    ))}
                  </Tabs>
                  {
                    userTabs.map((tab, index) => (
                      <div key={index} hidden={value !== index}>
                        {CustomTabPanel(tab.value)}
                      </div>
                    ))
                  }
                </TailwindBox>
              </div>
            </div>
          </div>
        </> : <>
          <div className='min-h-[47rem] justify-center flex flex-col items-center'>
            <h1 className='text-xl font-medium opacity-90'>
              No Data Found
            </h1>
            <div
              onClick={() => userQuery.refetch()}
              className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
              <h1 className='text-sm text-white'>
                Retry
              </h1>
            </div>
          </div>
        </> : null}
      </div>
    </Layout>
  );
};

export default EditUser;