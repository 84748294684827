import { useEffect, useState } from "react";
import {
  ReStore,
  TrascodeingDoneIcon,
  TrascodeingIcon,
  TrascodeInProsessIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "../Icon";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  createJob,
  getJob,
  seriesAction,
  updateSeriesJob,
} from "../../http/Apis";
import { setBulkActionCall } from "../../redux/slice​/movieSlice";
import moment from "moment";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { fetchAllSeries } from "../../redux/slice​/seriesSlice";
import { Hr } from "../../components";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const SeriesTable = ({ data, page }) => {
  const dispatch = useDispatch();
  const [allSelectArray, setallSelectArray] = useState([]);
  const { bulkAction, bulkActionCall } = useSelector((state) => state.movies);
  const [singleSelect, setSingleSelect] = useState([]);
  const [status, setStatus] = useState(false);
  const [bulkDelete, setBulkDelete] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: process.env.REACT_APP_PAGE_SIZE,
  });
  const [premanentlyDelete, setPremanentlyDelete] = useState(false);
  const [job, setJob] = useState(false);

  // status dialog
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const handleClickDialogOpen = () => {
    setStatusDialogOpen(true);
  };

  const handleDialogClose = () => {
    setStatusDialogOpen(false);
  };
  // delete dialog
  const [deleteDialogOpen, setSeleteDialogOpen] = useState(false);
  const handleClickDialogDeleteOpen = () => {
    setSeleteDialogOpen(true);
  };

  const handleDialogDeleteClose = () => {
    setSeleteDialogOpen(false);
  };

  // restore dialog
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
  const handleClickDialogRestoreOpen = () => {
    setRestoreDialogOpen(true);
  };

  const handleDialogRestoreClose = () => {
    setRestoreDialogOpen(false);
  };
  // handleStatus
  const handleStatus = async () => {
    const data = {
      id: singleSelect,
      action: status ? "publish" : "unpublish",
    };
    try {
      const response = await seriesAction(data);
      if (response.status === 200) {
        toast.success("Status updated successfully");
        dispatch(fetchAllSeries(page, pagination.limit));
        handleDialogClose(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  // handle Delete
  const handleDelete = async () => {
    try {
      const response = await seriesAction({
        id: singleSelect,
        action: "trash",
      });
      if (response.status === 200) {
        toast.success("Series deleted successfully");
        dispatch(fetchAllSeries(page, pagination.limit));
        handleDialogDeleteClose();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  // restore
  const handleRestore = async () => {
    try {
      const response = await seriesAction({
        id: singleSelect,
        action: "restore",
      });
      if (response.status === 200) {
        toast.success("Series restored successfully");
        dispatch(fetchAllSeries(page, pagination.limit));
        handleDialogRestoreClose();
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  // handlePremanentlyDelete
  const handlePremanentlyDelete = async () => {
    if (!premanentlyDelete)
      return toast.error("Please select atleast one series");
    try {
      const response = await seriesAction({
        id: singleSelect,
        action: "delete",
      });
      if (response.status === 200) {
        setPremanentlyDelete(false);
        toast.success("Series deleted successfully");
        dispatch(fetchAllSeries(page, pagination.limit));
        handleDialogRestoreClose();
      }
    } catch (error) {
      toast.error(error.response.data.error.message || "Something went wrong");
    }
  };
  // headle checkbox
  const headleCheckbox = (e) => {
    const { name: id, checked } = e.target;
    if (id === "selectAll") {
      if (checked) {
        setallSelectArray(data.map((data) => data._id));
      } else {
        setallSelectArray([]);
      }
    } else {
      setallSelectArray((prev) => {
        if (checked) {
          return [...prev, id];
        } else {
          return prev.filter((item) => item !== id);
        }
      });
    }
  };

  useEffect(() => {
    if (bulkActionCall) {
      headleBulkActions();
      dispatch(setBulkActionCall(false));
    }
  }, [bulkActionCall]);

  // bulkhandlePremanentlyDelete
  const bulkPremanentlyDelete = async () => {
    if (!bulkDelete) return toast.error("Please select atleast one series");
    try {
      const response = await seriesAction(bulkDelete);
      if (response.status === 200) {
        toast.success("Series deleted successfully");
        dispatch(fetchAllSeries(page, pagination.limit));
      }
    } catch (error) {
      toast.error(error.response.data.error.message || "Something went wrong");
    }
  };

  // headle bulk actions
  const headleBulkActions = async () => {
    const formData = new FormData();
    if (allSelectArray.length < 1) {
      formData.append("id", allSelectArray);
    } else {
      for (let i = 0; i < allSelectArray.length; i++) {
        formData.append("id", allSelectArray[i]);
      }
    }
    const newFormData =
      allSelectArray.length > 0
        ? { id: allSelectArray, action: bulkAction }
        : formData;

    if (bulkAction === "none") {
      toast.error("Please select bulk action");
    } else {
      if (bulkAction === "delete") {
        setBulkDelete(newFormData);
        document.getElementById("premanentlyDelete").click();
      } else {
        try {
          const response = await seriesAction(newFormData);
          if (response.status === 200) {
            toast.success("Bulk action performed successfully");
            dispatch(fetchAllSeries(page, pagination.limit));
          }
        } catch (error) {
          toast.error(
            error.response.data.error.message || "Something went wrong"
          );
        }
      }
    }
  };

  // handle start transcodeing
  const handleStartTranscodeing = async () => {
    const id = singleSelect[0] || singleSelect;
    try {
      const response = await createJob({ id, type: "SERIES" });
      if (response.status === 201) {
        toast.success("Job created successfully");
        dispatch(fetchAllSeries(page, pagination.limit));
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  // get job status
  const getJobStatus = async (id) => {
    try {
      const response = await getJob(id);
      if (response.status === 200) {
        setJob(response.data.data.Job);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  function secondsToHms(d, type) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    if (type) {
      var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "";
    } else {
      var hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " m, " : " m, ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    }

    return hDisplay + mDisplay + sDisplay;
  }

  // update job status
  const updateJobStatus = async () => {
    const id = singleSelect[0] || "";
    try {
      const response = await updateSeriesJob(id);
      if (response.status === 200) {
        toast.success("Job updated successfully");
        dispatch(fetchAllSeries(page, pagination.limit));
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div>
      <div className="overflow-x-auto w-full">
        <div className="flex px-5 mb-2.5">
          <div className="w-36">
            <div className="tooltip tooltip-right" data-tip="Select All">
              <label>
                <input
                  type="checkbox"
                  className="checkbox checkbox-primary border-blue-500 checked:bg-blue-500"
                  name="selectAll"
                  checked={allSelectArray.length === data.length}
                  onChange={headleCheckbox}
                />
              </label>
            </div>
          </div>
          <div className="w-[200rem]">
            <h1 className="text-gray-300">Series</h1>
          </div>
          <div className="w-full">
            <h1 className="text-gray-300">Visibility</h1>
          </div>
          <div className="w-full">
            <h1 className="text-gray-300">Restrictions</h1>
          </div>
          <div className="w-full">
            <h1 className="text-gray-300">Date</h1>
          </div>
          <div className="w-full">
            <h1 className="text-gray-300">Views</h1>
          </div>
          <div className="w-full">
            <h1 className="text-gray-300"></h1>
          </div>
        </div>
        <Hr />
        {data.length > 0 &&
          data.map((item, index) => {
            return (
              <div className="flex justify-start px-5 mt-3 mb-6">
                <div className="w-36">
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox checkbox-primary border-blue-500 checked:bg-blue-500"
                      name={item._id}
                      onChange={headleCheckbox}
                      checked={allSelectArray.includes(item._id)}
                    />
                  </label>
                </div>
                <div className="w-[200rem]">
                  <div className="flex">
                    <div className="w-32 h-[70px] aspect-video rounded overflow-hidden relative">
                      <img
                        src={
                          item.thumbnail
                            ? item.thumbnail
                            : "/default-product.png"
                        }
                        alt="Thumbnail"
                      />
                      <span className="absolute bottom-1.5 right-1.5 rounded bg-black opacity-80 w-fit px-2 text-sm">
                        {secondsToHms(item.duration, true)}
                      </span>
                    </div>
                    <div className="ml-3 w-60">
                      <h1 className="text-white text-base line-clamp-1">
                        {item.title || item.name}
                      </h1>
                      <p className="text-gray-400 text-xs mt-1 line-clamp-2">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full cursor-pointer">
                  <label
                    onClick={() => {
                      setSingleSelect([item._id]);
                      setStatus(item.published ? false : true);
                      handleClickDialogOpen(true);
                    }}
                  >
                    <h1 className="text-gray-300 cursor-pointer">
                      {item.published ? (
                        <div className="flex items-center gap-2">
                          <MdVisibility size={20} className="text-green-500" />
                          <span className="text-sm">Public</span>
                        </div>
                      ) : (
                        <div>
                          <div className="flex items-center gap-2">
                            <MdVisibilityOff
                              size={20}
                              className="text-red-500"
                            />
                            <span className="text-sm">Private</span>
                          </div>
                        </div>
                      )}
                    </h1>
                  </label>
                </div>
                <div className="w-full">
                  <h1 className="text-gray-300">None</h1>
                </div>
                <div className="w-full">
                  <h1 className="text-gray-300 capitalize">
                    {moment(item.createdAt).format("MMM DD, YYYY")}
                  </h1>
                  <p className="text-xs text-gray-400 mt-0.5">Published</p>
                </div>
                <div className="w-full">
                  <h1 className="text-gray-300">
                    {item.viewId?.views?.length ? item.viewId.views.length : 0}
                  </h1>
                </div>
                <div className="w-full">
                  {item.deleted ? (
                    <div className="tooltip tooltip-accent" data-tip="Restore">
                      {" "}
                      <Button
                        onClick={() => {
                          setSingleSelect([item._id]);
                          handleClickDialogRestoreOpen(true);
                          setPremanentlyDelete([item._id]);
                        }}
                        variant="text"
                      >
                        <RestoreFromTrashIcon className="cursor-pointer" />
                      </Button>{" "}
                    </div>
                  ) : (
                    <div className="tooltip tooltip-accent" data-tip="Delete">
                      {" "}
                      <Button
                        onClick={() => {
                          setSingleSelect([item._id]);
                          handleClickDialogDeleteOpen(true);
                        }}
                        variant="text"
                      >
                        <DeleteIcon className="cursor-pointer" />
                      </Button>
                    </div>
                  )}
                  <Link
                    to={`/admin/content/series/edit/${item._id}`}
                    className="cursor-pointer"
                  >
                    <div className="tooltip tooltip-accent" data-tip="Edit">
                      <Button
                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                        variant="text"
                      >
                        <EditIcon className="cursor-pointer" />
                      </Button>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        <div className="-mt-3">
          <Hr />
        </div>
        {/* <table className="table w-full">
                    <thead>
                        <tr>
                            <th className="bg-secondary-color">
                                <div className="tooltip tooltip-right" data-tip="Select All">
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="checkbox checkbox-primary"
                                            name="selectAll"
                                            checked={allSelectArray.length === data.length}
                                            onChange={headleCheckbox}
                                        />
                                    </label>
                                </div>
                            </th>
                            <th className="bg-secondary-color">Name</th>
                            <th className="bg-secondary-color">View</th>
                            <th className="bg-secondary-color"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length > 0 && data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <th className="bg-slate-800">
                                            <div className="tooltip tooltip-right" data-tip="Select One">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox checkbox-primary"
                                                        name={item._id}
                                                        onChange={headleCheckbox}
                                                        checked={allSelectArray.includes(item._id)}
                                                    />
                                                </label>
                                            </div>
                                        </th>
                                        <td className="bg-slate-800 border-slate-500">
                                            <div className="flex items-center space-x-3">
                                                <div className="avatar">
                                                    <div className="mask mask-squircle w-10 h-10">
                                                        <img src={item.thumbnail ? item.thumbnail : '/default-product.png'} alt="Thumbnail" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="font-bold text-white opacity-80 capitalize text-sm">{item.name}</div>
                                                    <div className="text-xs text-white opacity-70 mt-0.5">{
                                                        moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')
                                                    }</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="bg-slate-800 border-slate-500 text-white text-sm">
                                            <span>{
                                                item.viewId?.views?.length ? item.viewId.views.length : 0
                                            }</span>
                                        </td>
                                        <th className="bg-slate-800 border-slate-500 flex gap-4">
                                            <div className="tooltip" data-tip="Status">
                                                <label htmlFor="series-status" onClick={() => {
                                                    setSingleSelect([item._id])
                                                    setStatus(item.published ? false : true)
                                                }} className="cursor-pointer"> {item.published ? <VisibilityIcon width={45} height={45} backgroundColor="#172337" /> : <VisibilityOffIcon width={45} height={45} backgroundColor="#172337" />}</label>
                                            </div>
                                            <Link to={`/admin/series/edit/${item._id}`} className="cursor-pointer">
                                                <div className="tooltip" data-tip="Edit">
                                                    <span className="cursor-pointer"><EditIcon width={45} height={45} backgroundColor="#172337" /></span>
                                                </div>
                                            </Link>
                                            {
                                                !item.deleted && <div className="tooltip" data-tip="Delete">
                                                    <label htmlFor="series-delete" onClick={() => {
                                                        setSingleSelect([item._id])
                                                    }}
                                                        className="cursor-pointer"> <DeleteIcon width={45} height={45} /></label>
                                                </div>
                                            }
                                            {
                                                item.deleted && <div className="tooltip" data-tip="Restore">
                                                    <label htmlFor="series-restore" onClick={() => {
                                                        setSingleSelect([item._id])
                                                        setPremanentlyDelete(item._id)
                                                    }}
                                                        className="cursor-pointer"> <ReStore width={45} height={45} /></label>
                                                </div>
                                            }
                                            {
                                                item.trailerKey !== null && <>
                                                    {
                                                        item.streamable === 'NO' && <div className="tooltip" data-tip="Start Transcoding">
                                                            <label htmlFor="series-start-transcodeing" onClick={() => {
                                                                setSingleSelect([item._id])
                                                            }}
                                                                className="cursor-pointer"> <TrascodeingIcon width={45} height={45} /></label>
                                                        </div>
                                                    }
                                                    {
                                                        item.streamable === 'PROGRESS' && <div className="tooltip"
                                                            data-tip="Check Transcodeing Status">
                                                            <label htmlFor="series-cancel-transcodeing" onClick={() => {
                                                                setSingleSelect([item._id])
                                                                getJobStatus(item.jobId)
                                                            }}
                                                                className="cursor-pointer"> <TrascodeInProsessIcon width={45} height={45} /></label>
                                                        </div>
                                                    }
                                                    {
                                                        item.streamable === 'YES' && <div className="tooltip"
                                                            data-tip="Transcoding Completed">
                                                            <label className="cursor-pointer"> <TrascodeingDoneIcon width={45} height={45} /></label>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </th>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table> */}
      </div>

      {/* Status dialog */}
      <div className="bg-dark-color">
        <Dialog
          open={statusDialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Series Status"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to change the status of this Series?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleStatus} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* delete dialog */}
      <div className="bg-dark-color">
        <Dialog
          open={deleteDialogOpen}
          onClose={handleDialogDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Move to Trash"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to move this movie to trash?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogDeleteClose}>Cancel</Button>
            <Button onClick={handleDelete} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* restore dialog */}
      <div className="bg-dark-color">
        <Dialog
          open={restoreDialogOpen}
          onClose={handleDialogRestoreClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Restore from Trash"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to restore this movie from trash?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePremanentlyDelete}>Delete Forever</Button>
            <Button onClick={handleRestore} autoFocus>
              Restore
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* Status Update Code */}
      <input type="checkbox" id="series-status" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="series-status"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="font-bold text-lg">Series Status</h3>
          <p className="py-2">
            Are you sure you want to change the status of this series?
          </p>
          <div className="modal-action flex items-center justify-between">
            <label
              htmlFor="series-status"
              onClick={handleStatus}
              className="btn w-1/2 bg-red-800 hover:bg-red-900"
            >
              Yes
            </label>
            <label
              htmlFor="series-status"
              className="btn w-1/2 bg-secondary-color hover:bg-primary-color"
            >
              Cancel
            </label>
          </div>
        </div>
      </div>

      {/* Delete Code */}
      <input type="checkbox" id="series-delete" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="series-delete"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="font-bold text-lg">Series Delete</h3>
          <p className="py-2">Are you sure you want to delete this series?</p>
          <div className="modal-action flex items-center justify-between">
            <label
              htmlFor="series-delete"
              onClick={handleDelete}
              className="btn w-1/2 bg-red-800 hover:bg-red-900"
            >
              Yes
            </label>
            <label
              htmlFor="series-delete"
              className="btn w-1/2 bg-secondary-color hover:bg-primary-color"
            >
              Cancel
            </label>
          </div>
        </div>
      </div>

      {/* Restore Code */}
      <input type="checkbox" id="series-restore" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="series-restore"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="font-bold text-lg">Series Restore</h3>
          <p className="py-2">Are you sure you want to restore this series?</p>
          <div className="modal-action flex items-center justify-between">
            <label
              htmlFor="series-restore"
              onClick={handlePremanentlyDelete}
              className="btn w-1/2 bg-red-800 hover:bg-red-900"
            >
              Permanently Delete
            </label>
            <label
              htmlFor="series-restore"
              onClick={handleRestore}
              className="btn w-1/2 bg-secondary-color hover:bg-primary-color"
            >
              Restore
            </label>
          </div>
        </div>
      </div>
      {/* premanentlyDelete */}
      <input type="checkbox" id="premanentlyDelete" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="premanentlyDelete"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="font-bold text-lg">Series Permanently Delete</h3>
          <p className="py-2">
            Are you sure you want to permanently delete selected series?
          </p>
          <div className="modal-action flex items-center justify-between">
            <label
              htmlFor="premanentlyDelete"
              onClick={bulkPremanentlyDelete}
              className="btn w-1/2 bg-red-800 hover:bg-red-900"
            >
              Permanently Delete
            </label>
            <label
              htmlFor="premanentlyDelete"
              className="btn w-1/2 bg-secondary-color hover:bg-primary-color"
            >
              Cancel
            </label>
          </div>
        </div>
      </div>

      {/* series-start-transcodeing */}
      <input
        type="checkbox"
        id="series-start-transcodeing"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="series-start-transcodeing"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="font-bold text-lg">Start Transcodeing</h3>
          <p className="py-2">Are you sure you want to start transcodeing?</p>
          <div className="modal-action flex items-center justify-between">
            <label
              htmlFor="series-start-transcodeing"
              onClick={handleStartTranscodeing}
              className="btn w-1/2 bg-red-800 hover:bg-red-900"
            >
              Yes
            </label>
            <label
              htmlFor="series-start-transcodeing"
              className="btn w-1/2 bg-secondary-color hover:bg-primary-color"
            >
              Cancel
            </label>
          </div>
        </div>
      </div>

      {/* series-cancel-transcodeing */}
      <input
        type="checkbox"
        id="series-cancel-transcodeing"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="series-cancel-transcodeing"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="font-bold text-lg">Transcodeing Status</h3>
          {job ? (
            <div className="my-5 grid grid-cols-3 gap-5">
              <div className="w-32">
                <CircularProgressbar
                  value={job.Status === "COMPLETE" ? "100%" : "0"}
                  text={
                    job.Status === "COMPLETE"
                      ? "100%"
                      : job.JobPercentComplete
                      ? job.JobPercentComplete
                      : "0%"
                  }
                />
              </div>
              <div className="w-full flex flex-col col-span-2 gap-5 mt-1">
                <label
                  htmlFor="series-cancel-transcodeing"
                  onClick={() => {
                    job.Status === "COMPLETE" && updateJobStatus();
                  }}
                  className="btn w-full bg-green-500 hover:bg-green-900 text-white"
                >
                  {job.Status === "COMPLETE"
                    ? "Update Status Completed"
                    : "Service not available"}
                </label>
                <label
                  htmlFor="series-cancel-transcodeing"
                  onClick={() => {}}
                  className="btn bg-secondary-color hover:bg-primary-color"
                >
                  {job.Status === "PROGRESSING"
                    ? "Transcodeing Cancel"
                    : "Cancel not allowed"}
                </label>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <h1>Loading...</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SeriesTable;
