import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slice​/authSlice'
import stateSlice from './slice​/stateSlice'
import userSlice from './slice​/userSlice'
import ordersSlice from './slice​/ordersSlice'
import themesSlice from './slice​/themesSlice'
import moviesSlice from './slice​/movieSlice'
import subscriptionSlice from './slice​/SubscriptionSlice'

import genreSlice from './slice​/genreSlice'
import categorySlice from './slice​/categorySlice'
import languageSlice from './slice​/languageSlice'
import castSlice from './slice​/castSlice'
import sliderSlice from './slice​/sliderSlice'
import sectionSlice from './slice​/sectionSlice'
import seriesSlice from './slice​/seriesSlice'
import analyticsSlice from './slice​/analyticsSlice'
import configSlice from './slice​/configSlice'
import contantSlice from './slice​/contentSlice'
import themeSlice from './slice​/themeSlice'
import toolSlice from './slice​/toolSlice'
import blogSlice from './slice​/blogSlice'
import betaSlice from './slice​/betaSlice'

export const store = configureStore({
    reducer: {
        auth: authSlice,
        state: stateSlice,
        users: userSlice,
        orders: ordersSlice,
        themes: themesSlice,
        movies: moviesSlice,
        subscription: subscriptionSlice,
        genre: genreSlice,
        category: categorySlice,
        language: languageSlice,
        cast: castSlice,
        slider: sliderSlice,
        section: sectionSlice,
        series: seriesSlice,
        analytics: analyticsSlice,
        //content: contentSlice,
        config: configSlice,
        content: contantSlice,
        theme: themeSlice,
        tool: toolSlice,
        blog: blogSlice,
        beta:betaSlice
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch