// import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
// import { DeleteIcon, InputField, Title } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
// import {
//   addVersion,
//   getVersion,
//   updateAppSettingApi,
//   updateSettingApi,
//   updateVersion,
// } from "../../http/Apis";
// import toast from "react-hot-toast";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
// import LoadingButton from "@mui/lab/LoadingButton";
// import SaveIcon from "@mui/icons-material/Save";
// import { SketchPicker } from "react-color";
// import { UploadIcon } from "../../tailwind/Icon";
// import { fetchAppConfig } from "../../redux/slice​/configSlice";
import AppSplashScreen from "./components/AppSplashScreen";
import TVAppSplashScreen from "./components/TVAppSplashScreen";
// import Logo from "../../components/Logo/Logo";
import AppLogo from "./components/AppLogo";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import AppThemes from "./components/AppThemes";
import LoginPoster from "./components/LoginPoster";

interface ITabs {
  label: string;
  value: "splesh_screen" | "logo" | "themes" | "login_poster"
}

const SettingsTabs: ITabs[] = [
  {
    label: "Splash Screen",
    value: "splesh_screen"
  },
  {
    label: "Logo",
    value: "logo"
  },
  {
    label: "Themes",
    value: "themes"
  },
  {
    label: "Login Poster",
    value: "login_poster"
  }
]

const AppSettings = () => {
  const dispatch = useDispatch();
  const { appConfig } = useSelector((state: RootState) => state.config);
  const { splesh_screen, _id } = appConfig;
  // const [loading, setLoading] = useState(false);
  // interface IAppSettings {
  //   splesh_screen: {
  //     background_color?: string;
  //     screen_time?: number;
  //     splesh_logo?: string;
  //     splesh_logoUrl?: string;
  //     logoSize?: number;
  //   };
  //   logo: {
  //     logo?: string;
  //     logoUrl?: string;
  //   };
  //   data: {
  //     ipinfo_api_key: string;
  //   };
  // }
  // const [appSettings, setAppSettings] = useState<IAppSettings>({
  //   splesh_screen: {
  //     background_color: "#000",
  //     screen_time: 5,
  //     splesh_logo: "",
  //     splesh_logoUrl: "",
  //     logoSize: 100,
  //   },
  //   logo: {
  //     logo: "",
  //     logoUrl: "",
  //   },
  //   data: {
  //     ipinfo_api_key: "",
  //   },
  // });

  // const [versionData, setVersionData] = useState<{
  //   version: string;
  //   name: string;
  //   description: string;
  // }>({
  //   version: "",
  //   name: "",
  //   description: "",
  // });

  // const [isEdit, setIsEdit] = useState(false);
  // useEffect(() => {
  //   setAppSettings({
  //     splesh_screen: {
  //       background_color: splesh_screen.background_color,
  //       screen_time: splesh_screen.screen_time,
  //       splesh_logoUrl: splesh_screen.splesh_logo,
  //       logoSize: splesh_screen.logoSize,
  //     },
  //     logo: {
  //       logoUrl: appConfig.logo,
  //     },
  //     data: {
  //       ipinfo_api_key: appConfig.data.ipinfo_api_key,
  //     },
  //   });
  // }, [appConfig]);

  // useEffect(() => {
  //   dispatch(fetchAppConfig() as any);
  // }, []);

  // const getLogoChange = (e: any) => {
  //   // get object url and set it to logoUrl
  //   if (e.target.files[0]) {
  //     const logoUrl = URL.createObjectURL(e.target.files[0]);
  //     setAppSettings({
  //       ...appSettings,
  //       logo: { ...appSettings.logo, logo: e.target.files[0], logoUrl },
  //     });
  //   }
  // };

  // const updateSettings = async () => {
  //   if (!appConfig._id) return toast.error("Config not found");
  //   setLoading(true);
  //   const formData = new FormData();
  //   formData.append(
  //     "splesh_screen",
  //     JSON.stringify({
  //       background_color: appSettings.splesh_screen.background_color,
  //       screen_time: appSettings.splesh_screen.screen_time,
  //       logoSize: appSettings.splesh_screen.logoSize,
  //     })
  //   );

  //   if (appSettings.splesh_screen.splesh_logo) {
  //     formData.append("splesh_logo", appSettings.splesh_screen.splesh_logo);
  //   }

  //   if (appSettings.logo.logo) {
  //     formData.append("logo", appSettings.logo.logo);
  //   }

  //   formData.append(
  //     "data",
  //     JSON.stringify({
  //       ipinfo_api_key: appSettings.data.ipinfo_api_key,
  //     })
  //   );
  //   try {
  //     const response = await updateAppSettingApi(_id, formData);
  //     if (response.status === 200) {
  //       toast.success("Settings updated successfully");
  //       setLoading(false);
  //       dispatch(fetchAppConfig() as any);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //     toast.error("Something went wrong");
  //   }
  // };

  //   @useEffect for handling version

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let res: any = await getVersion();

  //       console.log(res);

  //       if (res && res.data.data) {
  //         if (res.status === 200) {
  //           setIsEdit(true);
  //           setVersionData({
  //             name: res.data.data.name,
  //             description: res.data.data.description,
  //             version: res.data.data.version,
  //           });
  //         }
  //       }
  //     } catch (error) {
  //       return console.log(error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  // const handleVersionSubmit = async () => {
  //   try {
  //     if (
  //       versionData.name === "" ||
  //       versionData.description === "" ||
  //       versionData.version === ""
  //     ) {
  //       return toast.error("All fields required");
  //     }

  //     if (versionData.version.endsWith(".")) {
  //       return toast.error("Invalid Version");
  //     }

  //     let res = await addVersion(versionData);
  //     console.log(res);
  //     if (res.status === 200) {
  //       return toast.success(res.data.message);
  //     }
  //   } catch (error) {
  //     return console.log(error);
  //   }
  // };

  // const handleVersionUpdate = async () => {
  //   try {
  //     if (
  //       versionData.name === "" ||
  //       versionData.description === "" ||
  //       versionData.version === ""
  //     ) {
  //       return toast.error("All fields required");
  //     }

  //     if (versionData.version.endsWith(".")) {
  //       return toast.error("Invalid Version");
  //     }

  //     let res = await updateVersion(versionData);

  //     if (res.status === 200) {
  //       return toast.success(res.data.message);
  //     }

  //     console.log(res);
  //   } catch (error) {
  //     return console.log(error);
  //   }
  // };


  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

  const CustomTabPanel = (value: ITabs['value']) => {
    switch (value) {
      case 'splesh_screen':
        return <>
          <AppSplashScreen />
          <div className="pr-6">
            <Hr />
          </div>
          <TVAppSplashScreen />
        </>
      case 'logo':
        return <AppLogo />
      case 'themes':
        return <AppThemes />
      case 'login_poster':
        return <LoginPoster />
    }
  }
  return (
    <Layout>
      <div className="pr-5">
        <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
          App Settings
        </h1>
      </div>

      <Tabs
        value={value}
        className="mt-5"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">
        {SettingsTabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      {
        SettingsTabs.map((tab, index) => (
          <div key={index} hidden={value !== index}>
            <div className="pr-6">
              <Hr />
            </div>
            {CustomTabPanel(tab.value)}
          </div>
        ))
      }


      {/* <h1 className="my-2 opacity-80 font-medium tracking-wide text-xl pr-5">
        App Version Control
      </h1>

      <div className="flex w-full items-center pr-56 mb-4 mt-4">
        <Title required={true} className="w-1/3">
          Name Of Version
        </Title>
        <div className="w-full">
          <div className="flex flex-col justify-center items-center">
            <InputField
              fieldName="ipinfo-api-key"
              placeholder="Enter Name Of Version"
              title="Name Of Version"
              type="text"
              value={versionData.name}
              onChange={(e) => {
                setVersionData({
                  ...versionData,
                  ["name"]: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full items-center pr-56 mb-4 mt-4">
        <Title required={true} className="w-1/3">
          Description of Version
        </Title>
        <div className="w-full">
          <div className="flex flex-col justify-center items-center">
            <InputField
              fieldName="ipinfo-api-key"
              placeholder="Enter Description of Version"
              title="Description of Version"
              type="text"
              value={versionData.description}
              onChange={(e) => {
                setVersionData({
                  ...versionData,
                  ["description"]: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full items-center pr-56 mb-4 mt-4">
        <Title required={true} className="w-1/3">
          Version
        </Title>
        <div className="w-full">
          <div className="flex flex-col justify-center items-center">
            <InputField
              fieldName="ipinfo-api-key"
              placeholder="Enter  Version of app"
              title="Version of App"
              type="text"
              value={versionData.version}
              onChange={(e) => {
                let reg =
                  /^(\d+\.)?(\d+\.)?(\d+\.)?(\d+\.)?(\d+\.)?(\d+\.)?(\d+\.)?(\d+\.)?(\*|\d+)?$/;
                if (!reg.test(e.target.value)) {
                  return;
                }

                setVersionData({
                  ...versionData,
                  ["version"]: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end pr-56 my-20">
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={() => {
            isEdit ? handleVersionUpdate() : handleVersionSubmit();
          }}
        >
          Save
        </LoadingButton>
      </div> */}
    </Layout>
  );
};

export default AppSettings;
