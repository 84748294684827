interface ILoaderProps {
    className?: string | "w-24" | "mt-32" | "mt-40" | "mt-40" | "mt-48" | "mt-64" | "mt-80" | "mt-96";
    smail?: boolean;
    color?: "border-blue-500" | "border-white" | "border-black";
    rounded?: boolean;
}
export const Loader = ({
    className,
    smail,
    color = "border-blue-500",
    rounded = true
}: ILoaderProps): JSX.Element => {
    if (smail) {
        return (
            // create loader useing tailwindcss

            <div className={`animate-spin ${rounded && 'rounded-full'} h-5 w-5 border-t-4 border-b-4 ${color}`}></div>
        );
    }
    return (
        // create loader useing tailwindcss
        <div className={`flex justify-center items-center ${className}`}>
            <div className={`animate-spin ${rounded && 'rounded-full'} h-32 w-32 border-t-4 border-b-4 border-blue-200`}></div>
        </div>
    );
}