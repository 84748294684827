// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { MuiDialog } from '../../../components'
import { subscriptionAssignApi } from '../../../http/Apis'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../redux/store'
import moment from 'moment'
import MuiFormDialog from '../../../components/Mui/Dialogs/muiFormdialogs'
import { SelectField } from '../../../tailwind'
import { fetchSubscriptions } from '../../../redux/slice​/SubscriptionSlice'
import { UseQueryResult } from '@tanstack/react-query'

export enum SubscriptionPlanDuration {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    QUARTERLY = 'quarterly',
    HALF_YEARLY = 'half-yearly',
    YEARLY = 'yearly',
    TWO_YEARLY = 'two-yearly',
    THREE_YEARLY = 'three-yearly',
    FOUR_YEARLY = 'four-yearly',
    FIVE_YEARLY = 'five-yearly',
    TEN_YEARLY = 'ten-yearly',
    FIFTEEN_YEARLY = 'fifteen-yearly',
}

export function durationMonthToDays(duration: string) {
    switch (duration) {
        case SubscriptionPlanDuration.WEEKLY:
            return 7
        case SubscriptionPlanDuration.MONTHLY:
            return 30
        case SubscriptionPlanDuration.QUARTERLY:
            return 90
        case SubscriptionPlanDuration.HALF_YEARLY:
            return 180
        case SubscriptionPlanDuration.YEARLY:
            return 365
        case SubscriptionPlanDuration.TWO_YEARLY:
            return 730
        case SubscriptionPlanDuration.THREE_YEARLY:
            return 1095
        case SubscriptionPlanDuration.FOUR_YEARLY:
            return 1460
        case SubscriptionPlanDuration.FIVE_YEARLY:
            return 1825
        case SubscriptionPlanDuration.TEN_YEARLY:
            return 3650
        case SubscriptionPlanDuration.FIFTEEN_YEARLY:
            return 5475
        default:
            return 0
    }
}

function getExpiry(active_at: string, duration: string) {
    const validityinDays = durationMonthToDays(duration);
    const expiry = moment(active_at).add(validityinDays, 'days');
    const now = moment();
    const expiresInMoment = moment(expiry).diff(now, 'days')
    return expiresInMoment;
}

interface IProps {
    id: string;
    user: IUsersResponse['data'][0]
    userQuery: UseQueryResult
}


const UserSubscription = ({ user, id, userQuery }: IProps): JSX.Element => {
    const [open, setOpen] = React.useState(false)
    const [openForm, setOpenForm] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [formOnBlur, setFormOnBlur] = React.useState(false)
    const [subscriptionId, setSubscriptionId] = React.useState<string>('')
    const dispatch = useDispatch()
    const { subscriptions } = useSelector((state: RootState) => state.subscription);

    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    useEffect(() => {
        dispatch(fetchSubscriptions() as any);
    }, []);

    const handleSubscriptions = async (id: string, type: 'add' | 'remove') => {
        if (!id) return toast.error('User id is required')
        try {
            setLoading(true)
            if (type === 'add') {
                const { status } = await subscriptionAssignApi('add', { id: id, subscriptionId: subscriptionId })
                if (status === 200) {
                    setLoading(false)
                    setOpen(false)
                    setOpenForm(false)
                    userQuery.refetch()
                    toast.success('User subscription added successfully')
                }
            } else {
                const { status } = await subscriptionAssignApi('remove', { id: id })
                if (status === 200) {
                    setLoading(false)
                    setOpen(false)
                    userQuery.refetch()
                    toast.success('User subscription deleted successfully')
                }
            }
        } catch (error: any) {
            setLoading(false)
            setOpen(false)
            toast.error(error.response?.data?.error?.message || "User can't be deleted");
        }
    }

    return (
        <div className="grid grid-cols-2 gap-x-5 gap-y-3 mt-5">
            <div>
                <div className="pr-5">
                    <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                        User Subscription
                    </h1>
                    <p className="mt-2 opacity-70">
                        You can activate or deactivate user subscription here.
                    </p>
                </div>
                {
                    user?.isPrimium?.status ? <>
                        <div className='mt-5'>
                            <div>
                                <div className="w-96 h-56 bg-red-100 rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-110">
                                    <img className="relative object-cover w-full h-full rounded-xl" src="https://i.imgur.com/kGkSg1v.png" />
                                    <div className="w-full px-8 absolute top-8">
                                        <div className="flex justify-between">
                                            <div className="">
                                                <h1 className="font-light">
                                                    Name
                                                </h1>
                                                <p className="font-medium tracking-widest">
                                                    {user?.name || "N/A"}
                                                </p>
                                            </div>
                                            <img className="w-14 h-14 rounded-full" src={user.avatar || "/default-profile.jpeg"} />
                                        </div>
                                        <div className="pt-1">
                                            <h1 className="font-light">
                                                Subscription Name
                                            </h1>
                                            <p className="font-medium tracking-more-wider">
                                                {user?.isPrimium?.subscriptionId?.name}
                                            </p>
                                        </div>
                                        <div className="pt-6 pr-6">
                                            <div className="flex justify-between">
                                                <div className="">
                                                    <h1 className="font-light text-xs">
                                                        Active
                                                    </h1>
                                                    <p className="font-medium tracking-wider text-sm">
                                                        {moment(user?.isPrimium?.active_at).format('DD/MM/YYYY')}
                                                    </p>
                                                </div>
                                                <div className="">
                                                    <h1 className="font-light text-xs">
                                                        Expiry
                                                    </h1>
                                                    <p className="font-medium tracking-wider text-sm">
                                                        {
                                                            user?.isPrimium?.active_at && user?.isPrimium?.subscriptionId?.duration && moment(user?.isPrimium?.active_at).add(durationMonthToDays(user?.isPrimium?.subscriptionId?.duration || ''), 'days').format('DD/MM/YYYY')
                                                        }
                                                    </p>
                                                </div>

                                                <div className="">
                                                    <h1 className="font-light text-xs">
                                                        Price
                                                    </h1>
                                                    <p className="font-bold tracking-more-wider text-sm">
                                                        {user?.isPrimium?.subscriptionId?.price}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 pr-5">
                            <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                                Delete Subscription
                            </h1>
                            <p className="mt-2 opacity-70">
                                You can delete user subscription here.
                            </p>
                        </div>
                        <div className='flex items-center gap-10 mt-3'>
                            <Button
                                variant="contained"
                                color="error"
                                size="small"
                                disabled={loading}
                                onClick={handleClickOpen}
                            >
                                {loading ? 'Deleting...' : 'Delete'}
                            </Button>
                        </div>
                    </> : <div className='mt-5'>
                        <h1>
                            User has no subscription
                        </h1>
                        <div className='flex items-center gap-10 mt-3'>
                            <Button
                                variant="contained"
                                color="success"
                                size="small"
                                disabled={loading}
                                onClick={() => setOpenForm(true)}
                            >
                                {loading ? 'Activating...' : 'Activate'}
                            </Button>
                        </div>
                    </div>
                }
                <MuiDialog
                    title="Subscription delete"
                    contentText='Are you sure you want to subscription delete this user?'
                    buttonOneText='Cancel'
                    onClickYes={() => handleSubscriptions(id as string, 'remove')}
                    onClose={handleClose}
                    onClickClose={handleClose}
                    loading={loading}
                    open={open}
                />
                <MuiFormDialog
                    title="Subscription Activate"
                    onClick={() => handleSubscriptions(id as string, 'add')}
                    open={openForm}
                    onClose={() => setOpenForm(false)}
                    onClickBtnName='Activate'
                    loading={loading}
                >
                    <div className={`${formOnBlur && 'h-96'} w-96`}>
                        <label htmlFor="name">
                            Select Subscription
                        </label>
                        <div className='mt-2' onClick={() => setFormOnBlur(!formOnBlur)}>
                            <SelectField
                                options={subscriptions}
                                onChange={(e) => {
                                    setSubscriptionId(e._id)
                                }}
                                placeholder={'Select subscription'}
                                isMulti={false}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option._id}
                                onBlur={() => {
                                    setFormOnBlur(false)
                                }}
                            />
                        </div>

                    </div>
                </MuiFormDialog>
            </div>
        </div>
    )
}

export default UserSubscription