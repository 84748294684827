import Layout from "../../components/Layout/Layout";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import { Link } from "react-router-dom";
import { Hr } from "../../components";
import qs from "qs";
import { RootState } from "../../redux/store";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { secToMin, totalViewsCalculate, totalWatchTimeCalculate, typeOfContant } from "../../utils/Utils";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useQuery } from "@tanstack/react-query";
import { getAnalytics, getContent } from "../../http/Apis";
import { Button } from "@mui/material";
import TVOD from "./Child/TVOD";

type ISelectedTab = "viewsTab" | "watchTime" | "subscriber";
export interface IAnalyticsQuery {
  timePeriod?: "daily" | "weekly" | "monthly" | "yearly" | "custom";
  startDate?: string;
  endDate?: string;
  content_id?: string;
}
const Analytics = () => {
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const [totalViews, setTotalViews] = useState<number>(0);
  const [customeStartDate, setCustomeStartDate] = useState("");
  const [customeEndDate, setCustomeEndDate] = useState("");
  const [filterMethod, setFilterMethod] = useState("last28");
  const [watchTimeChartdata, setWatchTimeChartData] = useState<any>(null);
  const [totalWatchTime, setTotalWatchTime] = useState<number>(0);
  const [timePeriod, setTimePeriod] = useState<IAnalyticsQuery['timePeriod']>("daily");

  const getContentQuery = qs.stringify({
    page: 1,
    limit: 5,
    sort: "desc",
  });

  // content query
  const contentQuery = useQuery<IContent, Error>({
    queryKey: ["content", getContentQuery],
    queryFn: () => getContent(getContentQuery),
  });

  const [chartdata, setChartData] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState<ISelectedTab>("viewsTab");

  //   useEffect for the filter date of view
  useEffect(() => {
    const fetchData = async () => {
      let query: IAnalyticsQuery = {
        timePeriod: timePeriod,
      };
      // formet query using qs
      const newQuery = qs.stringify(query);
      if (timePeriod === "custom") {
        let customQuery: IAnalyticsQuery = {
          timePeriod: timePeriod,
          startDate: customeStartDate,
          endDate: customeEndDate,
        };
        const newCustomQuery = qs.stringify(customQuery);
        try {
          let res = await getAnalytics(newCustomQuery);
          handleGraph(res.data.data);
        } catch (error) { }
      } else {
        try {
          let res = await getAnalytics(newQuery);
          handleGraph(res.data.data);
        } catch (error) { }
      }
    };
    fetchData();
  }, [timePeriod, customeStartDate, customeEndDate]);

  const handleGraph = (chartDataArr: any) => {
    //@data for the chart/graph
    const data = chartDataArr.views.map((item: any) => item.count);
    //@data for the watchTime Graph
    const watchTimeData = chartDataArr.watchTime.map((item: any) => {
      return secToMin(item.time);
    });
    //@Total Views
    const views = chartDataArr.views.reduce(
      (acc: any, item: any) => acc + item.count,
      0
    );
    // @Total WatchTime
    let totalWatchTime = chartDataArr.watchTime.reduce(
      (acc: any, item: any) => acc + item.time,
      0
    );
    totalWatchTime = totalWatchTime / 3600;
    // @X-AXIS For the total views
    const xaxis = chartDataArr.views.map((item: any) =>
      moment(item._id).format("DD MMMM YYYY")
    );

    // @X-AXIS for the total watchTime
    const watchTimeXaxis = chartDataArr.watchTime.map((item: any) =>
      moment(item._id).format("DD MMMM YYYY")
    );

    // @Chart Data Object for the Total Views
    const dataChart = {
      series: [
        {
          name: "Views",
          data: [...data] || [],
        },
      ],
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          type: "line",
          height: 350,
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [...xaxis] || [],
        },
      },
    };

    // @Chart Data Object for the Total WatchTime
    const watchdataChart = {
      series: [
        {
          name: "Watch Time",
          data: watchTimeData || [],
        },
      ],
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          type: "line",
          height: 350,
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [...watchTimeXaxis] || [],
        },
      },
    };

    setChartData({ ...dataChart });
    setWatchTimeChartData({ ...watchdataChart });
    setTotalViews(views);
    setTotalWatchTime(totalWatchTime);
  };

  const [analyticsType, setAnalyticsType] = useState("content")

  return (
    <>
      <Layout>
        {
          analyticsType === "content" ? <>
            <div className="relative">
              <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                Content Analytics
              </h1>
              <p className="mt-2 opacity-70 mb-4">
                Analytics of all the content on the platform.
              </p>
              <div className="absolute right-10" >
                <Tooltip title="Switch to tvod" arrow>
                  <h1 onClick={() => {
                    setAnalyticsType("tvod")
                  }}
                    className="cursor-pointer">
                    Switch to tvod
                  </h1>
                </Tooltip>
              </div>
            </div>
            <div className={`w-1/2 flex  justify-items-start items-end h-10`}>
              <select
                className="p-2 w-1/3 text-lg text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => {
                  if (e.target.value === "custom") {
                    setCustomeStartDate(moment().startOf("month").format("YYYY-MM-DD"));
                    setCustomeEndDate(moment().format("YYYY-MM-DD"));
                  }
                  setTimePeriod(e.target.value as any);
                }}>
                <option value="daily">
                  Last 24 Hours
                </option>
                <option value="weekly">
                  Last 7 Days
                </option>
                <option value="monthly">
                  Last 30 Days
                </option>
                <option value="yearly">
                  Last 1 Year
                </option>
                <option value="custom">
                  Custom
                </option>
              </select>

              {timePeriod === "custom" && (
                <>
                  <div className="flex flex-col mx-4">
                    <input
                      type="date"
                      className=" bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={customeStartDate + ""}
                      onChange={(e) => {
                        setCustomeStartDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="h-full flex justify-center items-center ">
                    <span className="text-white text-2xl">to</span>
                  </div>
                </>
              )}

              {timePeriod === "custom" && (
                <div className="flex flex-col mx-4">
                  <input
                    type="date"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={customeEndDate + ""}
                    onChange={(e) => {
                      setCustomeEndDate(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
            <div className="mt-5">
              <Hr />
            </div>
            <div className={`mt-5 mr-5 ${darkMode ? "border border-gray-700" : "border border-gray-200"}`}>
              <div className="grid grid-flow-col">
                <div onClick={() => setSelectedTab("viewsTab")}
                  className={`${selectedTab === "viewsTab"
                    ? darkMode
                      ? "bg-dark-color-box border-r border-gray-700"
                      : "bg-white border-r border-gray-200"
                    : ""
                    } h-36 p-5 flex justify-center cursor-pointer ripple`}
                >
                  <div className="flex flex-col items-center">
                    <div>Views</div>
                    <div>
                      <h1 className="text-5xl mt-4">
                        {totalViewsCalculate(totalViews)}
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => setSelectedTab("watchTime")}
                  className={`${selectedTab === "watchTime"
                    ? darkMode
                      ? "bg-dark-color-box border-r border-l border-gray-700"
                      : "bg-white border-r border-l border-gray-200"
                    : ""
                    } h-36 p-5 flex justify-center cursor-pointer`}
                >
                  <div className="flex flex-col items-center">
                    <div>Watch time (hours)</div>
                    <div>
                      <h1 className="text-5xl mt-4">
                        {totalWatchTimeCalculate(Number(totalWatchTime))}
                      </h1>
                    </div>
                  </div>
                </div>
                {/* <div
                          onClick={() => setSelectedTab('subscriber')}
                          className={`${selectedTab === 'subscriber' ? darkMode ? 'bg-dark-color-box border-l border-gray-700' : 'bg-white border-l border-gray-200' : ''} h-36 p-5 flex justify-center cursor-pointer`}>
                          <div className="flex flex-col items-center">
                              <div>
                                  Subscribers
                              </div>
                              <div>
                                  <h1 className="text-5xl mt-4">
                                      2k
                                  </h1>
                              </div>
                          </div>
                      </div> */}
              </div>
              <div
                className={`${darkMode ? "bg-dark-color-box" : "bg-white"
                  } p-5 w-full text-black`}
              >
                {selectedTab === "viewsTab" && chartdata && (
                  <div className="w-full">
                    <ReactApexChart
                      options={chartdata.options as any}
                      series={chartdata.series}
                      type="area"
                      height={350}
                      width={"100%"}
                    />
                  </div>
                )}
                {selectedTab === "watchTime" && watchTimeChartdata && (
                  <div className="w-full">
                    <ReactApexChart
                      options={watchTimeChartdata.options as any}
                      series={watchTimeChartdata.series}
                      type="area"
                      height={350}
                      width={"100%"}
                    />
                  </div>
                )}
                {selectedTab === "subscriber" && chartdata && (
                  <div className="w-full">
                    <ReactApexChart
                      options={chartdata.options as any}
                      series={chartdata.series}
                      type="area"
                      height={350}
                      width={"100%"}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="mt-5 mr-5">
              <table className="table-auto w-full overflow-scroll">
                <thead>
                  <tr>
                    <th className="px-4 py-2 flex justify-start">Content</th>
                    <td className="px-4 py-2">Visibility</td>
                    <td className="px-4 py-2">Type</td>
                    <td className="px-4 py-2">Views</td>
                    <td className="px-4 py-2">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {contentQuery.data?.data.map((item, index: number) => {
                    return (
                      <tr>
                        <td
                          className={`border-y ${darkMode ? "border-gray-700" : "border-gray-300"
                            } px-4 py-2`}
                        >
                          <div className="flex gap-4 items-center w-96">
                            <img src={item.thumbnail} className="w-28 aspect-video" />
                            <div>
                              <h1 className="font-semibold">{item.name}</h1>
                              <p className="text-xs">
                                {moment(item.createdAt).format("MMM DD, YYYY")}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td
                          className={`border-y ${darkMode ? "border-gray-700" : "border-gray-300"
                            } px-4 py-2`}
                        >
                          <div className="w-40">
                            <h1 className="cursor-pointer">
                              {item.status === "PUBLIC" ? (
                                <div className="flex items-center gap-2">
                                  <MdVisibility
                                    size={20}
                                    className="text-green-500"
                                  />
                                  <span className="text-sm">Public</span>
                                </div>
                              ) : (
                                <div>
                                  <div className="flex items-center gap-2">
                                    <MdVisibilityOff
                                      size={20}
                                      className="text-red-500"
                                    />
                                    <span className="text-sm">Private</span>
                                  </div>
                                </div>
                              )}
                            </h1>
                          </div>
                        </td>
                        <td
                          className={`border-y ${darkMode ? "border-gray-700" : "border-gray-300"
                            } px-4 py-2`}
                        >
                          <div className="w-40">{typeOfContant(item.type)}</div>
                        </td>
                        <td
                          className={`border-y ${darkMode ? "border-gray-700" : "border-gray-300"
                            } px-4 py-2`}
                        >
                          <div className="w-32">
                            {totalViewsCalculate(item.views)}
                          </div>
                        </td>
                        <td
                          className={`border-y ${darkMode ? "border-gray-700" : "border-gray-300"
                            } px-4 py-2`}
                        >
                          <div>
                            <Link
                              to={`/admin/content/edit/${item._id}`}
                              className="cursor-pointer"
                            >
                              <div
                                className="tooltip tooltip-accent"
                                data-tip="View Analytics"
                              >
                                <Button
                                  className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                  variant="text"
                                >
                                  <VisibilityIcon className="cursor-pointer" />
                                </Button>
                              </div>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </> : <TVOD setAnalyticsType={setAnalyticsType} />
        }
      </Layout>
    </>
  );
};

export default Analytics;