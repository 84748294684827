import axios from "axios";
import { ClientJS } from 'clientjs'
import { publicIpv4 } from 'public-ip';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    // headers: {
    //     "Accept": "application/json",
    //     "Content-Type": "multipart/form-data",
    //     "Access-Control-Allow-Origin": process.env.REACT_APP_URL!,
    // }
});

// list all endpoints

// auth endpoints
export const login = (data: any, headers?: any) => api.post("/auth/login/email", data);
export const logout = () => api.get("/1/auth/logout");

// user endpoints
export const updateUser = (id:string | undefined, data: any) => api.put(`/users/${id}`, data);
export const updateAvatarEndpoint = (id:string | undefined, data: any) => api.put(`/users/avatar/${id}`, data);

// theme endpoints
export const createTheme = (data: any) => api.post("/theme/create", data);
export const updateTheme = (id: string | undefined, data: any) => api.put(`/theme/update/${id}`, data);

// store endpoints
export const updateStore = (id: string | undefined, data: any) => api.put(`/store/update/${id}`, data);


// Products endpoints
export const getProducts = (page: number, limit: number, order: string) => api.get(`/product?page=${page}&limit=${limit}&order=${order}`);
export const createProduct = (data: any) => api.post("/product", data);
export const getProduct = (id: string | undefined) => api.get(`/product/${id}`);
export const updateProduct = (id: string | undefined, data: any) => api.put(`/product/${id}`, data);

// Categories endpoints
export const createCategory = (data: any) => api.post("/category", data);
export const updateCategory = (id: string | undefined, data: any) => api.put(`/category/${id}`, data);

// Brands endpoints
export const updateBrand = (id: string | undefined, data: any) => api.put(`/brand/${id}`, data);

// add headers to all requests using async/await
api.interceptors.request.use(async (config: any) => {
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();
    const browser = client.getBrowser();
    const os = client.getOS();
    const ipaddress = await publicIpv4();

    config.headers["fingerprint"] = fingerprint;
    config.headers["browser"] = browser;
    config.headers["os"] = os;
    config.headers["ipaddress"] = ipaddress;
    return config;
});


// Interceptor for handling 401 errors
// api.interceptors.response.use(
//     (config) => {
//         return config;
//     },
//     async (error) => {
//         const originalRequest = error.config;
//         if (error.response.status === 401 && originalRequest && !originalRequest.isRetry) {
//             originalRequest.isRetry = true;
//             try {
//                 await axios.get(
//                     `${process.env.REACT_APP_API_URL}/auth/refresh`,
//                     {
//                         withCredentials: true,
//                         headers: {
//                             "Content-Type": "application/json",
//                             "Accept": "application/json",
//                         }
//                     }
//                 );

//                 return api.request(originalRequest);
//             } catch (err: any) {
//                 console.log(err.message);
//             }
//         }

//         throw error;
//     }
// );

export default api;