import { createSlice } from '@reduxjs/toolkit'
import { getTheme, getThemes, themeReport, themeSearch } from '../../http/Apis'


export const STATUS = Object.freeze({
    SECCESS: 'secces',
    FAILED: 'failed',
    LOADING: 'loading',
})

const initialState = {
    data: false,
    status: STATUS.SECCESS,
    bulkAction: 'none',
    bulkActionCall: false,
    singleTheme: false,
    themeReport: false,
}

export const ThemesSlice = createSlice({
    name: 'themes',
    initialState,
    reducers: {
        setThemes: (state, actions) => {
            const { data } = actions.payload
            state.data = data
        },
        setStatus: (state, actions) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingleTheme: (state, actions) => {
            const { data } = actions.payload
            state.singleTheme = data
        },
        setThemeReport: (state, actions) => {
            const { data } = actions.payload
            state.themeReport = data
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setThemes,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingleTheme,
    setThemeReport
} = ThemesSlice.actions

export default ThemesSlice.reducer

// Thunks
export function fetchTheme(page = 1, limit = 5, order = 'desc') {
    return async function fetchThemeThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getThemes(page, limit, order);
            dispatch(setThemes(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}
// fetch Reports
export function fetchThemeReport() {
    return async function fetchThemeReportThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await themeReport();
            dispatch(setThemeReport(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchSearchTheme(search) {
    return async function fetchSearchThemeThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await themeSearch(search);
            dispatch(setThemes(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

export function fetchSingleTheme(id) {
    return async function fetchSingleThemeThunk(dispatch) {
        dispatch(setStatus(STATUS.LOADING))
        try {
            const res = await getTheme(id);
            dispatch(setSingleTheme(res.data))
            dispatch(setStatus(STATUS.SECCESS))
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUS.FAILED))
        }
    }
}

