// Author: Pukhraj Dhamu
// Date Created: Thuesday 13 Jul 2023 at 12:24 PM
// Date Modified: Thuesday 13 Jul 2023 at 12:24 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const usersApis = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    }
});

// list all endpoints
export const getUsers = (queryString?: string) => usersApis.get(`/users${queryString ? `?${queryString}` : ""}`).then(res => res.data);
export const createUser = (data: IUserCreate) => usersApis.post(`/users`, data).then(res => res.data);


usersApis.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

export default usersApis;