// Author: Pukhraj Dhamu
// Date Created: Sunday 21 May 2023 at 12:30 AM
// Date Modified: Sunday 21 May 2023 at 12:30 AM
class CustomLocalStorege {
    private _prefix: string;
    constructor(prefix: string) {
        this._prefix = prefix;
    }
    public setItem(key: ILocalstoregeCacheKeys | string, value: any) {
        localStorage.setItem(this._prefix + key, JSON.stringify(value));
    }
    public getItem(key: ILocalstoregeCacheKeys | string) {
        const value = localStorage.getItem(this._prefix + key);
        if (value) {
            return JSON.parse(value);
        }
        return null;
    }
    public removeItem(key: ILocalstoregeCacheKeys | string) {
        localStorage.removeItem(this._prefix + key);
    }
    public clear() {
        localStorage.clear();
    }
}

export const CustomLocalstorege = new CustomLocalStorege("zezotv_");