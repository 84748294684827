// Author: Pukhraj Dhamu
// Date Created: 24 June 2022 (Friday)
// Date Modified: Sunday 14 May 2023 at 7:26 PM
import { Avatar, Button } from "@mui/material"
import React, { useEffect } from "react"
import toast from "react-hot-toast"
import { useSelector, useDispatch } from "react-redux"
import { subtitleApiEndpoint } from "../../../http/Apis"
import { RootState } from "../../../redux/store"
import { InputField } from "../../../tailwind"
import qs from 'qs'
import { fetchAllContant } from "../../../redux/slice​/contentSlice"
import LoadingButton from "@mui/lab/LoadingButton"
import DeleteIcon from '@mui/icons-material/Delete';
import { MuiDialog } from "../../../components"
import _ from "lodash"

interface IContentSubtitleProps {
    content_id: string | undefined
    subtitles: ISubtitle[]
    queryString?: string
}

export interface ISubtitle {
    title: string
    language: string
    type: "text/vtt"
    file: File
}

const ContentEpisodeSubtitle = ({ content_id, subtitles, queryString }: IContentSubtitleProps): JSX.Element => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const dispatch = useDispatch()
    // const handleSubtitle
    const [subtitle, setSubtitle] = React.useState<ISubtitle>()
    const [open, setOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState(false)
    const [deleteLoading, setDeleteLoading] = React.useState(false)
    const [deleteKey, setDeleteKey] = React.useState<string | undefined>(undefined)
    const [subtitleList, setSubtitleList] = React.useState<ISubtitle[]>(subtitles)

    useEffect(() => {
        setSubtitleList(subtitles)
        return () => {
            setSubtitleList([])
        }
    }, [subtitles])

    const handleSubtitle = async (actionType: "add" | "remove", deleteKey?: string) => {
        try {
            if (actionType === "remove") {
                if (!deleteKey) {
                    toast.error('Please select a subtitle first.')
                    return
                }
                setDeleteLoading(true)
                const query = qs.stringify({
                    content_id: content_id,
                    action: 'remove',
                    contentType: 'series',
                })
                const { data, status } = await subtitleApiEndpoint(query, { language: deleteKey })
                if (status === 200) {
                    // remove subtitle from state
                    setSubtitleList(subtitleList.filter((item) => item.language !== deleteKey))
                    dispatch(fetchAllContant(queryString, 'season') as any)
                    toast.success('Subtitle removed successfully.')
                    setDeleteLoading(false)
                    setOpen(false)
                    setDeleteKey(undefined)
                }
            } else if (actionType === "add") {
                if (!subtitle) {
                    toast.error('Please add the subtitle first.')
                    return
                }
                setLoading(true)
                const formData = new FormData()
                formData.append('title', subtitle.title)
                formData.append('language', subtitle.language)
                formData.append('subtitle', subtitle.file)

                const query = qs.stringify({
                    content_id: content_id,
                    action: 'add',
                    contentType: 'series'
                })
                const { data, status } = await subtitleApiEndpoint(query, formData)
                if (status === 200) {
                    // add subtitle to state
                    setSubtitleList([...subtitleList, subtitle])
                    dispatch(fetchAllContant(queryString, 'season') as any)
                    toast.success('Subtitle added successfully.')
                    setSubtitle(undefined)
                    setLoading(false)
                }
            } else {
                toast.error('Something went wrong.')
            }
        } catch (error: any) {
            setLoading(false)
            setDeleteLoading(false)
            toast.error(error.message)
        }
    }
    return (
        <div>
            <div className="">
                <div>
                    <div className='text text-red-500 mb-3'>
                        Note: Subtitle file must be in .vtt format. and language name must be in 2 letter format. eg: en, hi, fr, etc. a list of language codes can be found <a href="https://www.loc.gov/standards/iso639-2/php/code_list.php" target="_blank" className="text-blue-500">here</a>
                    </div>

                    <div>
                        <InputField
                            fieldName="Title"
                            type="text"
                            placeholder="Subtitle Title"
                            value={subtitle?.title || ''}
                            onChange={(e) => setSubtitle({ ...subtitle, title: e.target.value } as any)}
                        />
                        <div className="mt-2">
                            <InputField
                                fieldName="Language"
                                type="text"
                                placeholder="Subtitle Language"
                                value={subtitle?.language || ''}
                                onChange={(e) => setSubtitle({ ...subtitle, language: e.target.value } as any)}
                            />
                        </div>
                        <input
                            type="file"
                            name="subtitleFile"
                            className="hidden"
                            id='subtitleFile'
                            onChange={(e) => {
                                if (e.target.files) {
                                    setSubtitle({ ...subtitle, file: e.target.files[0] } as any)
                                }
                            }}
                        />
                        <div className='my-2'>
                            <label htmlFor='subtitleFile' className='cursor-pointer'>
                                <div className={`${darkMode ? 'border-[#343434]' : 'border-gray-200'} border rounded px-3 flex items-center py-3`}>
                                    <span>{subtitle?.file ? subtitle.file.name : 'Select Subtitle File'}</span>
                                </div>
                            </label>
                        </div>

                        <LoadingButton
                            onClick={() => {
                                // title is required and must be less than 50 characters
                                if (!subtitle?.title || subtitle.title.length > 50) {
                                    toast.error('Subtitle title is required and must be less than 50 characters.')
                                    return
                                }
                                // language is required and must be less than 2 characters
                                if (!subtitle?.language || subtitle.language.length > 2) {
                                    toast.error('Subtitle language is required and must be less than 2 characters.')
                                    return
                                }
                                // subtitle language and file is required
                                if (!subtitle) {
                                    toast.error('I requst you to select a subtitle file.')
                                    return
                                }
                                // subtitle file must be in .vtt format
                                if (subtitle.file.name.split('.').pop() !== 'vtt') {
                                    toast.error('Subtitle file must be in .vtt format')
                                    return
                                }
                                // subtitle file must be less than 50MB
                                if (subtitle.file.size > 50000000) {
                                    toast.error('Subtitle file must be less than 50MB')
                                    return
                                }
                                handleSubtitle("add")
                            }}
                            variant="contained"
                            color="primary"
                            fullWidth
                            loading={loading}
                            disabled={loading}
                        > Add Subtitle </LoadingButton>
                    </div>
                </div>
                <div className="mt-5">
                    <h1 className="my-2">
                        Subtitles
                    </h1>
                    <div className="overflow-x-auto w-full">
                        <table className="table w-full">
                            <thead>
                                <tr>
                                    <th
                                        className={`${darkMode && 'bg-dark-card-color'}`}
                                    >Name</th>
                                    <th
                                        className={`${darkMode && 'bg-dark-card-color'}`}
                                    >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subtitleList.length > 0 ? subtitleList.map((item, index) => (
                                    <tr key={index}>
                                        <td className={`${darkMode && 'bg-dark-card-color'}`}>
                                            <div className="flex items-center space-x-3">
                                                <div className="avatar">
                                                    <div className="mask mask-squircle w-12 h-12">
                                                        <Avatar>
                                                            {item.language.toUpperCase()}
                                                        </Avatar>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="font-bold">
                                                        {item.title}
                                                    </div>
                                                    <div className="text-sm opacity-50 bg-[#1565C0] text-white flex justify-center items-center rounded-full px-2 py-0.5">
                                                        {
                                                            item.type || 'text/vtt'
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`${darkMode && 'bg-dark-card-color'}`}>
                                            <Button
                                                onClick={() => {
                                                    setOpen(true)
                                                    setDeleteKey(item.language)
                                                }}
                                                variant="text">
                                                <DeleteIcon className="cursor-pointer" />
                                            </Button>
                                        </td>
                                    </tr>
                                )) : <tr>
                                    <td colSpan={2} className={`${darkMode && 'bg-dark-card-color'}`}>
                                        <div className="flex justify-center items-center">
                                            <div className="text text-gray-500">
                                                No subtitle found
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <MuiDialog
                open={open}
                onClose={() => setOpen(false)}
                onClickClose={() => setOpen(false)}
                onClickYes={() => {
                    handleSubtitle("remove", deleteKey)
                }}
                title={"Delete subtitle"}
                contentText={"Are you sure you want to delete this subtitle?"}
                buttonOneText={"Cancel"}
                buttonTwoText={"Yes"}
                loading={deleteLoading}
            />

        </div>
    )
}

export default ContentEpisodeSubtitle