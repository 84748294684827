import { useEffect, useState } from 'react'
import { FileUploader } from "react-drag-drop-files";
import toast from 'react-hot-toast';
import axios from 'axios';
import { Line } from "rc-progress";
import { CreatingPresignUrl, createDataForFilemanager, getDataForFilemanager, startTranscoding } from '../../../http/Apis';
import { formatBytes } from '../helpers/helper';
import { Uploader } from '../../../utils/s3upload';
import { useQuery } from '@tanstack/react-query';
import ContentSourceUpdate from './ContentSourceUpdate';
import { updateContentSourceApi } from '../../../http/Content.http';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { getAwsConfigSettingsApi, getSettingsApi } from '../../../http/settingshttp';

interface IDragDrop {
    currDir: string,
    fileTypes: string[],
    setFiles: any,
    setRootDir: any,
    submitData: any,
    handleClose: () => void
}

function DragAndDrop({ currDir, fileTypes, setFiles, setRootDir, handleClose, submitData }: IDragDrop) {
    const [file, setFile] = useState<any | null>();
    const { sourceUpdate } = useSelector((state: RootState) => state.tool)
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploader, setUploader] = useState<any>(undefined)

    const getAwsConfigQuery = useQuery<IGetAwsConfigResponse>({
        queryKey: ['getAwsConfig'],
        queryFn: () => getAwsConfigSettingsApi(),
    })

    // useQuery for get transcoder settings
    const getTranscoderQuery = useQuery<{ data: ITranscoderSetting[] }>({
        queryKey: ["getTranscoderQuery"],
        queryFn: () => getSettingsApi("transcoder"),
        onError: (error) => {
            toast.error("Failed to fetch transcoder settings")
        }
    });

    const decodeBase64Data = (data: string | any): IGetAwsConfigResponse['data'] => {
        const decodedData: IGetAwsConfigResponse['data'] = JSON.parse(atob(data))
        return decodedData
    }

    // update content source
    const updateContentSource = async (payload: {
        job_id: string | null,
        keyName: string | null,
        input: string
    }) => {
        try {
            const job_id = payload.job_id
            const keyName = payload.keyName
            const cloudfrontUrl = decodeBase64Data(getAwsConfigQuery.data?.data).cloudfront.url
            const sourceLink = getTranscoderQuery.data?.data[0].status ? `${cloudfrontUrl}/codec/${keyName}/${keyName}_master.m3u8` : cloudfrontUrl + payload.input

            const fromData: any = {
                source_file_type: getTranscoderQuery.data?.data[0].status ? "HLS" : "MP4",
                source_link: sourceLink,
                source_type: sourceUpdate.sourceType as any
            }
            if (job_id) fromData.job_id = job_id
            if (sourceUpdate.episode_id) {
                fromData.content_type = "series"
                await updateContentSourceApi(sourceUpdate.episode_id, fromData)
                toast.success(`Content ${sourceUpdate.sourceType} source updated successfully`)
            } else if (sourceUpdate.content_id) {
                fromData.content_type = "movie"
                await updateContentSourceApi(sourceUpdate.content_id, fromData)
                toast.success(`Content source updated successfully`)
            } else {
                toast.error("Something went wrong")
            }
        } catch (error) {
            toast.error("Something went wrong")
        }
    }

    // upload file to s3
    const handleUploadFile = async () => {
        try {
            if (!file) return toast.error("No file selected")
            // Make a api call to server to get presigned url
            // remove first slash from currDir
            const currLocation = currDir.replace(/^\//, "")
            const target_upload_folder = currLocation === "" ? "/" : currLocation
            // if file size is greater than 4GB then upload file in chunks
            if (file.size > 4000000000) {
                const videoUploaderOptions = {
                    file_key: file?.name || "",
                    target_upload_folder: target_upload_folder,
                    file: file,
                }

                let percentage: any = undefined

                const uploader = new Uploader(videoUploaderOptions)
                setUploader(uploader)

                uploader.onProgress(async ({ percentage: newPercentage }: any) => {
                    // to avoid the same percentage to be logged twice
                    if (newPercentage !== percentage) {
                        percentage = newPercentage
                        setUploadProgress(percentage)
                        if (percentage === 100) {
                            const fileObject = {
                                name: file.name,
                                key: file.name,
                                type: "file",
                                size: file.size,
                            };
                            try {
                                let res = await createDataForFilemanager(
                                    fileObject,
                                    `?parent_dir=${currDir}`
                                );
                                if (res.status === 200) {
                                    const query = `?key=${currDir}&page=1&limit=10`;
                                    let res = await getDataForFilemanager(query);
                                    if (res.data.data) {
                                        let dataArr: any = [
                                            ...res.data.data.folder,
                                            ...res.data.data.file,
                                        ];
                                        setFiles(dataArr as any);
                                        setRootDir(true);
                                    }
                                    // if input is .mp4 then start transcoding
                                    if (file.name.split(".").pop() === "mp4") {
                                        submitData.input = currDir + file.name;
                                        submitData.label = file.name;
                                        if (getTranscoderQuery.isSuccess && getTranscoderQuery.data?.data?.length > 0 && getTranscoderQuery.data?.data[0].status) {
                                            const transcodingData = await startTranscoding(submitData)
                                            const jobData = transcodingData.data.data.data
                                            updateContentSource({
                                                job_id: jobData.job_id,
                                                keyName: jobData.keyName,
                                                input: submitData.input
                                            })
                                            toast.success("Transcoding started successfully");
                                        } else {
                                            updateContentSource({
                                                job_id: null,
                                                keyName: null,
                                                input: submitData.input
                                            })
                                            // now warn user to transcoder is not enabled
                                            toast.custom((t) => (
                                                <div
                                                    className={`${t.visible ? 'animate-enter' : 'animate-leave'
                                                        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                                                >
                                                    <div className="flex-1 w-0 p-4">
                                                        <div className="flex items-start">
                                                            <div className="flex-shrink-0 pt-0.5">
                                                                <img
                                                                    className="h-10 w-10"
                                                                    src="https://res.cloudinary.com/dm4uaqlio/image/upload/v1688804905/Zezo%20Ott/danger_obrqt2.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="ml-3 flex-1">
                                                                <p className="text-sm font-medium text-gray-900">
                                                                    Transcoder Warning
                                                                </p>
                                                                <p className="mt-1 text-sm text-gray-500">
                                                                    Transcoder is not enabled. Please enable transcoder from settings. <a href={`/admin/transcoding/settings`}>
                                                                        <span className="text-blue-500">Click here</span>
                                                                    </a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex border-l border-gray-200">
                                                        <button
                                                            onClick={() => toast.dismiss(t.id)}
                                                            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                        >
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    }
                                }
                            } catch (error) {
                                if (axios.isAxiosError(error)) {
                                    let err: any = error.response;
                                    return toast.error(err.data.error.message);
                                }
                            }

                            toast.success("File uploaded successfully");
                            setTimeout(() => {
                                handleClose();
                                setFile(null);
                                setUploadProgress(0);
                            }, 1000);
                        }
                    }

                }).onError((error: any) => {
                    setFile(undefined)
                })

                uploader.start()

            } else {
                const { data: presignedData, status } = await CreatingPresignUrl({
                    file_key: file?.name || "",
                    target_upload_folder: target_upload_folder
                })
                // upload file to s3 with presigned url and update progress bar 
                const response = await axios.put(presignedData.data, file, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        setUploadProgress(percent);
                    }
                })
                if (response.status === 200) {
                    const fileObject = {
                        name: file.name,
                        key: file.name,
                        type: "file",
                        size: file.size,
                    };
                    try {
                        let res = await createDataForFilemanager(
                            fileObject,
                            `?parent_dir=${currDir}`
                        );
                        if (res.status === 200) {
                            const query = `?key=${currDir}&page=1&limit=10`;
                            let res = await getDataForFilemanager(query);
                            if (res.data.data) {
                                let dataArr: any = [
                                    ...res.data.data.folder,
                                    ...res.data.data.file,
                                ];
                                setFiles(dataArr as any);
                                setRootDir(true);
                            }
                            // if input is .mp4 then start transcoding
                            if (file.name.split(".").pop() === "mp4") {
                                submitData.input = currDir + file.name;
                                submitData.label = file.name;
                                if (getTranscoderQuery.isSuccess && getTranscoderQuery.data?.data?.length > 0 && getTranscoderQuery.data?.data[0].status) {
                                    const transcodingData = await startTranscoding(submitData)
                                    const jobData = transcodingData.data.data.data
                                    updateContentSource({
                                        job_id: jobData.job_id,
                                        keyName: jobData.keyName,
                                        input: submitData.input
                                    })
                                    toast.success("Transcoding started successfully");
                                } else {
                                    updateContentSource({
                                        job_id: null,
                                        keyName: null,
                                        input: submitData.input
                                    })
                                    // now warn user to transcoder is not enabled
                                    toast.custom((t) => (
                                        <div
                                            className={`${t.visible ? 'animate-enter' : 'animate-leave'
                                                } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                                        >
                                            <div className="flex-1 w-0 p-4">
                                                <div className="flex items-start">
                                                    <div className="flex-shrink-0 pt-0.5">
                                                        <img
                                                            className="h-10 w-10"
                                                            src="https://res.cloudinary.com/dm4uaqlio/image/upload/v1688804905/Zezo%20Ott/danger_obrqt2.png"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="ml-3 flex-1">
                                                        <p className="text-sm font-medium text-gray-900">
                                                            Transcoder Warning
                                                        </p>
                                                        <p className="mt-1 text-sm text-gray-500">
                                                            Transcoder is not enabled. Please enable transcoder from settings. <a href={`/admin/transcoding/settings`}>
                                                                <span className="text-blue-500">Click here</span>
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex border-l border-gray-200">
                                                <button
                                                    onClick={() => toast.dismiss(t.id)}
                                                    className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                }
                            }
                        }
                    } catch (error) {
                        if (axios.isAxiosError(error)) {
                            let err: any = error.response;
                            return toast.error(err.data.error.message);
                        }
                    }

                    toast.success("File uploaded successfully");
                    setTimeout(() => {
                        handleClose();
                        setFile(null);
                        setUploadProgress(0);
                    }, 1000);
                }
            }

        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    // if file available then upload to s3
    useEffect(() => {
        if (file) handleUploadFile();
    }, [file]);

    const handleChange = (file: any) => {
        setFile(file);
    };

    return (
        <>
            <ContentSourceUpdate />
            <FileUploader
                handleChange={handleChange}
                name="file"
                children={
                    <div className="flex flex-col w-[35rem] my-5">
                        <div className="border-2 border-gray-500 px-16 py-5 border-dashed rounded flex flex-col items-center justify-between mx-5">
                            <div className="w-32 h-20 rounded-md flex items-center justify-center">
                                <img src={require("../../../assets/img/upload.png")} />
                            </div>
                            <p className="text-sm text-gray-500">
                                Drag and drop your files here
                            </p>
                            <p className="my-1 text-gray-400">OR</p>
                            <button className="bg-blue-500 text-white px-6 py-1.5 rounded-md">
                                Browse
                            </button>
                        </div>
                        <div className="mt-4 mx-5">
                            <div>
                                {file && (
                                    <div className="flex items-center justify-between my-2">
                                        <div className="flex items-center">
                                            <div className="ml-3">
                                                <p>Upload {uploadProgress}%</p>
                                                <Line
                                                    percent={uploadProgress}
                                                    strokeWidth={4}
                                                    strokeColor={uploadProgress === 100 ? "#52c41a" : "#D3D3D3"}
                                                />
                                                <p className="text-sm text-gray-500">{file.name}</p>
                                                <p className="text-xs text-gray-400">
                                                    {formatBytes(file.size)}
                                                </p>
                                            </div>
                                        </div>
                                        <button className="text-blue-500">remove</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
                types={fileTypes}
            />
        </>

    );
}

export default DragAndDrop