import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { deteleImageInContentImages, getImageSizes } from '../../../http/contents.http'
import { Loader } from '../../../components/smallComponents/loader'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { FiEdit, FiDelete } from 'react-icons/fi'
import { AiOutlinePlus } from 'react-icons/ai'
import { getContent } from '../../../http/Apis'
import qs from 'qs'
import Dialogs from "../../../components/Mui/Dialogs/Dialog"
import { toast } from 'react-hot-toast'
import axios from 'axios'
import { Link } from 'react-router-dom'


interface IProps {
    id: string | undefined
}

function ContentImages({ id }: IProps) {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const [content, setContent] = React.useState<IContentData>()
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
    const [deleteId, setDeleteId] = useState<string>('')
    // update and create state
    const [image_id, setImage_id] = useState<string>()
    const [update_image_id, setUpdate_image_id] = useState<string>()
    const [image, setImage] = useState<File | null>(null)
    const [type, setType] = useState<"create" | "update">("create")

    const contentImagesQuery = useQuery<IContentImagesResponse>({
        queryKey: ['contentImages'],
        queryFn: () => getImageSizes()
    })
    const queryString = qs.stringify({
        id
    })
    const contentQuery = useQuery<IContent>({
        queryKey: ['content', id],
        queryFn: () => getContent(queryString),
        onSuccess(data) {
            if (data.data && data.data.length > 0) setContent(data.data[0])
        },
    })

    // get contentimages by id from content
    function getContentImagesById(id: string): IContentImagesInContent | undefined {
        if (!content?.images) return undefined;
        const images = content.images.find(image => image.image_id.toString() === id)
        return images || undefined
    }



    // detele mutation
    const deleteMutation = useMutation({
        mutationKey: ['deleteImageSize'],
        mutationFn: () => deteleImageInContentImages({
            content_id: id as string,
            update_image_id: deleteId
        }),
        onSuccess() {
            toast.success('Image Size Deleted Successfully')
            setDeleteDialog(false)
            contentImagesQuery.refetch()
            contentQuery.refetch()
        },
        onError(error: any, variables, context) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
        },
    })

    function triggerDelete() {
        if (!deleteId || !id) return toast.error('Invalid id')
        deleteMutation.mutate()
    }


    async function triggerUpload() {
        try {
            if (!image_id) return toast.error('Invalid id')
            const formData = new FormData()
            formData.append('content_id', id as string)
            formData.append('image_id', image_id)
            formData.append('type', type)
            if (update_image_id) formData.append('update_image_id', update_image_id)
            if (!image) return toast.error('Please select an image')
            formData.append('image', image as any)

            const { status } = await axios.put(`${process.env.REACT_APP_BASE_URL}/service/content/images/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            })
            if (status === 200) {
                toast.success('Image Uploaded Successfully')
                contentImagesQuery.refetch()
                contentQuery.refetch()
                setImage(null)

            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
        }


    }

    useEffect(() => {
        if (!image) return
        triggerUpload()
    }, [image])




    return (
        <div className="pr-5 mb-20">
            <h1 className="my-6 opacity-80 font-medium tracking-wide text-xl pr-5">
                Images Sizes
            </h1>
            {contentImagesQuery.isLoading && (
                <div className='w-full flex justify-center items-center min-h-[47rem]'>
                    <Loader smail />
                </div>
            )}
            {contentImagesQuery.isError && (
                <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                    <h1 className='text-xl font-medium opacity-90'>
                        Server Error. Please try again
                    </h1>
                    <div
                        onClick={() => contentImagesQuery.refetch()}
                        className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                        <h1 className='text-sm text-white'>
                            Retry
                        </h1>
                    </div>
                </div>
            )}
            {contentImagesQuery.isSuccess && (
                <div className='w-full h-full'>
                    {
                        contentImagesQuery.data.data.length === 0 && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                            <h1 className='text-xl font-medium opacity-90'>
                                No Images Sizes Found
                            </h1>
                            <Link
                                to={`/admin/settings/content-images`}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                <h1 className='text-sm text-white'>
                                    Create
                                </h1>
                            </Link>
                        </div>
                    }
                    <div className='relative'>
                        {contentImagesQuery.data.data.length > 0 && <div className='grid grid-cols-4 gap-16 absolute overflow-hidden'>
                            {contentImagesQuery.data.data.map((image, index) => {
                                return (
                                    <div key={index}>
                                        <div
                                            style={{
                                                backgroundImage: `url(${getContentImagesById(image._id)?.sizes?.original})`,
                                                backgroundSize: 'contain',
                                            }}
                                            className={`w-72 h-72 border-blue-500 border-4`}>
                                            <div className='hover:bg-blue-500 hover:bg-opacity-50 duration-500 cursor-pointer transition-all w-[285px] h-[285px] absolute'>
                                                <div className='opacity-0 hover:opacity-100 duration-300 inset-0 z-10 flex items-center justify-center w-full h-full gap-10'>
                                                    <input
                                                        type="file"
                                                        name="image"
                                                        id="upload_content_image"
                                                        className='hidden'
                                                        onChange={(e) => {
                                                            if (!e.target.files) return
                                                            setImage(e.target.files[0])
                                                        }}
                                                    />
                                                    {getContentImagesById(image._id)?.id ? <FiEdit
                                                        onClick={() => {
                                                            setImage_id(image._id)
                                                            setUpdate_image_id(getContentImagesById(image._id)?.id as string)
                                                            setType('update')
                                                            // open file input
                                                            document.getElementById('upload_content_image')?.click()
                                                        }}
                                                        className='text-blue-500 text-3xl z-10'
                                                    /> : <AiOutlinePlus
                                                        onClick={() => {
                                                            setImage_id(image._id)
                                                            setType('create')
                                                            // open file input
                                                            document.getElementById('upload_content_image')?.click()
                                                        }}
                                                        className='text-white text-3xl z-10'
                                                    />
                                                    }
                                                    {getContentImagesById(image._id)?.id && <FiDelete
                                                        onClick={() => {
                                                            setDeleteDialog(true)
                                                            setDeleteId(getContentImagesById(image._id)?.id as string)
                                                        }}
                                                        className='text-red-500 text-3xl z-10'
                                                    />}
                                                </div>
                                            </div>
                                        </div>
                                        <p className='text-center text-sm py-2'>
                                            {image.name} ({image.width}x{image.height})
                                        </p>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>
                </div>
            )}

            <Dialogs
                open={deleteDialog}
                title="Delete Image Size"
                onClose={() => setDeleteDialog(false)}
                onClickClose={() => setDeleteDialog(false)}
                buttonOneText='Cancel'
                buttonTwoText='Delete'
                loading={deleteMutation.isLoading}
                contentText='Are you sure you want to delete this image size?'
                onClickYes={triggerDelete}
            />

        </div>
    )
}

export default ContentImages