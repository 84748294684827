import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addRelease, contactAction, getRelease, updateRelease, deleteRelease } from "../../http/Apis";
import { setBulkActionCall } from "../../redux/slice​/movieSlice";
import moment from "moment";
import "react-circular-progressbar/dist/styles.css";
import { Hr, MuiDialog } from "../../components";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import Button from "@mui/material/Button";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchIcon from "@mui/icons-material/Launch";
import { fetchAllContant } from "../../redux/slice​/contentSlice";
import { secondsToHms } from "../../utils/Utils";
import Checkbox from "@mui/material/Checkbox";

const ContantTable = ({ data, query }) => {
  const dispatch = useDispatch();
  const [allSelectArray, setallSelectArray] = useState([]);
  const { bulkAction, bulkActionCall } = useSelector((state) => state.movies);
  const { darkMode } = useSelector((state) => state.theme);
  const [singleSelect, setSingleSelect] = useState([]);
  const [status, setStatus] = useState(false);
  const [bulkDelete, setBulkDelete] = useState(false);

  // @holds new release id
  const [releaseID, setReleaseID] = useState("");

  const [muiDialogContant, setMuiDialogContant] = useState({
    title: "",
    contentText: "",
    buttonOneText: "",
    buttonTwoText: "",
    twoAction: false,
    actionKey: false,
    bulkDeleteData: false,
  });

  // status dialog
  const [muiDialogOpen, setMuiDialogOpen] = useState(false);
  const handleMuiDialogOpen = () => {
    setMuiDialogOpen(true);
  };
  const handleMuiDialogClose = () => {
    setMuiDialogOpen(false);
    setTimeout(() => {
      setMuiDialogContant({
        title: "",
        contentText: "",
      });
    }, 100);
  };

  // handleStatus
  const handleAction = async () => {
    const data = {
      id: singleSelect,
      action: status,
    };
    try {
      const response = await contactAction(data);
      if (response.status === 200) {
        toast.success("Status updated successfully");
        dispatch(fetchAllContant(query));
        handleMuiDialogClose(false);

        if (status === "private") {
          setReleaseID("");
        }
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.message || "Something went wrong"
      );
    }
  };

  const handleDeleteForeverdata = {
    id: singleSelect,
    action: "delete",
  };
  // handleDelete
  const handleDeleteForever = async (data) => {
    try {
      const response = await contactAction(data);
      if (response.status === 200) {
        toast.success("Status updated successfully");
        dispatch(fetchAllContant(query));
        handleMuiDialogClose(false);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.message || "Something went wrong"
      );
      handleMuiDialogClose(false);
    }
  };

  // headle checkbox
  const headleCheckbox = (e) => {
    const { name: id, checked } = e.target;
    if (id === "selectAll") {
      if (checked) {
        setallSelectArray(data.map((data) => data._id));
      } else {
        setallSelectArray([]);
      }
    } else {
      setallSelectArray((prev) => {
        if (checked) {
          return [...prev, id];
        } else {
          return prev.filter((item) => item !== id);
        }
      });
    }
  };

  useEffect(() => {
    if (bulkActionCall) {
      headleBulkActions();
      dispatch(setBulkActionCall(false));
    }
  }, [bulkActionCall]);

  // headle bulk actions
  const headleBulkActions = async () => {
    const formData = new FormData();
    if (allSelectArray.length < 1) {
      formData.append("id", allSelectArray);
    } else {
      for (let i = 0; i < allSelectArray.length; i++) {
        formData.append("id", allSelectArray[i]);
      }
    }
    const newFormData =
      allSelectArray.length > 0
        ? { id: allSelectArray, action: bulkAction }
        : formData;

    if (bulkAction === "none") {
      toast.error("Please select bulk action");
    } else {
      if (bulkAction === "delete") {
        setStatus("restore");
        handleMuiDialogOpen(true);
        setMuiDialogContant({
          title: "Restore from Trash",
          contentText:
            "Are you sure you want to restore this contant from trash?",
          buttonOneText: "Delete Forever",
          buttonTwoText: "Restore",
          twoAction: true,
          actionKey: "restore",
          bulkDeleteData: newFormData,
        });
      } else {
        try {
          const response = await contactAction(newFormData);
          if (response.status === 200) {
            toast.success("Bulk action performed successfully");
            dispatch(fetchAllContant(query));
          }
        } catch (error) {
          toast.error(
            error.response.data.error.message || "Something went wrong"
          );
        }
      }
    }
  };

  // type of contant type
  const typeOfContant = (type) => {
    switch (type) {
      case "movie":
        return "Movie";
      case "series":
        return "Series";
      case "live_stream":
        return "Live Stream";
      default:
        return "None";
    }
  };

  // @useEffect for the handling new Realeases
  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getRelease();
        console.log(res);
        if (res.status === 200) {
          setReleaseID(res.data.data.content_id._id);
        }
      } catch (error) {
        return console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleNewReleaseAdd = async (id) => {
    try {
      if (!id) {
        return console.log("All fields required..");
      }

      let dataObj = {
        contentID: id,
      };

      let res = await addRelease(dataObj);
      console.log(res);

      if (res.status === 200) {
        setReleaseID(id);
        toast.success(res.data.message);
      }
    } catch (error) {
      return console.log(error);
    }
  };

  const handleUpdateRelease = async (id) => {
    try {
      if (!id) {
        return console.log("All fields required..");
      }

      let dataObj = {
        contentID: id,
      };

      let res = await updateRelease(dataObj);
      console.log(res);

      if (res.status === 200) {
        setReleaseID(id);
        toast.success("Pop updated");
      }
    } catch (error) {
      return console.log(error);
    }
  };

  const handleDeleteRelease = async () => {
    try {
      let res = await deleteRelease();

      console.log(res);

      if (res.status === 200) {
        setReleaseID("");
        toast.success(res.data.message);
      }
    } catch (error) {
      return console.log(error);
    }
  };

  return (
    <div className="w-full">
      <div className="w-full">
        <div className="flex items-center">
          <div className="w-11 -mt-2.5 -ml-2.5">
            <div className="tooltip tooltip-right" data-tip="Select All">
              <Checkbox
                checked={allSelectArray.length === data.length}
                onChange={headleCheckbox}
                name="selectAll"
                color="primary"
              />
              {/* <label>
                                <input
                                    type="checkbox"
                                    className="checkbox checkbox-primary border-blue-500 checked:bg-blue-500"
                                    name="selectAll"
                                    checked={allSelectArray.length === data.length}
                                    onChange={headleCheckbox}
                                />
                            </label> */}
            </div>
          </div>
          <div className="grid grid-flow-row grid-cols-12 gap-3 pr-5 mb-2.5 w-full">
            <div className="col-span-4">
              <h1>Content</h1>
            </div>
            <div className="col-span-2">
              <h1>Visibility</h1>
            </div>
            <div className="col-span-2">
              <h1>Type</h1>
            </div>
            <div className="col-span-2">
              <h1>Date</h1>
            </div>

            <div className="col-span-2">
              <h1>Actions</h1>
            </div>
          </div>
        </div>
        <Hr />
        {data && data.length > 0
          ? data.map((item, index) => {
            const lastIndex = data.length - 1;
            return (
              <div
                key={index}
                className={`flex my-3 ${lastIndex === index ? "" : "border-b"
                  } ${darkMode ? "border-[#343434]" : "border-gray-200"}`}
              >
                <div className="w-11 -mt-2.5 -ml-2.5">
                  <Checkbox
                    checked={allSelectArray.includes(item._id)}
                    onChange={headleCheckbox}
                    name={item._id}
                    color="primary"
                  />
                </div>
                <div className="grid grid-flow-row grid-cols-12 gap-3 pr-5 mb-2.5 w-full">
                  <div className="col-span-4">
                    <div className="flex">
                      <div className="w-32 h-[70px] aspect-video rounded overflow-hidden relative">
                        <img
                          src={item.thumbnail || "/default-product.png"}
                          className={`${darkMode ? "bg-dark-color-box" : "bg-day-box-color"} w-full h-full`}
                          alt={item.name}
                        />
                        <span className="absolute bottom-1.5 right-1.5 rounded bg-black opacity-80 w-fit px-2 text-sm text-white">
                          {secondsToHms(item.duration, true)}
                        </span>
                      </div>
                      <div className="ml-3 w-60">
                        <h1 className="text-base line-clamp-1">
                          {item.name}
                        </h1>
                        <p className="text-gray-400 text-xs mt-1 line-clamp-2">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="w-full cursor-pointer">
                      <label
                        onClick={() => {
                          setSingleSelect([item._id]);
                          setStatus(
                            item.status === "PUBLIC" ? "private" : "public"
                          );
                          handleMuiDialogOpen(true);
                          setMuiDialogContant({
                            title: "Change Status",
                            contentText:
                              "Are you sure you want to change the status of this contant?",
                            buttonOneText: "Cancel",
                            buttonTwoText: "Yes",
                          });
                        }}
                      >
                        <h1 className="cursor-pointer">
                          {item.status === "PUBLIC" ? (
                            <div className="flex items-center gap-2">
                              <MdVisibility
                                size={20}
                                className="text-green-500"
                              />
                              <span className="text-sm">Public</span>
                            </div>
                          ) : (
                            <div>
                              <div className="flex items-center gap-2">
                                <MdVisibilityOff
                                  size={20}
                                  className="text-red-500"
                                />
                                <span className="text-sm">Private</span>
                              </div>
                            </div>
                          )}
                        </h1>
                      </label>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <h1>{typeOfContant(item.type)}</h1>
                  </div>
                  <div className="col-span-2">
                    <h1 className="capitalize">
                      {moment(item.createdAt).format("MMM DD, YYYY")}
                    </h1>
                    <p className="text-xs mt-0.5">Published</p>
                  </div>
                  <div className="col-span-2 flex">
                    {item.trash ? (
                      <div
                        className="tooltip tooltip-accent"
                        data-tip="Restore"
                      >
                        {" "}
                        <Button
                          onClick={() => {
                            setSingleSelect([item._id]);
                            setStatus("restore");
                            handleMuiDialogOpen(true);
                            setMuiDialogContant({
                              title: "Restore from Trash",
                              contentText:
                                "Are you sure you want to restore this contant from trash?",
                              buttonOneText: "Delete Forever",
                              buttonTwoText: "Restore",
                              twoAction: true,
                              actionKey: "restore",
                            });
                          }}
                          variant="text"
                        >
                          <RestoreFromTrashIcon className="cursor-pointer" />
                        </Button>{" "}
                      </div>
                    ) : (
                      <div
                        className="tooltip tooltip-accent"
                        data-tip="Delete"
                      >
                        {" "}
                        <Button
                          onClick={() => {
                            setSingleSelect([item._id]);
                            setStatus("trash");
                            handleMuiDialogOpen(true);
                            setMuiDialogContant({
                              title: "Move to Trash",
                              contentText:
                                "Are you sure you want to move this contant to trash?",
                              buttonOneText: "Cancel",
                              buttonTwoText: "Yes",
                            });
                          }}
                          variant="text"
                        >
                          <DeleteIcon className="cursor-pointer" />
                        </Button>
                      </div>
                    )}
                    <Link
                      to={`/admin/content/edit/${item._id}`}
                      //to={RoutesStrings.Content.Children.EditContent}

                      className="cursor-pointer"
                    >
                      <div className="tooltip tooltip-accent" data-tip="Edit">
                        <Button
                          className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                          variant="text"
                        >
                          <EditIcon className="cursor-pointer" />
                        </Button>
                      </div>
                    </Link>

                    <div
                      className="tooltip tooltip-accent"
                      data-tip="Make PopUp"
                    >
                      <Button
                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                        variant="text"
                        disabled={item.status === "PUBLIC" ? false : true}
                        color={
                          releaseID === item._id ? `secondary` : `primary`
                        }
                        onClick={() => {
                          // console.log(item._id);

                          if (releaseID === item._id) {
                            return handleDeleteRelease();
                          }

                          releaseID === ""
                            ? handleNewReleaseAdd(item._id)
                            : handleUpdateRelease(item._id);
                        }}
                      >
                        <LaunchIcon className="cursor-pointer" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
          : null}
        <div className="-mt-3">
          <Hr />
        </div>
      </div>
      {/* Dialog */}
      <MuiDialog
        open={muiDialogOpen}
        onClose={handleMuiDialogClose}
        title={muiDialogContant.title}
        contentText={muiDialogContant.contentText}
        onClickClose={() =>
          muiDialogContant.twoAction
            ? handleDeleteForever(
              muiDialogContant.bulkDeleteData
                ? muiDialogContant.bulkDeleteData
                : handleDeleteForeverdata
            )
            : handleMuiDialogClose()
        }
        onClickYes={handleAction}
        buttonOneText={muiDialogContant.buttonOneText}
        buttonTwoText={muiDialogContant.buttonTwoText}
        buttonTwoTitle="Yes"
      />
    </div>
  );
};

export default ContantTable;
