// Author: Pukhraj Dhamu
// Created Date: Saturday, July 15th 2023 at 12:18 PM
// Last Modified Date: Saturday, July 15th 2023 at 12:18 PM
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect, useState } from "react";
import { Hr } from "../../components";
import Layout from "../../components/Layout/Layout";
import SaveIcon from "@mui/icons-material/Save";
import { Switch } from "@mui/material";
import { InputField } from "../../tailwind";
import axios from "axios";
import toast from "react-hot-toast";
import { createWhatsAppCredential, getWhatsAppCredential, setWhatsAppAction } from "../../http/Apis";

function WhatsAppSetting() {
  const [loading, setLoading] = useState(false);
  const [isInDb, setIsInDb] = useState(false)
  const [twilio, setTwilio] = useState({
    sid: "",
    token: "",
    number: "",
    status: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getWhatsAppCredential();
        setTwilio(res.data.result);
        if (res.data.result.sid !== "") {
          setIsInDb(true)
        }
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const err: any = error.response;
          return toast.error(err.data.error.message);
        }
        return console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      let key: keyof typeof twilio;
      for (key in twilio) {
        if (twilio[key] === "") {
          return toast.error(`${key} field required...`)
        }
      }

      setLoading(true);
      let res = await createWhatsAppCredential(twilio);
      if (res.status === 200) {
        const msg = res.data.message;
        setLoading(false);
        res = await getWhatsAppCredential();
        setTwilio(res.data.result);
        setIsInDb(true)
        return toast.success(msg);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        setLoading(false);
        return toast.error(err.data.error.message);
      }
      setLoading(false);
      return console.log(error);
    }
  };

  const handleAction = async () => {
    try {
      const res = await setWhatsAppAction();

      if (res.status === 200) {
        return toast.success(res.data.message);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        return toast.error(err.data.error.message);
      }

      return console.log(error);
    }
  };

  return (
    <Layout>
      <div className="pr-5">
        <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
          WhatsApp API Settings
        </h1>
        <p className="mt-2 opacity-70">
          You can update your WhatsApp API settings here.
        </p>
      </div>
      <div className="my-5 pr-6">
        <Hr />
      </div>

      <div className="my-5 pr-6 flex flex-col gap-8">
        <div className="flex items-center gap-2">
          <h1 className="text-xl opacity-80 font-semibold tracking-wide">
            Twilio
          </h1>
          <span>
            <span className="text-green-500">(Available)</span>
          </span>
        </div>
        <InputField
          title={"Twilio AUTHORIZATION Token"}
          fieldName={"AUTHORIZATION Token"}
          type="text"
          placeholder={"Enter twilio authorization Token"}
          value={twilio.token}
          onChange={(e) => {
            setTwilio({
              ...twilio,
              ["token"]: e.target.value,
            });
          }}
        />
        <InputField
          title={"Twilio SID"}
          fieldName={"fieldname"}
          type="text"
          placeholder={"Enter Twilio SID"}
          value={twilio.sid}
          onChange={(e) => {
            setTwilio({
              ...twilio,
              ["sid"]: e.target.value,
            });
          }}
        />
        <InputField
          title={"Twilio Phone Number"}
          fieldName={"fieldname"}
          type="text"
          placeholder={"Enter Twilio Phone Number"}
          value={twilio.number}
          onChange={(e) => {
            setTwilio({
              ...twilio,
              ["number"]: e.target.value,
            });
          }}
        />
        <div className="flex items-center gap-4">
          <h1 className="text-lg opacity-80 font-semibold tracking-wide">
            {"Twilio"} Status
          </h1>
          {isInDb ? <Switch
            onChange={(e: any) => {
              setTwilio({
                ...twilio,
                ["status"]: !twilio.status,
              });
              handleAction()
            }}
            disabled={
              twilio.sid === "" || twilio.number === "" || twilio.token === "" ? true : false
            }
            checked={twilio.status}
            name="available"
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
          /> : ""}
        </div>
        <div className="my-4"></div>
      </div>

      <div className="pr-35">
        <div className="flex justify-end pr-28 mb-20">
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </Layout>
  );
}

export default WhatsAppSetting;
