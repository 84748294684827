import React from "react";
import NewSection from "../Pages/NewSections/Sections";
import AddNewNewSection from "../Pages/NewSections/AddNewSection";
import EditNewSection from "../Pages/NewSections/EditSection";
import {
  Dashboard,
  Users,
  EditUser,
  // Subscriptions Components
  Subscriptions,
  EditSubscriptions,
  // Genres Components
  Genres,
  EditGenre,
  AddNewGenre,
  // categories Components
  Category,
  EditCategory,
  // language Components
  Language,
  EditLanguage,
  // AddNewLanguage,
  // cast Components
  Cast,
  EditCast,
  AddNewCast,
  // slider Components
  Slider,
  EditSlider,
  AddNewSlider,
  // sction Components
  Section,
  EditSection,
  AddNewSection,
  // file manager Components
  FileManager,
  // settings Components
  Settings,
  SeoSetting,
  PaymentSettings,
  AdMobSettings,
  SMTPSetting,
  SMSSetting,
  WhatsAppSetting,
  // marketing Components
  PushNotification,
  EmailMarketing,
  SMSMarketing,
  WhatsappMarketing,
  // RTMP server Components
  RtmpDashboard,
  RtmpStreams,
  RtmpProfile,
  // Transcoding server Components
  TranscodingDashboard,
  Content,
  CreateContent,
  EditContent,
  EditContentSeason,
  AppSettings,
  // WebSettings Components
  WebSettings,
  PagesPage,
  CreateBlog,
  TranscoderSettings,
  MarketingSettings,
  Analytics,
  AdminUserSettings,
  // Contentpatners,
  // EditContentPartner,
  Country,
  Coupon,
  AdNetwork,
  CreateTranscodingJob,
  ViewTranscodingJob,
  AwsConfig,
} from "../Pages";
import EditBlogs from "../Pages/Blogs/EditBlogs";

import RouteStrings from "./routes.json";
import MarketingTemplates from "../Pages/Marketing/MarketingTemplates";
import CreateMarketingTemplate from "../Pages/Marketing/components/templates/Create";
import EditMarketingTemplate from "../Pages/Marketing/components/templates/Edit";
import CreateAd from "../Pages/Ads/CreateAd";
import EditAds from "../Pages/Ads/EditAds";
import ContentImagesSettings from "../Pages/Settings/ImageSizes";
import AuthProviders from "../Pages/Auth/AuthProvider/AuthProvider";

export interface IAllRoutes {
  path: string;
  search?: string;
  element: React.ReactNode;
}

export const AllRoutes: IAllRoutes[] = [
  {
    path: `${RouteStrings.Overview.Link}`,
    element: <Dashboard />,
  },
  {
    path: `${RouteStrings.Auth.Link}`,
    element: <AuthProviders />,
  },
  {
    path: `${RouteStrings.Users.Link}`,
    element: <Users />,
  },
  {
    path: `${RouteStrings.Users.Children.EditUser.Link}`,
    element: <EditUser />,
  },
  {
    path: `${RouteStrings.Genres.Link}`,
    element: <Genres />,
  },
  {
    path: `${RouteStrings.Genres.Children.EditGenre.Link}`,
    element: <EditGenre />,
  },
  {
    path: `${RouteStrings.Genres.Children.CreateGenre.Link}`,
    element: <AddNewGenre />,
  },
  {
    path: `${RouteStrings.Categories.Link}`,
    element: <Category />,
  },
  {
    path: `${RouteStrings.Categories.Children.EditCategory.Link}`,
    element: <EditCategory />,
  },
  {
    path: `${RouteStrings.Languages.Link}`,
    element: <Language />,
  },
  {
    path: `${RouteStrings.Languages.Children.EditLanguage.Link}`,
    element: <EditLanguage />,
  },
  {
    path: `${RouteStrings.CastAndCrew.Link}`,
    element: <Cast />,
  },
  {
    path: `${RouteStrings.CastAndCrew.Children.EditCastAndCrew.Link}`,
    element: <EditCast />,
  },
  {
    path: `${RouteStrings.CastAndCrew.Children.CreateCastAndCrew.Link}`,
    element: <AddNewCast />,
  },
  {
    path: `${RouteStrings.Subscriptions.Link}`,
    element: <Subscriptions />,
  },
  {
    path: `${RouteStrings.Subscriptions.Children.EditSubscription.Link}`,
    element: <EditSubscriptions />,
  },
  {
    path: `${RouteStrings.Sliders.Link}`,
    element: <Slider />,
  },
  {
    path: `${RouteStrings.Sliders.Children.EditSlider.Link}`,
    element: <EditSlider />,
  },
  {
    path: `${RouteStrings.Sliders.Children.CreateSlider.Link}`,
    element: <AddNewSlider />,
  },
  // new section
  {
    path: `${RouteStrings.Sections.Link}`,
    element: <NewSection />,
  },
  {
    path: `${RouteStrings.Sections.Children.EditSection.Link}`,
    element: <EditNewSection />,
  },
  {
    path: `${RouteStrings.Sections.Children.CreateSection.Link}`,
    element: <AddNewNewSection />,
  },
  // old section
  {
    path: `${RouteStrings.OldSections.Link}`,
    element: <Section />,
  },
  {
    path: `${RouteStrings.OldSections.Children.EditSection.Link}`,
    element: <EditSection />,
  },
  {
    path: `${RouteStrings.OldSections.Children.CreateSection.Link}`,
    element: <AddNewSection />,
  },
  {
    path: `${RouteStrings.FileManager.Link}`,
    element: <FileManager />,
  },
  {
    path: `${RouteStrings.Settings.Children.GeneralSettings.Link}`,
    element: <Settings />,
  },
  {
    path: `${RouteStrings.Settings.Children.SeoSettings.Link}`,
    element: <SeoSetting />,
  },
  {
    path: `${RouteStrings.Settings.Children.AdMob.Link}`,
    element: <AdMobSettings />,
  },
  {
    path: `${RouteStrings.Settings.Children.Payment.Link}`,
    element: <PaymentSettings />,
  },
  {
    path: `${RouteStrings.Settings.Children.SMTP.Link}`,
    element: <SMTPSetting />,
  },
  {
    path: `${RouteStrings.Settings.Children.SMS.Link}`,
    element: <SMSSetting />,
  },
  {
    path: `${RouteStrings.Settings.Children.WhatsApp.Link}`,
    element: <WhatsAppSetting />,
  },
  {
    path: `${RouteStrings.Settings.Children.AwsConfig.Link}`,
    element: <AwsConfig />,
  },
  {
    path: `${RouteStrings.Settings.Children.ContentImages.Link}`,
    element: <ContentImagesSettings />,
  },
  {
    path: `${RouteStrings.Marketing.Children.PushNotifications.Link}`,
    element: <PushNotification />,
  },
  {
    path: `${RouteStrings.Marketing.Children.EmailMarketing.Link}`,
    element: <EmailMarketing />,
  },
  {
    path: `${RouteStrings.Marketing.Children.SMSMarketing.Link}`,
    element: <SMSMarketing />,
  },
  {
    path: `${RouteStrings.Marketing.Children.WhatsappMarketing.Link}`,
    element: <WhatsappMarketing />,
  },
  {
    path: `${RouteStrings.Marketing.Children.Settings.Link}`,
    element: <MarketingSettings />,
  },
  {
    path: `${RouteStrings.RTMP.Children.Dashboard.Link}`,
    element: <RtmpDashboard />,
  },
  {
    path: `${RouteStrings.RTMP.Children.Streams.Link}`,
    element: <RtmpStreams />,
  },
  {
    path: `${RouteStrings.RTMP.Children.ServerProfiles.Link}`,
    element: <RtmpProfile />,
  },
  {
    path: `${RouteStrings.Transcoding.Children.Dashboard.Link}`,
    element: <TranscodingDashboard />,
  },
  {
    path: `${RouteStrings.Transcoding.Children.Settings.Link}`,
    element: <TranscoderSettings />,
  },
  {
    path: `${RouteStrings.Transcoding.Children.CreateJob.Link}`,
    element: <CreateTranscodingJob />,
  },
  {
    path: `${RouteStrings.Transcoding.Children.ViewJob.Link}`,
    element: <ViewTranscodingJob />,
  },
  {
    path: `${RouteStrings.Content.Link}`,
    element: <Content />,
  },
  {
    path: `${RouteStrings.Content.Children.CreateContent.Link}`,
    element: <CreateContent />,
  },
  {
    path: `${RouteStrings.Content.Children.EditContent.Link}`,
    element: <EditContent />,
  },
  {
    path: `${RouteStrings.Content.Children.EditContentSeason.Link}`,
    element: <EditContentSeason />,
  },
  {
    path: `${RouteStrings.AppSettings.Link}`,
    element: <AppSettings />,
  },
  {
    path: `${RouteStrings.WebSettings.Link}`,
    element: <WebSettings />,
  },
  {
    path: `${RouteStrings.Blogs.Link}`,
    element: <PagesPage />,
  },
  {
    path: `${RouteStrings.Blogs.Children.CreateBlog.Link}`,
    element: <CreateBlog />,
  },
  {
    path: `${RouteStrings.Blogs.Children.EditBlog.Link}`,
    element: <EditBlogs />,
  },
  {
    path: `${RouteStrings.Analytics.Link}`,
    element: <Analytics />,
  },
  {
    path: `${RouteStrings.Settings.Link}`,
    element: <AdminUserSettings />,
  },
  // {
  //   path: `${RouteStrings.ContentPatners.Link}`,
  //   element: <Contentpatners />,
  // },
  // {
  //   path: `${RouteStrings.ContentPatners.Children.EditContentPartner.Link}`,
  //   element: <EditContentPartner />,
  // },
  {
    path: `${RouteStrings.Country.Link}`,
    element: <Country />,
  },
  {
    path: `${RouteStrings.Coupon.Link}`,
    element: <Coupon />,
  },
  {
    path: `${RouteStrings.AdNetwork.Link}`,
    element: <AdNetwork />,
  },
  {
    path: `${RouteStrings.AdNetwork.Children.CreateAd.Link}`,
    element: <CreateAd />,
  },
  {
    path: `${RouteStrings.AdNetwork.Children.EditAd.Link}`,
    element: <EditAds />,
  },
  {
    path: `${RouteStrings.Marketing.Children.MarketingTemplates.Link}`,
    element: <MarketingTemplates />,
  },
  {
    path: `${RouteStrings.Marketing.Children.MarketingTemplates.Children.CreateMarketingTemplate.Link}`,
    element: <CreateMarketingTemplate />,
  },
  {
    path: `${RouteStrings.Marketing.Children.MarketingTemplates.Children.EditMarketingTemplate.Link}`,
    element: <EditMarketingTemplate />,
  }
];
