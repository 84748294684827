// Author: Pukhraj Dhamu
// Date Created: Thuesday 13 Jul 2023 at 12:24 PM
// Date Modified: Thuesday 13 Jul 2023 at 12:24 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const adsApis = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
});

export interface IAdsPayload {
    id: string[];
    action: "active" | "inactive" | "delete";
}

// list all endpoints
export const getAds = (queryString: string) => adsApis.get(`/ads${queryString ? `?${queryString}` : ""}`).then((res) => res.data);
export const AdsActionsApi = (payload: IAdsPayload) => adsApis.put("/ads/action", payload)
export const createAd = (payload: any) => adsApis.post("/ads", payload)
export const updateAd = (id: string, payload: any) => adsApis.put(`/ads/${id}`, payload)



adsApis.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

export default adsApis;