// Author: Pukhraj Dhamu
// Created Date: Friday, July 14th 2023 at 4:17 PM
// Last Modified Date: Friday, July 14th 2023 at 4:17 PM
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import Layout from "../../../../components/Layout/Layout";
import EmailEditor from "react-email-editor";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import toast from "react-hot-toast";
import { InputField, SelectField } from "../../../../tailwind";
import { getMarketingTemplates, updateMarketingTemplate } from "../../../../http/marketing.http";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../../../components/smallComponents/loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

function EditMarketingTemplate() {
  const { id } = useParams<{ id: string }>();
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = useState<string>("");
  const [templateType, setTemplateType] = useState<IMarketingTemplateData['type']>();
  const [templateBody, setTemplateBody] = useState<string>("");
  const [templateDesign, setTemplateDesign] = useState<any>();

  // get template by id useing react query
  const templateQuery = useQuery<{ data: { data: IMarketingTemplateData[] } }>({
    queryKey: ['marketing-template', id],
    queryFn: () => getMarketingTemplates(`id=${id}`),
    enabled: !!id,
    onSuccess(data) {
      if (!data.data.data.length) return toast.error('Template not found');
      const { name, type, content, editor_design_content } = data.data.data[0];
      setName(name);
      setTemplateType(type);
      setTemplateBody(content);
      editor_design_content && setTemplateDesign(JSON.parse(editor_design_content));
    },
    onError(error) {
      toast.error("Something went wrong");
    }
  });

  // email template code
  const emailEditorRef = React.useRef<any>(null);
  const handleSaveTemplate = async () => {

    if (!name) return toast.error("Please enter template name");
    if (!templateType) return toast.error("Please select template type");
    if (templateType === "email" && emailEditorRef.current) {
      setLoading(true);
      emailEditorRef.current!.editor.exportHtml(async (data: any) => {
        const { design, html } = data;
        try {
          const templateObj = {
            name: name,
            content: html,
            type: templateType as IMarketingTemplateData['type'],
            editor_design_content: JSON.stringify(design),
          };
          const res = await updateMarketingTemplate(id as string, templateObj);
          if (res.status === 200) {
            setLoading(false);
            return toast.success("Template Created Successfully");
          }
        } catch (error: any) {
          if (axios.isAxiosError(error)) {
            if (error.response && error.response.data) {
              let msg: any = error.response.data;
              setLoading(false);
              return toast.error(msg.error.message);
            }
          }
          setLoading(false);
          return console.log(error);
        }
      });
    } else {
      setLoading(true);
      try {
        const templateObj = {
          name: name,
          content: templateBody,
          type: templateType as IMarketingTemplateData['type'],
        };
        const res = await updateMarketingTemplate(id as string, templateObj);
        if (res.status === 201) {
          setLoading(false);
          return toast.success("Template Created Successfully");
        }
      }
      catch (error: any) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.data) {
            let msg: any = error.response.data;
            setLoading(false);
            return toast.error(msg.error.message);
          }
        }
        setLoading(false);
        return console.log(error);
      }
    }
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
    if (templateDesign) emailEditorRef.current.editor.loadDesign(templateDesign);
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };

  const typesData = [
    { value: 'email', label: 'Email' },
    { value: 'sms', label: 'SMS' },
    { value: 'push', label: 'Push Notification' },
    { value: 'whatsapp', label: 'Whatsapp' }
  ]

  return (
    <Layout>
      <div className="pr-6">
        <h1 className="font-medium text-2xl my-3 opacity-80">
          Edit Marketing Template
        </h1>

        <div className="mt-5 w-full">
          {
            templateQuery.isLoading && <div className='w-full flex justify-center items-center min-h-[47rem]'>
              <Loader smail />
            </div>
          }
        </div>
        <div className="mt-5 w-full">
          {
            templateQuery.isError && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
              <h1 className='text-xl font-medium opacity-90'>
                Server Error. Please try again
              </h1>
              <div
                onClick={() => templateQuery.refetch()}
                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                <h1 className='text-sm text-white'>
                  Retry
                </h1>
              </div>
            </div>
          }
        </div>

        {
          templateQuery.isSuccess && (
            <>
              <div className="flex flex-col gap-2 my-2">
                <InputField
                  fieldName="name"
                  placeholder="Enter Template Name"
                  type="text"
                  label="Template Name"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
                {
                  templateType && <SelectField
                    options={typesData}
                    defaultValue={[{
                      value: templateType as IMarketingTemplateData['type'],
                      label: typesData.find((type) => type.value === templateType)?.label || ''
                    }] as any}
                    onChange={(e: { value: IMarketingTemplateData['type'] }) => setTemplateType(e.value)}
                    placeholder="Select Template Type"
                  />
                }

              </div>
              {
                templateType && templateType === "email" ? (<EmailEditor
                  minHeight={800}
                  options={{
                    displayMode: "email",
                    features: {
                      stockImages: true,
                    },
                    id: "dy-email-editor",
                  }}
                  projectId={69082}
                  ref={emailEditorRef}
                  tools={{
                    "custom#dy_recommendation": {
                      position: 0,
                    },
                  }}
                  onLoad={onLoad}
                  onReady={onReady}
                />) : <div>
                  <InputField
                    fieldName="Template"
                    placeholder="Enter Template Body"
                    type="text"
                    label="Template Body"
                    value={templateBody}
                    required
                    onChange={(e) => setTemplateBody(e.target.value)}
                  />
                </div>
              }
              <div className="py-2 w-full flex justify-end">
                <LoadingButton
                  id="add-email-template"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  onClick={handleSaveTemplate}
                >
                  Save
                </LoadingButton>
              </div>
            </>
          )
        }
      </div>
    </Layout>
  );
}

export default EditMarketingTemplate;
