import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getCategories } from '../../http/Apis'

export interface ICategoryState {
    categories: {
        _id: string;
        name: string;
        slug: string;
        description?: string;
        status: boolean;
        trash: boolean;
        created_by: string;
        updated_by: string;
        createdAt: string;
        updatedAt: string;
    }[]
    status: 'idle' | 'loading' | 'failed' | 'success';
    bulkAction: 'none' | 'trash' | 'restore' | 'delete' | 'public' | 'private';
    bulkActionCall: boolean;
    category: {
        _id: string;
        name: string;
        slug: string;
        description: string;
        status: boolean;
        trash: boolean;
        created_by: string;
        updated_by: string;
        createdAt: string;
        updatedAt: string;
        icon?: string;
        show_in_menu: boolean;
    };
    meta: {
        pagination: {
            page: number,
            pageSize: number,
            pageCount: number,
            total: number,
        },
        report: {
            total: number,
            public: number,
            private: number,
        }
    }
}

const initialState: ICategoryState = {
    categories: [],
    status: 'idle',
    bulkAction: 'none',
    bulkActionCall: false,
    category: {} as ICategoryState['category'],
    meta: {} as ICategoryState['meta']
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setCategories: (state, actions) => {
            const { data } = actions.payload
            state.categories = data
            state.meta = actions.payload.meta
        },
        setStatus: (state, actions: PayloadAction<ICategoryState['status']>) => {
            state.status = actions.payload
        },
        setBulkAction: (state, actions) => {
            state.bulkAction = actions.payload
        },
        setBulkActionCall: (state, actions) => {
            state.bulkActionCall = actions.payload
        },
        setSingle: (state, actions) => {
            const { data } = actions.payload
            state.category = data
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setCategories,
    setStatus,
    setBulkAction,
    setBulkActionCall,
    setSingle,
} = categorySlice.actions

export default categorySlice.reducer

export interface IFetchAllGenresType {
    type?: 'single' | 'all'
}
// Thunks
export function fetchCategories(queryString?: string, type?: IFetchAllGenresType['type']) {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const {data} = await getCategories(queryString);
            if (type === 'single') {
                dispatch(setSingle(data))
            } else {
                dispatch(setCategories(data))
            }
            dispatch(setStatus('success'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

