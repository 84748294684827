import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import {
  Button,
  DeleteIcon,
  Input,
  InputField,
  TextAreaField,
  Title,
} from "../../tailwind";
import MenuItem from "@mui/material/MenuItem";
import {
  Button as MuiButton,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import AsyncSelect from "react-select/async";
import { UploadIcon } from "../../tailwind/Icon";
import { useSelector } from "react-redux";
import {
  getSMSTemplate,
  sendPushNotification,
  searchUser,
  searchUserNumber,
  sendSMSTemplate,
} from "../../http/Apis";
import toast from "react-hot-toast";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const SMSMarketing = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { config } = useSelector((state: RootState) => state.config);
  const [template, setTemplate] = useState<any[]>([]);
  const [smsTemplate, setSMSTemplate] = useState("");
  const navigate = useNavigate();
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const [mode, setMode] = useState("");
  const { credentials } = config;
  const [numberArr, setNumberArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("");
  const [groupNumArr, setGroupNumArr] = useState("");
  // const [User, searchUser] = useState();
  const [pushNotification, setPushNotification] = useState({
    title: "",
    body: "",
    image: "",
    image_url: "",
    url: "",
  });

  const handleLogoChange = (e: any) => {
    // get object url and set it to imageUrl
    if (e.target.files[0]) {
      setPushNotification({
        ...pushNotification,
        image: e.target.files[0],
        image_url: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const sentPushNotifiction = async () => {
    setLoading(true);
    if (pushNotification.title === "") {
      toast.error("Title is required");
      setLoading(false);
      return;
    }
    if (pushNotification.body === "") {
      toast.error("Body is required");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("title", pushNotification.title);
    formData.append("body", pushNotification.body);
    if (pushNotification.image) {
      formData.append("notification_image", pushNotification.image);
    }
    if (pushNotification.url) {
      formData.append("url", pushNotification.url);
    }
    try {
      const response = await sendPushNotification(formData);
      if (response.status === 200) {
        toast.success("Push notification sent successfully");
        setPushNotification({
          title: "",
          body: "",
          image: "",
          image_url: "",
          url: "",
        });
        // reload page
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      toast.error(error.response.data.error.message || "Something went wrong");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const query = "page=1&limit=25&type=sms";
      let res = await getSMSTemplate(query);
      // console.log(res);

      if (res.status === 200) {
        setTemplate(res.data.data);
      }
    };
    fetchData();
  }, []);

  const handleMode = async (event: SelectChangeEvent) => {
    setMode(event.target.value);

    if (event.target.value === "total") {
      try {
        let res = await searchUserNumber();
        console.log(res);
        setNumberArr(res.data.data.users);
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const err: any = error.response;
          return toast.error(err.data.error.message);
        }
        return console.log(error);
      }
    }
  };

  const handleSendTemplate = async () => {
    try {
      setLoading(true);
      let numArr: any = [];
      let arr = groupNumArr.length > 0 && groupNumArr.split(",");
      if (mode === "group") {
        arr &&
          arr.forEach((item: any) => {
            let num = "+91" + item;
            numArr.push(num);
          });
      } else if (mode === "total") {
        numArr = numberArr.map((item: any) => {
          return item.phone;
        });
      }

      let dataObj = {
        numArr: numArr.length > 0 ? numArr : ["+91" + number],
        message: smsTemplate,
      };

      let res = await sendSMSTemplate(dataObj);
      if (res.status === 200) {
        setLoading(false);
        return toast.success(res.data.message);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        setLoading(false);
        return toast.error(err.data.error.message);
      }
      setLoading(false);
      return console.log(error);
    }
  };

  return (
    <Layout>
      <div className="pr-5">
        <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
          SMS Marketing
        </h1>
        <p className="mt-2 opacity-70">
          Send push sms to your users from here. You can send sms to all users
          or to a specific user.
        </p>
        <p className="mt-1 text-red-700 text-sm">
          Note: SMS marketing is in beta version and will be available soon.
        </p>
      </div>
      <div className="my-5">
        <Hr />
      </div>

      <div className="flex w-full justify-between items-center pr-4">
        <h6 className="opacity-70 pr-5">
          * – Required fields. Enter all fields.
        </h6>

        <MuiButton
          variant="text"
          onClick={() => {
            navigate("/admin/marketing/message-template/view?type=sms");
          }}
          className="mr-6 bg-primary-blue text-white hover:bg-primary-blue-dark duration-100"
        >
          Templates
        </MuiButton>
      </div>
      <div className="my-5 pr-36 flex flex-col gap-5">
        {/* <InputField
          title="Title"
          required
          fieldName="title"
          type="text"
          placeholder="Enter your title"
          value={pushNotification.title}
          onChange={(e) =>
            setPushNotification({ ...pushNotification, title: e.target.value })
          }
        /> */}
        {/* <div className="flex w-full">
          <TextAreaField
            title="Body"
            required
            fieldName="body"
            type="textarea"
            placeholder="Enter your body"
            value={pushNotification.body}
            textAreaOnChange={(e) =>
              setPushNotification({ ...pushNotification, body: e.target.value })
            }
          />
        </div> */}

        <div className="w-full flex">
          <FormControl size="small" className="w-full">
            <InputLabel id="demo-select-small">Choose SMS Template</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={smsTemplate}
              label="Choose SMS Template"
              placeholder="Choose SMS Template"
              onChange={(e) => {
                setSMSTemplate(e.target.value);
              }}
            >
              {template.length > 0 &&
                template.map((item: any, index: number) => {
                  if (item.status) {
                    return (
                      <MenuItem key={index} value={item.message}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                })}
            </Select>
          </FormControl>
        </div>
        <div className="w-full flex capitalize">
          <FormControl size="small" className="w-full">
            <InputLabel id="demo-select-small">Choose Mode</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={mode}
              label="Select Mode"
              placeholder="Select Mode"
              onChange={(event) => {
                if (
                  event.target.value === "single" ||
                  event.target.value === "total"
                ) {
                  setGroupNumArr("");
                }
                handleMode(event);
              }}
            >
              <MenuItem value={"single"}>single</MenuItem>
              <MenuItem value={"group"}>group</MenuItem>
              <MenuItem value={"total"}>total</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="w-full flex">
          {(() => {
            switch (mode) {
              case "single":
                return (
                  <>
                    <InputField
                      title="user number"
                      required
                      fieldName="user number"
                      type="text"
                      placeholder="Enter Users's Number"
                      value={number}
                      onChange={(e) => {
                        setNumber(e.target.value);
                      }}
                    />
                  </>
                );
              case "group":
                return (
                  <div className="w-full">
                    <InputField
                      title="user number"
                      required
                      fieldName="user number"
                      type="text"
                      placeholder="Enter Users's Number"
                      value={groupNumArr}
                      onChange={(e) => {
                        setGroupNumArr(e.target.value);
                      }}
                    />
                    <p className="text-red-700 text-sm pl-2 my-2">
                      Note: Users Number must be coma separated.
                    </p>
                  </div>
                );
              case "total":
                return (
                  <>
                    <p className="text-green-700 text-sm pl-2">
                      Note: All Users are selected for the mail.
                    </p>
                  </>
                );
            }
          })()}
        </div>
      </div>

      <div className="my-14 pr-16 flex flex-col gap-8">
        <div className="flex justify-end">
          <div className="w-44">
            <LoadingButton
              loading={loading}
              loadingPosition="end"
              endIcon={<SendIcon />}
              variant="outlined"
              color="primary"
              onClick={() => {
                handleSendTemplate();
              }}
            >
              Send
            </LoadingButton>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SMSMarketing;
