import DashboardIcon from "@mui/icons-material/Dashboard";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import SubjectIcon from "@mui/icons-material/Subject";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MovieFilterOutlinedIcon from "@mui/icons-material/MovieFilterOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import {
  MarketingIcon,
  RTMPServerIcon,
  TranscoderIcon,
} from "../tailwind/Icon";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Strings from "../localData/strings.json";
import RouteStrings from "../localData/routes.json";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import WebIcon from "@mui/icons-material/Web";
import ArticleIcon from "@mui/icons-material/Article";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import TravelExploreSharpIcon from "@mui/icons-material/TravelExploreSharp";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import CampaignIcon from "@mui/icons-material/Campaign";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from '@mui/icons-material/Email';
import { ReactComponent as AdMobIcon } from "../icons/admob.svg";
import { ReactComponent as AwsIcon } from "../icons/aws-icon.svg";
import { blue } from "@mui/material/colors";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ImageIcon from '@mui/icons-material/Image';
import { SiGoogleanalytics } from "react-icons/si";
import { FaUsers } from "react-icons/fa";

const {
  en: {
    HomePage: { Menu },
  },
} = Strings;

export interface ISideBarchildren {
  id: number;
  name: string;
  path: string;
  icon: any;
}

export const SideBarData = [
  {
    id: 1,
    name: `${Menu.Overview}`,
    path: `${RouteStrings.Overview.Link}`,
    pathName: `${RouteStrings.Overview.PathName}`,
    icon: <SiGoogleanalytics className="text-xl" />,
    children: [] as Array<ISideBarchildren>,
  },
  {
    id: 2,
    name: `${Menu.Auth}`,
    path: `${RouteStrings.Auth.Link}`,
    pathName: `${RouteStrings.Auth.PathName}`,
    icon: <FaUsers className="text-xl" />,
  },
  {
    id: 3,
    name: `${Menu.Content}`,
    path: `${RouteStrings.Content.Link}`,
    pathName: `${RouteStrings.Content.PathName}`,
    icon: <VideoLibraryIcon />,
  },
  // {
  //   id: 3,
  //   name: `${Menu.oldSections}`,
  //   path: `${RouteStrings.OldSections.Link}`,
  //   pathName: `${RouteStrings.OldSections.PathName}`,
  //   icon: <SubjectIcon />,
  // },
  {
    id: 3222,
    name: `${Menu.Sections}`,
    path: `${RouteStrings.Sections.Link}`,
    pathName: `${RouteStrings.Sections.PathName}`,
    icon: <SubjectIcon />,
  },
  // {
  //   id: 31,
  //   name: `${Menu.Sliders}`,
  //   path: `${RouteStrings.Sliders.Link}`,
  //   pathName: `${RouteStrings.Sliders.PathName}`,
  //   icon: <AdjustIcon />,
  // },
  {
    id: 4,
    name: `${Menu.FileManager}`,
    path: `${RouteStrings.FileManager.Link}`,
    pathName: `${RouteStrings.FileManager.PathName}`,
    icon: <FolderOutlinedIcon />,
  },
  {
    id: 5,
    name: `${Menu.Users}`,
    path: `${RouteStrings.Users.Link}`,
    pathName: `${RouteStrings.Users.PathName}`,
    icon: <PeopleOutlinedIcon />,
  },
  // {
  //   id: 51,
  //   name: `${Menu.ContentPatners}`,
  //   path: `${RouteStrings.ContentPatners.Link}`,
  //   pathName: `${RouteStrings.ContentPatners.PathName}`,
  //   icon: <PeopleOutlineIcon />,
  // },
  {
    id: 6,
    name: `${Menu.Genres}`,
    path: `${RouteStrings.Genres.Link}`,
    pathName: `${RouteStrings.Genres.PathName}`,
    icon: <MovieFilterOutlinedIcon />,
  },
  {
    id: 7,
    name: `${Menu.Categories}`,
    path: `${RouteStrings.Categories.Link}`,
    pathName: `${RouteStrings.Categories.PathName}`,
    icon: <CategoryOutlinedIcon />,
  },
  {
    id: 8,
    name: `${Menu.Languages}`,
    path: `${RouteStrings.Languages.Link}`,
    pathName: `${RouteStrings.Languages.PathName}`,
    icon: <TranslateOutlinedIcon />,
  },
  {
    id: 9,
    name: `${Menu.CastAndCrew}`,
    path: `${RouteStrings.CastAndCrew.Link}`,
    pathName: `${RouteStrings.CastAndCrew.PathName}`,
    icon: <SupervisorAccountOutlinedIcon />,
  },
  {
    id: 10,
    name: `${Menu.Subscriptions}`,
    path: `${RouteStrings.Subscriptions.Link}`,
    pathName: `${RouteStrings.Subscriptions.PathName}`,
    icon: <SubscriptionsOutlinedIcon />,
  },
  {
    id: 11,
    name: `${Menu.Transcoding.Title}`,
    path: "",
    pathName: `${RouteStrings.Transcoding.PathName}`,
    icon: <TranscoderIcon className={`w-6 h-6`} />,
    children: [
      {
        id: 12,
        name: `${Menu.Transcoding.Dashboard}`,
        path: `${RouteStrings.Transcoding.Children.Dashboard.Link}`,
        icon: <DashboardIcon />,
      },
      {
        id: 113,
        name: `${Menu.Transcoding.Settings}`,
        path: `${RouteStrings.Transcoding.Children.Settings.Link}`,
        icon: <SettingsOutlinedIcon />,
      },
    ],
  },
  {
    id: 13,
    name: `${Menu.RTMP.Title}`,
    path: "",
    pathName: `${RouteStrings.RTMP.PathName}`,
    icon: <RTMPServerIcon className={`w-6 h-6`} />,
    children: [
      {
        id: 14,
        name: `${Menu.RTMP.Dashboard}`,
        path: `${RouteStrings.RTMP.Children.Dashboard.Link}`,
        icon: <DashboardIcon />,
      },
      {
        id: 15,
        name: `${Menu.RTMP.Streams}`,
        path: `${RouteStrings.RTMP.Children.Streams.Link}`,
        icon: <DashboardIcon />,
      },
      {
        id: 16,
        name: `${Menu.RTMP.ServerProfile}`,
        path: `${RouteStrings.RTMP.Children.ServerProfiles.Link}`,
        icon: <DashboardIcon />,
      },
    ],
  },
  {
    id: 17,
    name: `${Menu.Marketing.Title}`,
    path: "",
    pathName: `${RouteStrings.Marketing.PathName}`,
    icon: <MarketingIcon className="w-6 h-6" />,
    children: [
      {
        id: 18,
        name: `${Menu.Marketing.PushNotifications}`,
        path: `${RouteStrings.Marketing.Children.PushNotifications.Link}`,
        icon: <NotificationsActiveOutlinedIcon />,
      },
      {
        id: 191,
        name: `${Menu.Marketing.EmailMarketing}`,
        path: `${RouteStrings.Marketing.Children.EmailMarketing.Link}`,
        icon: <EmailOutlinedIcon />,
      },
      {
        id: 192,
        name: `${Menu.Marketing.SMSMarketing}`,
        path: `${RouteStrings.Marketing.Children.SMSMarketing.Link}`,
        icon: <SmsOutlinedIcon />,
      },
      {
        id: 193,
        name: `${Menu.Marketing.WhatsappMarketing}`,
        path: `${RouteStrings.Marketing.Children.WhatsappMarketing.Link}`,
        icon: <WhatsAppIcon />,
      },
      {
        id: 1932,
        name: `${Menu.Marketing.MarketingTemplates}`,
        path: `${RouteStrings.Marketing.Children.MarketingTemplates.Link}`,
        icon: <DocumentScannerIcon />,
      },
      {
        id: 181,
        name: `${Menu.Marketing.Settings}`,
        path: `${RouteStrings.Marketing.Children.Settings.Link}`,
        icon: <SettingsOutlinedIcon />,
      },
    ],
  },
  {
    id: 19,
    name: `${Menu.Analytics}`,
    path: `${RouteStrings.Analytics.Link}`,
    pathName: `${RouteStrings.Analytics.PathName}`,
    icon: <SignalCellularAltOutlinedIcon />,
  },
  {
    id: 20,
    name: `${Menu.Settings.Title}`,
    path: "",
    pathName: `${RouteStrings.Settings.PathName}`,
    icon: <SettingsOutlinedIcon />,
    children: [
      {
        id: 21,
        name: `${Menu.Settings.General}`,
        path: `${RouteStrings.Settings.Children.GeneralSettings.Link}`,
        icon: <SettingsOutlinedIcon />,
      },
      {
        id: 22,
        name: `${Menu.Settings.Seo}`,
        path: `${RouteStrings.Settings.Children.SeoSettings.Link}`,
        icon: <TravelExploreSharpIcon />,
      },
      {
        id: 231,
        name: `${Menu.Settings.AdMob}`,
        path: `${RouteStrings.Settings.Children.AdMob.Link}`,
        icon: <AdMobIcon width={25} height={25} />,
      },
      {
        id: 232,
        name: `${Menu.Settings.Payment}`,
        path: `${RouteStrings.Settings.Children.Payment.Link}`,
        icon: <PaymentOutlinedIcon />,
      },
      {
        id: 233,
        name: `${Menu.Settings.SMTP}`,
        path: `${RouteStrings.Settings.Children.SMTP.Link}`,
        icon: <EmailIcon />,
      },
      {
        id: 234,
        name: `${Menu.Settings.SMS}`,
        path: `${RouteStrings.Settings.Children.SMS.Link}`,
        icon: <MessageIcon />,
      },
      {
        id: 235,
        name: `${Menu.Settings.WhatsApp}`,
        path: `${RouteStrings.Settings.Children.WhatsApp.Link}`,
        icon: <WhatsAppIcon />,
      },
      {
        id: 236,
        name: `${Menu.Settings.AwsConfig}`,
        path: `${RouteStrings.Settings.Children.AwsConfig.Link}`,
        icon: <AwsIcon width={25} height={25} fill={blue[500]} />,
      },
      {
        id: 237,
        name: `${Menu.Settings.ContentImages}`,
        path: `${RouteStrings.Settings.Children.ContentImages.Link}`,
        icon: <ImageIcon />,
      },
    ],
  },
  {
    id: 23,
    name: `${Menu.AppSettings}`,
    path: `${RouteStrings.AppSettings.Link}`,
    pathName: `${RouteStrings.AppSettings.PathName}`,
    icon: <AppSettingsAltIcon />,
  },
  {
    id: 24,
    name: `${Menu.WebSettings}`,
    path: `${RouteStrings.WebSettings.Link}`,
    pathName: `${RouteStrings.WebSettings.PathName}`,
    icon: <WebIcon />,
  },
  {
    id: 25,
    name: `${Menu.Blogs}`,
    path: `${RouteStrings.Blogs.Link}`,
    pathName: `${RouteStrings.Blogs.PathName}`,
    icon: <ArticleIcon />,
  },
  {
    id: 26,
    name: `${Menu.Country}`,
    path: `${RouteStrings.Country.Link}`,
    pathName: `${RouteStrings.Country.PathName}`,
    icon: <VpnLockIcon />,
  },

  {
    id: 27,
    name: `${Menu.Coupon}`,
    path: `${RouteStrings.Coupon.Link}`,
    pathName: `${RouteStrings.Coupon.PathName}`,
    icon: <LocalOfferIcon />,
  },
  {
    id: 28,
    name: `${Menu.AdNetwork}`,
    path: `${RouteStrings.AdNetwork.Link}`,
    pathName: `${RouteStrings.AdNetwork.PathName}`,
    icon: <CampaignIcon />,
  },
  // {
  //     id: 26,
  //     name: `${Menu.Reports}`,
  //     path: `${RouteStrings.Reports.Link}`,
  //     pathName: `${RouteStrings.Reports.PathName}`,
  //     icon: <ReportOutlinedIcon />,
  // },
];
