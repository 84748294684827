// Author: Pukhraj Dhamu
// Created Date: Sunday, July 19th 2023 at 3:09 PM
// Last Modified Date: Sunday, July 19th 2023 at 3:09 PM
import { useState, useEffect } from "react";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export interface INewQuery {
    page: number;
    limit: number;
    order: string;
    q?: string;
}

export function useQueryParamsForPaginationNew(): { query: INewQuery, setQuery?: React.Dispatch<React.SetStateAction<INewQuery>> } {
    const location = useLocation();
    const navigate = useNavigate();
    // get query params
    const { page = 1, limit = 25, order = "desc", q } = qs.parse(location.search, { ignoreQueryPrefix: true });
    // max limit is 25 if greater than 25 then set to 25
    const newLimit: any = limit
    const [limitValue, setLimitValue] = useState<number>(newLimit > 25 ? 25 : limit as number);
    const [query, setQuery] = useState<INewQuery>({ page: Number(page), limit: Number(limitValue), order: order as string });
    // set limit value greater than 25 then update limit value to 25 in url using navigate
    useEffect(() => {
        if (newLimit > limit || newLimit < 1) {
            setLimitValue(limit as number);
            navigate(`?page=${page}&limit=25&order=${order}`);
            toast.error("Limit can't be greater than 25 or less than 1");
        }
        // limit must be a number
        if (isNaN(limit as number)) {
            setLimitValue(limit as number);
            navigate(`?page=${page}&limit=25&order=${order}`);
            toast.error("Limit must be a number");
        }
        // if sort is not desc or asc then set to desc
        if (order !== "desc" && order !== "asc") {
            navigate(`?page=${page}&limit=${limit}&order=desc`);
            toast.error("Order must be desc or asc");
        }
        // if page is not a number then set to 1
        if (isNaN(page as number)) {
            navigate(`?page=1&limit=${limit}&order=${order}`);
            toast.error("Page must be a number");
        }
        if(q) navigate(`?page=${q ? 1 : page}&limit=${query.limit}&order=${query.order}${q ? `&q=${q}` : ""}`);
        else navigate(`?page=${query.page}&limit=${query.limit}&order=${query.order}${query.q ? `&q=${query.q}` : ""}`);
    }, [query]);

    return { query, setQuery };

}