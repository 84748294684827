import RouteStrings from './routes.json';
import { SideBarData } from './SideBarMenuData';
import AddIcon from '@mui/icons-material/Add';

export const ComponentsStrings = [
    {
        "id": 1,
        "name": `${RouteStrings.Overview.Name}`,
        "description": `${RouteStrings.Overview.Description}`,
        "link": `${RouteStrings.Overview.Link}`,
        "icon": SideBarData[0].icon
    },
    {
        "id": 2,
        "name": `${RouteStrings.Content.Name}`,
        "description": `${RouteStrings.Content.Description}`,
        "link": `${RouteStrings.Content.Link}`,
        "icon": SideBarData[1].icon
    },
    {
        "id": 3,
        "name": `${RouteStrings.Content.Children.CreateContent.Name}`,
        "description": `${RouteStrings.Content.Children.CreateContent.Description}`,
        "link": `${RouteStrings.Content.Children.CreateContent.Link}`,
        "icon": <AddIcon />
    },
    {
        "id": 4,
        "name": `${RouteStrings.Sections.Name}`,
        "description": `${RouteStrings.Sections.Description}`,
        "link": `${RouteStrings.Sections.Link}`,
        "icon": SideBarData[2].icon
    },
    {
        "id": 5,
        "name": `${RouteStrings.Sections.Children.CreateSection.Name}`,
        "description": `${RouteStrings.Sections.Children.CreateSection.Description}`,
        "link": `${RouteStrings.Sections.Children.CreateSection.Link}`,
        "icon": <AddIcon />
    },
    {
        "id": 6,
        "name": `${RouteStrings.FileManager.Name}`,
        "description": `${RouteStrings.FileManager.Description}`,
        "link": `${RouteStrings.FileManager.Link}`,
        "icon": SideBarData[3].icon

    },
    {
        "id": 7,
        "name": `${RouteStrings.Users.Name}`,
        "description": `${RouteStrings.Users.Description}`,
        "link": `${RouteStrings.Users.Link}`,
        "icon": SideBarData[4].icon
    },
    {
        "id": 8,
        "name": `${RouteStrings.Genres.Name}`,
        "description": `${RouteStrings.Genres.Description}`,
        "link": `${RouteStrings.Genres.Link}`,
        "icon": SideBarData[5].icon
    },
    {
        "id": 9,
        "name": `${RouteStrings.Categories.Name}`,
        "description": `${RouteStrings.Categories.Description}`,
        "link": `${RouteStrings.Categories.Link}`,
        "icon": SideBarData[6].icon
    },
    {
        "id": 10,
        "name": `${RouteStrings.Categories.Children.CreateCategory.Name}`,
        "description": `${RouteStrings.Categories.Children.CreateCategory.Description}`,
        "link": `${RouteStrings.Categories.Children.CreateCategory.Link}`,
        "icon": <AddIcon />
    },
    {
        "id": 11,
        "name": `${RouteStrings.Languages.Name}`,
        "description": `${RouteStrings.Languages.Description}`,
        "link": `${RouteStrings.Languages.Link}`,
        "icon": SideBarData[7].icon
    },
    {
        "id": 12,
        "name": `${RouteStrings.Languages.Children.CreateLanguage.Name}`,
        "description": `${RouteStrings.Languages.Children.CreateLanguage.Description}`,
        "link": `${RouteStrings.Languages.Children.CreateLanguage.Link}`,
        "icon": <AddIcon />
    },
    {
        "id": 13,
        "name": `${RouteStrings.CastAndCrew.Name}`,
        "description": `${RouteStrings.CastAndCrew.Description}`,
        "link": `${RouteStrings.CastAndCrew.Link}`,
        "icon": SideBarData[8].icon
    },
    {
        "id": 14,
        "name": `${RouteStrings.CastAndCrew.Children.CreateCastAndCrew.Name}`,
        "description": `${RouteStrings.CastAndCrew.Children.CreateCastAndCrew.Description}`,
        "link": `${RouteStrings.CastAndCrew.Children.CreateCastAndCrew.Link}`,
        "icon": <AddIcon />
    },
    {
        "id": 15,
        "name": `${RouteStrings.Subscriptions.Name}`,
        "description": `${RouteStrings.Subscriptions.Description}`,
        "link": `${RouteStrings.Subscriptions.Link}`,
        "icon": SideBarData[9].icon
    },
    {
        "id": 16,
        "name": `${RouteStrings.Transcoding.Children.Dashboard.Name}`,
        "description": `${RouteStrings.Transcoding.Children.Dashboard.Description}`,
        "link": `${RouteStrings.Transcoding.Children.Dashboard.Link}`,
        "icon": <AddIcon />
    },
    {
        "id": 17,
        "name": `${RouteStrings.RTMP.Children.Dashboard.Name}`,
        "description": `${RouteStrings.RTMP.Children.Dashboard.Description}`,
        "link": `${RouteStrings.RTMP.Children.Dashboard.Link}`,
        "icon": SideBarData[10].icon
    },
    {
        "id": 18,
        "name": `${RouteStrings.RTMP.Children.Streams.Name}`,
        "description": `${RouteStrings.RTMP.Children.Streams.Description}`,
        "link": `${RouteStrings.RTMP.Children.Streams.Link}`,
        "icon": SideBarData[10].icon
    },
    {
        "id": 19,
        "name": `${RouteStrings.RTMP.Children.ServerProfiles.Name}`,
        "description": `${RouteStrings.RTMP.Children.ServerProfiles.Description}`,
        "link": `${RouteStrings.RTMP.Children.ServerProfiles.Link}`,
        "icon": SideBarData[10].icon
    },
    {
        "id": 20,
        "name": `${RouteStrings.Marketing.Children.PushNotifications.Name}`,
        "description": `${RouteStrings.Marketing.Children.PushNotifications.Description}`,
        "link": `${RouteStrings.Marketing.Children.PushNotifications.Link}`,
        "icon": SideBarData[12].icon
    },
    {
        "id": 21,
        "name": `${RouteStrings.Settings.Children.GeneralSettings.Name}`,
        "description": `${RouteStrings.Settings.Children.GeneralSettings.Description}`,
        "link": `${RouteStrings.Settings.Children.GeneralSettings.Link}`,
        "icon": SideBarData[14].icon
    },
    {
        "id": 22,
        "name": `${RouteStrings.Settings.Children.SeoSettings.Name}`,
        "description": `${RouteStrings.Settings.Children.SeoSettings.Description}`,
        "link": `${RouteStrings.Settings.Children.SeoSettings.Link}`,
        "icon": SideBarData[14].icon
    },
]