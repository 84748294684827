// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import { useSelector } from "react-redux"
import { TailwindBox } from "../../../../tailwind"
import { RootState } from "../../../../redux/store"
import { BiRefresh } from "react-icons/bi"
import { Link } from "react-router-dom"
import React from "react"
import axios from "axios"
import Base64 from "base-64"
import { useQuery } from "@tanstack/react-query"
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Loader } from "../../../../components/smallComponents/loader"
import { getSettingsApi } from "../../../../http/settingshttp"
import { toast } from "react-hot-toast"

const RunningTranscodingJob = () => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const [transcoderConfig, setTranscoderConfig] = React.useState<{
        hostname: string;
        username: string;
        password: string;
    } | undefined>();

    // useQuery for get transcoder settings
    useQuery<{ data: ITranscoderSetting[] }>({
        queryKey: ["getTranscoderQuery"],
        queryFn: () => getSettingsApi("transcoder"),
        onSuccess: (data) => {
            if (data && data.data.length > 0) {
                setTranscoderConfig({
                    hostname: data.data[0].hostname,
                    username: data.data[0].username,
                    password: data.data[0].password
                });
            }
        },
        onError: (error) => {
            toast.error("Failed to fetch transcoder settings")
        }
    });

    // fetch jobs with pagination using react-query
    const fetchJobs = async () => {
        const res = await axios.get<IGetTranscoderJobs>(`${transcoderConfig?.hostname}/api/v1/jobs?page=1&filter=processing`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Basic " + Base64.encode(`${transcoderConfig?.username}:${transcoderConfig?.password}`),
            }
        })
        return res.data;
    }

    const { data: JobsData, refetch, error, isLoading, isError, isFetching, isFetched, isSuccess } = useQuery({
        queryKey: ['jobs', "processing"],
        queryFn: () => fetchJobs(),
        enabled: transcoderConfig?.hostname ? true : false,
        refetchInterval: 1000 * 20 // 20 sec
    })
    return (
        <TailwindBox title="Transcoding Running Jobs">
            {
                isLoading && <div>
                    <div className="w-full h-full flex justify-center items-center mb-3">
                        <div className='min-h-[15rem] justify-center flex flex-col items-center'>
                            <Loader smail />
                        </div>
                    </div>
                </div>
            }
            {
                isError && <div>
                    <div className="w-full h-full flex justify-center items-center mb-3">
                        <div className='min-h-[15rem] justify-center flex flex-col items-center'>
                            <h1 className='text-sm font-medium opacity-90'>
                                Server Error. Please try again
                            </h1>
                            <div
                                onClick={() => refetch()}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                <BiRefresh className={`text-black text-lg bg-white rounded-full ${isLoading || isFetching && "animate-spin"}`} />
                                <h1 className='text-sm text-white'>
                                    Refresh
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                isSuccess && <div>
                    <div className="mt-7 mb-5">
                        <div>
                            {
                                JobsData.data.length === 0 ? <div className="w-full h-full flex justify-center items-center mb-3">
                                    <div className='min-h-[15rem] justify-center flex flex-col items-center'>
                                        <h1 className='text-sm font-medium opacity-90'>
                                            No jobs found. Please try again
                                        </h1>
                                        <div
                                            onClick={() => refetch()}
                                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                            <BiRefresh className={`text-black text-lg bg-white rounded-full ${isLoading || isFetching && "animate-spin"}`} />
                                            <h1 className='text-sm text-white'>
                                                Refresh
                                            </h1>
                                        </div>
                                    </div>
                                </div> : <div className="px-16 py-8">
                                    <CircularProgressbarWithChildren value={JobsData.data[0].progress}>
                                        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                        <img style={{ width: 50, marginTop: -5 }} src="https://res.cloudinary.com/dm4uaqlio/image/upload/v1679132853/zezo-ott-app-logo/Group_174_lmnm4o.png" alt="Zezo" />
                                        <div style={{ fontSize: 18, marginTop: 5 }}>
                                            <strong>{JobsData.data[0].progress}%</strong> Completed
                                        </div>
                                    </CircularProgressbarWithChildren>
                                    <h1 className="text-center mt-5 text-lg">
                                        {JobsData.data[0].name.length > 20 ? JobsData.data[0].name?.slice(0, 20) + "..." : JobsData.data[0].name}
                                    </h1>
                                </div>
                            }

                        </div>
                        <Link to="/admin/transcoding/dashboard">
                            <button className="uppercase text-blue-400 font-semibold ">
                                View All
                            </button>
                        </Link>
                    </div>
                </div>
            }
        </TailwindBox>
    )
}

export default RunningTranscodingJob