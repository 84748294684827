import React from 'react'
import Layout from '../../components/Layout/Layout'
import { InputField, TextAreaField, Title } from '../../tailwind'
import toast from 'react-hot-toast'
import { updateLanguageEndPoint } from '../../http/Apis'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { RootState } from '../../redux/store'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save';
import { fetchLanguages } from '../../redux/slice​/languageSlice'

const EditCategory = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { language } = useSelector((state: RootState) => state.language)

    const [loading, setLoading] = React.useState(false)

    const [languageData, setLanguageData] = React.useState({
        name: '',
        description: '',
    })

    useEffect(() => {
        dispatch(fetchLanguages(`id=${id}`, 'single') as any)
    }, [])

    useEffect(() => {
      setLanguageData({
            name: language.name,
            description: language.description,
        })
    }, [language])


    const handleSubmit = async () => {
        setLoading(true)
        try {
            const res = await updateLanguageEndPoint(id, languageData)
            if (res.status === 200) {
                toast.success('Language update successfully')
                setLoading(false)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
            setLoading(false)
        }
    }

    return (
        <Layout>
            <Title className="font-medium text-xl mb-5 pr-5">Edit Category</Title>

            <div className='flex flex-col gap-4 pr-5'>
                <InputField
                title='Name'
                    type="text"
                    fieldName='Name'
                    placeholder="Name"
                    value={languageData.name}
                    onChange={(e) => setLanguageData({ ...languageData, name: e.target.value })}
                />
                <TextAreaField
                    type="textarea"
                    title='Description'
                    fieldName='Description'
                    placeholder="Description"
                    value={languageData.description}
                    textAreaOnChange={(e) => setLanguageData({ ...languageData, description: e.target.value })}
                />

            </div>
            <div className="flex justify-end mt-5 px-5">
                <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                variant="contained"
                startIcon={<SaveIcon />}
                >
                    Save
                </LoadingButton>
            </div>
        </Layout>
    )
}

export default EditCategory


// import React from 'react'
// import Layout from '../../components/Layout/Layout'
// import { Button, Input, Title } from '../../tailwind'
// import toast from 'react-hot-toast'
// import { createGenre, updateCategory, updateGenre, updateLanguage } from '../../http/Apis'
// import { useParams } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'
// import { useEffect } from 'react'
// import { fetchGenre } from '../../redux/slice​/genreSlice'
// import { fetchCategory } from '../../redux/slice​/categorySlice'
// import { fetchLanguage } from '../../redux/slice​/languageSlice'

// const EditLanguage = () => {
//     const { id } = useParams()
//     const dispatch = useDispatch()
//     const { single } = useSelector(state => state.language)

//     const [loading, setLoading] = React.useState(false)

//     const [languageData, setLanguageData] = React.useState({
//         name: '',
//         description: '',
//     })

//     useEffect(() => {
//         dispatch(fetchLanguage(id))
//     }, [])

//     useEffect(() => {
//         setLanguageData({
//             name: single.name,
//             description: single.description,
//         })
//     }, [single])


//     const handleSubmit = async () => {
//         setLoading(true)
//         try {
//             const res = await updateLanguage(id, languageData)
//             if (res.status === 200) {
//                 toast.success('Language update successfully')
//                 setLoading(false)
//             }
//         } catch (error) {
//             toast.error(error.response.data.message)
//             setLoading(false)
//         }
//     }

//     return (
//         <Layout>
//             <Title className="text-white font-medium text-xl mb-5 px-5">Edit Language</Title>

//             <div className='flex flex-col gap-4 px-5'>
//                 <Input
//                     type="text"
//                     name="name"
//                     placeholder="Name"
//                     className="w-full bg-transparent border border-gray-700 text-white"
//                     value={languageData.name}
//                     onChange={(e) => setLanguageData({ ...languageData, name: e.target.value })}
//                 />
//                 <Input
//                     type="textarea"
//                     inputType='textarea'
//                     name="description"
//                     placeholder="Description"
//                     className="w-full bg-transparent border border-gray-700 text-white"
//                     rows='6'
//                     defaultValue={languageData.description}
//                     onChange={(e) => setLanguageData({ ...languageData, description: e.target.value })}
//                 />

//             </div>
//             <div className="flex justify-end mt-5 px-5">
//                 <Button
//                     onClick={() => handleSubmit()}
//                     title='Submit'
//                     width='w-32'
//                     className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
//                     loading={loading}
//                 />
//             </div>
//         </Layout>
//     )
// }

// export default EditLanguage