// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import { Hr, MuiDialog } from '../../../components'
import { UseQueryResult } from '@tanstack/react-query'
import { removeSessionEndpointPost } from '../../../http/Apis'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import toast from 'react-hot-toast'
import { IMuiDialog } from '../../Content/EditContent'
import { useEffect, useState } from 'react'
import { ClientJS } from 'clientjs'
interface IProps {
    id: string;
    user: IUsersResponse['data'][0]
    userQuery: UseQueryResult
}

const UserSessions = ({ user, id, userQuery }: IProps): JSX.Element => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const [fingerprint, setFingerprint] = useState<number>()
    const [removeSessionToken, setRemoveSessionRefreshToken] = useState<string>('')
    const [muiDialogContant, setMuiDialogContant] = useState<IMuiDialog>({
        title: "",
        contentText: "",
        buttonOneText: "",
        buttonTwoText: "",
        twoAction: false,
        actionKey: false,
        bulkDeleteData: false,
    })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const client = new ClientJS();
        const fingerprint = client.getFingerprint();
        setFingerprint(fingerprint);
    }, []);

    // status dialog
    const [muiDialogOpen, setMuiDialogOpen] = useState(false);
    const handleMuiDialogOpen = () => {
        setMuiDialogOpen(true);
    };
    const handleMuiDialogClose = () => {
        setMuiDialogOpen(false);
        setTimeout(() => {
            setMuiDialogContant({
                title: '',
                contentText: '',
                buttonOneText: '',
                buttonTwoText: '',
                twoAction: false,
                actionKey: false,
                bulkDeleteData: false,
            })
        }, 100);
    };

    const removeSession = async (refreshToken: string) => {
        try {
            if (!id) return toast.error("Something went wrong")
            setLoading(true)
            await removeSessionEndpointPost({ token: removeSessionToken, userId: id })
            toast.success("Session removed")
            userQuery.refetch()
            setLoading(false)
            handleMuiDialogClose()

        } catch (error) {
            setLoading(false)
            toast.error("Something went wrong")
        }
    }

    const printLocation = (location: any) => {
        if (location) {
            return `${location?.city ? location.city + "," : ""} ${location.state ? location.state + "," : ""} ${location.country || ''}`
        } else {
            return 'Unknown location'
        }
    }

    const deviceIdCheck = (deviceId: string) => {
        if (deviceId === fingerprint?.toString()) {
            return "This device";
        } else {
            return false;
        }
    };

    return (
        <div className="grid grid-cols-2 gap-x-5 gap-y-3 mt-5">
            <div>
                <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                    User Sessions
                </h1>
                <p className="mt-2 opacity-70 mb-4">
                    Manage and log out your active sessions on other browsers and devices.
                </p>
            </div>
            <Hr />
            <div>
                {
                    user.sessions && user.sessions.length > 0 ? user.sessions.map((session, index) => {
                        return (
                            <div key={index} className={`${darkMode ? 'bg-gray-600' : 'bg-[#DEDEDE]'} p-5 mr-5 mb-2 w-[30rem] rounded cursor-pointer`}>
                                <div className='flex justify-between items-center'>
                                    <div className='flex gap-5 items-center'>
                                        <div>
                                            <h1>
                                                <img src="https://res.cloudinary.com/dm4uaqlio/image/upload/v1674713906/svgexport-5_ocib7q.svg" />
                                            </h1>
                                        </div>
                                        <div>
                                            <h1 className='font-bold'>
                                                {session.deviceInfo?.name || 'Unknown device'}
                                            </h1>
                                            <h1 className='text-sm'>
                                                {printLocation(session.deviceInfo?.location)}
                                            </h1>
                                            <div className='flex gap-3 items-center'>
                                                <div className="flex gap-3 items-center">
                                                    <h1 className="text-sm mt-0.5">
                                                        {session.deviceInfo?.browser || "Unknown browser"}
                                                    </h1>
                                                    {session.deviceId && (
                                                        <div className="flex items-center gap-1 mt-0.5">
                                                            {deviceIdCheck(session.deviceId) && (
                                                                <img
                                                                    src="https://res.cloudinary.com/dm4uaqlio/image/upload/v1674736515/svgexport-9_glkch0.svg"
                                                                    className="mt-0.5 w-4"
                                                                />
                                                            )}
                                                            <h1 className="mt-0.5 text-sm">
                                                                {deviceIdCheck(session.deviceId)}
                                                            </h1>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div>
                                        {session.deviceId ? (
                                            <>
                                                {deviceIdCheck(session.deviceId) ? null : (
                                                    <div
                                                        onClick={() => {
                                                            handleMuiDialogOpen();
                                                            setRemoveSessionRefreshToken(session.token);
                                                            setMuiDialogContant({
                                                                title: "Remove session",
                                                                contentText:
                                                                    "Are you sure you want to remove this session?",
                                                            });
                                                        }}
                                                        className="flex gap-2 items-center border border-gray-500 px-3 py-1 rounded-full cursor-pointer"
                                                    >
                                                        <img
                                                            src="https://res.cloudinary.com/dm4uaqlio/image/upload/v1674737500/svgexport-12_1_ueucz0.svg"
                                                            alt=""
                                                            className="w-4"
                                                        />
                                                        <h1 className="text-sm">Sign out</h1>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    handleMuiDialogOpen();
                                                    setRemoveSessionRefreshToken(session.token);
                                                    setMuiDialogContant({
                                                        title: "Remove session",
                                                        contentText:
                                                            "Are you sure you want to remove this session?",
                                                    });
                                                }}
                                                className="flex gap-2 items-center border border-gray-500 px-3 py-1 rounded-full cursor-pointer"
                                            >
                                                <img
                                                    src="https://res.cloudinary.com/dm4uaqlio/image/upload/v1674737500/svgexport-12_1_ueucz0.svg"
                                                    alt=""
                                                    className="w-4"
                                                />
                                                <h1 className="text-sm">Sign out</h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <div>
                        <h1 className='text-base'>
                            No sessions found
                        </h1>
                    </div>
                }
            </div>
            {/* Dialog */}
            <MuiDialog
                open={muiDialogOpen}
                onClose={handleMuiDialogClose}
                title={muiDialogContant.title}
                contentText={muiDialogContant.contentText}
                onClickClose={handleMuiDialogClose}
                onClickYes={() => removeSession(removeSessionToken)}
                buttonOneText={muiDialogContant.buttonOneText}
                buttonTwoText={muiDialogContant.buttonTwoText}
                loading={loading}
            />
        </div>
    )
}

export default UserSessions