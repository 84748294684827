import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";
import { Hr } from "../../components";
import Layout from "../../components/Layout/Layout";
import SaveIcon from "@mui/icons-material/Save";
import { Switch } from "@mui/material";
import { InputField } from "../../tailwind";
import axios from "axios";
import toast from "react-hot-toast";
import {
  createSMSCredential,
  getSMSCredential,
  setSMSAction,
} from "../../http/Apis";

function SMSSettings() {
  const [loading, setLoading] = useState(false);
  const [isFast2Sms, setIsFast2Sms] = useState(false);
  const [isTwilio, setIsTwilio] = useState(false);
  const [smsCredArr, setSMSCredArr] = useState([
    {
      _id: 101,
      name: "fast2sms",
      auth_key: "",
      status: false,
    }, {
      _id: 102,
      name: "twilio",
      sid: "",
      token: "",
      number: "",
      status: false,
    }
  ]);
  const [fast2sms, setFast2SMS] = useState({
    _id: 101,
    name: "fast2sms",
    auth_key: "",
    status: false,
  });

  const [twilio, setTwilio] = useState({
    _id: 102,
    name: "twilio",
    sid: "",
    token: "",
    number: "",
    status: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getSMSCredential();
        console.log(res);
        setSMSCredArr(res.data.result);
        setFast2SMS(res.data.result[0]);
        if (res.data.result[0].auth_key !== "") {
          setIsFast2Sms(true);
        }
        setTwilio(res.data.result[1]);
        if (res.data.result[1].sid !== "") {
          setIsTwilio(true)
        }
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const err: any = error.response;
          return toast.error(err.data.error.message);
        }
        return console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    try {

      let key: keyof typeof twilio;

      for (key in twilio) {

        if (fast2sms.auth_key === "" && smsCredArr[0].auth_key === "") {
          return toast.error(`Authorization key for fast2sms required`)
        };

        if (twilio[key] === "" && smsCredArr[0].auth_key !== "") {
          return toast.error(`twilio ${key} required`)
        }
      }

      setLoading(true);
      const dataObj = { dataArr: [fast2sms, twilio] };
      const res = await createSMSCredential(dataObj);
      if (res.status === 200) {
        setLoading(false);
        if (twilio.sid !== "") {
          setIsTwilio(true)
        }

        if (fast2sms.auth_key !== "") {
          setIsFast2Sms(true)
        }

        return toast.success(res.data.message);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        setLoading(false);
        return toast.error(err.data.error.message);
      }
      setLoading(false);
      return console.log(error);
    }
  };

  const handleAction = async (id: string) => {
    try {
      const res = await setSMSAction(id);

      if (res.status === 200) {
        return toast.success(res.data.message);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        return toast.error(err.data.error.message);
      }

      return console.log(error);
    }
  };

  return (
    <Layout>
      <div className="pr-5">
        <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
          SMS Settings
        </h1>
        <p className="mt-2 opacity-70">
          You can update your SMS settings here.
        </p>
      </div>
      <div className="my-5">
        <Hr />
      </div>

      {smsCredArr.length > 0 &&
        smsCredArr.map((item: any, index: number) => {
          return (
            <>
              <div className="flex items-center gap-2">
                <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                  {item.name}
                </h1>
                <span>
                  <span className="text-green-500">(Available)</span>
                </span>
              </div>

              <div>
                <p className="mt-2 opacity-70">
                  Update your {item.name} api key and secret key here. You can
                  get your {item.name} api key and secret key from{" "}
                  <a
                    href="https://payumoney.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
              </div>

              {item.name === "fast2sms" && (
                <div className="my-5 pr-36 flex flex-col gap-8">
                  <InputField
                    title={" API Key"}
                    fieldName={"fieldname"}
                    type="text"
                    placeholder={"placeholder"}
                    value={fast2sms.auth_key}
                    onChange={(e) => {
                      setFast2SMS({
                        ...fast2sms,
                        ["auth_key"]: e.target.value,
                      });
                    }}
                  />
                  <div className="flex items-center gap-4">
                    <h1 className="text-lg opacity-80 font-semibold tracking-wide">
                      {item.name} Status
                    </h1>
                    {isFast2Sms ? <Switch
                      onChange={(e: any) => {
                        setFast2SMS({
                          ...fast2sms,
                          ["status"]: !fast2sms.status,
                        });
                        if (twilio.status) {
                          setTwilio({
                            ...twilio,
                            ["status"]: !twilio.status,
                          });
                        }
                        handleAction(item._id);
                      }}
                      checked={fast2sms.status}
                      disabled={fast2sms.auth_key === "" ? true : false}
                      name="available"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    /> : ""}
                  </div>
                  <div className="my-4"></div>
                </div>
              )}

              {item.name === "twilio" && (
                <div className="my-5 pr-36 flex flex-col gap-8">
                  <InputField
                    title={"Twilio AUTHORIZATION Token"}
                    fieldName={"AUTHORIZATION Token"}
                    type="text"
                    placeholder={"Enter twilio authorization Token"}
                    value={twilio.token}
                    onChange={(e) => {
                      setTwilio({
                        ...twilio,
                        ["token"]: e.target.value,
                      });
                    }}
                  />
                  <InputField
                    title={"Twilio SID"}
                    fieldName={"fieldname"}
                    type="text"
                    placeholder={"Enter Twilio SID"}
                    value={twilio.sid}
                    onChange={(e) => {
                      setTwilio({
                        ...twilio,
                        ["sid"]: e.target.value,
                      });
                    }}
                  />
                  <InputField
                    title={"Twilio Phone Number"}
                    fieldName={"fieldname"}
                    type="text"
                    placeholder={"Enter Twilio Phone Number"}
                    value={twilio.number}
                    onChange={(e) => {
                      setTwilio({
                        ...twilio,
                        ["number"]: e.target.value,
                      });
                    }}
                  />
                  <div className="flex items-center gap-4">
                    <h1 className="text-lg opacity-80 font-semibold tracking-wide">
                      {item.name} Status
                    </h1>
                    {isTwilio ? <Switch
                      onChange={(e: any) => {
                        if (fast2sms.status) {
                          setFast2SMS({
                            ...fast2sms,
                            ["status"]: !fast2sms.status,
                          });
                        }
                        setTwilio({
                          ...twilio,
                          ["status"]: !twilio.status,
                        });
                        handleAction(item._id);
                      }}
                      checked={twilio.status}
                      disabled={
                        twilio.sid === "" || twilio.number === "" || twilio.token === "" ? true : false
                      }
                      name="available"
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    /> : ""}
                  </div>
                  <div className="my-4"></div>
                </div>
              )}
            </>
          );
        })}

      <div className="pr-35">
        <div className="flex justify-end pr-28 mb-20">
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </Layout>
  );
}

export default SMSSettings;
