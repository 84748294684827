import { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useSelector } from "react-redux";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { getSettingsApi } from "../../http/settingshttp";
import { Loader } from "../../components/smallComponents/loader";
import RazorpayPaymentSettings from "./components/payments/razorpay";
import StripePaymentSettings from "./components/payments/stripe";
import UPIPaymentSettings from "./components/payments/UPI";
import PaymentsAlart from "./components/payments/PaymentsAlart";
import CcAvenuePaymentSettings from "./components/payments/ccavenue";

const PaymentSettings = () => {
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const [razorpayData, setRazorpayData] = useState<IPaymentGatewaySettings>({} as IPaymentGatewaySettings);
  const [stripeData, setStripeData] = useState<IPaymentGatewaySettings>({} as IPaymentGatewaySettings);
  const [upiData, setUpiData] = useState<IPaymentGatewaySettings>({} as IPaymentGatewaySettings);
  const [ccavenueData, setCcavenueData] = useState<IPaymentGatewaySettings | undefined>(undefined);

  // get payment gateways useQuery
  const getPaymentGatewaysQuery = useQuery<{ data: IPaymentGatewaySettings[] }>({
    queryKey: ["getPaymentGateways"],
    queryFn: () => getSettingsApi("payment_gateways"),
    retryOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      // get razorpay data
      const razorpayData = data.data.find((gateway) => gateway.provider === "razorpay");
      const stripeData = data.data.find((gateway) => gateway.provider === "stripe");
      const upiData = data.data.find((gateway) => gateway.provider === "UPI");
      const ccavenueData = data.data.find((gateway) => gateway.provider === "ccavenue");
      if (razorpayData) setRazorpayData(razorpayData);
      if (stripeData) setStripeData(stripeData);
      if (upiData) setUpiData(upiData);
      if (ccavenueData) setCcavenueData(ccavenueData);
    },
    onError(err) {
      toast.error("Error while fetching payment gateways");
    },
  })

  return (
    <Layout>
      <div className="flex justify-between items-center">
        <div className="pr-6">
          <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
            Payment Gateway Settings
          </h1>
          <p className="mt-2 opacity-70">
            You can update your payment gateway settings here.
          </p>
        </div>
      </div>
      <PaymentsAlart />
      <div className="my-5 pr-6">
        <Hr />
      </div>
      <div className="mt-5 w-full">
        {
          getPaymentGatewaysQuery.isLoading && <div className='w-full flex justify-center items-center min-h-[47rem]'>
            <Loader smail />
          </div>
        }
      </div>
      <div className="mt-5 w-full">
        {
          getPaymentGatewaysQuery.isError && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
            <h1 className='text-xl font-medium opacity-90'>
              Server Error. Please try again
            </h1>
            <div
              onClick={() => getPaymentGatewaysQuery.refetch()}
              className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
              <h1 className='text-sm text-white'>
                Retry
              </h1>
            </div>
          </div>
        }
      </div>
      {
        getPaymentGatewaysQuery.isSuccess && <>
          <RazorpayPaymentSettings darkMode={darkMode} data={razorpayData} getPaymentGatewaysQuery={getPaymentGatewaysQuery} />
          {ccavenueData && <CcAvenuePaymentSettings darkMode={darkMode} data={ccavenueData} getPaymentGatewaysQuery={getPaymentGatewaysQuery} />}
          <StripePaymentSettings darkMode={darkMode} data={stripeData} getPaymentGatewaysQuery={getPaymentGatewaysQuery} />
          <UPIPaymentSettings darkMode={darkMode} data={upiData} getPaymentGatewaysQuery={getPaymentGatewaysQuery} />
        </>
      }
    </Layout>
  );
};

export default PaymentSettings;