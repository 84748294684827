// Author: Pukhraj Dhamu
// Created Date: Sunday, July 19th 2023 at 8:29 PM
// Last Modified Date: Sunday, July 19th 2023 at 8:29 PM
import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAuth } from "../redux/slice​/authSlice";
import { ClientJS } from "clientjs";
import { publicIpv4 } from "public-ip";

export function useLoadingWithRefresh() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();
    const browser = client.getBrowser();
    const os = client.getOS();

    (async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/auth/whoami`,
          {
            withCredentials: true,
          }
        );
        dispatch(setAuth(data.data.user));
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  return { loading };
}
