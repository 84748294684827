import React, { useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import { Button, Input, Title } from '../../tailwind'
import toast from 'react-hot-toast'
import { createSlider, getCategories, getMovies } from '../../http/Apis'
import Multiselect from 'multiselect-react-dropdown'

const AddNewSlider = () => {
    const [loading, setLoading] = React.useState(false)

    const [sliderData, setSliderData] = React.useState({
        title: '',
        category: '',
        order: '',
        sliderData: [],
    })

    const [categories, setCategories] = React.useState([])
    const [movies, setMovies] = React.useState([])

    const fetchCategories = async () => {
        const { data } = await getCategories();
        setCategories(data.data)
    };

    const fetchMovies = async () => {
        const { data } = await getMovies();
        setMovies(data.data)
    }

    useEffect(() => {
        fetchCategories()
        fetchMovies()
    }, [])

    // category select
    const onCategorySelect = (selectedList) => {
        setSliderData({
            ...sliderData,
            category: selectedList,
        })
    }

    const onCategoryRemove = (removedList) => {
        setSliderData({
            ...sliderData,
            category: removedList,
        })
    }

    // movie select
    const onMovieSelect = (selectedList) => {
        setSliderData({
            ...sliderData,
            sliderData: selectedList,
        })
    }

    const onMovieRemove = (removedList) => {
        setSliderData({
            ...sliderData,
            sliderData: removedList,
        })
    }




    const handleSubmit = async () => {
        const fromData = new FormData()
        fromData.append('title', sliderData.title)
        // category
        if (sliderData.category.length > 0) {
            for (let key of sliderData.category.entries()) {
                fromData.append('category', key[1].id);
            }
        }
        fromData.append('order', sliderData.order)
        // movies
        if (sliderData.sliderData.length > 0) {
            for (let key of sliderData.sliderData.entries()) {
                fromData.append('sliderData', key[1].id);
            }
        }

        setLoading(true)
        try {
            const res = await createSlider(fromData)
            if (res.status === 201) {
                toast.success('Slider created successfully')
                setLoading(false)
            }
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    return (
        <Layout>
            <Title className="text-white font-medium text-xl mb-5">Add a Slider</Title>

            <div className='flex flex-col gap-4'>
                <Input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="w-full"
                    value={sliderData.title}
                    onChange={(e) => setSliderData({ ...sliderData, title: e.target.value })}
                />
                <div className='bg-secondary-color-800 rounded h-11 px-3'>
                    <Multiselect
                        options={categories} // Options to display in the dropdown
                        selectionLimit={1} // Maximum number of options can be selected
                        onSelect={onCategorySelect} // Function will trigger on select event
                        onRemove={onCategoryRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder='Select Category' // Placeholder to display when no option is selected
                    />
                </div>
                <Input
                    type="number"
                    name="order"
                    placeholder="Order"
                    className="w-full"
                    value={sliderData.order}
                    onChange={(e) => setSliderData({ ...sliderData, order: e.target.value })}
                />
                <div className='bg-secondary-color-800 rounded h-11 px-3'>
                    <Multiselect
                        options={movies} // Options to display in the dropdown
                        selectionLimit={10} // Maximum number of options can be selected
                        onSelect={onMovieSelect} // Function will trigger on select event
                        onRemove={onMovieRemove} // Function will trigger on remove event
                        displayValue="title" // Property name to display in the dropdown options
                        placeholder='Select Movies' // Placeholder to display when no option is selected
                    />
                </div>
            </div>
            <div className="flex justify-end mt-5">
                <Button
                    onClick={() => handleSubmit()}
                    title='Submit'
                    width='w-32'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    loading={loading}
                />
            </div>
        </Layout>
    )
}

export default AddNewSlider