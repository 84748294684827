// check file extension and return icon
export interface IFileIconProps {
    name: string;
    key?: string;
}

// check file extension and return true or false
export const checkFileExtension = (file: IFileIconProps, types: any) => {
    const fileName = file.key?.split("/");
    let ext;
    if (fileName) {
        ext = fileName[fileName.length - 1].split(".").pop();
    }
    if (types.includes(ext)) {
        return true;
    }
    return false;
};

// kb to mb
export const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};





