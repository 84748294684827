import { SectionsTable } from './components'
import Layout from '../../components/Layout/Layout'

function Sections() {
  return (
    <Layout>
      <div className='pr-6 pb-10'>
        <SectionsTable />
      </div>
    </Layout>
  )
}

export default Sections
