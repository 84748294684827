// Author: Pukhraj Dhamu
// Created Date: Friday, July 14th 2023 at 4:17 PM
// Last Modified Date: Friday, July 14th 2023 at 4:17 PM
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import Layout from "../../../../components/Layout/Layout";
import EmailEditor from "react-email-editor";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import toast from "react-hot-toast";
import { InputField, SelectField } from "../../../../tailwind";
import { createMarketingTemplate } from "../../../../http/marketing.http";

function CreateMarketingTemplate() {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = useState<string>("");
  const [templateType, setTemplateType] = useState<IMarketingTemplateData['type']>();
  const [templateBody, setTemplateBody] = useState<string>("");
  // email template code
  const emailEditorRef = React.useRef<any>(null);
  const handleSaveTemplate = async () => {

    if (!name) return toast.error("Please enter template name");
    if (!templateType) return toast.error("Please select template type");
    if (templateType === "email" && emailEditorRef.current) {
      setLoading(true);
      emailEditorRef.current!.editor.exportHtml(async (data: any) => {
        const { design, html } = data;
        try {
          const templateObj = {
            name: name,
            content: html,
            type: templateType as IMarketingTemplateData['type'],
            editor_design_content: JSON.stringify(design),
          };
          const res = await createMarketingTemplate(templateObj);
          if (res.status === 201) {
            setLoading(false);
            return toast.success("Template Created Successfully");
          }
        } catch (error: any) {
          if (axios.isAxiosError(error)) {
            if (error.response && error.response.data) {
              let msg: any = error.response.data;
              setLoading(false);
              return toast.error(msg.error.message);
            }
          }
          setLoading(false);
          return console.log(error);
        }
      });
    } else {
      setLoading(true);
      try {
        const templateObj = {
          name: name,
          content: templateBody,
          type: templateType as IMarketingTemplateData['type'],
        };
        const res = await createMarketingTemplate(templateObj);
        if (res.status === 201) {
          setLoading(false);
          return toast.success("Template Created Successfully");
        }
      }
      catch (error: any) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.data) {
            let msg: any = error.response.data;
            setLoading(false);
            return toast.error(msg.error.message);
          }
        }
        setLoading(false);
        return console.log(error);
      }
    }
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };

  return (
    <Layout>
      <div className="pr-6">
        <h1 className="font-medium text-2xl my-3 opacity-80">
          Create Marketing Template
        </h1>
        <div className="flex flex-col gap-2 my-2">
          <InputField
            fieldName="name"
            placeholder="Enter Template Name"
            type="text"
            label="Template Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
          <SelectField
            options={[
              { value: 'email', label: 'Email' },
              { value: 'sms', label: 'SMS' },
              { value: 'push', label: 'Push Notification' },
              { value: 'whatsapp', label: 'Whatsapp' }
            ]}
            onChange={(e: { value: IMarketingTemplateData['type'] }) => setTemplateType(e.value)}
            placeholder="Select Template Type"
          />
        </div>
        {
          templateType && templateType === "email" ? (<EmailEditor
            minHeight={800}
            options={{
              displayMode: "email",
              features: {
                stockImages: true,
              },
              id: "dy-email-editor",
            }}
            projectId={69082}
            ref={emailEditorRef}
            tools={{
              "custom#dy_recommendation": {
                position: 0,
              },
            }}
            onLoad={onLoad}
            onReady={onReady}
          />) : <div>
            <InputField
              fieldName="Template"
              placeholder="Enter Template Body"
              type="text"
              label="Template Body"
              value={templateBody}
              required
              onChange={(e) => setTemplateBody(e.target.value)}
            />
          </div>
        }
        <div className="py-2 w-full flex justify-end">
          <LoadingButton
            id="add-email-template"
            type="submit"
            loading={loading}
            disabled={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={handleSaveTemplate}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </Layout>
  );
}

export default CreateMarketingTemplate;
