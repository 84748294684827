import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { RootState } from "../../redux/store";
import { logoutEndpoint } from "../../http/Apis";
import { ClientJS } from "clientjs";
import { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { blue } from "@mui/material/colors";

const Profile = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const [fingerprint, setFingerprint] = useState<number>();

  useEffect(() => {
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();
    setFingerprint(fingerprint);
  }, []);

  // LogoutFunction
  const LogoutFunc = async () => {
    try {
      const response = await logoutEndpoint({
        deviceId: fingerprint?.toString() as any,
      });
      if (response.status === 200) {
        toast.success("Logout Successfully");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    } catch (error: any) {
      toast.error(error.response.data.message || "Something went wrong");
    }
  };

  return (
    <div className="container flex flex-wrap justify-between items-center mx-auto">
      <div className="dropdown dropdown-end">
        <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
          <div className="rounded-full">
            {/* <img src={user.avatar} /> */}
            <Avatar sx={{ bgcolor: blue[500] }}>
              {user?.name?.charAt(0).toUpperCase() || "A"}
            </Avatar>
          </div>
        </label>
        <ul tabIndex={0} className={`m-0 p-0 shadow menu menu-compact dropdown-content ${darkMode ? "bg-[#282828]" : "bg-white"} rounded  w-52`}>
          <div
            className={`z-50 pb-4 pt-2 px-2 text-base list-none ${darkMode ? "bg-[#282828]" : "bg-white"
              } rounded  divide-y divide-gray-100 shadow dark:divide-gray-400`}
            id="dropdown"
          >
            <div className="p-2">
              <span className="block text-sm">{user.name}</span>
              <span className="block text-sm font-medium">
                {user.email.length >= 22
                  ? user.email.substring(0, 20) + "..."
                  : user.email}
              </span>
            </div>
            <ul className="py-1" aria-labelledby="dropdown">
              <Link to={`/admin/users/edit/${user._id}`}>
                <li>
                  <span className="block py-2 px-4 text-sm">Profile</span>
                </li>
              </Link>

              <Link to="/admin/dashboard">
                <li>
                  <span className="block py-2 px-4 text-sm">Dashboard</span>
                </li>
              </Link>
              <Link to="/admin/settings">
                <li>
                  <span className="block py-2 px-4 text-sm">Settings</span>
                </li>
              </Link>
              <li onClick={LogoutFunc}>
                <span className="block py-2 px-4 text-sm">Sign out</span>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Profile;
