// Author: Pukhraj Dhamu
// Date Created: Sunday 21 May 2023 at 7:04 PM
// Date Modified: Sunday 21 May 2023 at 7:04 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const getBaseUrl = (url: string) => {
    // remove /api from the end of the url
    const urlArray = url.split("/");
    urlArray.pop();
    const baseUrl = urlArray.join("/");
    return baseUrl;
};
const SectionApis = axios.create({
    baseURL: getBaseUrl(process.env.REACT_APP_API_URL!),
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
});

// list all endpoints
// init payment gateway settings if new provider added
export const initSctionApi = () => SectionApis.get(`/service/sections/initiate`);
export const getSectionApi = (query: string) => SectionApis.get(`/service/sections${query ? '?' + query : ""}`).then((res) => res.data);
export const createSectionApi = <T>(payload: T) => SectionApis.post(`/service/sections`, payload);
export const updateSectionApi = <T>(payload: T) => SectionApis.put(`/service/sections`, payload);
export const deleteSectionApi = (id: string) => SectionApis.delete(`/service/sections/${id}`);
export const statusSectionApi = (id: string, status: boolean) => SectionApis.put(`/service/sections/status/${id}`, { 
    status: status
 });
export const updateOrderSectionApi = (payload: any) => SectionApis.put(`/service/sections/order`, payload);

SectionApis.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

export default SectionApis;