// Author: Pukhraj Dhamu
// Date Created: Thuesday 13 Jul 2023 at 12:24 PM
// Date Modified: Thuesday 13 Jul 2023 at 12:24 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const marketingApis = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    }
});

export interface IUpdateContentSourcePayload {
    source_type: "source" | "trailer";
    content_type: IContentData["type"];
    source_link: string;
    source_file_type: IContentData["source_type"];
    job_id: string | null;
}

// list all endpoints
export const sentEmailMarketing = (payload: ISentEmailPayload) => marketingApis.post(`/marketing/send-email`, payload);
export const getMarketingTemplates = (query?: string) => marketingApis.get(`/marketing/template${query ? `?${query}` : ""}`);
export const marketingTemplateActions = (payload: IMarketingTemplatePayload) => marketingApis.put(`/marketing/template/action`, payload);
export const createMarketingTemplate = (payload: ICreateMarketingTemplatePayload) => marketingApis.post("/marketing/template", payload);
export const updateMarketingTemplate = (id: string, payload: ICreateMarketingTemplatePayload) => marketingApis.put(`/marketing/template/${id}`, payload);


marketingApis.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

export default marketingApis;