// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { getSettingsApi } from '../../../../http/settingshttp';
import { useState } from 'react';
import { initCCavenuePaymentGatewaySettingApi } from '../../../../http/paymentshttp';


const availablePaymentGateways = [
    "razorpay",
    "stripe",
    "UPI",
    "ccavenue"
];

function PaymentsAlart() {
    const [razorpayMissing, setRazorpayMissing] = useState<boolean>(false);
    const [stripeMissing, setStripeMissing] = useState<boolean>(false);
    const [upiMissing, setUpiMissing] = useState<boolean>(false);
    const [ccavenueMissing, setCcavenueMissing] = useState<boolean>(false);

    // get payment gateways useQuery
    useQuery<{ data: IPaymentGatewaySettings[] }>({
        queryKey: ["getPaymentGateways"],
        queryFn: () => getSettingsApi("payment_gateways"),
        retryOnMount: false,
        refetchOnWindowFocus: false,
        onSuccess(data) {
            const allGateways = data.data.map((gateway) => gateway.provider);
            const missingGateways = availablePaymentGateways.filter((gateway) => !allGateways.includes(gateway as any));
            if (missingGateways.length > 0) {
                // now tell me the name of the missing gateways
                missingGateways.forEach((gateway) => {
                    switch (gateway) {
                        case "razorpay":
                            setRazorpayMissing(true);
                            break;
                        case "stripe":
                            setStripeMissing(true);
                            break;
                        case "UPI":
                            setUpiMissing(true);
                            break;
                        case "ccavenue":
                            setCcavenueMissing(true);
                            break;
                        default:
                            break;
                    }
                }
                )
            }
        },
        onError(err) {
            toast.error("Error while fetching payment gateways");
        },
    })

    // create ccavenue default config
    const createDefaultCcavenueConfigMutation = useMutation({
        mutationKey: ['createDefaultCcavenueConfig'],
        mutationFn: async () => initCCavenuePaymentGatewaySettingApi(),
        onSuccess: (data) => {
            toast.success("CCAvenue configured successfully");
            setCcavenueMissing(false);
        },
        onError: (error) => {
            toast.error("Failed to configure CCAvenue");
        }
    });
    return (
        <div className='mt-4 pr-6'>
            {
                ccavenueMissing && <div className='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative' role='alert'>
                    <strong className='font-bold'>
                        New Payment Gateway Available!
                    </strong><br />
                    <span className='block sm:inline'>
                        CCAvenue payment gateway is now available. Please update your payment gateway settings to start accepting payments. <br />
                        <a
                            onClick={() => createDefaultCcavenueConfigMutation.mutate()}
                            className='text-blue-500 hover:text-blue-700 mt-1 cursor-pointer'>
                            {createDefaultCcavenueConfigMutation.isLoading ?
                                'Configuring CCAvenue...'
                                : "Click here to accept payments"}
                        </a>
                    </span>
                </div>
            }
        </div>
    )
}

export default PaymentsAlart