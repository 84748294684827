import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: () => void;
    title: string;
    contentText?: string;
    onClick: () => void;
    onClickBtnName?: string;
    onCloseBtnName?: string;
    loading?: boolean;
    children?: React.ReactNode;
}

export default function MuiFormDialog({ open, onClose, title, contentText, onClick, onClickBtnName, onCloseBtnName, loading, children }: IProps) {
    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {contentText}
                    </DialogContentText>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{onCloseBtnName || 'Cancel'}</Button>
                    <LoadingButton
                        loading={loading}
                        disabled={loading}
                        onClick={onClick}>
                        {onClickBtnName || 'Create'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}