import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IPropsFormDialog {
    open: boolean;
    onClick: () => void;
    btnText: string;
    onClose: () => void;
    title: string;
    contentText: string;
    label: string;
    id: string;
    type?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

}

const FormDialog = ({open, onClick, btnText, onClose, title, contentText, label, id, type = 'text', value, onChange}: IPropsFormDialog) => {

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {contentText}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id={id}
            label={label}
            type={type}
            fullWidth
            variant="standard"
            value={value}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onClick}>{btnText}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;