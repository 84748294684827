// Author: Pukhraj Dhamu
// Created Date: Tuesday, July 11th 2023 at 11:00 AM
// Last Modified Date: Tuesday, July 11th 2023 at 11:00 AM
import React from 'react'
import Layout from '../../components/Layout/Layout'
import { InputField, SelectField, TagField } from '../../tailwind'
import toast from 'react-hot-toast'
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save';
import { useParams } from 'react-router-dom'
import { CustomMarkDownEditor } from '../../components'
import { getAds, updateAd } from '../../http/ads.http'
import { useQuery } from '@tanstack/react-query';
import qs from 'qs'
import { Loader } from '../../components/smallComponents/loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const EditAds = () => {
    const { id } = useParams<{ id: string }>()
    const [loading, setLoading] = React.useState(false)
    const queryString: string = qs.stringify({ id: id }, { encodeValuesOnly: true });
    const { darkMode } = useSelector((state: RootState) => state.theme)


    // fetch content query
    const adQuery = useQuery<IAdsNetworkResponse>({
        queryKey: ["get-ad", queryString],
        queryFn: () => getAds(queryString),
        enabled: !!id,
    })

    const data: IAdsNetwork = adQuery.data?.data && adQuery.data?.data.length > 0 ? adQuery.data?.data[0] : null

    const initialValues = {
        title: data?.title || '',
        description: data?.description || '',
        adsSource: data?.adsSource || '',
        poster: data?.poster || '',
        targetUrl: data?.targetUrl || '',
    };

    const editAdSchema = Yup.object().shape({
        title: Yup.string().required("Title is required").min(2, "Title is too short").max(80, "Title is too long"),
        description: Yup.string().required("Description is required").min(2, "Description is too short").max(200, "Description is too long"),
        // adssource must be a valid url
        adsSource: Yup.string().required("Ads source is required").url("Ads source must be a valid url"),
        poster: Yup.string().required("Poster url is required").url("Poster url must be a valid url"),
        targetUrl: Yup.string().required("Target url is required").url("Target url must be a valid url"),
    });

    const handleSubmitFrom = async (values: any) => {
        try {
            setLoading(true)
            const response = await updateAd(id as string, values)
            if (response.status === 200) {
                toast.success('Ad Update successfully')
                setLoading(false)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
            setLoading(false)
        }

    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =

        useFormik({
            initialValues,
            validationSchema: editAdSchema,
            enableReinitialize: true,
            onSubmit: (values, action) => {
                handleSubmitFrom(values)
                //action.resetForm();
            },
        });

    interface IContentForm {
        name: string;
        slug?: boolean;
        placeholder: string;
        type: string;
        value?: any;
        error?: any;
        required?: boolean;
        maxLength?: number;
        limitValidation?: boolean;
        options?: any;
        isMulti?: boolean;
        promiseOptions?: any;
        urlName?: string;
        url?: string;
        className?: string;
        imageStyle?: string;
        placeholderStyle?: string;
        col?: string;
    }

    const contentForm: IContentForm[] = [
        {
            "name": "title",
            "placeholder": "Type ad title here",
            "type": "text",
            "value": values.title,
            "error": errors.title,
            "required": true,
            "maxLength": 80,
            "limitValidation": true,
            "col": "col-span-2"
        },
        {
            "name": "description",
            "placeholder": "Type description here",
            "type": "text",
            "value": values.description,
            "error": errors.description,
            "required": true,
            "maxLength": 200,
            "limitValidation": true,
            "col": "col-span-2"
        },
        {
            "name": "adsSource",
            "placeholder": "Type ads source here",
            "type": "text",
            "value": values.adsSource,
            "error": errors.adsSource,
            "required": true,
            "limitValidation": false,
            "col": "col-span-2"
        },
        {
            "name": "poster",
            "placeholder": "Type poster url here",
            "type": "text",
            "value": values.poster,
            "error": errors.poster,
            "required": true,
            "limitValidation": false,
            "col": "col-span-2"
        },
        {
            "name": "targetUrl",
            "placeholder": "Type target url here",
            "type": "text",
            "value": values.targetUrl,
            "error": errors.targetUrl,
            "required": true,
            "limitValidation": false,
            "col": "col-span-2"
        },
    ]

    return (
        <Layout>
            <h1 className="font-medium opacity-80 text-2xl mb-3.5">
                Edit Ad
            </h1>
            <div className="mt-5 w-full">
                {adQuery.isLoading && <div className='w-full flex justify-center items-center min-h-[47rem]'>
                    <Loader smail />
                </div>}
            </div>
            <div className="mt-5 w-full">
                {
                    adQuery.isError && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            Server Error. Please try again
                        </h1>
                        <div
                            onClick={() => adQuery.refetch()}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                            <h1 className='text-sm text-white'>
                                Retry
                            </h1>
                        </div>
                    </div>
                }
            </div>
            {
                adQuery.isSuccess ? adQuery.data?.data.length > 0 ? <>
                    <form id='edit-ad' className='pr-6' onSubmit={handleSubmit}>
                        <div className='flex flex-col gap-4'>
                            {
                                contentForm.map((item) => {
                                    switch (item.type) {
                                        case 'text':
                                            return (<InputField
                                                fieldName={item.name}
                                                placeholder={item.placeholder}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={item.value}
                                                type='text'
                                                required={item.required}
                                                maxLength={item.maxLength}
                                                error={item.error}
                                                limitValidation={item.limitValidation}
                                                col={item.col}
                                                showErrorMessage={true}
                                            />)
                                        case 'textarea':
                                            return (
                                                <div className='h-auto w-full'>
                                                    <CustomMarkDownEditor
                                                        value={item.value}
                                                        onChange={(e) => {
                                                            let event = { target: { name: item.name, value: e } }
                                                            handleChange(event)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        case 'select':
                                            return (
                                                <SelectField
                                                    options={item.options}
                                                    onChange={(e) => {
                                                        let event = { target: { name: item.name, value: e } }
                                                        handleChange(event)
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder={item.placeholder}
                                                    isMulti={item.isMulti}
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option._id}
                                                    promiseOptions={item.promiseOptions}
                                                    col={item.col}
                                                />
                                            )
                                        case 'tags':
                                            return (
                                                <TagField
                                                    fieldName={item.name}
                                                    placeholder={item.placeholder}
                                                    col={item.col}
                                                    onKeyDown={(e) => {
                                                        if (e.key !== 'Enter') return
                                                        e.preventDefault()
                                                        let event = {
                                                            target: {
                                                                name: item.name,
                                                                value: [...item.value as any, e.target.value]
                                                            }
                                                        }
                                                        handleChange(event)
                                                        e.target.value = ''
                                                    }}
                                                    value={item.value}
                                                    onRemove={(index) => {
                                                        let event = {
                                                            target: {
                                                                name: item.name,
                                                                value: item.value.filter((item: any, i: number) => i !== index) as any
                                                            }
                                                        }
                                                        handleChange(event)
                                                    }}
                                                />
                                            )
                                    }
                                })
                            }
                        </div>

                        <div className="flex justify-end my-5">
                            <LoadingButton
                                id='edit-ad'
                                type='submit'
                                loading={loading}
                                disabled={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant='contained'>
                                Save
                            </LoadingButton>
                        </div>
                    </form>
                </> : <>
                    <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            No Data Found
                        </h1>
                        <div
                            onClick={() => adQuery.refetch()}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                            <h1 className='text-sm text-white'>
                                Retry
                            </h1>
                        </div>
                    </div>
                </> : null
            }

        </Layout>
    )
}

export default EditAds