// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import { useQuery, QueryClient, UseQueryResult } from '@tanstack/react-query'
import { getUserHistoryApi } from '../../../http/Apis'

interface IProps {
    id: string;
    user: IUsersResponse['data'][0]
    userQuery: UseQueryResult
}

const UserHistory = ({ user, id, userQuery }: IProps): JSX.Element => {
    // history query
    const { data: historyData, isLoading, isError, refetch, isFetching } = useQuery<IGetUserHistory>({
        queryKey: ['userhistory'],
        queryFn: () => getUserHistoryApi(`id=${id}`),
    })

    return (
        <div className='w-full mt-5'>
            {isError && <div>
                <div> {isFetching ? null : 'No history found'}  </div>
            </div>}
            <div className='grid grid-flow-col grid-cols-7 gap-2'>
                {isFetching ? <div>Loading...</div> : isError ? null : historyData && historyData.data.length > 0 && historyData.data.map((history, index) => {
                    return (
                        <div key={index} className={`relative`} >
                            <img
                                className='rounded object-cover aspect-video'
                                src={history.content.thumbnail}
                            />
                            <div className='absolute bg-gradient-to-t from-black top-0 bottom-0 w-full h-full rounded'>
                                <div className='text-sm absolute bottom-1 left-1.5'>{history.content.name}</div>
                            </div>

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default UserHistory