// Author: Pukhraj Dhamu
// Created Date: Saturday 8 July 2023 at 11:03 AM
// Last Modified Date: Saturday 8 July 2023 at 11:03 AM
import Layout from '../../../components/Layout/Layout'
import { AllJobs } from '../components';

const TranscoderDashboard = () => {
    return <Layout>
        <div className='pr-6'>
            <AllJobs />
        </div>
    </Layout>
}

export default TranscoderDashboard;
