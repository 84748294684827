import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ISourceUpdateType } from '../../Pages/FileManager/components/ContentSourceUpdate'

export interface ToolState {
  isFullScreen: boolean,
  sourceUpdate: {
    content_id: string | undefined,
    episode_id: string | undefined,
    sourceType: ISourceUpdateType['value'] | undefined,
    source: string | undefined,
  }
}

const initialState: ToolState = {
  isFullScreen: false,
  sourceUpdate: {} as ToolState['sourceUpdate'],
}

export const ToolSlice = createSlice({
  name: 'tool',
  initialState,
  reducers: {
    handleFullScreen: (state, actions: PayloadAction<{ isFullScreen: boolean }>) => {
      const isFullScreen = actions.payload
      state.isFullScreen = isFullScreen.isFullScreen
    },
    handleSourceUpdate: (state, actions: PayloadAction<ToolState['sourceUpdate']>) => {
      const sourceUpdate = actions.payload
      console.log("🚀 ~ file: toolSlice.ts:30 ~ sourceUpdate:", sourceUpdate)
      state.sourceUpdate = sourceUpdate
    }
  },
})

// Action creators are generated for each case reducer function
export const { handleFullScreen, handleSourceUpdate } = ToolSlice.actions

export default ToolSlice.reducer