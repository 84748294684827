import { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { InputField, TextAreaField } from "../../tailwind";
import { useSelector } from "react-redux";
import { updateSettingApi } from "../../http/Apis";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";


const SeoSetting = () => {
    const { user } = useSelector((state: RootState) => state.auth);
    const { config } = useSelector((state: RootState) => state.config);
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const { credentials } = config;
    const [loading, setLoading] = useState(false);
    const [seo, setSeo] = useState({
        name: config.name,
        description: config.description,
        copy_right_text: config.copy_right_text,
        siteUrl: config.siteUrl,
        seo_title: config.seo_title,
        meta_description: config.meta_description,
        meta_keywords: config.meta_keywords || [],
        google_teg_manager: config.google_teg_manager,
        logo: "",
        logoUrl: config.logo,
        favicon: "",
        faviconUrl: config.favicon,
        fcm: {
            server_key: credentials?.fcm?.server_key || "",
        }
    });

    useEffect(() => {
        if (user) {
            const timer = setTimeout(() => {
                setSeo({
                    name: config.name,
                    description: config.description,
                    copy_right_text: config.copy_right_text,
                    siteUrl: config.siteUrl,
                    seo_title: config.seo_title,
                    meta_description: config.meta_description,
                    meta_keywords: config.meta_keywords,
                    google_teg_manager: config.google_teg_manager,
                    logo: "",
                    logoUrl: config.logo,
                    favicon: "",
                    faviconUrl: config.favicon,
                    fcm: {
                        server_key: credentials?.fcm?.server_key || "",
                    }
                });
                // if config is empty object
                if (!Object.keys(config).length) {
                    toast.error("Error to get config");
                }
            }, 1000);
            return () => {
                clearTimeout(timer);
            }
        }
    }, [config]);

    const updateSeoSettings = async () => {
        setLoading(true);
        try {
            // data validation
            if (!seo?.seo_title || !seo?.meta_description || !seo?.google_teg_manager) {
                toast.error("Please fill all the fields");
                setLoading(false);
                return;
            }
            const formData = new FormData();
            if (!config._id) return toast.error("Config not found");
            formData.append("name", seo.name);
            formData.append("description", seo.description);
            formData.append("siteUrl", seo.siteUrl);
            formData.append("seo_title", seo.seo_title);
            formData.append("meta_description", seo.meta_description);
            formData.append("copy_right_text", seo.copy_right_text);
            // append fcm object
            formData.append("fcm", JSON.stringify(seo.fcm));
            // meta_keywords
            if (seo.meta_keywords.length > 0) {
                for (let key of seo.meta_keywords.entries() as any) {
                    formData.append('meta_keywords', key[1]);
                }
            }
            formData.append("google_teg_manager", seo.google_teg_manager);
            const res = await updateSettingApi(config._id, formData);
            if (res.status === 200) {
                toast.success("Updated Successfully");
                setLoading(false);
            } else {
                toast.error("Something went wrong");
                setLoading(false);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || "Something went wrong");
            setLoading(false);
        }
    }

    return (
        <Layout>
            <div className="pr-5">
                <h1 className="text-2xl opacity-80 font-semibold tracking-wide">SEO Settings</h1>
                <p className="mt-2 opacity-70">
                    Here you can update your website SEO settings.
                </p>
            </div>
            <div className="my-5">
                <Hr />
            </div>
            <h6 className="opacity-70 pr-5">
                SEO is a way to optimize your website search engine ranking. You can update your website SEO settings here.
            </h6>
            <div className="my-14 pr-36 flex flex-col gap-8">
                <InputField
                    title="SEO Title"
                    fieldName="seo_title"
                    type="text"
                    placeholder="Enter your site SEO title"
                    value={seo.seo_title}
                    onChange={(e) => setSeo({ ...seo, seo_title: e.target.value })}
                />
                <div className="flex w-full">
                    <TextAreaField
                        title="Meta Description"
                        fieldName="meta_description"
                        type="textarea"
                        value={seo.meta_description}
                        placeholder="Enter your store's meta description"
                        textAreaOnChange={(e) => setSeo({ ...seo, meta_description: e.target.value })}
                    />
                </div>
                <div className={`border pr-5 py-3 rounded ${darkMode ? 'border-[#343434]' : 'border-gray-200'} mt-2 w-full`}>
                    <input
                        placeholder='Type and press enter to add tag'
                        className="bg-transparent border-none focus:outline-none font-medium w-full pl-4"
                        name='tags'
                        onKeyDown={(e: any) => {
                            if (e.key !== 'Enter') return
                            const value = e.target.value
                            if (!value.trim()) return
                            setSeo({ ...seo, meta_keywords: [...seo.meta_keywords, value] })
                            e.target.value = ''
                        }}
                    />
                </div>
                {
                    seo.meta_keywords && seo.meta_keywords.length > 0 && <div className={`bg-transparent border ${darkMode ? 'border-[#343434]' : 'border-gray-200'} rounded px-2 py-2.5 flex items-center flex-wrap gap-3`}>
                        {
                            seo.meta_keywords.map((tag, index) => {
                                return (
                                    <div key={index} className="bg-blue-500 py-1 px-3 rounded flex gap-2">
                                        <div className="text-white">{tag}</div>
                                        <div className="text-white bg-gray-800 px-1 w-6 items-center justify-center flex rounded-full cursor-pointer" onClick={() => {
                                            const newTags = [...seo.meta_keywords]
                                            newTags.splice(index, 1)
                                            setSeo({ ...seo, meta_keywords: newTags })
                                        }} >&times;</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>

            <div className="mt-24 pr-5">
                <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                    Google Tag Manager
                </h1>
                <p className="mt-2 opacity-70">
                    Tag Manager gives you the ability to add and update your own tags for conversion tracking, site analytics, remarketing, and more. There are nearly endless ways to track activity across your sites and apps, and the intuitive design lets you change tags whenever you want. for more information visit <a href="https://tagmanager.google.com/" target="_blank" rel="noreferrer" className="text-blue-500">Google Tag Manager</a>
                </p>
            </div>

            <div className="my-10 pr-36 flex flex-col gap-8">
                <InputField
                    title="Google Tag Manager ID"
                    fieldName="google_teg_manager"
                    type="text"
                    placeholder="Enter your Google Tag Manager ID"
                    value={seo.google_teg_manager}
                    onChange={(e) => setSeo({ ...seo, google_teg_manager: e.target.value })}
                />
                <div className="flex justify-end">
                    <div className="flex justify-end">
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            onClick={updateSeoSettings}>
                            Save
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </Layout>

    );
}

export default SeoSetting;