import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useMutation, useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { getAppSettingsApi, updateAppSettingsApi } from '../../../http/app.settings.http';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import { InputField, SelectField } from '../../../tailwind';
import Lottie from "lottie-react";
import { toast } from 'react-hot-toast';
import SaveIcon from "@mui/icons-material/Save";
import CustomizedSwitches from '../../../components/Mui/Switch/MuiSwitch';


function TVAppSplashScreen() {
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [appSplashScreen, setAppSplashScreen] = useState<AppSettingsApiResponse['data']['tv_splesh_screen']>({} as AppSettingsApiResponse['data']['tv_splesh_screen']);
    const [splashSource, setSplashSource] = useState<string | null>(null);
    const [lottieAnimation, setLottieAnimation] = useState<string | null>(null);
    const queryStrings = qs.stringify({
        fields: ["tv_splesh_screen"],
    }, { encodeValuesOnly: true });
    // fetch lottie animation from api
    const getLottieData = async (url: string) => {
        try {
            const response = await fetch(url);
            const data = await response.json();
            setLottieAnimation(data);
        } catch (error) {
            toast.error("Error fetching lottie animation");
        }
    }
    // get app splash screen settings
    const appSplashScreenQuery = useQuery<AppSettingsApiResponse>({
        queryKey: ["tvAppSplashScreen"],
        queryFn: () => getAppSettingsApi(queryStrings),
        refetchOnWindowFocus: false,
        retryOnMount: false,
        onSuccess: (res) => {
            setAppSplashScreen(res.data.tv_splesh_screen);
            if (res.data.tv_splesh_screen.type === 'lottie-animation') getLottieData(res.data.tv_splesh_screen.source);
        }
    })

    const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { files } } = e
        // get object url and set it to logoUrl
        if (files && files.length > 0) {
            const splesh_logoUrl = URL.createObjectURL(files[0]);
            setSplashSource(splesh_logoUrl);
            setAppSplashScreen({ ...appSplashScreen, source: files[0] as any });
            if (appSplashScreen.type === 'lottie-animation') getLottieData(splesh_logoUrl);
        }
    };

    interface ResizeModeData {
        label: string;
        value: SpleshScreen['resizeMode'];
    }
    const ResizeModeData: ResizeModeData[] = [
        { label: 'Contain', value: 'contain' },
        { label: 'Cover', value: 'cover' },
        { label: 'Stretch', value: 'stretch' },
        { label: 'Center', value: 'center' },
        { label: 'Repeat', value: 'repeat' }
    ]
    function getSelectedResizeModeLable(resizeMode: SpleshScreen['resizeMode']): ResizeModeData | undefined {
        return ResizeModeData.find((item) => item.value === resizeMode);
    }

    interface SpleashScreenSourceType {
        label: string;
        value: SpleshScreen['type'];
    }

    const SpleashScreenSourceType: SpleashScreenSourceType[] = [
        { label: 'Image', value: 'image' },
        { label: 'Video', value: 'video' },
        { label: 'Lottie Animation', value: 'lottie-animation' }
    ]
    function getSeelctedSpleashScreenSourceTypeLabel(type: SpleshScreen['type']): SpleashScreenSourceType | undefined {
        return SpleashScreenSourceType.find((item) => item.value === type);
    }

    // update the app splash screen settings using muteion
    const AppSpleshScreeMutation = useMutation({
        mutationKey: ["tvupdateAppSpleshScreen"],
        mutationFn: (payload: IUpdateAppSpleshScreenPayload) => updateAppSettingsApi<IUpdateAppSpleshScreenPayload>(payload, "tv_splesh_screen", {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }),
        onSuccess(data, variables, context) {
            toast.success("TV App splash screen updated successfully");
        },
        onError(error, variables, context) {
            toast.error("Error updating app splash screen");
        },
    })

    const handleAppSpleshScreenUpdate = () => {
        const formData = new FormData();
        formData.append("type", appSplashScreen.type);
        formData.append("resizeMode", appSplashScreen.resizeMode);
        formData.append("backgroundColor", appSplashScreen.backgroundColor);
        formData.append("screenTime", appSplashScreen.screenTime.toString());
        formData.append("backgroundTransparent", appSplashScreen.backgroundTransparent.toString());
        formData.append("fullscreen", appSplashScreen.fullscreen.toString());
        // if source is a file then append it to form data
        const file = appSplashScreen.source as any;
        if (file instanceof File) formData.append("source", appSplashScreen.source);
        formData.append("height", appSplashScreen.height.toString());
        formData.append("width", appSplashScreen.width.toString());
        AppSpleshScreeMutation.mutate(formData as any);

    }

    return (
        <div>
            <h1 className="my-6 opacity-80 font-medium tracking-wide text-xl pr-5">
                TV App Splash Screen
            </h1>
            <span className='text-red-500'>
                Note:- After updating the splash screen configuration on the admin dashboard, please note that users opening the app for the first time might not immediately experience the changes due to caching. Subsequent openings will reflect the updated splash screen.
            </span>
            {
                appSplashScreenQuery.isLoading && (
                    <div className="flex justify-center items-center">
                        <div className="w-10 h-10 border-t-2 border-primary rounded-full animate-spin"></div>
                    </div>
                )
            }
            {
                appSplashScreenQuery.isError && (
                    <div className="flex justify-center items-center">
                        <div className="text-red-500">Error fetching data</div>
                    </div>
                )
            }
            {
                appSplashScreenQuery.isSuccess && (
                    <div className="my-10 flex flex-col gap-8 pr-6">
                        <div className="flex w-full justify-between pr-10">
                            <div className="w-1/2 flex flex-col gap-5">
                                <div className="w-full">
                                    <h6 className="opacity-70 pr-5 pb-2">Background Color</h6>
                                    <SketchPicker
                                        styles={{
                                            default: {
                                                picker: {
                                                    background: darkMode ? "#222" : "#fff",
                                                    color: darkMode ? "#000" : "#000",
                                                },
                                            },
                                        }}
                                        color={appSplashScreen.backgroundColor}
                                        onChange={(color) => setAppSplashScreen({ ...appSplashScreen, backgroundColor: color.hex })}
                                    />
                                </div>
                                <InputField
                                    fieldName="screen-time"
                                    placeholder="Screen Time in seconds"
                                    title="Screen Time"
                                    type="number"
                                    value={appSplashScreen.screenTime}
                                    onChange={(e) => setAppSplashScreen({ ...appSplashScreen, screenTime: parseInt(e.target.value) })}
                                />
                                <InputField
                                    fieldName="logo-size"
                                    placeholder="Logo width in px"
                                    title="Width"
                                    type="number"
                                    value={appSplashScreen.width}
                                    onChange={(e) => setAppSplashScreen({ ...appSplashScreen, width: parseInt(e.target.value) })}
                                />
                                <InputField
                                    fieldName="logo-size-height"
                                    placeholder="Logo height in px"
                                    title="Height"
                                    type="number"
                                    value={appSplashScreen.height}
                                    onChange={(e) => setAppSplashScreen({ ...appSplashScreen, height: parseInt(e.target.value) })}
                                />
                                <SelectField
                                    placeholder='Select resize mode'
                                    options={ResizeModeData}
                                    defaultValue={[getSelectedResizeModeLable(appSplashScreen.resizeMode)] as any}
                                    onChange={(e) => setAppSplashScreen({ ...appSplashScreen, resizeMode: e.value })}
                                />
                                <SelectField
                                    placeholder='Source Type'
                                    options={SpleashScreenSourceType}
                                    defaultValue={[getSeelctedSpleashScreenSourceTypeLabel(appSplashScreen.type)] as any}
                                    onChange={(e) => setAppSplashScreen({ ...appSplashScreen, type: e.value })}
                                />
                                <CustomizedSwitches
                                    label='Full Screen'
                                    checked={appSplashScreen.fullscreen}
                                    variant='ios'
                                    onChange={(e) => setAppSplashScreen({ ...appSplashScreen, fullscreen: e.target.checked })}
                                />
                            </div>
                            <div className="mt-7">
                                <input
                                    type="file"
                                    className={`display-none`}
                                    onChange={handleLogoChange}
                                    id='upload_app_tv_splash'
                                />
                                <label htmlFor='upload_app_tv_splash'>
                                    <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[16px] rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px]">
                                        <div className="rounded-xl overflow-hidden h-[140px] md:h-[262px]">
                                            <div
                                                style={{
                                                    backgroundColor: appSplashScreen.backgroundColor,
                                                }}
                                                className={`h-[140px] md:h-[262px] w-[30rem] flex justify-center items-center rounded-xl z-10`}>

                                                {
                                                    appSplashScreen.type === 'video' && (
                                                        <video
                                                            src={splashSource ? splashSource : appSplashScreen.source}
                                                            style={{
                                                                width: appSplashScreen.fullscreen ? `100%` : appSplashScreen.width ? `${appSplashScreen.width}px` : "auto",
                                                                height: appSplashScreen.fullscreen ? `100%` : appSplashScreen.height ? `${appSplashScreen.height}px` : "auto",
                                                            }}
                                                            className={`${`object-${appSplashScreen.resizeMode}`}`}
                                                            autoPlay
                                                            loop
                                                            muted
                                                        />
                                                    )
                                                }
                                                {
                                                    appSplashScreen.type === "image" && (
                                                        <img
                                                            src={splashSource ? splashSource : appSplashScreen.source}
                                                            style={{
                                                                width: appSplashScreen.fullscreen ? `100%` : appSplashScreen.width ? `${appSplashScreen.width}px` : "auto",
                                                                height: appSplashScreen.fullscreen ? `100%` : appSplashScreen.height ? `${appSplashScreen.height}px` : "auto",
                                                            }}
                                                            className={`${`object-${appSplashScreen.resizeMode}`}`}
                                                        />
                                                    )
                                                }
                                                {
                                                    appSplashScreen.type === "lottie-animation" && lottieAnimation && (
                                                        <Lottie
                                                            animationData={lottieAnimation}
                                                            loop={true}
                                                            autoplay={true}
                                                            style={{
                                                                width: appSplashScreen.fullscreen ? `100%` : appSplashScreen.width ? `${appSplashScreen.width}px` : "auto",
                                                                height: appSplashScreen.fullscreen ? `100%` : appSplashScreen.height ? `${appSplashScreen.height}px` : "auto",
                                                            }}
                                                        />
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative mx-auto bg-gray-900 dark:bg-gray-700 rounded-b-xl h-[24px] max-w-[301px] md:h-[42px] md:max-w-[512px]"></div>
                                    <div className="relative mx-auto bg-gray-800 rounded-b-xl h-[55px] max-w-[83px] md:h-[95px] md:max-w-[142px]"></div>
                                </label>
                            </div>
                        </div>
                        <div className="flex justify-end my-20">
                            <LoadingButton
                                loading={AppSpleshScreeMutation.isLoading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                onClick={handleAppSpleshScreenUpdate}>
                                Save
                            </LoadingButton>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default TVAppSplashScreen
