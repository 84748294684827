import { useEffect, useState } from "react";
import { InputField } from "../../../../tailwind";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";
import { Checkbox, Switch } from "@mui/material";
import { UseQueryResult, useMutation } from "@tanstack/react-query";
import { updatePaymentGatewaySettingApi } from "../../../../http/settingshttp";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import TvIcon from '@mui/icons-material/Tv';

interface IProps {
    data: IPaymentGatewaySettings;
    darkMode: boolean;
    getPaymentGatewaysQuery: UseQueryResult
}

const RazorpayPaymentSettings = ({ data, darkMode, getPaymentGatewaysQuery }: IProps) => {
    const [viewKey, setViewKey] = useState<boolean>(false);
    const [viewSecret, setViewSecret] = useState<boolean>(false);
    const [razorpayData, setRazorpayData] = useState<IPaymentGatewaySettings>(data);

    useEffect(() => {
        setRazorpayData({ ...data, name: "Razorpay" });
    }, [data]);

    const updatedPaymentGatewaysMutation = useMutation({
        mutationKey: ["updatePaymentGateways"],
        mutationFn: (data: { payment_gateways: IPaymentGatewaySettingsUpdatePayload[] }) => updatePaymentGatewaySettingApi(data, "razorpay"),
        onSuccess(data, variables, context) {
            getPaymentGatewaysQuery.refetch();
            toast.success("Payment gateway updated successfully");
        },
        onError(err, variables, context) {
            toast.error("Error while updating payment gateways");
        }
    })

    const handleSave = async () => {
        // remove availablity key from payment gateways
        delete razorpayData.availablity;
        delete razorpayData.merchant
        delete razorpayData.provider
        delete razorpayData.mode
        delete razorpayData.website
        // convert payment gateways to array
        const paymentGatewaysData = [razorpayData];
        updatedPaymentGatewaysMutation.mutate({ payment_gateways: JSON.stringify(paymentGatewaysData) as any })
    };
    return (
        <div>
            <div className="mt-5 pr-6">
                <div className="flex items-center gap-2">
                    <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                        {razorpayData.name}
                    </h1>
                    <span>
                        {razorpayData.status ? (
                            <span className="text-green-500">(Active)</span>
                        ) : (
                            <span className="text-red-500">(Inactive)</span>
                        )}
                    </span>
                </div>
                <div>
                    <p className="mt-2 opacity-70">
                        Update your {razorpayData.provider} api key and secret key here. You can
                        get your {razorpayData.provider} api key and secret key from{" "}
                        <a
                            href={"https://razorpay.com/"}
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>
                        .
                    </p>
                </div>
                <div className="my-5 flex flex-col gap-8">
                    <InputField
                        inputType="password"
                        title={razorpayData.name + " API Key"}
                        fieldName={razorpayData.provider + "_api_key"}
                        type={viewKey ? "text" : "password"}
                        placeholder={"Enter your " + data.provider + " api key"}
                        value={razorpayData.key}
                        onPasswordVisibilityClick={() => setViewKey(!viewKey)}
                        onChange={(e) => setRazorpayData({ ...razorpayData, key: e.target.value })}
                    />
                    <InputField
                        inputType="password"
                        title={razorpayData.name + " Secret Key"}
                        fieldName={razorpayData.provider + "_secret_key"}
                        type={viewSecret ? "text" : "password"}
                        placeholder={"Enter your " + data.provider + " secret key"}
                        value={razorpayData.secret}
                        onPasswordVisibilityClick={() => setViewSecret(!viewSecret)}
                        onChange={(e) => setRazorpayData({ ...razorpayData, secret: e.target.value })}
                    />
                </div>
                <div className="my-3.5">
                    <p className="mt-2 opacity-70">
                        Enable this gateway for different platform. like web, android, ios etc.
                    </p>
                    <div className="my-3.5">
                        <div className="flex items-center gap-4">
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <PhoneAndroidIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        Android
                                    </h1>
                                    {
                                        razorpayData.availablity?.android ? <Checkbox
                                            checked={razorpayData.enabled_on?.android}
                                            onChange={(e: any) => setRazorpayData({ ...razorpayData, enabled_on: { ...razorpayData.enabled_on, android: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <PhoneIphoneIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        IOS
                                    </h1>
                                    {
                                        razorpayData.availablity?.ios ? <Checkbox
                                            checked={razorpayData.enabled_on?.ios}
                                            onChange={(e: any) => setRazorpayData({ ...razorpayData, enabled_on: { ...razorpayData.enabled_on, ios: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <LaptopMacIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        Website
                                    </h1>
                                    {
                                        razorpayData.availablity?.web ? <Checkbox
                                            checked={razorpayData.enabled_on?.web}
                                            onChange={(e: any) => setRazorpayData({ ...razorpayData, enabled_on: { ...razorpayData.enabled_on, web: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <TvIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        TV
                                    </h1>
                                    {
                                        razorpayData.availablity?.android_tv ? <Checkbox
                                            checked={razorpayData.enabled_on?.android_tv}
                                            onChange={(e: any) => setRazorpayData({ ...razorpayData, enabled_on: { ...razorpayData.enabled_on, android_tv: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <h1 className="text-lg opacity-80 font-semibold tracking-wide">
                        {razorpayData.name} Status
                    </h1>
                    <Switch
                        checked={razorpayData.status}
                        onChange={(e: any) => setRazorpayData({ ...razorpayData, status: e.target.checked })}
                        name="available"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </div>
            </div>
            <div className="pr-6">
                <div className="flex justify-end mb-20">
                    <LoadingButton
                        loading={updatedPaymentGatewaysMutation.isLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        onClick={() => handleSave()}>
                        Save
                    </LoadingButton>
                </div>
            </div>
        </div >
    );
};

export default RazorpayPaymentSettings;