import { useEffect, useState } from "react"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { castActionsEndPoint } from "../../http/Apis";
import { fetchCasts, setBulkActionCall } from "../../redux/slice​/castSlice";
import { RootState } from "../../redux/store";
import { Button, Checkbox } from "@mui/material";
import { IMuiDialog } from "../../Pages/Content/EditContent";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { MuiDialog } from "../../components";
import qs from 'qs'

interface TableProps {
    Data: any;
    page: number;
}

const CastTable = ({ Data, page }: TableProps) => {

    const dispatch = useDispatch();
    const [allSelectArray, setallSelectArray] = useState([])
    const { bulkAction, bulkActionCall } = useSelector((state: RootState) => state.cast);
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [singleSelect, setSingleSelect] = useState([])
    const [status, setStatus] = useState<string>("")
    interface IPagination {
        page: number;
        limit: number;
        order: 'asc' | 'desc';
    }
    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        limit: process.env.REACT_APP_PAGE_SIZE || 25 as any,
        order: 'desc'
    })

    const query = qs.stringify({
        page: pagination.page,
        limit: pagination.limit,
        order: pagination.order
    })

    const [muiDialogContant, setMuiDialogContant] = useState<IMuiDialog>({
        title: "",
        contentText: "",
        buttonOneText: "",
        buttonTwoText: "",
        twoAction: false,
        actionKey: false,
        bulkDeleteData: false,
    })

    // status dialog
    const [muiDialogOpen, setMuiDialogOpen] = useState(false);
    const handleMuiDialogOpen = () => {
        setMuiDialogOpen(true);
    };
    const handleMuiDialogClose = () => {
        setMuiDialogOpen(false);
        setTimeout(() => {
            setMuiDialogContant({
                title: '',
                contentText: '',
            })
        }, 100);
    };
    const data = {
        id: singleSelect,
        action: status
    }
    // handleStatus
    const handleActions = async (data: any) => {
        try {
            const response = await castActionsEndPoint(data)
            if (response.status === 200) {
                toast.success('Cast action performed successfully')
                dispatch(fetchCasts(query) as any)
            }
        } catch (error) {
            toast.error('Something went wrong')
        }
    }

    // headle checkbox
    const headleCheckbox = (e: any) => {
        const { name: id, checked } = e.target;
        if (id === 'selectAll') {
            if (checked) {
                setallSelectArray(Data.map((user: any) => user._id))

            } else {
                setallSelectArray([])
            }
        } else {
            setallSelectArray((prev: any) => {
                if (checked) {
                    return [...prev, id]
                } else {
                    return prev.filter((item: any) => item !== id)
                }
            })
        }

    }

    useEffect(() => {
        if (bulkActionCall) {
            headleBulkActions()
            dispatch(setBulkActionCall(false))
        }
    }, [bulkActionCall])

    const handleDeleteForeverdata = {
        id: singleSelect,
        action: 'delete'
    }
    // handleDelete
    const handleDeleteForever = async (data: any) => {

        try {
            const response = await castActionsEndPoint(data)
            if (response.status === 200) {
                toast.success('Status updated successfully')
                dispatch(fetchCasts(query) as any)
                handleMuiDialogClose()
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
            handleMuiDialogClose()
        }
    }


    // headle bulk actions
    const headleBulkActions = async () => {
        const formData = new FormData();
        if (allSelectArray.length < 1) {
            formData.append('id', allSelectArray as any)
        } else {
            for (let i = 0; i < allSelectArray.length; i++) {
                formData.append('id', allSelectArray[i])
            }
        }
        const newFormData = allSelectArray.length > 0 ? { id: allSelectArray, action: bulkAction } : formData

        if (bulkAction === 'none') {
            toast.error("Please select bulk action")
        } else {
            if (bulkAction === 'delete') {
                setStatus('restore')
                handleMuiDialogOpen()
                setMuiDialogContant({
                    title: 'Restore from Trash',
                    contentText: 'Are you sure you want to restore this cast from trash?',
                    buttonOneText: 'Delete Forever',
                    buttonTwoText: 'Restore',
                    twoAction: true,
                    actionKey: 'restore',
                    bulkDeleteData: newFormData
                })
            } else {
                try {
                    const response = await castActionsEndPoint(newFormData)
                    if (response.status === 200) {
                        toast.success('Cast action performed successfully')
                        dispatch(fetchCasts(query) as any)
                    }
                } catch (error: any) {
                    toast.error(error.response.data.error.message || 'Something went wrong')
                }
            }
        }
    }

    return (
        <>
            <div className="overflow-x-auto w-full pr-5">
                <table className="table w-full">
                    <thead className="w-full">
                        <tr className="w-full">
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434] text-white' : 'border-gray-200'} capitalize text-base font-normal w-10`}>
                                <div className="tooltip tooltip-right -ml-2.5" data-tip="Select All">
                                    <Checkbox
                                        checked={allSelectArray.length === Data.length}
                                        onChange={headleCheckbox}
                                        indeterminate={allSelectArray.length > 0 && allSelectArray.length < Data.length}
                                        color="primary"
                                        name="selectAll"
                                    />
                                </div>
                            </th>
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434] text-white' : 'border-gray-200'} capitalize text-base font-normal`}>Name</th>
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434] text-white' : 'border-gray-200'} capitalize text-base font-normal`}>
                                Status
                            </th>
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434] text-white' : 'border-gray-200'} capitalize text-base font-normal w-full`}>
                                Type
                            </th>

                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434] text-white' : 'border-gray-200'} capitalize text-base font-normal w-full`}>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Data.length > 0 && Data.map((item: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-10`}>
                                            <div className="tooltip tooltip-right -ml-2.5" data-tip="Select One">
                                                <Checkbox
                                                    checked={allSelectArray.includes(item._id as never)}
                                                    onChange={headleCheckbox}
                                                    color="primary"
                                                    name={item._id}
                                                />
                                            </div>
                                        </th>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal`}>
                                            <div className="flex items-center space-x-3">
                                                <div className="w-11 h-11 bg-secondary-color rounded-full overflow-hidden">
                                                    <img
                                                        src={item.avatar || 'https://i.stack.imgur.com/dr5qp.jpg'}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="font-semibold opacity-80 capitalize text-sm">{item.name || 'N/A'}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-[50%]`}>
                                            <span>
                                                {
                                                    item.status ? (
                                                        <div className="flex items-center space-x-2">
                                                            <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                                                            <div className="text-xs text-green-500">
                                                                Public
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="flex items-center space-x-2">
                                                            <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                                                            <div className="text-xs text-red-500">
                                                                Private
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </span>
                                        </td>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-full`}>
                                            <span>
                                                {
                                                    item.type
                                                }
                                            </span>
                                        </td>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} w-full`}>
                                            <div className="flex gap-4">
                                                <label onClick={() => {
                                                    setSingleSelect([item._id as never])
                                                    setStatus(item.status ? 'private' : 'public')
                                                    handleMuiDialogOpen()
                                                    setMuiDialogContant({
                                                        title: 'Cast Status',
                                                        contentText: 'Are you sure you want to change the status of this cast?',
                                                        buttonOneText: 'Cancel',
                                                        buttonTwoText: 'Yes',
                                                        actionKey: 'statusCast'
                                                    })
                                                }} className="cursor-pointer"> {item.status ? <div className="tooltip tooltip-accent" data-tip="Public">
                                                    <Button
                                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                        variant="text">
                                                        <VisibilityIcon />
                                                    </Button>
                                                </div> : <div className="tooltip tooltip-accent" data-tip="Private">
                                                    <Button
                                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                        variant="text">
                                                        <VisibilityOffIcon />
                                                    </Button>
                                                </div>}</label>

                                                <Link to={`/admin/cast-and-crew/edit/${item._id}`} className="cursor-pointer">
                                                    <div className="tooltip tooltip-accent" data-tip="Edit">
                                                        <Button
                                                            className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                            variant="text">
                                                            <EditIcon />
                                                        </Button>
                                                    </div>
                                                </Link>

                                                {
                                                    !item.trash && <label onClick={() => {
                                                        setSingleSelect([item._id] as any)
                                                        setStatus('trash')
                                                        handleMuiDialogOpen()
                                                        setMuiDialogContant({
                                                            title: 'Delete Cast',
                                                            contentText: 'Are you sure you want to delete this cast?',
                                                            buttonOneText: 'Cancel',
                                                            buttonTwoText: 'Yes',
                                                            actionKey: 'deleteCast'
                                                        })
                                                    }}
                                                        className="cursor-pointer">
                                                        <div className="tooltip tooltip-accent" data-tip="Delete">
                                                            <Button
                                                                className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                                variant="text">
                                                                <DeleteIcon />
                                                            </Button>
                                                        </div>
                                                    </label>
                                                }
                                                {
                                                    item.trash && <label onClick={() => {
                                                        setSingleSelect([item._id] as any)
                                                        setStatus('restore')
                                                        handleMuiDialogOpen()
                                                        setMuiDialogContant({
                                                            title: 'Restore Cast',
                                                            contentText: 'Are you sure you want to restore this cast?',
                                                            buttonOneText: 'Cancel',
                                                            buttonTwoText: 'Yes',
                                                            actionKey: 'restoreCast'
                                                        })
                                                    }}
                                                        className="cursor-pointer">
                                                        <div className="tooltip tooltip-accent" data-tip="Delete">
                                                            <Button
                                                                className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                                variant="text">
                                                                <RestoreFromTrashIcon />
                                                            </Button>
                                                        </div>
                                                    </label>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>

            <MuiDialog
                open={muiDialogOpen}
                onClose={handleMuiDialogClose}
                title={muiDialogContant.title}
                contentText={muiDialogContant.contentText}
                onClickClose={() => muiDialogContant.twoAction ? handleDeleteForever(muiDialogContant.bulkDeleteData || handleDeleteForeverdata) : handleMuiDialogClose()}
                onClickYes={() => muiDialogContant.twoAction ? handleActions({
                    id: muiDialogContant.bulkDeleteData.id,
                    action: 'restore'
                }) : handleActions(data)}
                // onClickYes={() => handleActions(data)}
                buttonOneText={muiDialogContant.buttonOneText}
                buttonTwoText={muiDialogContant.buttonTwoText}
            />
        </>
    )
}

export default CastTable;







// import { useEffect, useState } from "react"
// import { DeleteIcon, EditIcon, ReStore, VisibilityIcon, VisibilityOffIcon } from "../Icon"
// import toast from "react-hot-toast";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import { castDelete, castRestore, castStatus } from "../../http/Apis";
// import { fetchCasts, setBulkActionCall } from "../../redux/slice​/castSlice";
// import moment from "moment";

// const CastTable = ({ data, page }) => {
//     const dispatch = useDispatch();
//     const [allSelectArray, setallSelectArray] = useState([])
//     const { bulkAction, bulkActionCall } = useSelector(state => state.cast);
//     const [singleSelect, setSingleSelect] = useState([])
//     const [status, setStatus] = useState(false)
//     const [pagination, setPagination] = useState({
//         page: page,
//         limit: process.env.REACT_APP_PAGE_SIZE
//     })
//     // handleStatus
//     const handleStatus = async () => {
//         const data = {
//             isPublished: status,
//             id: singleSelect,
//         }
//         try {
//             const response = await castStatus(data)
//             if (response.status === 200) {
//                 toast.success('Status updated successfully')
//                 dispatch(fetchCasts(pagination.page, pagination.limit))
//             }
//         } catch (error) {
//             toast.error('Something went wrong')
//         }
//     }
//     // handle Delete
//     const handleDelete = async () => {
//         try {
//             const response = await castDelete({ id: singleSelect })
//             if (response.status === 200) {
//                 toast.success('Cast deleted successfully')
//                 dispatch(fetchCasts(pagination.page, pagination.limit))
//             }
//         }
//         catch (error) {
//             toast.error('Something went wrong')
//         }
//     }
//     // restore
//     const handleRestore = async () => {
//         try {
//             const response = await castRestore({ id: singleSelect })
//             if (response.status === 200) {
//                 toast.success('Cast restored successfully')
//                 dispatch(fetchCasts(pagination.page, pagination.limit))
//             }
//         } catch (error) {
//             toast.error('Something went wrong')
//         }
//     }

//     // headle checkbox
//     const headleCheckbox = (e) => {
//         const { name: id, checked } = e.target;
//         if (id === 'selectAll') {
//             if (checked) {
//                 setallSelectArray(data.map(data => data.id))

//             } else {
//                 setallSelectArray([])
//             }
//         } else {
//             setallSelectArray(prev => {
//                 if (checked) {
//                     return [...prev, id]
//                 } else {
//                     return prev.filter(item => item !== id)
//                 }
//             })
//         }

//     }

//     useEffect(() => {
//         if (bulkActionCall) {
//             headleBulkActions()
//             dispatch(setBulkActionCall(false))
//         }
//     }, [bulkActionCall])


//     // headle bulk actions
//     const headleBulkActions = async () => {
//         const formData = new FormData();
//         if (allSelectArray.length < 1) {
//             formData.append('id', allSelectArray)
//         } else {
//             for (let i = 0; i < allSelectArray.length; i++) {
//                 formData.append('id', allSelectArray[i])
//             }
//         }
//         const newFormData = allSelectArray.length > 0 ? { id: allSelectArray } : formData

//         if (bulkAction === 'none') {
//             toast.error("Please select bulk action")
//         }
//         if (bulkAction === 'Delete') {
//             if (allSelectArray.length === 0) {
//                 toast.error("Please select at least one item")
//             } else {
//                 try {
//                     const res = await castDelete(newFormData);
//                     if (res.status === 200) {
//                         dispatch(fetchCasts(pagination.page, pagination.limit))
//                         toast.success("Cast deleted successfully")

//                     }
//                 } catch (e) {
//                     toast.error("Something went wrong")
//                 }
//             }
//         }
//         if (bulkAction === 'UnPublish') {
//             if (allSelectArray.length === 0) {
//                 toast.error("Please select at least one item")
//             } else {
//                 const data = {
//                     isPublished: false,
//                     id: allSelectArray
//                 }
//                 try {
//                     const res = await castStatus(data);
//                     if (res.status === 200) {
//                         dispatch(fetchCasts(pagination.page, pagination.limit))
//                         toast.success("Status updated")

//                     }
//                 } catch (e) {
//                     toast.error("Something went wrong")
//                 }
//             }
//         }
//         if (bulkAction === 'Publish') {
//             if (allSelectArray.length === 0) {
//                 toast.error("Please select at least one item")
//             } else {
//                 const data = {
//                     isPublished: true,
//                     id: allSelectArray
//                 }
//                 try {
//                     const res = await castStatus(data);
//                     if (res.status === 200) {
//                         dispatch(fetchCasts(pagination.page, pagination.limit))
//                         toast.success("Status updated")
//                     }
//                 } catch (e) {
//                     toast.error("Something went wrong")
//                 }
//             }
//         }
//         if (bulkAction === 'Restore') {
//             if (allSelectArray.length === 0) {
//                 toast.error("Please select at least one item")
//             }
//             try {
//                 const response = await castRestore(newFormData)
//                 if (response.status === 200) {
//                     toast.success('Cast restored successfully')
//                     dispatch(fetchCasts(pagination.page, pagination.limit))
//                 }
//             } catch (error) {
//                 toast.error('Something went wrong')
//             }
//         }
//     }

//     return (
//         <>
//             <div className="overflow-x-auto w-full ">
//                 <table className="table w-full">
//                     <thead>
//                         <tr>
//                             <th className="bg-transparent border-b border-gray-700">
//                                 <div className="tooltip tooltip-right" data-tip="Select All">
//                                     <label>
//                                         <input
//                                             type="checkbox"
//                                             className="checkbox checkbox-primary"
//                                             name="selectAll"
//                                             checked={allSelectArray.length === data.length}
//                                             onChange={headleCheckbox}
//                                         />
//                                     </label>
//                                 </div>
//                             </th>
//                             <th className="bg-transparent border-b border-gray-700 text-gray-300 capitalize text-base font-normal">Name</th>
//                             <th className="bg-transparent border-b border-gray-700 text-gray-300 capitalize text-base font-normal">Description</th>
//                             <th className="bg-transparent border-b border-gray-700 text-gray-300 capitalize text-base font-normal"></th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {
//                             data.length > 0 && data.map((item, index) => {
//                                 return (
//                                     <tr key={index}>
//                                         <th className="bg-transparent border-b border-gray-700 w-10">
//                                             <div className="tooltip tooltip-right" data-tip="Select One">
//                                                 <label>
//                                                     <input
//                                                         type="checkbox"
//                                                         className="checkbox checkbox-primary"
//                                                         name={item.id}
//                                                         onChange={headleCheckbox}
//                                                         checked={allSelectArray.includes(item.id)}
//                                                     />
//                                                 </label>
//                                             </div>
//                                         </th>
//                                         <td className="bg-transparent border-b border-gray-700">
//                                             <div className="flex items-center space-x-3">
//                                                 <div className="avatar">
//                                                     <div className="mask mask-squircle w-10 h-10">
//                                                         <img src={item.avatar ? item.avatar : '/default-product.png'} alt="avatar" />
//                                                     </div>
//                                                 </div>
//                                                 <div>
//                                                     <div className="font-bold text-white opacity-80 capitalize text-sm">{item.name}</div>
//                                                     <div className="text-xs text-white opacity-70 mt-0.5">{
//                                                         moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')
//                                                     }</div>
//                                                 </div>
//                                             </div>
//                                         </td>
//                                         <td className="bg-transparent border-b border-gray-700 text-white text-sm">
//                                             {
//                                             item.description.length > 50 ? item.description.slice(0, 50) + '...' : item.description
//                                             }
//                                         </td>
//                                         <th className="bg-transparent border-b border-gray-700 flex gap-4">
//                                             <div className="tooltip" data-tip="Status">
//                                                 <label htmlFor="cast-status" onClick={() => {
//                                                     setSingleSelect([item.id])
//                                                     setStatus(item.isPublished ? false : true)
//                                                 }} className="cursor-pointer"> {item.isPublished ? <VisibilityIcon backgroundColor="#172337" /> : <VisibilityOffIcon backgroundColor="#172337" />}</label>
//                                             </div>
//                                             <Link to={`/admin/cast/edit/${item.id}`} className="cursor-pointer">
//                                                 <div className="tooltip" data-tip="Edit">
//                                                     <span className="cursor-pointer"><EditIcon backgroundColor="#172337" /></span>
//                                                 </div>
//                                             </Link>
//                                             {
//                                                 !item.isDeleted && <div className="tooltip" data-tip="Delete">
//                                                     <label htmlFor="cast-delete" onClick={() => {
//                                                         setSingleSelect([item.id])
//                                                     }}
//                                                         className="cursor-pointer"> <DeleteIcon /></label>
//                                                 </div>
//                                             }
//                                             {
//                                                 item.isDeleted && <div className="tooltip" data-tip="Restore">
//                                                     <label htmlFor="cast-restore" onClick={() => {
//                                                         setSingleSelect([item.id])
//                                                     }}
//                                                         className="cursor-pointer"> <ReStore /></label>
//                                                 </div>
//                                             }
//                                         </th>
//                                     </tr>
//                                 )
//                             })
//                         }
//                     </tbody>
//                 </table>
//             </div>

//             {/* Status Update Code */}
//             <input type="checkbox" id="cast-status" className="modal-toggle" />
//             <div className="modal">
//                 <div className="modal-box relative bg-dark-card-color">
//                     <label htmlFor="cast-status" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
//                     <h3 className="font-bold text-lg">Cast Status</h3>
//                     <p className="py-2">Are you sure you want to chance status?</p>
//                     <div className="modal-action flex items-center justify-between">
//                         <label htmlFor="cast-status" onClick={handleStatus} className="btn w-1/2">Yes</label>
//                         <label htmlFor="cast-status" className="btn w-1/2">Cancel</label>
//                     </div>
//                 </div>
//             </div>

//             {/* Delete Code */}
//             <input type="checkbox" id="cast-delete" className="modal-toggle" />
//             <div className="modal">
//                 <div className="modal-box relative bg-dark-card-color">
//                     <label htmlFor="cast-delete" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
//                     <h3 className="font-bold text-lg">Cast Delete</h3>
//                     <p className="py-2">Are you sure you want to delete this cast?</p>
//                     <div className="modal-action flex items-center justify-between">
//                         <label htmlFor="cast-delete" onClick={handleDelete} className="btn w-1/2">Yes</label>
//                         <label htmlFor="cast-delete" className="btn w-1/2">Cancel</label>
//                     </div>
//                 </div>
//             </div>

//             {/* Restore Code */}
//             <input type="checkbox" id="cast-restore" className="modal-toggle" />
//             <div className="modal">
//                 <div className="modal-box relative bg-dark-card-color">
//                     <label htmlFor="cast-restore" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
//                     <h3 className="font-bold text-lg">Cast Restore</h3>
//                     <p className="py-2">Are you sure you want to restore this cast?</p>
//                     <div className="modal-action flex items-center justify-between">
//                         <label htmlFor="cast-restore" onClick={handleRestore} className="btn w-1/2">Yes</label>
//                         <label htmlFor="cast-restore" className="btn w-1/2">Cancel</label>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default CastTable;