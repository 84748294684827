import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  contentAnalytics,
  getAnalytics,
  getRevenue,
  getTrending,
  userAnalytics,
} from "../../http/Apis";
import { dateRange } from "../../utils/Utils";

export interface IAnalytics {
  analytics: {
    views: {
      _id: string;
      count: number;
    }[];
    watchTime: {
      _id: string;
      time: number;
    }[];
  };
  LatestMoviePerformance: {
    views: {
      _id: string;
      count: number;
    }[];
    watchTime: {
      _id: string;
      time: number;
    }[];
  };
  trendings: boolean;
  userAnalyticsData: boolean;
  contentAnalytics: boolean;
  revenue: number;
  status: "idle" | "seccess" | "failed" | "loading";
}

const initialState: IAnalytics = {
  analytics: {} as IAnalytics["analytics"],
  LatestMoviePerformance: {} as IAnalytics["LatestMoviePerformance"],
  trendings: false,
  userAnalyticsData: false,
  contentAnalytics: false,
  revenue: 0,
  status: "idle",
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalytics: (state, actions) => {
      const { data } = actions.payload;
      state.analytics = data;
    },
    setStatus: (state, actions: PayloadAction<IAnalytics["status"]>) => {
      state.status = actions.payload;
    },
    setLatestMoviePerformance: (state, actions) => {
      const { data } = actions.payload;
      state.LatestMoviePerformance = data;
    },
    setTrendings: (state, actions) => {
      const { data } = actions.payload;
      state.trendings = data;
    },
    setUserAnalyticsData: (state, actions) => {
      const { data } = actions.payload;
      state.userAnalyticsData = data;
    },
    setContentAnalytics: (state, actions) => {
      const { data } = actions.payload;
      state.contentAnalytics = data;
    },
    setRevenue: (state, actions) => {
      const { data } = actions.payload;
      // console.log("😀😀😀😀", data[0].sum);
      state.revenue = data[0].sum;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAnalytics,
  setStatus,
  setLatestMoviePerformance,
  setTrendings,
  setUserAnalyticsData,
  setContentAnalytics,
  setRevenue,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;

// Thunks
export function fetchAnalytics(queryString: string) {
  return async (dispatch: any) => {
    dispatch(setStatus("loading"));
    try {
      const res = await getAnalytics(queryString);
      console.log(res.data);
      dispatch(setAnalytics(res.data));
      dispatch(setStatus("seccess"));
    } catch (error) {
      console.log(error);
      dispatch(setStatus("failed"));
    }
  };
}

export function fetchAnalyticsLatestMoviePerformance(queryString: string) {
  return async (dispatch: any) => {
    dispatch(setStatus("loading"));
    try {
      const res = await getAnalytics(queryString);
      dispatch(setLatestMoviePerformance(res.data));
      dispatch(setStatus("seccess"));
    } catch (error) {
      console.log(error);
      dispatch(setStatus("failed"));
    }
  };
}

export function fetchAnalyticsTrendings(queryString: string) {
  return async (dispatch: any) => {
    dispatch(setStatus("loading"));
    try {
      const startDate = dateRange("last7").startDate;
      const endDate = dateRange("last7").endDate;
      const res = await getTrending(startDate, endDate);
      dispatch(setTrendings(res.data));
      dispatch(setStatus("seccess"));
    } catch (error) {
      console.log(error);
      dispatch(setStatus("failed"));
    }
  };
}

// fetch user analytics
export function fetchAnalyticsUser(queryString?: string) {
  return async (dispatch: any) => {
    dispatch(setStatus("loading"));
    try {
      const res = await userAnalytics(queryString);
      dispatch(setUserAnalyticsData(res.data));
      dispatch(setStatus("seccess"));
    } catch (error) {
      console.log(error);
      dispatch(setStatus("failed"));
    }
  };
}

// fetch content analytics
export function fetchAnalyticsContent(queryString: string) {
  return async (dispatch: any) => {
    dispatch(setStatus("loading"));
    try {
      const res = await contentAnalytics(queryString);
      dispatch(setContentAnalytics(res.data));
      dispatch(setStatus("seccess"));
    } catch (error) {
      console.log(error);
      dispatch(setStatus("failed"));
    }
  };
}
// fetch revenue
export function fetchRevenue(queryString?: string) {
  return async (dispatch: any) => {
    dispatch(setStatus("loading"));
    try {
      const res = await getRevenue(queryString);
      console.log(res);
      dispatch(setRevenue(res.data));
      dispatch(setStatus("seccess"));
    } catch (error) {
      console.log(error);
      dispatch(setStatus("failed"));
    }
  };
}
