import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { InputField } from "../../tailwind";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import style from "./styles/smtp.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getSettingsApi, updateSMTPCredential } from "../../http/settingshttp";


function SMTPSetting() {
  const { darkMode } = useSelector((state: RootState) => state.theme)
  const [secure, setSecure] = React.useState<string>("");
  const [isShow, setShow] = useState<boolean>(false);
  const [smtpObj, setSMTP] = useState<ISMTPServerSettings>({
    host: "",
    port: "" as any,
    user: "",
    pass: "",
    name: "",
    secure: false as any,
  });

  // Fetch SMTP Credential query
  const smtpQuery = useQuery<{ data: ISMTPServerSettings }>({
    queryKey: ["get-smtp-credential"],
    queryFn: () => getSettingsApi("smtp_server"),
    onSuccess(data) {
      setSMTP(data.data as any);
      // setSecure(data.data.result.secure as any);
    },
    onError(error) {
      toast.error("Failed to fetch SMTP Credential from server");
    }
  })

  const handleChange = (event: SelectChangeEvent) => {
    setSecure(event.target.value as any);
  };

  // useMutation for update and create SMTP Credential
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: ["smtp-credential"],
    mutationFn: (data: ISMTPServerSettings) => updateSMTPCredential(data),
    onSuccess(data) {
      toast.success("SMTP Credential updated successfully");
      smtpQuery.refetch();
    },
    onError(error) {
      toast.error("Failed to update SMTP Credential");
    }
  })

  return (
    <Layout>
      <h1 className="font-medium text-2xl pr-5 mb-3 opacity-80">
        SMTP Credential <span>
          {smtpQuery.isLoading && <span className={`text-base text-gray-400 dot-loading ${style.dotLoading}`}>Loading</span>}
        </span>
      </h1>

      {
        smtpQuery.isError && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
          <h1 className='text-xl font-medium opacity-90'>
            Server Error. Please try again
          </h1>
          <div
            onClick={() => smtpQuery.refetch()}
            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
            <h1 className='text-sm text-white'>
              Retry
            </h1>
          </div>
        </div>
      }
      {
        smtpQuery.isSuccess && <div className="w-full flex flex-col pr-6 py-1">
          <div className="border-1">
            <InputField
              title="Host Name"
              fieldName={"Host Name"}
              type="text"
              value={smtpObj.host || ""}
              placeholder={"Enter Your Host Name"}
              onChange={(e) => {
                setSMTP({
                  ...smtpObj,
                  ["host"]: e.target.value,
                });
              }}
            />
          </div>
          <div className="my-2 border-1">
            <InputField
              title="Port Number"
              fieldName={"Port Number"}
              type="number"
              placeholder={"Enter Your Port Number"}
              value={smtpObj.port || ""}
              onChange={(e) => {
                setSMTP({
                  ...smtpObj,
                  ["port"]: e.target.value as any,
                });
              }}
            />
          </div>
          <div className="border-1">
            <InputField
              title="User Name"
              fieldName={"User Name"}
              value={smtpObj.user || ""}
              type="text"
              placeholder={"Enter Your User Name"}
              onChange={(e) => {
                setSMTP({
                  ...smtpObj,
                  ["user"]: e.target.value,
                });
              }}
            />
          </div>
          <div className="my-2 border-1">
            <InputField
              title="Password"
              fieldName={"Password Name"}
              value={smtpObj.pass || ""}
              type={isShow ? "text" : "password"}
              placeholder={"Enter Your Password"}
              onChange={(e) => {
                setSMTP({
                  ...smtpObj,
                  ["pass"]: e.target.value,
                });
              }}
            />
          </div>
          <div className="my-2 border-1">
            <InputField
              title="Name"
              fieldName={"Name"}
              type="text"
              value={smtpObj.name || ""}
              placeholder={"Enter Your Platform Name"}
              onChange={(e) => {
                setSMTP({
                  ...smtpObj,
                  ["name"]: e.target.value,
                });
              }}
            />
          </div>
          <div className="my-2 border-1">
            <Box sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Secure Option
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={secure || smtpQuery?.data?.data?.secure.toString() || ""}
                  label="Select Secure Option"
                  onChange={handleChange}>
                  <MenuItem value={"true"}>true</MenuItem>
                  <MenuItem value={"false"}>false</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="flex justify-end my-2 border-1  px-2">
            <div className="flex justify-end mt-5">
              <div className="pr-4">
                <Checkbox onChange={() => setShow(!isShow)} />
                Show Paasword
              </div>
              <LoadingButton
                onClick={() => {
                  let key: keyof typeof smtpObj;
                  for (key in smtpObj) {
                    if (smtpObj[key] === "") {
                      return toast.error(`${key}  required.`)
                    }
                  }
                  mutateAsync(smtpObj)
                }}
                loading={isLoading}
                disabled={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained">
                Save
              </LoadingButton>
            </div>
          </div>
        </div>
      }
    </Layout>
  );
}

export default SMTPSetting;