// Author: Pukhraj Dhamu
// Created Date: Tuesday, July 11th 2023 at 11:00 AM
// Last Modified Date: Tuesday, July 11th 2023 at 11:00 AM
import { useEffect, useState } from "react"
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { blogActionsApi } from "../../http/Apis";
import { RootState } from "../../redux/store";
import { Button, Checkbox } from "@mui/material";
import { IMuiDialog } from "../../Pages/Content/EditContent";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { MuiDialog } from "../../components";
import { setBulkActionCall } from "../../redux/slice​/blogSlice";
import { UseQueryResult } from "@tanstack/react-query";
import { AdsActionsApi } from "../../http/ads.http";

interface TableProps {
    Query: UseQueryResult;
}

const AdsTable = ({ Query }: TableProps) => {
    const AdsData: any = Query.data
    const Data: IAdsNetwork[] = AdsData?.data || []
    const dispatch = useDispatch();
    const [allSelectArray, setallSelectArray] = useState([])
    const { bulkAction, bulkActionCall } = useSelector((state: RootState) => state.blog);
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [singleSelect, setSingleSelect] = useState([])
    const [status, setStatus] = useState<string>("")
    const [muiDialogContant, setMuiDialogContant] = useState<IMuiDialog>({
        title: "",
        contentText: "",
        buttonOneText: "",
        buttonTwoText: "",
        twoAction: false,
        actionKey: false,
        bulkDeleteData: false,
    })

    // status dialog
    const [muiDialogOpen, setMuiDialogOpen] = useState(false);
    const handleMuiDialogOpen = () => {
        setMuiDialogOpen(true);
    };
    const handleMuiDialogClose = () => {
        setMuiDialogOpen(false);
        setTimeout(() => {
            setMuiDialogContant({
                title: '',
                contentText: '',
            })
        }, 100);
    };
    const data = {
        id: singleSelect,
        action: status
    }
    // handleStatus
    const handleActions = async (data: any) => {
        try {
            const response = await AdsActionsApi(data)
            if (response.status === 200) {
                toast.success('Ad action performed successfully')
                Query.refetch()
                handleMuiDialogClose()
            }
        } catch (error) {
            toast.error('Something went wrong')
            handleMuiDialogClose()
        }
    }

    // headle checkbox
    const headleCheckbox = (e: any) => {
        const { name: id, checked } = e.target;
        if (id === 'selectAll') {
            if (checked) setallSelectArray(Data.map((item: any) => item._id) as any)
            else setallSelectArray([])
        } else {
            setallSelectArray((prev: any) => {
                if (checked) return [...prev, id]
                else return prev.filter((item: any) => item !== id)
            })
        }
    }

    useEffect(() => {
        if (bulkActionCall) {
            headleBulkActions()
            dispatch(setBulkActionCall(false))
        }
    }, [bulkActionCall])

    const handleDeleteForeverdata = {
        id: singleSelect,
        action: 'delete'
    }
    // handleDelete
    const handleDeleteForever = async (data: any) => {
        try {
            const response = await AdsActionsApi(data)
            if (response.status === 200) {
                toast.success('Status updated successfully')
                Query.refetch()
                handleMuiDialogClose()
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
            handleMuiDialogClose()
        }
    }
    // headle bulk actions
    const headleBulkActions = async () => {
        const formData = new FormData();
        if (allSelectArray.length < 1) {
            formData.append('id', allSelectArray as any)
        } else {
            for (let i = 0; i < allSelectArray.length; i++) {
                formData.append('id', allSelectArray[i])
            }
        }
        const newFormData = allSelectArray.length > 0 ? { id: allSelectArray, action: bulkAction } : formData

        if (bulkAction === 'none') {
            toast.error("Please select bulk action")
        } else {
            try {
                const response = await AdsActionsApi(newFormData as any)
                if (response.status === 200) {
                    toast.success('action performed successfully')
                    Query.refetch()
                }
            } catch (error: any) {
                toast.error(error.response.data.error.message || 'Something went wrong')
            }
        }
    }
    return (
        <>
            <div className="overflow-x-auto w-full pr-5">
                <table className="table w-full">
                    <thead className="w-full">
                        <tr className="w-full">
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434] text-white' : 'border-gray-200'} capitalize text-base font-normal w-10`}>
                                <div className="tooltip tooltip-right -ml-2.5" data-tip="Select All">
                                    <Checkbox
                                        checked={allSelectArray.length === Data.length}
                                        onChange={headleCheckbox}
                                        indeterminate={allSelectArray.length > 0 && allSelectArray.length < Data.length}
                                        color="primary"
                                        name="selectAll"
                                    />
                                </div>
                            </th>
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434] text-white' : 'border-gray-200'} capitalize text-base font-normal w-96`}>Name</th>
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434] text-white' : 'border-gray-200'} capitalize text-base font-normal`}>
                                Status
                            </th>
                            <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434] text-white' : 'border-gray-200'} capitalize text-base font-normal w-full`}>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Data.length > 0 && Data.map((item, index: number) => {
                                return (
                                    <tr key={index}>
                                        <th className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-10`}>
                                            <div className="tooltip tooltip-right -ml-2.5" data-tip="Select One">
                                                <Checkbox
                                                    checked={allSelectArray.includes(item._id as never)}
                                                    onChange={headleCheckbox}
                                                    color="primary"
                                                    name={item._id}
                                                />
                                            </div>
                                        </th>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal`}>
                                            <div className="flex items-center space-x-3 w-96">
                                                <div className="w-11 h-11 bg-secondary-color rounded-full overflow-hidden object-contain">
                                                    <img
                                                        src={item.poster || "/default-product.png"}
                                                        className="w-11 h-11 object-contain"
                                                    />
                                                </div>
                                                <div className="font-semibold opacity-80 capitalize text-sm">{item.title || 'N/A'}</div>
                                            </div>
                                        </td>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} capitalize text-base font-normal w-[50%]`}>
                                            <span>
                                                {
                                                    item.status === "active" && (
                                                        <div className="flex items-center space-x-2">
                                                            <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                                                            <div className="text-xs text-green-500">
                                                                Active
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    item.status === "inactive" && (
                                                        <div className="flex items-center space-x-2">
                                                            <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                                                            <div className="text-xs text-red-500">
                                                                Inactive
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    item.status === "pending" && (
                                                        <div className="flex items-center space-x-2">
                                                            <div className="w-2 h-2 bg-yellow-500 rounded-full"></div>
                                                            <div className="text-xs text-yellow-500">
                                                                Pending
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </span>
                                        </td>
                                        <td className={`bg-transparent border-b ${darkMode ? 'border-[#343434]' : 'border-gray-200'} w-full`}>
                                            <div className="flex gap-4">
                                                <label onClick={() => {
                                                    setSingleSelect([item._id as never])
                                                    setStatus(item.status === 'active' ? "inactive" : 'active')
                                                    handleMuiDialogOpen()
                                                    setMuiDialogContant({
                                                        title: 'Ad Status',
                                                        contentText: 'Are you sure you want to change the status of this ad?',
                                                        buttonOneText: 'Cancel',
                                                        buttonTwoText: 'Yes',
                                                        actionKey: 'statusAd',
                                                    })
                                                }} className="cursor-pointer"> {item.status === "active" ? <div className="tooltip tooltip-accent" data-tip="Public">
                                                    <Button
                                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                        variant="text">
                                                        <VisibilityIcon />
                                                    </Button>
                                                </div> : <div className="tooltip tooltip-info" data-tip="Private">
                                                    <Button
                                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                        variant="text">
                                                        <VisibilityOffIcon />
                                                    </Button>
                                                </div>}</label>

                                                <Link to={`/admin/ads/edit/${item._id}`} className="cursor-pointer">
                                                    <div className="tooltip tooltip-accent" data-tip="Edit">
                                                        <Button
                                                            className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                            variant="text">
                                                            <EditIcon />
                                                        </Button>
                                                    </div>
                                                </Link>

                                                {
                                                    <label onClick={() => {
                                                        setSingleSelect([item._id] as any)
                                                        setStatus('delete')
                                                        handleMuiDialogOpen()
                                                        setMuiDialogContant({
                                                            title: 'Delete Ad',
                                                            contentText: 'Are you sure you want to delete this Ad?',
                                                            buttonOneText: 'Cancel',
                                                            // buttonTwoText: 'Yes',
                                                            actionKey: 'deleteAd'
                                                        })
                                                    }}
                                                        className="cursor-pointer">
                                                        <div className="tooltip tooltip-accent" data-tip="Delete">
                                                            <Button
                                                                className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                                variant="text">
                                                                <DeleteIcon />
                                                            </Button>
                                                        </div>
                                                    </label>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <MuiDialog
                open={muiDialogOpen}
                onClose={handleMuiDialogClose}
                title={muiDialogContant.title}
                contentText={muiDialogContant.contentText}
                onClickClose={() => muiDialogContant.twoAction ? handleDeleteForever(muiDialogContant.bulkDeleteData || handleDeleteForeverdata) : handleMuiDialogClose()}
                onClickYes={() => muiDialogContant.twoAction ? handleActions({
                    id: muiDialogContant.bulkDeleteData.id,
                    action: 'restore'
                }) : handleActions(data)}
                // onClickYes={() => handleActions(data)}
                buttonOneText={muiDialogContant.buttonOneText}
                buttonTwoText={muiDialogContant.buttonTwoText}
            />
        </>
    )
}

export default AdsTable;