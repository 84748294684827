// Author: Pukhraj Dhamu
// Date Created: Wed 23 Aug 2023 at 3:05 PM
// Date Modified: Wed 23 Aug 2023 at 3:05 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const contentsApis = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
    }
});


// list all endpoints images plugin
export const getImageSizes = (queryString?: string) => contentsApis.get(`/content/images${queryString || ""}`).then((res) => res.data);
export const createImageSize = (payload: {
    name: string;
    width: number;
    height: number;
}) => contentsApis.post(`/content/images`, payload);
export const deleteImageSize = (id: string) => contentsApis.delete(`/content/images/${id}`);

// list all endpoints content service
export const deteleImageInContentImages = (payload: { content_id: string; update_image_id: string; }) => contentsApis.delete(`/service/content/images/delete`, { data: payload });

contentsApis.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

export default contentsApis;