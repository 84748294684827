// Author: Pukhraj Dhamu
// Created Date: Saturday 8 July 2023 at 11:03 AM
// Last Modified Date: Saturday 8 July 2023 at 11:03 AM
import React from 'react'
import { BiRefresh, BiTime } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import RouteStrings from '../../../localData/routes.json'
import { HiOutlinePlusSm } from 'react-icons/hi'
import { SelectField } from '../../../tailwind'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { useDebounce } from 'use-debounce'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Base64 from 'base-64';
import { MdOutlineDone, MdOutlineClose } from 'react-icons/md'
import { BsFillEmojiHeartEyesFill } from 'react-icons/bs'
import toast from 'react-hot-toast'
import { Loader } from '../../../components/smallComponents/loader'
import { counter } from '../../../utils/Utils'
import { getSettingsApi } from '../../../http/settingshttp'

function TranscoderJobsPage(): JSX.Element {
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [selectFillter, setSelectFillter] = React.useState<"all" | "completed" | "failed" | "processing" | "waiting">("all");
    const [transcoderConfig, setTranscoderConfig] = React.useState<{
        hostname: string;
        username: string;
        password: string;
        status?: boolean;
    } | undefined>();
    const [search, setSearch] = React.useState<string>();
    const [searchValue] = useDebounce(search, 1000);

    // useQuery for get transcoder settings
    useQuery<{ data: ITranscoderSetting[] }>({
        queryKey: ["getTranscoderQuery"],
        queryFn: () => getSettingsApi("transcoder"),
        onSuccess: (data) => {
            if (data && data.data.length > 0) {
                setTranscoderConfig({
                    hostname: data.data[0].hostname,
                    username: data.data[0].username,
                    password: data.data[0].password,
                    status: data.data[0].status
                });
            }
        },
        onError: (error) => {
            toast.error("Failed to fetch transcoder settings")
        }
    });

    // fetch jobs with pagination using react-query
    const fetchJobs = async (page: number) => {
        const res = await axios.get<IGetTranscoderJobs>(`${transcoderConfig?.hostname}/api/v1/jobs?page=${page}${selectFillter ? '&filter=' + selectFillter : ""}${searchValue ? '&query=' + searchValue : ""}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Basic " + Base64.encode(`${transcoderConfig?.username}:${transcoderConfig?.password}`),
            }
        })
        if (res.data.data.length === 0) {
            toast.error("No jobs found")
            return res.data;
        }
        return res.data;
    }

    const { data: JobsData, refetch, isLoading, isError, isFetching, fetchNextPage, hasNextPage, isSuccess } = useInfiniteQuery({
        queryKey: ['jobs'],
        queryFn: ({ pageParam = 1 }) => fetchJobs(pageParam),
        getNextPageParam: (lastPage) => lastPage.meta.pagination.page + 1,
        enabled: transcoderConfig?.hostname ? true : false,
        refetchInterval: 1000 * 60 * 1, // 1 minute
    })

    // filter jobs by status in server side
    const [onDocumentMount, setonDocumentMount] = React.useState<boolean>(false)
    React.useEffect(() => {
        if (onDocumentMount) {
            refetch();
        }
        setonDocumentMount(true)
        return () => {
            setonDocumentMount(false)
        }

    }, [selectFillter, searchValue])

    const checkStatus = (status: "submitted" | "processing" | "completed" | "failed") => {
        switch (status) {
            case 'submitted':
                return <div className='flex gap-2 items-center'>
                    <BiTime size={20} className='text-yellow-500' />
                    <span className='capitalize text-yellow-500'>
                        waiting
                    </span>
                </div>;
            case 'processing':
                return <div className='flex gap-2 items-center'>
                    <BiRefresh className='animate-spin' size={20} />
                    <span className='capitalize'>
                        processing
                    </span>
                </div>
            case 'completed':
                return <div className='flex gap-2 items-center'>
                    <MdOutlineDone size={22} className='text-green-500' />
                    <span className='capitalize text-green-500'>
                        completed
                    </span>
                </div>
            case 'failed':
                return <div className='flex gap-2 items-center'>
                    <MdOutlineClose size={20} className='text-red-500' />
                    <span className='capitalize text-red-500'>
                        failed
                    </span>
                </div>
            default:
                return <div>
                    unknown
                </div>
        }
    }

    // job priority
    const checkPriority = (priority: "high" | "medium" | "low") => {
        switch (priority) {
            case 'high':
                return <div className='flex gap-2 items-center mt-0.5'>
                    <span className='bg-red-500 px-2 py-0.5 rounded-full capitalize'>
                        <h1 className='text-xs'>
                            {priority}
                        </h1>
                    </span>
                </div>;
            case 'medium':
                return <div className='flex gap-2 items-center'>
                    <span className='text-white bg-yellow-600 px-2 py-0.5 rounded-full capitalize'>
                        <h1 className='text-xs'>
                            {priority}
                        </h1>
                    </span>
                </div>
            case 'low':
                return <div className='flex gap-2 items-center'>
                    <span className='text-white bg-green-500 px-2 py-0.5 rounded-full capitalize'>
                        <h1 className='text-xs'>
                            {priority}
                        </h1>
                    </span>
                </div>
            default:
                return <div>
                    unknown
                </div>
        }
    }

    const checkProgress = (progress: number, status: string) => {
        if (progress === 100) {
            return 'bg-emerald-500';
        } else if (progress > 0 && progress < 100) {
            return 'bg-orange-500';
        } else if (progress === 0 && status === 'failed') {
            return 'bg-red-900';
        }
        else {
            return 'bg-red-900';
        }
    }

    const checkProgressbg = (progress: number, status: string) => {
        if (progress === 0 && status === 'failed') {
            return 'bg-red-500';
        }
        else {
            return 'bg-gray-500';
        }
    }

    // next page button
    const nextPage = () => {
        if (hasNextPage) {
            if (JobsData) {
                if (JobsData.pages[JobsData.pages.length - 1].meta.pagination.page === JobsData.pages[JobsData.pages.length - 1].meta.pagination.totalPage) {
                    toast.error("No more jobs found")
                } else {
                    fetchNextPage()
                }
            }

        }
    }

    const hostNames = ["localhost", "zezosoft"];
    // check allowed hostname
    const checkHostname = (hostname: string) => {
        if (!hostname) return false;
        if (!transcoderConfig?.status) return false;
        // now check if hostname is valid URL
        const url = new URL(hostname);
        // check if hostname is mentioned in hostNames array with regex
        if (hostNames.some((hostName) => new RegExp(hostName, "i").test(url.hostname))) return true;
    }

    return (
        <div className='mb-36'>
            {checkHostname(transcoderConfig?.hostname as string) ? <>
                <div className='bg-opacity-25 2xl:mx-0 rounded-xl overflow-hidden'>
                    <div className='px-1 py-4 flex justify-between items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            Jobs
                        </h1>
                        <div className='flex gap-4 items-center'>
                            <div
                                onClick={() => {
                                    setSearch("");
                                    setSelectFillter("all");
                                    refetch()
                                }}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                                <BiRefresh className={`text-black text-lg bg-white rounded-full ${isFetching && 'animate-spin'}`} />
                                <h1 className='text-sm select-none text-white'>
                                    Refresh
                                </h1>
                            </div>
                            <Link to={RouteStrings.Transcoding.Children.CreateJob.Link}>
                                <div className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                                    <HiOutlinePlusSm className='text-black text-lg bg-white rounded-full' />
                                    <h1 className='text-sm text-white'>
                                        Create Job
                                    </h1>
                                </div>
                            </Link>
                            <div className='w-48'>
                                <SelectField
                                    onChange={(e) => setSelectFillter(e.value)}
                                    options={[
                                        {
                                            name: 'All',
                                            value: 'all'
                                        },
                                        {
                                            name: 'Waiting',
                                            value: 'submitted'
                                        },
                                        {
                                            name: 'Completed',
                                            value: 'completed'
                                        },
                                        {
                                            name: 'Processing',
                                            value: 'processing'
                                        },
                                        {
                                            name: 'Failed',
                                            value: 'failed'
                                        }
                                    ]}
                                    getOptionLabel={(option) => option.name}
                                    placeholder='Filter by status'
                                />
                            </div>
                            <input
                                type="text"
                                placeholder="Search by name"
                                className={`input input-bordered input-md w-56 bg-transparent focus:outline-none h-[38px] rounded ${darkMode ? "border-gray-700" : "border-gray-300"}`}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            />
                        </div>
                    </div>
                    {
                        isLoading && <div className='w-full flex justify-center items-center min-h-[35rem]'>
                            <Loader smail />
                        </div>
                    }
                    {
                        isError && <div className='w-full flex justify-center items-center min-h-[35rem]'>
                            <h1 className='text-xl font-medium opacity-90'>
                                Error while fetching jobs
                            </h1>
                        </div>
                    }
                    {
                        isSuccess && <div className='w-full min-h-[35rem]'>
                            <div className="overflow-x-auto w-full">
                                <table className="table w-full">
                                    {/* head */}
                                    <thead>
                                        <tr>
                                            <th className='bg-transparent'>Name</th>
                                            <th className='bg-transparent'>
                                                Status
                                            </th>
                                            <th className='bg-transparent'>
                                                Progress
                                            </th>
                                            <th className='bg-transparent'>
                                                View
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            JobsData?.pages.map(page => page.data).flat().map((job) => {
                                                return (
                                                    <tr key={job._id}>
                                                        <td className={`bg-transparent border-t ${darkMode ? "border-t-gray-700 border-b-gray-700" : "border-t-gray-300 border-b-gray-300"}`}>
                                                            <div className="font-bold capitalize">
                                                                {
                                                                    job.name.length > 20 ? job.name.slice(0, 20) + '...' : job.name
                                                                }
                                                            </div>
                                                            <div>{checkPriority(job.priority)}</div>
                                                        </td>
                                                        <td className={`bg-transparent border-t ${darkMode ? "border-t-gray-700 border-b-gray-700" : "border-t-gray-300 border-b-gray-300"}`}>
                                                            {checkStatus(job.status)}
                                                        </td>
                                                        <td className={`bg-transparent border-t ${darkMode ? "border-t-gray-700 border-b-gray-700" : "border-t-gray-300 border-b-gray-300"}`}>
                                                            <div className="flex items-center">

                                                                <div className="relative w-full">
                                                                    <div className={`overflow-hidden h-2 text-xs flex rounded ${checkProgressbg(job.progress, job.status)}`}>
                                                                        <div
                                                                            style={{ width: `${job.progress}%` }}
                                                                            className={`shadow-none flex flex-col text-center whitespace-nowrap duration-1000 justify-center ${checkProgress(job.progress, job.status)}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <span className="ml-3 text-sm w-20">{job.progress}%</span>
                                                            </div>
                                                        </td>
                                                        <th className={`bg-transparent border-t ${darkMode ? "border-t-gray-700 border-b-gray-700" : "border-t-gray-300 border-b-gray-300"}`}>
                                                            <Link to={RouteStrings.Transcoding.Children.ViewJob.dynamicLink + job._id}>
                                                                <BsFillEmojiHeartEyesFill className={`${darkMode ? "text-white" : "text-blue-500"} text-2xl cursor-pointer hover:scale-125 duration-300`} />
                                                            </Link>
                                                        </th>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                </div>
                <div className="flex gap-8 items-center justify-center my-10">
                    <div className="flex gap-3.5">
                        <p>Total</p>
                        <p>{counter(JobsData?.pages[0].meta.pagination.total || 0)}</p>
                    </div>
                    <div className="flex gap-3.5">
                        <p>Submitted</p>
                        <p>{counter(JobsData?.pages[0].meta.pagination.submitted_jobs || 0)}</p>
                    </div>
                    <div className="flex gap-3.5">
                        <p>Processing</p>
                        <p>{counter(JobsData?.pages[0].meta.pagination.processing_jobs || 0)}</p>
                    </div>
                    <div className="flex gap-3.5">
                        <p>Completed</p>
                        <p>{counter(JobsData?.pages[0].meta.pagination.completed_jobs || 0)}</p>
                    </div>
                    <div className="flex gap-3.5">
                        <p>Failed</p>
                        <p>{counter(JobsData?.pages[0].meta.pagination.failed_jobs || 0)}</p>
                    </div>
                </div>
                <div className='mx-36 2xl:mx-0 mt-5'>
                    <div className='w-full flex justify-center'>
                        <div className="btn-group grid grid-cols-1 w-72">
                            <button onClick={nextPage} className={`btn btn-outline ${darkMode ? "text-white" : "text-blue-400"}`}>
                                Load More
                            </button>
                        </div>
                    </div>
                </div>
            </> : <>
                <div className='w-full flex justify-center items-center min-h-[50rem]'>
                    <h1 className='text-xl font-medium opacity-90'>
                        No transcoder found
                    </h1>
                </div>
            </>}

        </div>
    )
}

export default TranscoderJobsPage

