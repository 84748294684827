import React from 'react'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { sectionDelete } from '../../../http/Apis';
import { RootState } from '../../../redux/store';
import { Loader } from '../../../components/smallComponents/loader';
import { useQueryClient } from '@tanstack/react-query'
import { deleteSectionApi } from '../../../http/sections.http';

const SectionDeleteModal = () => {
    const { selectedSection } = useSelector((state: RootState) => state.section);
    const [loading, setLoading] = React.useState<boolean>(false);
    const queryClient = useQueryClient();
    // handle Delete
    const handleDelete = async () => {
        try {
            const response = await deleteSectionApi(selectedSection as any);
            if (response.status === 200) {
                // now refecth the data
                queryClient.invalidateQueries(["new_sections"]);
                setLoading(false);
                toast.success("Section deleted successfully");
                // click sectionStatusModal id to close modal
                document.getElementById("sectionDeleteModal")?.click();
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    };
    return (
        <div>
            <input type="checkbox" id="sectionDeleteModal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box text-black">
                    <h3 className="font-bold text-lg">
                        Section Delete
                    </h3>
                    <p className="py-4">
                        Are you sure? you want to delete this section?
                    </p>
                    <div className="modal-action">
                        <label onClick={handleDelete} className="btn">
                            {loading ? <Loader smail /> : "Yes"}
                        </label>
                        <label htmlFor="sectionDeleteModal" className="btn">
                            Cancel
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionDeleteModal