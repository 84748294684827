import { ISingleFile } from './ContextMenu'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactPlayer from "react-player";
import { formatBytes } from '../helpers/helper';
import { useQuery } from '@tanstack/react-query';
import { getAwsConfigSettingsApi } from '../../../http/settingshttp';
import { Loader } from '../../../components/smallComponents/loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

interface IDialogBox {
    createFolderOpen: boolean,
    deleteOpen: boolean,
    syncOpen: boolean,
    detailsOpen: boolean,
    dialogType?: string,
    setDialogType?: any,
    handleDetailsClose: () => void,
    handleSyncCloseDialog: () => void,
    handleCreateFolderClose: () => void,
    handleDeleteCloseDialog: () => void,
    singleFile: ISingleFile,
    folderName: string,
    setFolderName: any,
    isRename: boolean,
    loading: boolean,
    renameFile: (singleFile: ISingleFile, folderName: string) => void,
    createFolder: (isroot: boolean) => void,
    deleteFile: (singleFile: ISingleFile) => void,
    syncFolder: () => void,
    checkFileExtension: (singleFile: ISingleFile, ext: string[]) => boolean
    selectedImage: any,
    bucketName: string | undefined,
    copied: boolean,
    setCopied: any
}


function DialogBox({
    createFolderOpen,
    deleteOpen,
    syncOpen,
    detailsOpen,
    handleDetailsClose,
    handleSyncCloseDialog,
    handleCreateFolderClose,
    handleDeleteCloseDialog,
    singleFile,
    folderName,
    setFolderName,
    isRename,
    loading,
    renameFile,
    createFolder,
    deleteFile,
    syncFolder,
    checkFileExtension,
    selectedImage,
    bucketName,
    copied,
    setCopied

}: IDialogBox) {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const getAwsConfigQuery = useQuery<IGetAwsConfigResponse>({
        queryKey: ['getAwsConfig'],
        queryFn: () => getAwsConfigSettingsApi(),
    })

    const decodeBase64Data = (data: string | any): IGetAwsConfigResponse['data'] => {
        const decodedData: IGetAwsConfigResponse['data'] = JSON.parse(atob(data))
        return decodedData
    }
    if (createFolderOpen) {
        return <Dialog open={createFolderOpen} onClose={handleCreateFolderClose}>
            <DialogTitle>Create Folder</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To create a folder, please enter the folder name here.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Folder Name"
                    type="text"
                    fullWidth
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCreateFolderClose}>Cancel</Button>
                <LoadingButton
                    onClick={() => {
                        isRename
                            ? renameFile(singleFile, folderName)
                            : createFolder(false);
                    }}
                    loading={loading}>
                    {isRename ? "Update" : "Create"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    } else if (deleteOpen) {
        return <Dialog
            open={deleteOpen}
            onClose={handleDeleteCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {`Do you really want to delete this?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <p className="text-red-600">
                        WARNING: You are about to delete all files and subdirectories
                        in this directory recursively. This operation is irreversible
                        and all data will be lost. Are you sure you want to proceed?
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteCloseDialog}>Cancel</Button>
                <LoadingButton
                    onClick={() => deleteFile(singleFile)}
                    loading={loading}>
                    Agree
                </LoadingButton>
            </DialogActions>
        </Dialog>
    } else if (syncOpen) {
        return <Dialog
            open={syncOpen}
            onClose={handleSyncCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {`Do you really want to sync with filemanger.`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <p className="text-red-600">
                        Warning: Syncing this folder with the database may take time
                        any type of interruption cause data loss or corruption. Please
                        ensure that you have a backup of both the file and the folder
                        before proceeding.
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSyncCloseDialog}>Cancel</Button>
                <LoadingButton onClick={() => syncFolder()} loading={loading}>
                    Agree
                </LoadingButton>
            </DialogActions>
        </Dialog>
    } else {
        return <Dialog
            open={detailsOpen}
            onClose={handleDetailsClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">
                <div className="flex justify-between items-center">
                    <h1 className="font-medium text-2xl">File Details</h1>
                </div>
            </DialogTitle>
            <DialogContent>
                {singleFile && (
                    <div>
                        {getAwsConfigQuery.isLoading && <div className='flex items-center justify-center w-96 h-96'>
                            <Loader smail />
                        </div>}
                        {getAwsConfigQuery.isError && <div className='flex flex-col items-center justify-center w-96 h-96'>
                            <h1 className='text-xl font-medium opacity-90'>
                                Server Error. Please try again
                            </h1>
                            <div
                                onClick={() => getAwsConfigQuery.refetch()}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                <h1 className='text-sm text-white'>
                                    Retry
                                </h1>
                            </div>
                        </div>}
                        {getAwsConfigQuery.isSuccess && (
                            <div className="mb-2">
                                {checkFileExtension(singleFile, ["mp4", "m3u8", "mov"]) && (
                                    <>
                                        {
                                            <ReactPlayer
                                                className="w-full"
                                                width={"100%"}
                                                url={selectedImage || ""}
                                                controls
                                            />
                                        }
                                    </>
                                )}
                                {checkFileExtension(singleFile, ["jpg", "jpeg", "png", "gif", "webp"]) && (
                                    <><img className="w-full" src={selectedImage || ""} /></>
                                )}

                                <p className="my-2">
                                    {`https://${decodeBase64Data(getAwsConfigQuery.data?.data).s3.bucket}.s3.${decodeBase64Data(getAwsConfigQuery.data?.data).s3.region}.amazonaws.com/${singleFile?.key.split("/").splice(1).join("/") || ""}`}{" "}
                                    <span className="uppercase cursor-pointer text-blue-400">
                                        <CopyToClipboard
                                            text={`https://${decodeBase64Data(getAwsConfigQuery.data?.data).s3.bucket}.s3.${decodeBase64Data(getAwsConfigQuery.data?.data).s3.region}.amazonaws.com/${singleFile?.key.split("/").splice(1).join("/") || ""}`}>
                                            <button onClick={() => setCopied(true)}>
                                                {copied ? "Copied" : "Copy"}
                                            </button>
                                        </CopyToClipboard>
                                    </span>
                                </p>

                                <p>{singleFile.type === "file" && formatBytes(singleFile.size)}</p>

                                <p className="mt-3">CloudFront URL</p>

                                <p className="my-2">
                                    {`${decodeBase64Data(getAwsConfigQuery.data?.data).cloudfront.url}${singleFile?.key || ""}`}{" "}
                                    <span className="uppercase cursor-pointer text-blue-400">
                                        <CopyToClipboard
                                            text={`${decodeBase64Data(getAwsConfigQuery.data?.data).cloudfront.url}${singleFile?.key || ""}`}>
                                            <button onClick={() => setCopied(true)}>
                                                {copied ? "Copied" : "Copy"}
                                            </button>
                                        </CopyToClipboard>
                                    </span>
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDetailsClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    }

}

export default DialogBox