import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
interface IData {
  name: string;
  key: string;
}

interface IBreadcrumbsProps {
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  breadArr: IData[];
  setCurrDir: any;
  setBreadArr: any;
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

function BreadCrumb({ breadArr, setCurrDir, setBreadArr }: IBreadcrumbsProps) {
  const handelFolderNavigate = (key: string) => {
    let idx = -1;
    idx = breadArr.findIndex((item: IData) => {
      return item.key === key;
    });

    if (idx > -1) {
      breadArr.splice(idx + 1);
      setBreadArr([...breadArr]);
    }
    console.log(idx);
    setCurrDir(key);
  };

  return (
    <div role="presentation">
      <Breadcrumbs maxItems={6} aria-label="breadcrumb">
        {breadArr.length > 0 &&
          breadArr.map((item, index) => {
            return (
              <StyledBreadcrumb
                key={index}
                component="a"
                href="#"
                label={item.name}
                onClick={() => handelFolderNavigate(item.key)}
              />
            );
          })}
      </Breadcrumbs>
    </div>
  );
}

export default BreadCrumb;
