import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export interface LogoProps {
  url?: string | null;
  className?: string;
}
const Logo = ({ url = null, className}: LogoProps) => {
  const { config } = useSelector((state: RootState) => state.config);
  return (
    <div className={`${className} w-36 h-12 overflow-hidden`}>
      <img
        src={url || config.logo}
        className="object-contain h-full w-full"
      />
    </div>
  );
};

export default Logo;
