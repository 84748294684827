import { BasicTabs, FullScreenDialog as ComponentFullScreenDialog, Hr } from "../../../components";
import IconButton from "@mui/material/IconButton";
import toast from 'react-hot-toast';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store"
import ReactJson from "react-json-view";
import Button from "@mui/material/Button";
import { List, ListItem } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

interface ISingleResolution {
    id: number,
    name: string,
    value: string,
    selected: boolean,
    disabled: boolean,
}

interface IFullScreen {
    fullScreenDialog: boolean,
    handleFullScreenDialogClose: () => void,
    transcodingProvider: string | null,
    setTranscodingProvider: any,
    Resolution: ISingleResolution[],
    setResolution: any,
    submitData: any,
    credentials: any,
    loading: boolean,
    inputUrl: string,
    setInputUrl: any,
    transcodingFileName: string,
    setTranscodingFileName: any,
    onSubmitTranscoding: (
        hostname: string,
        username: string,
        password: string
    ) => void

}

function FullScreenDialog({
    fullScreenDialog,
    handleFullScreenDialogClose, transcodingProvider, setTranscodingProvider,
    Resolution, setResolution, submitData, credentials, loading, onSubmitTranscoding,
    inputUrl, setInputUrl, transcodingFileName, setTranscodingFileName
}: IFullScreen) {
    const { darkMode } = useSelector((state: RootState) => state.theme);
    return (
        <>
            <ComponentFullScreenDialog
                title="Video Transcoding"
                open={fullScreenDialog}
                onClose={handleFullScreenDialogClose}>
                <div>
                    {transcodingProvider ? (
                        <div className="px-7 my-5">
                            <div className="flex items-center">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => setTranscodingProvider(null)}
                                    aria-label="close">
                                    <ArrowBackIcon />
                                </IconButton>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{
                                        flexGrow: 1,
                                        textTransform: "capitalize",
                                        marginLeft: 1,
                                    }}>
                                    Zezo Transcoding Provider
                                </Typography>
                            </div>
                            <div className="my-20">
                                <div className="flex flex-col justify-center items-center">
                                    <h1 className="text-2xl mb-10">Zezo Cloud Transcoding</h1>
                                    <div className="w-11/12 2xl:w-2/4">
                                        <BasicTabs
                                            tabs={[
                                                {
                                                    label: "url",
                                                },
                                                {
                                                    label: "upload",
                                                },
                                            ]}
                                            tabPanels={[
                                                {
                                                    children: (
                                                        <div className="pt-2">
                                                            <input
                                                                name={"url"}
                                                                placeholder="Enter video url"
                                                                value={inputUrl}
                                                                onChange={(e: any) => setInputUrl(e.target.value)}
                                                                className="w-full border bg-transparent rounded-md p-2 mt-2"
                                                            />
                                                            <input
                                                                name={"name"}
                                                                placeholder="Enter video name"
                                                                value={transcodingFileName}
                                                                onChange={(e: any) => setTranscodingFileName(e.target.value)}
                                                                className="w-full border bg-transparent rounded-md p-2 mt-3"
                                                            />
                                                            <div className="grid grid-cols-3 my-10">
                                                                <div className="col-span-1">
                                                                    <div className="grid grid-cols-2 gap-5">
                                                                        <div>
                                                                            <h1 className="opacity-80 mb-1">
                                                                                Format
                                                                            </h1>
                                                                            <select className="select font-normal w-full max-w-xs bg-transparent border border-gray-600 rounded">
                                                                                <option selected>HLS</option>
                                                                            </select>
                                                                            <h1 className="opacity-80 mb-1 mt-4">
                                                                                Codec
                                                                            </h1>
                                                                            <select className="select font-normal w-full max-w-xs bg-transparent border border-gray-600 rounded">
                                                                                <option selected>H.264</option>
                                                                            </select>
                                                                        </div>
                                                                        <div>
                                                                            <h1 className="opacity-80 mb-1">
                                                                                Resolution
                                                                            </h1>
                                                                            <div className={`${darkMode ? "bg-dark-color-box" : ""} rounded w-fit`}>
                                                                                {Resolution &&
                                                                                    Resolution.map(
                                                                                        (singleResolution, index) => {
                                                                                            return (
                                                                                                <div key={index} className="">
                                                                                                    <div className="flex items-center py-1 px-3">
                                                                                                        <div className="form-control">
                                                                                                            <label className="cursor-pointer label">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    checked={
                                                                                                                        singleResolution.selected
                                                                                                                    }
                                                                                                                    disabled={
                                                                                                                        singleResolution.disabled
                                                                                                                    }
                                                                                                                    onChange={(e) => {
                                                                                                                        const newResolution =
                                                                                                                            [...Resolution];
                                                                                                                        newResolution[
                                                                                                                            index
                                                                                                                        ].selected =
                                                                                                                            e.target.checked;
                                                                                                                        setResolution(
                                                                                                                            newResolution
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    className="checkbox checkbox-secondary"
                                                                                                                />
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <span className="ml-2">
                                                                                                            {singleResolution.name}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <Hr />
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    ).reverse()}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-span-2">
                                                                    <h1 className="opacity-80 mb-1">Your JSON</h1>
                                                                    <div className={`p-5 ${darkMode ? "bg-dark-color-box" : "bg-dark-color-box"} overflow-auto`}>
                                                                        <ReactJson
                                                                            src={submitData}
                                                                            theme="monokai"
                                                                            style={{ backgroundColor: `${darkMode ? "#282828" : "#282829"}`}}
                                                                            collapsed={false}
                                                                            enableClipboard={false}
                                                                            displayObjectSize={false}
                                                                            displayDataTypes={false}
                                                                        />
                                                                    </div>
                                                                    <div className="my-4">
                                                                        <Button
                                                                            variant="contained"
                                                                            className="w-full"
                                                                            disabled={loading}
                                                                            onClick={() => {
                                                                                if (credentials?.transcoder) {
                                                                                    const {
                                                                                        hostname,
                                                                                        username,
                                                                                        password,
                                                                                    } = credentials?.transcoder[0];
                                                                                    setTranscodingProvider("zezo");
                                                                                    onSubmitTranscoding(
                                                                                        hostname,
                                                                                        username,
                                                                                        password
                                                                                    );
                                                                                } else {
                                                                                    toast.error(
                                                                                        "Please add your Zezo credentials first."
                                                                                    );
                                                                                }
                                                                            }}>
                                                                            {loading
                                                                                ? "Loading..."
                                                                                : "Start transcoding"}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    children: (
                                                        <div className="flex justify-center items-center mt-10">
                                                            Upload file service is not available yet.
                                                        </div>
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <List>
                            <ListItem
                                button
                                onClick={() => {
                                    const timeOut = setTimeout(() => {
                                        setTranscodingProvider("aws");
                                    }, 200);
                                    return () => {
                                        clearTimeout(timeOut);
                                    };
                                }}>
                                <ListItemText
                                    primary="Zezo Cloud Transcoder"
                                    secondary="Transcode your video to a different format. This is a paid service."
                                />
                            </ListItem>
                        </List>
                    )}
                </div>
            </ComponentFullScreenDialog>
        </>
    )
}

export default FullScreenDialog