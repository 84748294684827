import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useSelector } from "react-redux";
import { getMarketingFirebaseSettingsApi, updateMarketingFirebaseSettingsApi } from "../../http/Apis";
import toast from "react-hot-toast";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";
import CodeEditor from '@uiw/react-textarea-code-editor';


const MarketingSettings = () => {
    const { user } = useSelector((state: RootState) => state.auth);
    const { config } = useSelector((state: RootState) => state.config);
    const { credentials } = config;
    const [loading, setLoading] = useState(false);

    const [code, setCode] = useState<string>();


    const getMarketingFirebaseSettings = async () => {
        try {
            const { data } = await getMarketingFirebaseSettingsApi();
            setCode(data.data);
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    useEffect(() => {
        getMarketingFirebaseSettings();
        return () => {
            setCode("");
        }
    }, []);

    // update firebase settings
    const updateFirebaseSettings = async () => {
        setLoading(true);
        try {
            await updateMarketingFirebaseSettingsApi({ data: code });
            toast.success("Firebase settings updated successfully");
            setLoading(false);
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || "Something went wrong");
            setLoading(false);
        }
    }




    return (
        <Layout>
            <div className="pr-5">
                <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                    Marketing Settings
                </h1>
                <p className="mt-2 opacity-70">
                    Manage your marketing settings
                </p>
            </div>
            <div className="my-5">
                <Hr />
            </div>
            <h6 className="opacity-70 pr-5">
                Firebase Credentials
            </h6>
            <div className="my-5 pr-36 flex flex-col gap-5">
                <CodeEditor
                    value={code}
                    language="js"
                    placeholder="Please enter your firebase credentials."
                    onChange={(evn) => setCode(evn.target.value)}
                    padding={15}
                    style={{
                        fontSize: 18,
                        backgroundColor: "#282828",
                        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    }}
                />
            </div>

            <div className="my-14 pr-16 flex flex-col gap-8">
                <div className="flex justify-end">
                    <div className="w-44">
                        <LoadingButton
                            loading={loading}
                            loadingPosition="end"
                            variant="outlined"
                            color="primary"
                            onClick={updateFirebaseSettings}

                        >
                            Save
                        </LoadingButton>
                        {/* <Button
                            title={"Send Notification"}
                            onClick={sentPushNotifiction}
                            loading={loading}
                        /> */}
                    </div>

                </div>
            </div>
        </Layout>

    );
}

export default MarketingSettings;
