// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import { useEffect, useState } from 'react'
import { InputField } from '../../../tailwind'
import { Button } from '@mui/material'
import UserActions from './userActions';
import { updateUser } from '../../../http';
import { toast } from 'react-hot-toast';
import { UseQueryResult } from '@tanstack/react-query';

interface IProps {
    id: string;
    user: IUsersResponse['data'][0]
    userQuery: UseQueryResult
}

function UserInfo({ id, user }: IProps) {
    const [updateUserLoading, setUpdateUserLoading] = useState(false);
    const [userData, setUser] = useState<IUsersResponse['data'][0]>({} as IUsersResponse['data'][0]);

    useEffect(() => {
        setUser(user);
    }, [user]);

    const onSubmit = async () => {
        const data = new FormData();
        data.append("name", userData.name || "");
        if (userData.phone) data.append("phone", user?.phone as any || "");
        data.append("isActivated", userData.isActivated ? "true" : "false");
        data.append("trash", userData.trash ? "true" : "false");
        try {
            setUpdateUserLoading(true);
            const res = await updateUser(id, data);
            if (res.status === 200) {
                setUpdateUserLoading(false);
                toast.success("User updated successfully");
            }
        } catch (error: any) {
            setUpdateUserLoading(false);
            toast.error(error.response?.data?.error?.message || "User update failed");
        }
    };

    return (
        <>
            <div className="grid grid-cols-2 gap-x-5 gap-y-3 mt-5">
                <InputField
                    fieldName="Name"
                    type="text"
                    placeholder="Name"
                    value={userData.name || ""}
                    onChange={(e) => setUser({ ...userData, name: e.target.value })}
                />
                <InputField
                    fieldName="phone"
                    type="text"
                    placeholder="Phone"
                    value={userData.phone || ""}
                    onChange={(e) => setUser({ ...userData, phone: e.target.value as any })}
                />
                <InputField
                    fieldName="role"
                    type="text"
                    placeholder="Role"
                    value={user.role || ""}
                    readOnly
                    onChange={(e) => setUser({ ...user, role: e.target.value as any })}
                />
                <InputField
                    fieldName="email"
                    type="text"
                    placeholder="Email"
                    value={userData.email || ""}
                    onChange={(e) => setUser({ ...userData, email: e.target.value })}
                />
            </div>
            <UserActions user={userData as any} setUser={setUser} id={id as any} />
            <div className="w-full my-5">
                <div className="flex gap-4 justify-end">
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="w-40 h-10"
                        disabled={updateUserLoading}
                        onClick={() => onSubmit()}>
                        Save
                    </Button>
                </div>
            </div>
        </>
    )
}

export default UserInfo
