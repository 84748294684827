import DashboardIcon from "@mui/icons-material/Dashboard";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import SubjectIcon from "@mui/icons-material/Subject";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MovieFilterOutlinedIcon from "@mui/icons-material/MovieFilterOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import { MarketingIcon, RTMPServerIcon, TranscoderIcon } from "../../Icon";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";

export const SideBarData = [
  {
    name: "Dashboard",
    path: "/admin/dashboard",
    icon: <DashboardIcon />,
  },
  {
    name: "Contant",
    path: "/admin/contant",
    icon: <VideoLibraryIcon />,
  },
  {
    name: "Sections",
    path: "/admin/sections",
    icon: <SubjectIcon />,
  },
  {
    name: "Files Manager",
    path: "/admin/file-manager",
    icon: <FolderOutlinedIcon />,
  },
  {
    name: "Users",
    path: "/admin/users",
    icon: <PeopleOutlinedIcon />,
  },
  {
    name: "Genres",
    path: "/admin/genres",
    icon: <MovieFilterOutlinedIcon />,
  },
  {
    name: "Categories",
    path: "/admin/categories",
    icon: <CategoryOutlinedIcon />,
  },
  {
    name: "Languages",
    path: "/admin/languages",
    icon: <TranslateOutlinedIcon />,
  },
  {
    name: "Cast & Crew",
    path: "/admin/cast",
    icon: <SupervisorAccountOutlinedIcon />,
  },
  {
    name: "Subscriptions",
    path: "/admin/subscriptions",
    icon: <SubscriptionsOutlinedIcon />,
  },
  {
    name: "Transcoding",
    path: "",
    icon: <TranscoderIcon className={`w-6 h-6`} />,
    children: [
      {
        name: "Dashboard",
        path: "/admin/transcoding/dashboard",
        icon: <DashboardIcon />,
      },
    ],
  },
  {
    name: "RTMP Server",
    path: "",
    icon: <RTMPServerIcon className={`w-6 h-6`} />,
    children: [
      {
        name: "Dashboard",
        path: "/admin/rtmp/dashboard",
        icon: <DashboardIcon />,
      },
      {
        name: "Streams",
        path: "/admin/rtmp/streams",
        icon: <DashboardIcon />,
      },
      {
        name: "Server Profile",
        path: "/admin/rtmp/server-profile",
        icon: <DashboardIcon />,
      },
    ],
  },
  {
    name: "Marketing",
    path: "",
    icon: <MarketingIcon className="w-6 h-6" />,
    children: [
      {
        name: "Push Notifications",
        path: "/admin/marketing/push-notification",
        icon: <NotificationsActiveOutlinedIcon />,
      },
    ],
  },
  {
    name: "Analytics",
    path: "/admin/analytics",
    icon: <SignalCellularAltOutlinedIcon />,
  },
  {
    name: "Settings",
    path: "",
    icon: <SettingsOutlinedIcon />,
    children: [
      {
        name: "Site Settings",
        path: "/admin/settings/site-settings",
        icon: <SettingsOutlinedIcon />,
      },
      {
        name: "Seo Settings",
        path: "/admin/settings/seo-settings",
        icon: <SettingsOutlinedIcon />,
      },
    ],
  },
  {
    name: "Reports",
    path: "/admin/reports",
    icon: <ReportOutlinedIcon />,
  },
];
