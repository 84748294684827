import { useDispatch } from 'react-redux';
import { setSelectedSection } from '../../../redux/slice​/sectionSlice';
import { AiFillDelete } from 'react-icons/ai'


interface ISectionsDeleteProps {
    data: IGetSectionData
}

function SectionsDelete({ data }: ISectionsDeleteProps): JSX.Element {
    const dispatch = useDispatch();
    return <label
        htmlFor="sectionDeleteModal"
        onClick={() => {
            dispatch(setSelectedSection([data._id]))
        }}>
        <AiFillDelete className='text-blue-500 text-2xl cursor-pointer' />
    </label>
}

export default SectionsDelete