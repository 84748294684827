import React from 'react'
import { LoadingButton } from "@mui/lab";
import { FolderIcon } from "../../../tailwind/Icon";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ISingleBreadItem } from './ContextMenu';
import BreadCrumb from './BreadCrumb';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { UseQueryResult } from '@tanstack/react-query';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});

interface IHeader {
    files: any,
    loading: boolean,
    isRootDir: boolean,
    setSearch: any,
    setSyncType: any,
    handleSyncOpenDialog: () => void,
    handleClickCreateFolderOpen: () => void,
    handleClickOpen: () => void,
    handleClick: (e: React.MouseEvent<HTMLElement>, item: any) => void,
    breadArr: ISingleBreadItem[],
    setCurrDir: any,
    setBreadArr: any,
    displayMenu: any,
    getFileIcon: (name: { name: string }, bucketName: string | undefined) => React.ReactNode
    bucketName: string | undefined
    fetchFilesDataQuery: UseQueryResult

}
function Header({ files, loading, isRootDir, setSearch, setSyncType, handleSyncOpenDialog, handleClickCreateFolderOpen,
    handleClick,
    handleClickOpen,
    breadArr,
    setCurrDir,
    setBreadArr,
    displayMenu,
    getFileIcon,
    bucketName,
    fetchFilesDataQuery
}: IHeader) {
    console.log("files", files)
    const { darkMode } = useSelector((state: RootState) => state.theme);
    return (
        <div>
            <div className="flex justify-between items-center pr-5">
                <h1 className={`font-medium w-[30rem] border px-4 rounded ${darkMode ? "border-gray-700" : "border-gray-300"} py-2`}>
                    <input
                        type="text"
                        placeholder="Search files and folders"
                        className="w-full outline-none bg-transparent"
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                    />
                </h1>
                <div className="flex gap-2.5">
                    <LoadingButton
                        onClick={() => {
                            setSyncType("global");
                            handleSyncOpenDialog();
                        }}
                        loading={loading}
                        variant="outlined">
                        Sync Global
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => {
                            setSyncType("folder");
                            handleSyncOpenDialog();
                        }}
                        loading={loading}
                        variant="outlined">
                        Sync
                    </LoadingButton>
                    <LoadingButton
                        onClick={handleClickCreateFolderOpen}
                        loading={loading}
                        variant="outlined">
                        Create Folder
                    </LoadingButton>
                    <LoadingButton
                        onClick={handleClickOpen}
                        loading={loading}
                        variant="outlined">
                        Upload
                    </LoadingButton>
                </div>
            </div>
            <div className="mt-5">
                <BreadCrumb
                    breadArr={breadArr}
                    setCurrDir={setCurrDir}
                    setBreadArr={setBreadArr}
                />
            </div>

            {
                fetchFilesDataQuery.isLoading && <div className="pr-5 my-5 w-full">
                    <div className="grid grid-cols-12 gap-5 justify-center items-center">
                        {
                            new Array(89).fill("").map((item: any, index: number) => (
                                <CustomWidthTooltip placement="top" title={item.name}>
                                    <div
                                        key={index}
                                        className={`flex flex-col justify-center items-center w-full`}>
                                        <div className={`w-16 h-14 ${darkMode ? "bg-dark-color-box" : "bg-[#DEDEDE]"} rounded animate-pulse`} />
                                        <div className={`w-16 h-2 ${darkMode ? "bg-dark-color-box" : "bg-[#DEDEDE]"} rounded mt-2`} />
                                    </div>
                                </CustomWidthTooltip>
                            ))}
                    </div>
                </div>
            }
            {
                fetchFilesDataQuery.isError && <div className="flex justify-center items-center h-full">
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-gray-400 font-medium text-lg mt-72">
                            No data Found in the folder
                        </p>
                    </div>
                </div>
            }
            {
                fetchFilesDataQuery.isFetched && files?.length > 0 && <div className="pr-5 my-5 w-full">
                    <div className="grid grid-cols-12 gap-5 justify-center items-center">
                        {files?.map((item: any, index: number) => (
                            <CustomWidthTooltip placement="top" title={item.name}>
                                <div
                                    onContextMenu={(e) => {
                                        displayMenu(e, item);
                                    }}
                                    onDoubleClickCapture={(e) => handleClick(e, item)}
                                    key={index}
                                    className={`flex flex-col justify-center items-center w-full ${item.type === "folder" ? "cursor-pointer" : ""}`}>
                                    <div>
                                        {item.type === "folder" ? (
                                            <FolderIcon className="w-16" />
                                        ) : (
                                            getFileIcon({ name: item.key }, bucketName)
                                        )}
                                    </div>
                                    <div className="text-gray-400 font-normal text-xs mt-2 line-clamp-1 w-20 text-center">
                                        {item.name}
                                    </div>
                                </div>
                            </CustomWidthTooltip>
                        ))}
                    </div>
                </div> 
            }
        </div>
    )
}

export default Header