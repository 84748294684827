// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getRevenue } from "../../../../../http/Apis";
import { TailwindBox } from "../../../../../tailwind";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import QueryString from "qs";
import { useQueries } from "@tanstack/react-query";
import { BiRefresh } from "react-icons/bi";
import { Loader } from "../../../../../components/smallComponents/loader";
import { counter } from "../../../../../utils/Utils";

interface IRevenueAnalyticsQuery {
  timePeriod?: "daily" | "weekly" | "monthly" | "yearly" | "all_time";
  startDate?: string;
  endDate?: string;
  revenue_type?: "tvod" | "svod" | "other";
  tvodId?: string;
  tvodType?: "rent" | "buy" | "other";
  status?: "created" | "paid" | "failed" | "attempted";
}

interface IRevenueAnalyticsResponse {
  razorpay: number;
  UPI: number;
  cashfree: number;
  subpaisa: number;
  payumoney: number;
  stripe: number;
  ccavenue: number;
}

const RevenueAnalytics = () => {
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const [timePeriod, setTimePeriod] = useState<IRevenueAnalyticsQuery['timePeriod']>("daily");
  const [svod, setSvod] = useState<number>(0);
  const [tvod, setTvod] = useState<number>(0);
  const [series, setSeries] = useState([0, 0]);
  const [options, setOptions] = useState({
    chart: {
      width: "100%",
      type: "pie",
      foreColor: "#008FFB",
    },
    labels: ["SVOD", "TVOD"],
    colors: ["#008FFB", "#f44336"],
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  let totalRevenuequery: IRevenueAnalyticsQuery = { timePeriod: timePeriod, status: "paid" };

  const getSvodAndTvodRevenueQuery = useQueries({
    queries: [
      {
        queryKey: ["getSvodRevenue", timePeriod],
        queryFn: () => getRevenue(QueryString.stringify({ ...totalRevenuequery, revenue_type: "svod" })),
        onSuccess: (data: any) => {
          const newData: IRevenueAnalyticsResponse = data.data.data;
          const svodRevenue = newData.razorpay + newData.UPI + newData.cashfree + newData.subpaisa + newData.payumoney + newData.stripe + newData.ccavenue;
          setSvod(svodRevenue);
          setSeries([svodRevenue, tvod]);
        }
      },
      {
        queryKey: ["getTvodRevenue", timePeriod],
        queryFn: () => getRevenue(QueryString.stringify({ ...totalRevenuequery, revenue_type: "tvod" })),
        onSuccess: (data: any) => {
          const newData: IRevenueAnalyticsResponse = data.data.data;
          const tvodRevenue = newData.razorpay + newData.UPI + newData.cashfree + newData.subpaisa + newData.payumoney + newData.stripe + newData.ccavenue;
          setTvod(tvodRevenue);
          setSeries([svod, tvodRevenue]);
        }
      }
    ]
  });

  const isLoading = getSvodAndTvodRevenueQuery.some((query) => query.isLoading);
  const isError = getSvodAndTvodRevenueQuery.some((query) => query.isError);
  const isSuccess = getSvodAndTvodRevenueQuery.some((query) => query.isSuccess);
  const isFetching = getSvodAndTvodRevenueQuery.some((query) => query.isFetching);

  useEffect(() => {
    getSvodAndTvodRevenueQuery.forEach((query) => query.refetch());
  }, [timePeriod]);

  return (
    <TailwindBox title="Revenue">
      {
        isLoading && <div>
          <div className={`w-full flex justify-items-start items-end h-10`}>
            <div className={`h-6 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-24 animate-pulse`} />
          </div>
          <div className="w-full">
            <div className="aspect-video w-full overflow-hidden rounded my-4 relative">
              <div className="flex justify-center items-center h-full">
                <Loader smail />
              </div>
            </div>
          </div>
          <div className={`stats shadow w-full ${darkMode ? "bg-gray-900" : "bg-white"} mt-5`}>
            <div className="stat place-items-center">
              <div className={`stat-title text-xs ${darkMode && "text-white"}`}>Total</div>
              <div className={`h-5 mt-1 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-day-box-color"} rounded-full w-8`} />
            </div>
            <div className="stat place-items-center">
              <div className={`stat-title text-xs ${darkMode && "text-white"}`}>SVOD</div>
              <div className={`h-5 mt-1 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-day-box-color"} rounded-full w-8`} />
            </div>
            <div className="stat place-items-center">
              <div className={`stat-title text-xs ${darkMode && "text-white"}`}>TVOD</div>
              <div className={`h-5 mt-1 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-day-box-color"} rounded-full w-8`} />
            </div>
          </div>
        </div>
      }
      {
        isError && <div>
          <div className="w-full h-full flex justify-center items-center mb-3">
            <div className='min-h-[15rem] justify-center flex flex-col items-center'>
              <h1 className='text-sm font-medium opacity-90'>
                Server Error. Please try again
              </h1>
              <div
                onClick={() => getSvodAndTvodRevenueQuery.forEach((query) => query.refetch())}
                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                <BiRefresh className={`text-black text-lg bg-white rounded-full ${isLoading || isFetching && "animate-spin"}`} />
                <h1 className='text-sm text-white'>
                  Refresh
                </h1>
              </div>
            </div>
          </div>
        </div>
      }
      {
        isSuccess && <div>
          <div className={`w-full flex justify-items-start items-end h-10`}>
            <select
              className={darkMode ? "p-1 w-28 text-sm text-white border border-gray-300 bg-gray-700 rounded-sm" : "p-1 w-28 text-sm text-gray-900 border border-gray-300 rounded-sm"}
              onChange={(e) => setTimePeriod(e.target.value as any)}>
              <option selected={timePeriod === "daily"} value="daily">Last 24 Hours</option>
              <option selected={timePeriod === "weekly"} value="weekly">Last 7 Days</option>
              <option selected={timePeriod === "monthly"} value="monthly">Last 30 Days</option>
              <option selected={timePeriod === "yearly"} value="yearly">Last 1 Year</option>
            </select>
          </div>
          <div className="w-full">
            {
              svod === 0 && tvod === 0 ? <div className="aspect-video w-full overflow-hidden rounded my-4 relative">
                <div className="flex justify-center items-center h-full">
                  <h1 className="text-sm font-medium opacity-90">
                    No data available
                  </h1>
                </div>
              </div> : <Chart series={series} options={options as any} type="pie" width={"100%"} />
            }
          </div>
          <div className={`stats shadow w-full ${darkMode ? "bg-gray-900" : "bg-white"} mt-5`}>
            <div className="stat place-items-center">
              <div className={`stat-title text-xs ${darkMode && "text-white"}`}>Total</div>
              <div className={`stat-value text-xl ${darkMode && "text-white"}`}>{counter(svod + tvod)}</div>
            </div>
            <div className="stat place-items-center">
              <div className={`stat-title text-xs ${darkMode && "text-white"}`}>SVOD</div>
              <div className="stat-value text-[#008FFB] text-xl">{counter(svod)}</div>
            </div>
            <div className="stat place-items-center">
              <div className={`stat-title text-xs ${darkMode && "text-white"}`}>TVOD</div>
              <div className={`stat-value text-[#f44336] text-xl ${darkMode && "text-white"}`}>{counter(tvod)}</div>
            </div>
          </div>
        </div>
      }
    </TailwindBox>
  );
};

export default RevenueAnalytics;
