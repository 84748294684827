// Author: Pukhraj Dhamu
// Created Date: Tuesday, July 11th 2023 at 11:00 AM
// Last Modified Date: Tuesday, July 11th 2023 at 11:00 AM
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import ReactMarkdown from 'react-markdown'
import { MdPreview } from 'react-icons/md'

interface IProps {
    value: string
    onChange: (value: string) => void
}

function CustomMarkDownEditor({ value, onChange }: IProps) {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const [preview, setPreview] = React.useState<boolean>(false)

    return (
        <div className={`border rounded ${darkMode ? 'border-[#343434]' : 'border-gray-200'} w-full`}>
            <div>
                {/* 
                toolbar
                */}
                <div className='flex justify-between items-center px-5 py-3'>
                    <div className='flex items-center'>
                        <MdPreview className={`text-xl cursor-pointer ${preview ? 'text-blue-500' : 'text-gray-500'}`} onClick={() => {
                            setPreview(!preview)
                        }} />
                    </div>
                </div>
                <textarea className={`bg-transparent w-full p-5 focus:outline-none min-h-[400px]`} value={value} onChange={(e) => onChange(e.target.value)}>
                    {value}
                </textarea>
            </div>
            {
                preview && <>
                    <hr />
                    <div className='px-5 py-3'>
                        <h1 className='text-xl font-bold'>Preview</h1>
                    </div>
                    <div className='w-full bg-white overflow-scroll flex justify-center items-center p-5'>
                        <ReactMarkdown children={value} className='prose prose-indigo w-full lg:prose-xl' />
                    </div>
                </>
            }
        </div>
    )
}

export default CustomMarkDownEditor