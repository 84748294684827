// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import { useSelector } from "react-redux";
import Layout from "../../components/Layout/Layout";
import UserAnalytics from "./components/Card/Users/Users";
import Subscriber from "./components/Card/Subscriber/Subscriber";
import RevenueAnalytics from "./components/Card/Revenue/Revenue";
import LatestMoviePerformance from "./components/Card/LatestMoviePerformance/LatestMoviePerformance";
import { RootState } from "../../redux/store";
import { ContentAnalytics, ContentReportAnalytics, NewUsersCard, RunningTranscodingJob } from "./components/Card";
import { DashboardAlart } from "./components";

const Dashboard = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <Layout>
      <div className="w-full">
        <div className="flex justify-between pr-5">
          <h1 className="text-[1.4rem] tracking-wider opacity-70">
            Welcome back, <span className="font-semibold">{user.name || "Admin"}</span>
          </h1>
        </div>
        <DashboardAlart />
        <div className="grid grid-cols-3 gap-5 pr-5 mt-5">
          <div className="flex flex-col gap-5">
            <LatestMoviePerformance />
            <UserAnalytics />
            <Subscriber />
          </div>
          <div className="flex flex-col gap-5">
            <ContentAnalytics />
            <ContentReportAnalytics />
            <RevenueAnalytics />
          </div>
          <div className="flex flex-col gap-5">
            <NewUsersCard />
            <RunningTranscodingJob />
          </div>
        </div>
        <div className="flex justify-end pr-3 my-3">
          <p className={`text-xs px-3`}>
            Version 1.0.0 |{" "}
            <a href="https://www.zezosoft.com/" target="_blank" rel="noreferrer">
              Powered by Zezo
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};
export default Dashboard;
