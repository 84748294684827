import axios, { AxiosResponse } from "axios";
import { publicIpv4 } from "public-ip";
const apis = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// list all endpoints
// user endpoints
export const whoamiEndpoint = () =>
  apis.get("/auth/whoami").then((res) => res.data);
// auth endpoints
export const removeSessionEndpoint = (data: IRemoveSessionEndpoint) =>
  apis.put("/auth/remove-session", data);
export const removeSessionEndpointPost = (data: IRemoveSessionEndpointPost) =>
  apis.post("/auth/remove-session", data);
export const logoutEndpoint = (data: ILogoutEndpoint) =>
  apis.put("/auth/logout", data);

// settings endpoints
export const getSettings = () => apis.get("/settings");
export const updateSettingApi = (id: string, data: any) =>
  apis.put(`/settings/${id}`, data);
export const getAppSettings = () => apis.get("/settings/app");
export const updateAppSettingApi = (id: string, data: any) =>
  apis.put(`/settings/app/${id}`, data);
export const updateWebSettingApi = (id: string, data: any) =>
  apis.put(`/settings/web/${id}`, data);
// social media endpoints
export const SocialMediaEndpoint = (id: string, queryString: string) =>
  apis.put(`/settings/social/${id}${queryString ? `?${queryString}` : ""}`);
// add social media endpoints
export const addSocialMediaEndpoint = (id: string, data: any) =>
  apis.put(`/settings/social/${id}`, data);

export const createSMSCredential = (data: any) =>
  apis.post("/settings/sms", data);
export const setSMSAction = (id: string) =>
  apis.put(`/settings/sms/action/${id}`);
export const getSMSCredential = () => apis.get("/settings/sms");

export const createWhatsAppCredential = (data: any) =>
  apis.post("/settings/whatsapp", data);
export const setWhatsAppAction = () => apis.put(`/settings/whatsapp/action`);
export const getWhatsAppCredential = () => apis.get("/settings/whatsapp");
// update social media endpoints
export const updateSocialMediaEndpoint = (
  id: string,
  queryString: string,
  data: any
) =>
  apis.put(
    `/settings/social/${id}${queryString ? `?${queryString}` : ""}`,
    data
  );
// update footer menu
export const updateFooterMenuEndpoint = (
  id: string,
  queryString: string,
  data: any
) =>
  apis.put(
    `/settings/footer/${id}${queryString ? `?${queryString}` : ""}`,
    data
  );
// users endpoints
export const getAllUsers = () => apis.get(`/users?isEmail=true`);
export const userActionsApi = (data: any) => apis.put("/users/action", data);
export const getUser = (id: string) => apis.get(`/users?id=${id}`);
export const subscriptionAssignApi = (
  type: "add" | "remove",
  data: ISubscriptionAssignApi
) => apis.put(`/users/subscription-assign?type=${type}`, data);
// old endpoints
export const getUserReport = () => apis.get("/user/report");
export const searchUser = (search: string) =>
  apis.get(`/users?keyword=${search}`);
export const searchUserNumber = () => apis.get(`/users?isPhone=true`);
export const RegisterUser = (data: any) => apis.post("/auth/register", data);
export const deleteUser = (data: any) => apis.put("/user/delete", data);
export const statusUser = (data: any) => apis.put("/user/status", data);
export const restoreUser = (data: any) => apis.put("/user/restore", data);
interface IUserPermissionPolicyPayload {
  id: string;
  permission: string[]
}
export const usersPermissionPolicy = (data: IUserPermissionPolicyPayload) => apis.put("/users/permission/policy", data);
export const onlineUsers = () => apis.get("/users/online").then((res) => res.data);

// Orders endpoints
export const getOrders = (page: number, limit: number, order: string) =>
  apis.get(`/payment/list?limit=${limit}&page=${page}&order=${order}`);
export const getOrderReport = (id: string) => apis.get(`/payment/list/${id}`);

// @end-point for the content-partner
export const getPartners = (query: string) => apis.get(`/users?${query}`);
export const createPartner = (data: any) => apis.post("/users/partner", data);
export const updatePartner = (id: string, data: any) =>
  apis.put(`/users/partner/${id}`, data);
export const setPartnerAction = (data: any) => apis.put(`/users/action`, data);
export const deletePartner = (id: string) =>
  apis.delete(`/users/partner/${id}`);
export const getPartnerCount = () => apis.get(`/users/partner/count`);

// Themes endpoints
export const getThemes = (page: number, limit: number, order: string) =>
  apis.get(`/theme/list?limit=${limit}&page=${page}&order=${order}`);
export const themeStatus = (data: any) => apis.put("/theme/status", data);
export const themeDelete = (data: any) => apis.put("/theme/delete", data);
export const themeRestore = (data: any) => apis.put("/theme/restore", data);
export const themeSearch = (search: string) =>
  apis.get(`/theme/search/${search}`);
export const getTheme = (id: string) => apis.get(`/theme/list/${id}`);
export const themeReport = () => apis.get(`/theme/report`);

// Store endpoints
export const getStores = (page: number, limit: number, order: string) =>
  apis.get(`/store/list?limit=${limit}&page=${page}&order=${order}`);
export const storeStatus = (data: any) => apis.put("/store/status", data);
export const storeDelete = (data: any) => apis.put("/store/delete", data);
export const storeRestore = (data: any) => apis.put("/store/restore", data);
export const storeSearch = (search: string) =>
  apis.get(`/store/search/${search}`);
export const getStore = (id: string) => apis.get(`/store/single/${id}`);
export const storeReport = () => apis.get(`/store/report`);

// Subscription endpoints
export const getSubscriptions = (queryString?: string) =>
  apis.get(`/subscriptions${queryString ? `?${queryString}` : ""}`);
export const subscriptionStatus = (data: any) =>
  apis.put("/subscriptions/status", data);
export const UpdateSubscription = (id: string | undefined, data: any) =>
  apis.put(`/subscriptions/update/${id}`, data);
export const subscriptionInitEndpoint = () => apis.get("/subscriptions/init");

// deprecated endpoints
export const subscriptionDelete = (data: any) =>
  apis.put("/subscription/delete", data);
export const subscriptionRestore = (data: any) =>
  apis.put("/subscription/restore", data);
export const subscriptionSearch = (search: string) =>
  apis.get(`/subscription/search/${search}`);
export const getSubscription = (id: string) =>
  apis.get(`/subscription/list/${id}`);
export const subscriptionReport = () => apis.get(`/subscription/report`);
export const CreateSubscription = (data: any) =>
  apis.post("/subscription/create", data);

// movie endpoints
export const createMovie = (data: any) => apis.post("/movie/create", data);
export const getMovies = (page = 1, limit = 25, order = "desc") =>
  apis.get(`/movie/list?limit=${limit}&page=${page}&order=${order}`);
export const movieStatus = (data: any) => apis.put("/movie/status", data);
export const movieDelete = (data: any) => apis.put("/movie/delete", data);
export const movieRestore = (data: any) => apis.put("/movie/restore", data);
export const movieSearch = (search: string) =>
  apis.get(`/movie/search/${search}`);
export const getMovie = (id: string) => apis.get(`/movie/list/${id}`);
export const movieReport = () => apis.get(`/movie/report`);
export const updateMovie = (id: string, data: any) =>
  apis.put(`/movie/update/${id}`, data);
export const updateJob = (id: string) => apis.get(`movie/updateJob/${id}`);
export const premanentlyDeleteMovie = (id: string) =>
  apis.delete(`/movie/${id}`);

// media convertion endpoints aws
export const createJob = (data: any) =>
  apis.post("/media-convert/create", data);
export const createJobNew = (data: any) => apis.post("/media-convert", data);
export const getJob = (id: string) => apis.get(`/media-convert/list/${id}`);

// genre endpoints
export const createGenreApi = (data: any) => apis.post("/genre", data);
export const getGenres = (queryString?: string) =>
  apis.get(`/genre${queryString ? `?${queryString}` : ""}`);
export const genreActionsApi = (data: any) => apis.patch("/genre/action", data);
export const genreSearch = (search: string) =>
  apis.get(`/genre?keyword=${search}`);

// deprecated endpoints
export const genreStatus = (data: any) => apis.put("/genre/status", data);
export const genreDelete = (data: any) => apis.put("/genre/delete", data);
export const genreRestore = (data: any) => apis.put("/genre/restore", data);
export const getGenre = (id: string) => apis.get(`/genre/list/${id}`);
export const genreReport = () => apis.get(`/genre/report`);
export const updateGenre = (id: string | undefined, data: any) =>
  apis.put(`/genre/update/${id}`, data);

// category endpoints
export const getCategories = (queryString?: string): Promise<AxiosResponse> =>
  apis.get(`/categories${queryString ? `?${queryString}` : ""}`)
export const createCategoryEndPoint = (data: any) =>
  apis.post("/categories", data);
export const categoryActionsEndPoint = (data: any) =>
  apis.patch("/categories/action", data);
export const updateCategoryEndPoint = (id: string | undefined, data: any) =>
  apis.put(`/categories/${id}`, data);
export const categorySearch = (search: string) =>
  apis.get(`/categories?keyword=${search}`);
export const updateCategoryOrder = (data: any) =>
  apis.put(`/categories/order/reorder`, data);

//deprecated endpoints
export const getCategory = (id: string) => apis.get(`/categories/list/${id}`);
export const categoryStatus = (data: any) =>
  apis.put("/categories/status", data);
export const categoryDelete = (data: any) =>
  apis.put("/categories/delete", data);
export const categoryRestore = (data: any) =>
  apis.put("/categories/restore", data);
export const categoryReport = () => apis.get(`/categories/report`);

// language endpoints
export const getLanguagesEndPoint = (queryString?: string) =>
  apis.get(`/language${queryString ? `?${queryString}` : ""}`);
export const createLanguageEndPoint = (data: any) =>
  apis.post("/language", data);
export const languageActionsEndPoint = (data: any) =>
  apis.patch("/language/action", data);
export const updateLanguageEndPoint = (id: string | undefined, data: any) =>
  apis.put(`/language/${id}`, data);
export const languageSearch = (search: string) =>
  apis.get(`/language?keyword=${search}`);

// deprecated endpoints
export const languageStatus = (data: any) => apis.put("/language/status", data);
export const languageDelete = (data: any) => apis.put("/language/delete", data);
export const languageRestore = (data: any) =>
  apis.put("/language/restore", data);
export const getLanguage = (id: string) => apis.get(`/language/list/${id}`);
export const languageReport = () => apis.get(`/language/report`);
export const updateLanguage = (id: string, data: any) =>
  apis.put(`/language/update/${id}`, data);

// Cast endpoints
export const getCastsEndPoint = (queryString?: string) =>
  apis.get(`/cast${queryString ? `?${queryString}` : ""}`);
export const createCastEndPoint = (data: any) => apis.post("/cast", data);
export const castActionsEndPoint = (data: any) =>
  apis.patch("/cast/action", data);
export const updateCastEndPoint = (id: string | undefined, data: any) =>
  apis.put(`/cast/${id}`, data);
export const castSearch = (search: string) =>
  apis.get(`/cast?keyword=${search}`);

// deprecated endpoints
export const createCast = (data: any) => apis.post("/cast/create", data);
export const castStatus = (data: any) => apis.put("/cast/status", data);
export const castDelete = (data: any) => apis.put("/cast/delete", data);
export const castRestore = (data: any) => apis.put("/cast/restore", data);
export const getCast = (id: string) => apis.get(`/cast/list/${id}`);
export const castReport = () => apis.get(`/cast/report`);
export const updateCast = (id: string, data: any) =>
  apis.put(`/cast/update/${id}`, data);

// slider endpoints
export const getSliderEndPoint = () =>
  apis.get(`/slider`).then((res) => res.data);
export const updateSliderEndPoint = (
  id: string | undefined,
  data: IUpdateSlider
) => apis.put(`/slider/${id}`, data);

// deprecated endpoints
export const createSlider = (data: any) => apis.post("/slider/create", data);
export const getSliders = (page = 1, limit = 25, order = "desc") =>
  apis.get(`/slider/list?limit=${limit}&page=${page}&order=${order}`);
export const sliderStatus = (data: any) => apis.put("/slider/status", data);
export const sliderDelete = (data: any) => apis.put("/slider/delete", data);
export const sliderSearch = (search: string) =>
  apis.get(`/slider/search/${search}`);

export const sliderReport = () => apis.get(`/slider/report`);
export const updateSlider = (id: string, data: any) =>
  apis.put(`/slider/update/${id}`, data);

// section endpoints
export const createSection = (data: any) => apis.post("/section/create", data);
export const getSections = (query?: string) =>
  apis.get(`/section?${query ? query : ""}`);
export const sectionStatus = (data: any) => apis.put("/section/status", data);
export const sectionDelete = (data: any) => apis.put("/section", data);
export const sectionSearch = (search: string) =>
  apis.get(`/section/search/${search}`);

export const getSection = (id: string | undefined) => apis.get(`/section/list/${id}`);
export const sectionReport = () => apis.get(`/section/report`);
export const updateSection = (id: string | undefined, data: any) =>
  apis.put(`/section/${id}`, data);
export const updateSectionOrder = (data: any) =>
  apis.put(`/section/update-order`, data);

// series endpoints
export const createSeries = (data: any) =>
  apis.post("/series/create?version=v1.0.0", data);
export const getAllSeries = (page = 1, limit = 25, sort = "desc") =>
  apis.get(`/series/v1/list?limit=${limit}&page=${page}&sort=${sort}`);
export const getSeries = (queryString: string) =>
  apis.get(`/series/v1?${queryString}`);
export const seriesAction = (data: any) => apis.put("/series/action", data);
export const createSeriesSeason = (id: string, data: any) =>
  apis.post(`/series/season/${id}?version=v1.0.0`, data);
export const deleteSeriesSeason = (id: string) =>
  apis.delete(`/series/season/${id}`);
export const updateSeriesSeason = (id: string, data: any) =>
  apis.put(`/series/season/${id}`, data);
export const createSeriesEpisode = (
  seriesid: string,
  seasonid: string,
  data: any
) => apis.post(`/series/episode/${seriesid}/${seasonid}?version=v1.0.0`, data);
export const deleteSeriesEpisode = (id: string) =>
  apis.delete(`/series/episode/${id}`);
export const updateSeriesEpisode = (id: string, data: any) =>
  apis.put(`/series/episode/${id}`, data);

export const seriesStatus = (data: any) => apis.put("/series/status", data);
export const seriesDelete = (data: any) => apis.put("/series/softdelete", data);
export const SeriesRestore = (data: any) => apis.put("/series/restore", data);
export const premanentlyDeleteApi = (id: string) =>
  apis.delete(`/series/delete/${id}`);
export const updateSeriesJob = (id: string) =>
  apis.put(`/series/update-job/${id}`);
export const createSeason = (id: string, data: any) =>
  apis.post(`/series/create-season/${id}`, data);
export const deleteSeason = (id: string, seasonId: string) =>
  apis.delete(`/series/remove-season/${id}/${seasonId}`);
export const getSeason = (id: string, seasonId: string) =>
  apis.get(`/series/season/${id}/${seasonId}`);
export const updateSeason = (id: string, seasonId: string, data: any) =>
  apis.put(`/series/update-season/${id}/${seasonId}`, data);
export const createEpisode = (id: string, seasonId: string, data: any) =>
  apis.post(`/series/create-episode/${id}/${seasonId}`, data);
export const deleteEpisode = (
  id: string,
  seasonId: string,
  episodeId: string
) => apis.delete(`/series/remove-episode/${id}/${seasonId}/${episodeId}`);
export const updateEpisodeJob = (data: any) =>
  apis.put(
    `/series/update-episode-job/${data.id}/${data.seasonId}/${data.episodeId}`
  );
export const getEpisode = (id: string, seasonId: string, episodeId: string) =>
  apis.get(`/series/episode/${id}/${seasonId}/${episodeId}`);
export const updateEpisodeApi = (
  id: string,
  seasonId: string,
  episodeId: string,
  data: any
) => apis.put(`/series/update-episode/${id}/${seasonId}/${episodeId}`, data);

// analytics endpoints
export const getAnalytics = (queryString: string) => apis.get(`/analytics/content?${queryString}`);
export const getTrending = (startDate: string, endDate: string) => apis.get(`/trending?startDate=${startDate}&endDate=${endDate}`);
export const userAnalytics = (queryString?: string) => apis.get(`/analytics/users?${queryString || ""}`);
export const contentAnalytics = (queryString: string) => apis.get(`/analytics/content?${queryString}`);
export const getRevenue = (queryString?: string) => apis.get(`/analytics/revenue?${queryString || ""}`);
export const getPaidSubscribersEndPoint = () => apis.get(`/analytics/subscriber`).then((res) => res.data);

// @endpoint for the TVOD analytics
export const getTVOD = (query: string) => apis.get(`/analytics/content/tvod?${query}`)
export const getUserTVOD = (query: string) => apis.get(`/analytics/tvod/user?${query}`)

// content endpoints
export const getContent = (queryString?: string) =>
  apis.get(`/content?${queryString ? queryString : ""}`).then((res) => res.data);
export const searchContent = (keyword: string) =>
  apis.get(`/content?type=none&keyword=${keyword}`);

// new contact endpoints
export const getContant = (queryString?: string) => apis.get(`/content?${queryString || ""}`);
export const contactAction = (data: any) => apis.patch("/content/action", data);
export const contantSeasonsDelete = (id: string) =>
  apis.delete(`/content/season/${id}`);
export const contantCreateSeason = (id: string | undefined, data: any) =>
  apis.post(`/content/season/${id}`, data);
export const contantUpdateSeason = (id: string | undefined, data: any) =>
  apis.put(`/content/season/${id}`, data);
export const contantCreateEpisode = (id: string | undefined, data: any) =>
  apis.post(`/content/episode/${id}`, data);
export const contantEpisodeDelete = (id: string | undefined) =>
  apis.delete(`/content/episode/${id}`);
export const contantUpdateEpisode = (id: string | undefined, data: any) =>
  apis.put(`/content/episode/${id}`, data);
// marketing endpoints
export const sendPushNotification = (data: any) =>
  apis.post("/marketing", data);
export const getMarketingFirebaseSettingsApi = () =>
  apis.get("/marketing/firebase");
export const updateMarketingFirebaseSettingsApi = (data: any) =>
  apis.put("/marketing/firebase", data);

// Blog endpoints
export const blogActionsApi = (data: any) => apis.put("/blog/action", data);

// user history endpoints
export const getUserHistoryApi = (queryString: string) =>
  apis.get(`/history/user-hostory?${queryString}`).then((res) => res.data);

// subtitles endpoints
export const subtitleApiEndpoint = (queryString: string, data: any) =>
  apis.post(`/content/subtitle?${queryString}`, data);

// @Admob endpoints
export const getPublisherID = () => apis.get("/admob/publisher");
export const addPublisherID = (data: any) =>
  apis.post("/admob/publisher", data);

export const addPublisherIDS = (data: any) => apis.post("/admob/s", data);
export const updatePublisherID = (id: string, data: any) =>
  apis.put(`/admob/publisher/${id}`, data);

export const getAdUnits = () => apis.get(`/admob/adunit`);
export const addAdUnits = (data: IAdunit) => apis.post(`/admob/adunit`, data);
export const updateAdUnits = (id: string, data: IAdunit) =>
  apis.put(`/admob/adunit/${id}`, data);
export const deleteAdUnits = (id: string) => apis.delete(`/admob/adunit/${id}`);

// @New Release Endpoints
export const addRelease = (data: any) => apis.post(`/release`, data);
export const updateRelease = (data: any) => apis.put(`/release`, data);
export const deleteRelease = () => apis.delete(`/release`);
export const getRelease = () => apis.get(`/release`);

// @New Version Endpoints
export const addVersion = (data: any) => apis.post(`/version`, data);
export const updateVersion = (data: any) => apis.put(`/version`, data);
export const getVersion = () => apis.get(`/version`);

// @Change password
export const changePassword = (data: any) =>
  apis.put("/auth/change-password", data);

// @ geo-block Endpoints
export const getGeoBlockCountries = (query?: string) =>
  apis.get(`/geo${query ? "?" + query : ""}`);
export const postGeoBlockCountry = (data: any) => apis.post("/geo", data);
export const updateGeoBlockCountry = (id: string, data: any) =>
  apis.put(`/geo/${id}`, data);
export const deleteGeoBlockCountry = (id: string) => apis.delete(`/geo/${id}`);
export const setActionGeoBlockCountry = (id: string) =>
  apis.put(`/geo/action/${id}`);

export const addContentForBock = (id: string, data: any) => apis.post(`/geo/content/${id}`, data);

export const deleteContentForBock = (id: string, data: string) =>
  apis.delete(`/geo/content/${id}?countryID=${data}`);

export const updateBuyOrRentSettings = (id: string | undefined, data: any) => apis.put(`/content/buy-or-rent/${id}`, data);
// export const countCountry = () => apis.get(`/country/count`);
export const countCountry = () => apis.get(`/geo/count`);
export const autoImportCountries = () => apis.post(`/geo/auto-import`);

// @ Coupon Endpoint
export const createCoupon = (data: ICoupon) =>
  apis.post<ICouponCreateResponse>("/coupon", data);

export const getCoupon = (query: string) =>
  apis.get<ICouponResponseArr>(`/coupon?${query}`);

export const updateCoupon = (id: string, data: ICoupon) =>
  apis.put(`/coupon/${id}`, data);

export const deleteCoupon = (id: string) => apis.delete(`/coupon/${id}`);
export const setCouponAction = (id: string) => apis.put(`/coupon/action/${id}`);
export const useCoupon = (id: string) => apis.put(`/coupon/${id}`);
export const countCoupon = () => apis.get(`/coupon/count`);

// @Email Marketing Routes

export const updateTemplate = (id: string, data: ITemplate) =>
  apis.put<ITemplateSingleResponse>(`/email-marketing/${id}`, data);

export const deleteTemplate = (id: string) =>
  apis.delete(`/email-marketing/${id}`);

export const setTemplateAction = (id: string) =>
  apis.put(`/email-marketing/action/${id}`);

export const getTemplateCount = () =>
  apis.put<{ message: string; data: number }>(`/email-marketing/count`);

export const sendTemplate = (data: any) =>
  apis.post("/email-marketing/send-mail", data);

// @SMS-Marketing endpoints
export const getSMSTemplate = (query: string) =>
  apis.get<ISMSTemplateResponseArr>(`/sms-template?${query}`);
export const createSMSTemplate = (data: ISMSTemplate) =>
  apis.post<ISMSSingleTemplateResponse>("/sms-template", data);
export const setSMSActionTemplate = (id: string) =>
  apis.put<ISMSSingleTemplateResponse>(`/sms-template/action/${id}`);
export const updateSMSTemplate = (id: string, data: ISMSTemplate) =>
  apis.put<ISMSSingleTemplateResponse>(`/sms-template/${id}`, data);
export const deleteSMSTemplate = (id: string) =>
  apis.delete(`/sms-template/${id}`);
export const countSMSTemplate = (type: string) =>
  apis.get(`/sms-template/count?type=${type}`);
export const sendSMSTemplate = (data: any) =>
  apis.post(`/sms-template/send-sms`, data);
export const sendWhatsAppFemplate = (data: any) =>
  apis.post(`/sms-template/whatsapp`, data);

// @ beta-section endpoints
export const createBetaSection = (data: any) =>
  apis.post("/beta-section", data);

export const updateBetaSection = (data: any) => apis.put("/beta-section", data);
export const deleteBetaSection = (id: string) =>
  apis.delete(`/beta-section/${id}`);

// @ file manager Endpoints
export const CreatingPresignUrl = (data: ICreatePresignedUrlPayload): Promise<ICreatePresignedUrlResponse> => apis.post("/filemanager/presign/create", data).then((res) => {
  return {
    status: res.status,
    data: res.data,
  }
});
export const CreateSignUrl = (data: ICreateSignUrlPayload): Promise<ICreateSignUrlResponse> => apis.post("/filemanager/signurl/create", data).then((res) => {
  return {
    status: res.status,
    data: res.data,
  }
});
export const CompleteUpload = (data: ICompleteUploadPayload): Promise<ICompleteUploadResponse> => apis.post("/filemanager/upload/complete", data).then((res) => {
  return {
    status: res.status,
    data: res.data,
  }
});
export const createDataForFilemanager = (data: any, query: string = "") => apis.post(`/filemanager${query}`, data);
export const getDataForFilemanager = (query: string = "") => apis.get(`/filemanager${query}`);
export const renameDataForFilemanager = (data: any, query: string = "") => apis.put(`/filemanager/rename${query}`, data);
export const deleteDataForFilemanager = (id: string) => apis.delete(`/filemanager/${id}`);
export const syncDataForFilemanager = (query: string) => apis.put(`/filemanager/sync${query}`);

// @endpoint for the automatic transcoding
export const startTranscoding = (data: any) => apis.post("/transcoder/job", { ...data });

apis.interceptors.request.use(async (config: any) => {
  const ipaddress = await publicIpv4();
  config.headers["ipaddress"] = ipaddress;
  return config;
});

// Interceptor for handling 401 errors
// apis.interceptors.response.use(
//     (config) => {
//         return config;
//     },
//     async (error) => {
//         const originalRequest = error.config;
//         if (error.response.status === 401 && originalRequest && !originalRequest.isRetry) {
//             originalRequest.isRetry = true;
//             try {
//                 await axios.get(
//                     `${process.env.REACT_APP_API_URL}/auth/refresh`,
//                     {
//                         withCredentials: true,
//                         headers: {
//                             "Content-Type": "application/json",
//                             "Accept": "application/json",
//                         }
//                     }
//                 );

//                 return apis.request(originalRequest);
//             } catch (err: any) {
//                 console.log(err.message);
//             }
//         }

//         throw error;
//     }
// );

export default apis;
