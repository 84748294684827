// Author: Pukhraj Dhamu
// Date Created: 24 June 2022 (Friday)
// Date Modified: Sunday 14 May 2023 at 7:26 PM
import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { InputField, SelectField, TagField, TextAreaField } from "../../tailwind";
import toast from "react-hot-toast";
import { castSearch, categorySearch, genreSearch, getCastsEndPoint, getCategories, getGenres, getLanguagesEndPoint, languageSearch } from "../../http/Apis";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ImageField } from "../../tailwind/Input/inputField";
import slugify from "slugify";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom"

const initialValues = {
  name: "",
  slug: "",
  description: "",
  uacertificate: "",
  genres: [],
  casts: [],
  rating: 0,
  duration: "",
  content_duration: "",
  period: 0,
  price: 0,
  tags: [],
  thumbnail: "",
  thumbnailUrl: "",
  poster: "",
  posterUrl: "",
  titleImage: "",
  titleImageUrl: "",
  category: "",
  language: "",
  type: "",
  content_offering_type: "",
};

const createContentSchema = Yup.object().shape({
  name: Yup.string().required("Title is required").min(2, "Title is too short").max(100, "Title is too long"),
  description: Yup.string().optional().max(5000, "Description is too long"),
  uacertificate: Yup.string().optional(),
  genres: Yup.array().default([]),
  casts: Yup.array().default([]),
  rating: Yup.string().default("0"),
  duration: Yup.number().optional(),
  content_duration: Yup.string().optional(),
  period: Yup.number().optional(),
  price: Yup.number().optional(),
  tags: Yup.array().default([]),
  thumbnail: Yup.mixed().required("Thumbnail is required")
    .test(
      "fileSize",
      "File too large limit is 1mb",
      (value) => value && value.size <= 1000000
    ) // 1mb
    .test(
      "fileFormat",
      "Unsupported Format. try JPEG, PNG, JPG, WEBP",
      (value) =>
        value &&
        ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(
          value.type
        )
    ),
  poster: Yup.mixed().required("Poster is required")
    .test(
      "fileSize",
      "File too large limit is 1mb",
      (value) => value && value.size <= 1000000
    ) // 1mb
    .test(
      "fileFormat",
      "Unsupported Format. try JPEG, PNG, JPG, WEBP",
      (value) =>
        value &&
        ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(
          value.type
        )
    ),
  titleImage: Yup.mixed()
    .optional()
    .test("fileSize", "File too large limit is 1mb", (value: any) => {
      if (!value) return true;
      return value && value.size <= 1024 * 1024 * 1; // 1mb
    })
    .test(
      "fileFormat",
      "Unsupported Format. try JPEG, PNG, JPG, WEBP",
      (value: any) => {
        if (!value) return true;
        return (
          value &&
          ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(
            value.type
          )
        );
      }
    ),

});

const CreateContent = () => {
  const [loading, setLoading] = React.useState(false);
  const [isRental, setIsRental] = React.useState(false);
  const navigate = useNavigate();
  // submit form
  const handleSubmitFrom = async (values: any) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("slug", slugify(values.name));
    if (values.description) formData.append("description", values.description);
    if (values.uacertificate) formData.append("u_age", values.uacertificate);
    if (values.genres?.length > 0) {
      const genreIds = values.genres.map((item: any) => item._id);
      formData.append("genres", JSON.stringify(genreIds));
    }
    if (values.casts?.length > 0) {
      const castIds = values.casts.map((item: any) => item._id);
      formData.append("cast", JSON.stringify(castIds));
    }
    if (values.rating) formData.append("rating", values.rating);
    if (values.duration) formData.append("duration", values.duration);
    if (values.content_duration) formData.append("content_duration", values.content_duration);
    if (values.tags) formData.append("tags", JSON.stringify(values.tags));
    if (values.thumbnail) formData.append("thumbnail", values.thumbnail);
    if (values.poster) formData.append("poster", values.poster);
    if (values.titleImage) formData.append("title_image", values.titleImage);
    if (values.category) formData.append("category", values.category._id);
    if (values.language) formData.append("language", values.language._id);
    if (values.type) formData.append("type", values.type._id);
    if (values.rental_period) formData.append("period", values.rental_period);
    if (values.price) formData.append("type", values.price);
    if (values.content_offering_type)
      formData.append(
        "content_offering_type",
        values.content_offering_type._id
      );
    if (!values.type) return toast.error("Please select type");
    //Set up the config
    const config = {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    // submit form
    try {
      setLoading(true);
      const { data, status } = await axios.post(`${process.env.REACT_APP_API_URL}/content`,
        formData,
        config
      );
      if (status === 201) {
        setLoading(false);
        //redirect to content page
        setTimeout(() => {
          navigate(`/admin/content/edit/${data?.data?._id}`)
        }, 1000);
        return toast.success("Content created successfully!");
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.error?.message || "Something went wrong"
      );
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: createContentSchema,
    onSubmit: (values, action) => {
      handleSubmitFrom(values);
      //action.resetForm();
    },
  });

  // fetching all the categories and languages, genres and subtitles from the server
  // innitializing the state with the fetched data
  const [categories, setCategories] = React.useState([]);
  const [genreData, setGenreData] = React.useState([]);
  const [languagesData, setLanguages] = React.useState([]);
  const [castData, setCastData] = React.useState([]);

  const fetchCategories = async () => {
    const { data } = await getCategories();
    setCategories(data.data);
  };
  const onCategorySearch = async (search: string) => {
    const { data } = await categorySearch(search);
    setCategories(data.data);
    return data.data;
  };
  const fetchGenres = async () => {
    const { data } = await getGenres();
    setGenreData(data.data);
  };
  const onGenreSearch = async (search: string) => {
    const { data } = await genreSearch(search);
    setGenreData(data.data);
    return data.data;
  };
  const fetchAllLanguages = async () => {
    const { data } = await getLanguagesEndPoint();
    setLanguages(data.data);
  };
  const onLanguageSearch = async (search: string) => {
    const { data } = await languageSearch(search);
    setLanguages(data.data);
    return data.data;
  };
  const fetchAllCast = async () => {
    const { data } = await getCastsEndPoint();
    setCastData(data.data);
  };
  const onCastSearch = async (search: string) => {
    const { data } = await castSearch(search);
    setCastData(data.data);
    return data.data;
  };

  useEffect(() => {
    fetchGenres();
    fetchCategories();
    fetchAllLanguages();
    fetchAllCast();
  }, []);

  // type
  const contentOfferingType = [
    { name: "Premium", _id: "PREMIUM" },
    { name: "Free", _id: "FREE" },
    { name: "Buy or rent", _id: "BUY_OR_RENT" },
  ];
  // content type
  const contentType = [
    { name: "Movie", _id: "movie" },
    { name: "Series", _id: "series" },
    { name: "Live Stream", _id: "live_stream" },
  ];

  const promiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(onGenreSearch(inputValue));
      }, 1000);
    });

  const castsPromiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(onCastSearch(inputValue));
      }, 1000);
    });
  const categoryPromiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(onCategorySearch(inputValue));
      }, 1000);
    });

  const languagePromiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(onLanguageSearch(inputValue));
      }, 1000);
    });

  interface IContentForm {
    title?: string;
    name: string;
    slug?: boolean;
    placeholder: string;
    type: string;
    value?: any;
    error?: any;
    required?: boolean;
    maxLength?: number;
    limitValidation?: boolean;
    options?: any;
    isMulti?: boolean;
    promiseOptions?: any;
    urlName?: string;
    url?: string;
    className?: string;
    imageStyle?: string;
    placeholderStyle?: string;
    col?: string;
  }

  const contentForm: IContentForm[] = [
    {
      name: "name",
      placeholder: "Type contact name here",
      type: "text",
      value: values.name,
      error: errors.name,
      required: true,
      maxLength: 100,
      limitValidation: true,
      col: "col-span-2",
    },
    {
      name: "description",
      placeholder: "Type contact description here",
      type: "textarea",
      value: values.description,
      error: errors.description,
      required: true,
      maxLength: 5000,
      limitValidation: true,
      col: "col-span-2",
    },
    {
      name: "genres",
      options: genreData,
      placeholder: "Select genres",
      isMulti: true,
      type: "select",
      value: values.genres,
      promiseOptions: promiseOptions,
      col: "col-span-2",
    },
    {
      name: "uacertificate",
      placeholder: "Type contact uacertificate here",
      type: "text",
      value: values.uacertificate,
      limitValidation: false,
      col: "col-span-2",
    },
    {
      name: "casts",
      options: castData,
      placeholder: "Select casts",
      isMulti: true,
      type: "select",
      value: values.casts,
      promiseOptions: castsPromiseOptions,
      col: "col-span-2",
    },
    {
      name: "rating",
      placeholder: "Type contact rating here",
      type: "text",
      value: values.rating,
      limitValidation: false,
      col: "col-span-2",
    },
    {
      name: "duration",
      placeholder: "Type contact duration here in seconds",
      type: "text",
      value: values.duration,
      limitValidation: false,
      col: "col-span-2",
    },
    {
      title: "Content Duration (beta)",
      name: "content_duration",
      placeholder: "Type contact duration here",
      type: "text",
      value: values.content_duration,
      limitValidation: false,
      col: "col-span-2",
    },
    {
      name: "period",
      placeholder: "Type Rental Period",
      type: isRental ? "text" : "none",
      value: values.period,
      limitValidation: false,
      col: "col-span-2",
    },
    {
      name: "price",
      placeholder: "Enter Your Price Here",
      type: isRental ? "text" : "none",
      value: values.price,
      limitValidation: false,
      col: "col-span-2",
    },
    {
      name: "tags",
      placeholder: "Type tag and press enter to add",
      type: "tags",
      value: values.tags,
      limitValidation: false,
      col: "col-span-2",
    },
  ];

  const contentFormTwo: IContentForm[] = [
    {
      name: "thumbnail",
      urlName: "thumbnailUrl",
      placeholder: "Please upload a thumbnail 1280x720px.",
      type: "imagewithPreview",
      value: values.thumbnail,
      url: values.thumbnailUrl,
      error: errors.thumbnail,
      limitValidation: false,
      className:
        "w-full aspect-video rounded overflow-hidden border cursor-pointer",
      col: "col-span-1",
    },
    {
      name: "poster",
      urlName: "posterUrl",
      placeholder: "upload poster. 1175x1763px.",
      type: "imagewithPreview",
      value: values.poster,
      url: values.posterUrl,
      error: errors.poster,
      limitValidation: false,
      className: "w-full overflow-hidden border rounded flex justify-center",
      imageStyle: "object-contain w-52",
      placeholderStyle: "cursor-pointer text-sm py-32",
      col: "col-span-1",
    },
    {
      title: "Title Image (beta)",
      name: "titleImage",
      urlName: "titleImageUrl",
      placeholder: "Please upload a title 480x270px.",
      type: "imagewithPreview",
      value: values.titleImage,
      url: values.titleImageUrl,
      error: errors.titleImage,
      limitValidation: false,
      className:
        "w-full aspect-video rounded overflow-hidden border cursor-pointer",
      col: "col-span-1",
    },
    {
      name: "category",
      options: categories,
      placeholder: "Select category",
      type: "select",
      isMulti: false,
      value: values.category,
      promiseOptions: categoryPromiseOptions,
      col: "col-span-1",
    },
    {
      name: "language",
      options: languagesData,
      placeholder: "Select language",
      type: "select",
      isMulti: false,
      value: values.language,
      promiseOptions: languagePromiseOptions,
      col: "col-span-1",
    },
    {
      name: "type",
      options: contentType,
      placeholder: "Select type",
      type: "select",
      isMulti: false,
      value: values.type,
      col: "col-span-1",
    },
    {
      name: "content_offering_type",
      options: contentOfferingType,
      placeholder: "Select contant offering type",
      type: "select",
      isMulti: false,
      value: values.content_offering_type,
      col: "col-span-1",
    },
  ];

  return (
    <Layout>
      <h1 className="font-medium opacity-80 text-2xl mb-3.5">Create Content</h1>
      <form id="create-content" onSubmit={handleSubmit}>
        <div className="grid grid-cols-3 gap-5 pr-5">
          <div className="flex flex-col col-span-2 gap-4">
            {contentForm.map((item) => {
              switch (item.type) {
                case "text":
                  return (
                    <InputField
                      title={item.title || item.name}
                      fieldName={item.name}
                      placeholder={item.placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={item.value}
                      type="text"
                      required={item.required}
                      maxLength={item.maxLength}
                      error={item.error}
                      limitValidation={item.limitValidation}
                      col={item.col}
                    />
                  );
                case "textarea":
                  return (
                    <TextAreaField
                      fieldName={item.name}
                      placeholder={item.placeholder}
                      textAreaOnBlur={handleBlur}
                      textAreaOnChange={handleChange}
                      value={item.value}
                      type="text"
                      //required
                      maxLength={item.maxLength}
                      error={item.error}
                      limitValidation={item.limitValidation}
                      col={item.col}
                    />
                  );
                case "select":
                  return (
                    <SelectField
                      options={item.options}
                      onChange={(e) => {
                        let event = { target: { name: item.name, value: e } };
                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      placeholder={item.placeholder}
                      isMulti={item.isMulti}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      promiseOptions={item.promiseOptions}
                      col={item.col}
                    />
                  );
                case "tags":
                  return (
                    <TagField
                      fieldName={item.name}
                      placeholder={item.placeholder}
                      col={item.col}
                      onKeyDown={(e) => {
                        if (e.key !== "Enter") return;
                        e.preventDefault();
                        let event = {
                          target: {
                            name: item.name,
                            value: [...(item.value as any), e.target.value],
                          },
                        };
                        handleChange(event);
                        e.target.value = "";
                      }}
                      value={item.value}
                      onRemove={(index) => {
                        let event = {
                          target: {
                            name: item.name,
                            value: item.value.filter(
                              (item: any, i: number) => i !== index
                            ) as any,
                          },
                        };
                        handleChange(event);
                      }}
                    />
                  );
              }
            })}
          </div>
          <div className="flex flex-col col-span-1 gap-4">
            {contentFormTwo.map((item) => {
              switch (item.type) {
                case "select":
                  return (
                    <SelectField
                      options={item.options}
                      onChange={(e) => {
                        if (e.name === "Rental") {
                          setIsRental(true);
                        } else {
                          setIsRental(false);
                        }
                        let event = { target: { name: item.name, value: e } };
                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      placeholder={item.placeholder}
                      isMulti={item.isMulti}
                      getOptionLabel={(option) => {
                        // console.log(option.name);
                        return option.name;
                      }}
                      getOptionValue={(option) => {
                        // console.log(option._id);
                        return option._id;
                      }}
                      promiseOptions={item.promiseOptions}
                      col={item.col}
                    />
                  );
                case "imagewithPreview":
                  return (
                    <ImageField
                      fieldName={item.name}
                      placeholder={item.placeholder}
                      onChange={(e) => {
                        // set image in thumbnail field and url in thumbnailUrl field
                        let event = {
                          target: { name: item.name, value: e.target.files[0] },
                        };
                        handleChange(event);
                        let urlEvent = {
                          target: {
                            name: item.urlName,
                            value: URL.createObjectURL(e.target.files[0]),
                          },
                        };
                        handleChange(urlEvent);
                      }}
                      onBlur={handleBlur}
                      url={item.url}
                      value={item.value}
                      className={item.className}
                      error={item.error}
                      imageStyle={item.imageStyle}
                      placeholderStyle={item.placeholderStyle}
                      col={item.col}
                    />
                  );
              }
            })}
          </div>
        </div>
        <div className="flex justify-end pr-5 my-5">
          <LoadingButton
            id="create-content"
            type="submit"
            loading={loading}
            disabled={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Create
          </LoadingButton>
        </div>
      </form>
    </Layout>
  );
};

export default CreateContent;
