import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Loader } from '../../../components/smallComponents/loader';
import { useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import authProviderHttp, { IAuthProviderGetAuthMethodsResponse } from '../../../http/auth.provider.http';

// const return provider icon
const returnProviderData = (provider: "email" | "phone" | "qrcode", enabled_methods: IAuthProviderGetAuthMethodsResponse['data']['methods_enabled']): {
    name: string,
    description?: string,
    type: string,
    status: boolean,
    icon: string,
} => {
    switch (provider) {
        case "email":
            return {
                name: "Email",
                description: "Available in web and mobile apps.",
                type: "email",
                // check email is true or false
                status: enabled_methods.email ? true : false,
                icon: "https://res.cloudinary.com/dm4uaqlio/image/upload/v1693210000/Auth%20Provider%20Icon/confidential-email_un4bfm.png"
            }
        case "phone":
            return {
                name: "Phone",
                description: "Available in web and mobile apps.",
                type: "phone",
                status: enabled_methods.phone ? true : false,
                icon: "https://res.cloudinary.com/dm4uaqlio/image/upload/v1693210239/Auth%20Provider%20Icon/telephone_su6lm1.png"
            };
        case "qrcode":
            return {
                name: "QR Code",
                description: "Available in only tv app.",
                type: "qrcode",
                status: enabled_methods.qrcode ? true : false,
                icon: "https://res.cloudinary.com/dm4uaqlio/image/upload/v1693291051/qr-code_r1xkir.png"
            };
    }
}

// return provider name
const returnProviderName = (provider: "email" | "phone" | "qrcode"): string => {
    switch (provider) {
        case "email":
            return "Email"
        case "phone":
            return "Phone"
        case "qrcode":
            return "QR Code"
    }
}


function AuthProviders() {
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [selectedProvider, setSelectedProvider] = useState<"email" | "phone" | "qrcode" | null>(null);
    const [selectedProviderStatus, setSelectedProviderStatus] = useState<boolean>(false);

    // get the auth provider
    const authProviderQuery = useQuery({
        queryKey: ["authProvider"],
        queryFn: () => authProviderHttp.getAuthMethods(),
    })

    // update the auth provider
    const HendleStatusMutation = useMutation({
        mutationKey: ["authProvider"],
        mutationFn: ({ selectedProvider, selectedProviderStatus }: { selectedProvider: "email" | "phone" | "qrcode", selectedProviderStatus: boolean }) => authProviderHttp.updateAuthMethods({
            methods: [selectedProvider],
            status: selectedProviderStatus
        }),
        onSuccess(data, variables, context) {
            toast.success("Provider updated successfully");
            authProviderQuery.refetch();
            window && window?.auth_provider_status?.close();
        },
        onError(error: any, variables, context) {
            toast.error(error?.response?.data?.error?.message || "Error updating theme");
        },
    })

    return (
        <Layout>
            <div className='pr-6'>
                <h1 className="mb-5 opacity-80 font-medium tracking-wide text-xl pr-5">
                    Auth Methods
                </h1>
                {authProviderQuery.isLoading && (
                    <div className='w-full flex justify-center items-center min-h-[47rem]'>
                        <Loader smail />
                    </div>
                )}
                {authProviderQuery.isError && (
                    <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            Server Error. Please try again
                        </h1>
                        <div
                            onClick={() => authProviderQuery.refetch()}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                            <h1 className='text-sm text-white'>
                                Retry
                            </h1>
                        </div>
                    </div>
                )}
                {authProviderQuery.isSuccess && (
                    <div className='flex gap-4'>
                        {authProviderQuery.data.data.methods.map((provider, index) => {
                            return (
                                <div key={index} className={`w-72 h-64 rounded-md ${darkMode ? "bg-[#1B1B27]" : "bg-slate-300"} flex flex-col justify-center items-center`}>
                                    <div className='rounded-full cursor-pointer bg-white p-2'>
                                        <img
                                            src={returnProviderData(provider, authProviderQuery.data.data.methods_enabled).icon}
                                            alt={provider}
                                            className='w-8 h-8'
                                        />
                                    </div>
                                    <p className='pt-5 capitalize text-xl'>
                                        {returnProviderData(provider, authProviderQuery.data.data.methods_enabled).name}
                                    </p>
                                    <p className='pt-1 capitalize mb-10 text-center text-sm'>
                                        {returnProviderData(provider, authProviderQuery.data.data.methods_enabled).description}
                                    </p>
                                    <p
                                        onClick={() => {
                                            window.auth_provider_status.showModal()
                                            setSelectedProvider(provider)
                                            setSelectedProviderStatus(returnProviderData(provider, authProviderQuery.data.data.methods_enabled).status)
                                        }}
                                        className={`lowercase ${darkMode ? "bg-[#27293A]" : "bg-slate-400"} px-3 py-1 rounded-full cursor-pointer select-none`}>
                                        {returnProviderData(provider, authProviderQuery.data.data.methods_enabled).status
                                            ? <span className={`${darkMode && 'text-green-600'}`}>
                                                Enabled
                                            </span> : <span>
                                                Disabled
                                            </span>}
                                    </p>
                                </div>
                            )
                        })}
                    </div>

                )}
            </div>

            <dialog id="auth_provider_status" className="modal">
                <form method="dialog" className="modal-box">
                    <h3 className="font-bold text-lg text-black">
                        {selectedProviderStatus ? "Disable" : "Enable"} {returnProviderName(selectedProvider!)} Authentication
                    </h3>
                    <p className="py-4 text-black">
                        {selectedProviderStatus ? "Are you sure you want to disable this authentication method?" : "Are you sure you want to enable this authentication method?"}
                    </p>
                    <div className="modal-action">
                        <button className="btn">Close</button>
                        {/* if there is a button in form, it will close the modal */}
                        <div
                            onClick={() => HendleStatusMutation.isLoading ? {} : HendleStatusMutation.mutate({ selectedProvider: selectedProvider!, selectedProviderStatus: !selectedProviderStatus })}
                            className="btn">
                            {HendleStatusMutation.isLoading ? "Updating..." : "Update"}
                        </div>
                    </div>
                </form>
            </dialog>
        </Layout>
    )
}

export default AuthProviders
