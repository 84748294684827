import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Hr } from "../../components";
import qs from "qs";
import { RootState } from "../../redux/store";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import { getTVOD } from "../../http/Apis";
import { calculateBuyRentAmount } from "../../utils/Utils"
import { IAnalyticsQuery } from "../../Pages/analytics/analytics";


type ISelectedTab = "rent" | "buy";

interface ITVODProp {
    content_id: string
}

const TVOD = ({ content_id }: ITVODProp) => {
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [totalRentContent, setTotalRentContent] = useState<number>(0);
    const [totalBuyContent, setTotalBuyContent] = useState<number>(0);
    const [customeStartDate, setCustomeStartDate] = useState("");
    const [customeEndDate, setCustomeEndDate] = useState("");
    const [buyChartdata, setBuyChartData] = useState<any>(null);
    const [rentChartdata, setRentChartData] = useState<any>(0);
    const [selectedTab, setSelectedTab] = useState<ISelectedTab>("buy");
    const [timePeriod, setTimePeriod] = useState<IAnalyticsQuery['timePeriod']>("daily");
    const [tvodRecords, setTvodRecords] = useState<{
        buyCount: number;
        rentCount: number;
    }>({
        buyCount: 0,
        rentCount: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            let query: IAnalyticsQuery = {
                timePeriod: timePeriod,
                content_id: content_id
            };
            // formet query using qs
            const newQuery = qs.stringify(query);
            if (timePeriod === "custom") {
                let customQuery: IAnalyticsQuery = {
                    timePeriod: timePeriod,
                    startDate: customeStartDate,
                    endDate: customeEndDate,
                    content_id: content_id
                };
                const newCustomQuery = qs.stringify(customQuery);
                try {
                    let res = await getTVOD(newCustomQuery);
                    setTvodRecords({
                        buyCount: res.data.data.buyCount,
                        rentCount: res.data.data.rentCount,
                    });
                    handleGraph(res.data.data);
                } catch (error) { }
            } else {
                try {
                    let res = await getTVOD(newQuery);
                    setTvodRecords({
                        buyCount: res.data.data.buyCount,
                        rentCount: res.data.data.rentCount,
                    });
                    handleGraph(res.data.data);
                } catch (error) { }
            }
        };
        fetchData();
    }, [timePeriod, customeStartDate, customeEndDate]);

    const handleGraph = (chartDataArr: any) => {
        //@data for the chart/graph
        const data = chartDataArr.buy.map((item: any) => item.total_price);
        //@data for the watchTime Graph
        const watchTimeData = chartDataArr.rent.map((item: any) => {
            return item.total_price;
        });

        //@Total Views
        const totalBuyContent = chartDataArr.buy.reduce(
            (acc: any, item: any) => acc + item.total_price,
            0
        );
        setTotalBuyContent(totalBuyContent)

        // @Total WatchTime
        let totalRentContent = chartDataArr.rent.reduce(
            (acc: any, item: any) => acc + item.total_price,
            0
        );


        setTotalRentContent(totalRentContent)

        // @X-AXIS For the total views
        const xaxis = chartDataArr.buy.map((item: any) =>
            moment(item._id).format("MMM DD YYYY")
        );

        // @X-AXIS for the total watchTime
        const totalRentXaxis = chartDataArr.rent.map((item: any) =>
            moment(item._id).format("MMM DD YYYY")
        );
        // @Chart Data Object for the Total Views
        const buyDataChart = {
            series: [
                {
                    name: "Views",
                    data: [...data] || [],
                },
            ],
            options: {
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    type: "line",
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                },
                stroke: {
                    curve: "straight",
                },
                xaxis: {
                    categories: [...xaxis] || [],
                },
            },
        };

        // @Chart Data Object for the Total WatchTime
        const rentDataChart = {
            series: [
                {
                    name: "Watch Time",
                    data: watchTimeData || [],
                },
            ],
            options: {
                chart: {
                    zoom: {
                        enabled: false,
                    },
                    type: "line",
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                },
                stroke: {
                    curve: "straight",
                },
                xaxis: {
                    categories: [...totalRentXaxis] || [],
                },
            },
        };

        setBuyChartData({ ...buyDataChart });
        setRentChartData({ ...rentDataChart });
    };

    return (
        <>
            <div className={`w-1/2 flex  justify-items-start items-end h-10 mt-3`}>
                <select
                    className="p-2 w-1/3 text-lg text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => {
                        if (e.target.value === "custom") {
                            setCustomeStartDate(moment().startOf("month").format("YYYY-MM-DD"));
                            setCustomeEndDate(moment().format("YYYY-MM-DD"));
                        }
                        setTimePeriod(e.target.value as any);
                    }}>
                    <option value="daily">
                        Last 24 Hours
                    </option>
                    <option value="weekly">
                        Last 7 Days
                    </option>
                    <option value="monthly">
                        Last 30 Days
                    </option>
                    <option value="yearly">
                        Last 1 Year
                    </option>
                    <option value="custom">
                        Custom
                    </option>
                </select>

                {timePeriod === "custom" && (
                    <>
                        <div className="flex flex-col mx-4">
                            <input
                                type="date"
                                className=" bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={customeStartDate + ""}
                                onChange={(e) => {
                                    setCustomeStartDate(e.target.value);
                                }}
                            />
                        </div>
                        <div className="h-full flex justify-center items-center ">
                            <span className="text-white text-2xl">to</span>
                        </div>
                    </>
                )}

                {timePeriod === "custom" && (
                    <div className="flex flex-col mx-4">
                        <input
                            type="date"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={customeEndDate + ""}
                            onChange={(e) => {
                                setCustomeEndDate(e.target.value);
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="mt-3">
                <Hr />
            </div>

            <div className={`mt-5 mr-5 ${darkMode ? "border border-gray-700" : "border border-gray-200"}`}>
                <div className="grid grid-flow-col">
                    <div
                        onClick={() => setSelectedTab("buy")}
                        className={`${selectedTab === "buy" ? darkMode
                            ? "bg-dark-color-box border-r border-gray-700"
                            : "bg-white border-r border-gray-200"
                            : ""
                            } h-36 p-5 flex justify-center cursor-pointer ripple`}
                    >
                        <div className="flex flex-col items-center">
                            <div>
                                Total Buy (Rupees)
                            </div>
                            <div>
                                <h1 className="text-5xl mt-4">
                                    {calculateBuyRentAmount(totalBuyContent)}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => setSelectedTab("rent")}
                        className={`${selectedTab === "rent" ? darkMode ? "bg-dark-color-box border-r border-l border-gray-700" : "bg-white border-r border-l border-gray-200" : ""} h-36 p-5 flex justify-center cursor-pointer`}>
                        <div className="flex flex-col items-center">
                            <div>Total Rent (Rupees)</div>
                            <div>
                                <h1 className="text-5xl mt-4">
                                    {calculateBuyRentAmount(totalRentContent)}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${darkMode ? "bg-dark-color-box" : "bg-white"} p-5 w-full text-black`}>
                    {selectedTab === "buy" && buyChartdata && (
                        <div className="w-full">
                            <ReactApexChart
                                options={buyChartdata.options as any}
                                series={buyChartdata.series}
                                type="area"
                                height={350}
                                width={"100%"}
                            />
                        </div>
                    )}
                    {selectedTab === "rent" && rentChartdata && (
                        <div className="w-full">
                            <ReactApexChart
                                options={rentChartdata.options as any}
                                series={rentChartdata.series}
                                type="area"
                                height={350}
                                width={"100%"}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="flex my-5">
                <span className="mx-2 text-xl">Bought (All Time) : {tvodRecords.buyCount}</span>
                <span className="mx-2 text-xl">Rented (All Time) : {tvodRecords.rentCount}</span>
                <span className="mx-2 text-xl">Total (All Time) : {tvodRecords.buyCount + tvodRecords.rentCount}</span>
            </div>
        </>
    );
};

export default TVOD;
