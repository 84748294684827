// Author: Pukhraj Dhamu
// Created Date: Sunday, July 19th 2023 at 3:09 PM
// Last Modified Date: Sunday, July 19th 2023 at 3:09 PM
import { useState, useEffect } from "react";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export interface IQuery {
    page: number;
    limit: number;
    sort: string;
    keyword?: string;
}

export function useQueryParamsForPagination(): { query: IQuery, setQuery?: React.Dispatch<React.SetStateAction<IQuery>> } {
    const location = useLocation();
    const navigate = useNavigate();
    // get query params
    const { page = 1, limit = 25, sort = "desc", order = "desc" } = qs.parse(location.search, { ignoreQueryPrefix: true });
    // max limit is 25 if greater than 25 then set to 25
    const newLimit: any = limit
    const [limitValue, setLimitValue] = useState<number>(newLimit > 25 ? 25 : limit as number);
    const [query, setQuery] = useState<IQuery>({ page: Number(page), limit: Number(limitValue), sort: sort as string });

    // set limit value greater than 25 then update limit value to 25 in url using navigate
    useEffect(() => {
        if (query.keyword) navigate(`?page=${query.page}&limit=${query.limit}&sort=${query.sort}&keyword=${query.keyword}`);
        else navigate(`?page=${query.page}&limit=${query.limit}&sort=${query.sort}`);
        if (newLimit > 25 || newLimit < 1) {
            setLimitValue(25);
            navigate(`?page=${page}&limit=25&sort=${sort}`);
            toast.error("Limit can't be greater than 25 or less than 1");
        }
        // limit must be a number
        if (isNaN(limit as number)) {
            setLimitValue(25);
            navigate(`?page=${page}&limit=25&sort=${sort}`);
            toast.error("Limit must be a number");
        }
        // if sort is not desc or asc then set to desc
        if (sort !== "desc" && sort !== "asc") {
            navigate(`?page=${page}&limit=${limit}&sort=desc`);
            toast.error("Sort must be desc or asc");
        }
        // if page is not a number then set to 1
        if (isNaN(page as number)) {
            navigate(`?page=1&limit=${limit}&sort=${sort}`);
            toast.error("Page must be a number");
        }
        navigate(`?page=${query.page}&limit=${query.limit}&sort=${query.sort}`);
    }, [query]);

    return { query, setQuery };

}