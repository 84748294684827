import React from 'react'
import Layout from '../../components/Layout/Layout'
import { InputField, TextAreaField, Title } from '../../tailwind'
import toast from 'react-hot-toast'
import { updateGenre } from '../../http/Apis'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { fetchGenres } from '../../redux/slice​/genreSlice'
import { RootState } from '../../redux/store'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save';

const EditGenre = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { genre } = useSelector((state: RootState) => state.genre)

    const [loading, setLoading] = React.useState(false)

    const [genreData, setGenreData] = React.useState({
        name: '',
        description: '',
    })

    useEffect(() => {
        dispatch(fetchGenres(`id=${id}`, 'single') as any)
    }, [])

    useEffect(() => {
        setGenreData({
            name: genre.name,
            description: genre.description,
        })
    }, [genre])


    const handleSubmit = async () => {
        setLoading(true)
        try {
            const res = await updateGenre(id, genreData)
            if (res.status === 200) {
                toast.success('Genre update successfully')
                setLoading(false)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
            setLoading(false)
        }
    }

    return (
        <Layout>
            <Title className="font-medium text-xl mb-5 pr-5">Edit Genre</Title>

            <div className='flex flex-col gap-4 pr-5'>
                <InputField
                title='Name'
                    type="text"
                    fieldName='Name'
                    placeholder="Name"
                    value={genreData.name}
                    onChange={(e) => setGenreData({ ...genreData, name: e.target.value })}
                />
                <TextAreaField
                    type="textarea"
                    title='Description'
                    fieldName='Description'
                    placeholder="Description"
                    value={genreData.description}
                    textAreaOnChange={(e) => setGenreData({ ...genreData, description: e.target.value })}
                />

            </div>
            <div className="flex justify-end mt-5 px-5">
                <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                variant="contained"
                startIcon={<SaveIcon />}
                >
                    Save
                </LoadingButton>
            </div>
        </Layout>
    )
}

export default EditGenre