// Author: Pukhraj Dhamu
// Date Created: Sunday 21 May 2023 at 7:04 PM
// Date Modified: Sunday 21 May 2023 at 7:04 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const getBaseUrl = (url: string) => {
    // remove /api from the end of the url
    const urlArray = url.split("/");
    urlArray.pop();
    const baseUrl = urlArray.join("/");
    return baseUrl;
};
const settingsApis = axios.create({
    baseURL: getBaseUrl(process.env.REACT_APP_API_URL!),
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
});

export interface IUpdateCCavenuePaymentGatewaySettingApiPayload {
    name: string;
    status: boolean;
    key: string;
    secret: string;
    merchant: string;
    mode: "test" | "live";
    website: string;
    enabled_on: {
        android: boolean;
        ios: boolean;
        web: boolean;
        android_tv: boolean;
    };
}

// list all endpoints
// init payment gateway settings if new provider added
export const initCCavenuePaymentGatewaySettingApi = () => settingsApis.get(`/payments/ccavenue/initiate`);
export const updateCCavenuePaymentGatewaySettingApi = (payload: IUpdateCCavenuePaymentGatewaySettingApiPayload) => settingsApis.post(`/payments/ccavenue/update`, payload);


settingsApis.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

export default settingsApis;