// Author: Pukhraj Dhamu
// Date Created: Tuesday 4 Jul 2023 at 5:43 PM
// Date Modified: Tuesday 4 Jul 2023 at 5:43 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const contentApis = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    }
});

export interface IUpdateContentSourcePayload {
    source_type: "source" | "trailer";
    content_type: IContentData["type"];
    source_link: string;
    source_file_type: IContentData["source_type"];
    job_id: string | null;
}

// list all endpoints
export const getEpisodesByContentId = (id: string, queryString: string | undefined) => contentApis.get(`/content/episodes/${id}${queryString || ""}`).then((res) => res.data);
export const updateContentSourceApi = (id: string, payload: IUpdateContentSourcePayload) => contentApis.put(`/content/source/${id}`, payload);


contentApis.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

export default contentApis;