// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import { TvodContentTable } from "../../../tailwind";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { RootState } from "../../../redux/store";
import axios from "axios";
import { getUserTVOD } from "../../../http/Apis";
import { UseQueryResult } from "@tanstack/react-query";

export interface IQuery {
    page: number;
    limit: number;
    sort: string;
    type: string;
    user_id: string;
}

interface IProps {
    id: string;
    user: IUsersResponse['data'][0]
    userQuery: UseQueryResult
}

const UserTvodContent = ({ id, user, userQuery }: IProps) => {
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const location = useLocation();
    // get query params
    const {
        page = 1,
        limit = process.env.REACT_APP_PAGE_SIZE || 25,
        sort = "desc",
    } = qs.parse(location.search, { ignoreQueryPrefix: true });
    // max limit is 25 if greater than 25 then set to 25
    const [limitValue, setLimitValue] = useState(Number(limit) > 25 ? 25 : limit);
    const [tvodContent, setTvodContent] = useState([])
    const [meta, setMeta] = useState({
        total: 0
    })
    const [query, setQuery] = useState<IQuery>({
        page: Number(page),
        limit: Number(limitValue),
        sort: sort as string,
        type : "BUY",
        user_id: id ? id : "",
    });


    const queryString: string = qs.stringify(query);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const currQuery = `page=${query.page}&limit=${query.limit}&user_id=${query.user_id}&type=${query.type}`
                const res = await getUserTVOD(currQuery)
                setMeta({
                    total: res.data.meta.total
                })
                setTvodContent(res.data.data)

            } catch (error) {
                if (axios.isAxiosError(error)) {
                    const err: any = error.response?.data
                    return toast.error(err.error.message)
                }
            }
        }

        fetchData()
    }, [query])

    const headlePrev = () => {
        if (query.page > 1) {
            setQuery({
                ...query,
                page: query.page - 1,
            });
        } else {
            toast.error("You are on first page");
        }
    };

    const handleNext = () => {
        if (query.page < Math.ceil(meta.total / query.limit)) {
            setQuery({
                ...query,
                page: query.page + 1,
            });
        } else {
            toast.error("No more pages");
        }
    };

    return (
        <div className="w-full">
            <p className="flex justify-center h-48 items-center">
                Coming Soon
            </p>
        </div>
    );
};

export default UserTvodContent;
