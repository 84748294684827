import React, { useEffect, useState } from "react";
import { Hr } from "../../components";
import Layout from "../../components/Layout/Layout";
import SearchBar from "../../components/SearchBar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Checkbox,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import {
  countCoupon,
  createCoupon,
  deleteCoupon,
  getCoupon,
  setCouponAction,
  updateCoupon,
} from "../../http/Apis";
import {
  DeleteIcon,
  EditIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "../../tailwind";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";

function Coupon() {
  const { darkMode } = useSelector((state: RootState) => state.theme);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<string>("");
  const [meta, setMeta] = React.useState({
    total: 0,
    totalPublic: 0,
    totalPrivate: 0,
  });
  const [updateCouponIdx, setCouponUpdateIdx] = useState<number>(-1);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 25,
    sort: "desc",
  });
  const [createCouponOpen, setCreateCouponOpen] =
    React.useState<boolean>(false);
  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [code, setCode] = React.useState<string>("");
  const [discount, setDiscount] = React.useState<string>("");
  const [limit, setLimit] = React.useState<string>("");
  const [type, setType] = React.useState<string>("");
  const [couponsArr, setCouponsArr] = useState<ICouponResponse[]>([]);
  const [search, setSearch] = useState<string>();
  const [value] = useDebounce(search, 1000);

  const handleCreateCouponClose = (): void => {
    clearStateData()
    setCreateCouponOpen(false);
    clearStateData();
  };
  const handleCreateCouponOpen = (): void => {
    setCreateCouponOpen(true);
  };

  React.useEffect(() => {
    let query = `page=${pagination.page}&limit=${pagination.limit}&sort=${pagination.sort}`;
    const fetchData = async () => {
      try {
        let res = await getCoupon(query);
        if (res.status === 200) {
          setCouponsArr([...res.data.data]);
          setMeta(res.data.meta.report);
        }
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const err: any = error.response;
          return toast.error(err.data.error.message);
        }
        return console.log(error);
      }
    };

    fetchData();
  }, [pagination]);

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  const handleCreateCoupon = async () => {
    try {
      setLoading(true);
      let dataObj: ICoupon = {
        name: name,
        description: description,
        discount: +discount,
        type: type.toLowerCase(),
        max_uses: +limit,
        coupon_code: code
      };

      // if (code !== "") {
      //   dataObj.coupon_code = code;
      // }

      let res = await createCoupon(dataObj);
      if (res.status === 200) {
        const msg = res.data.message;
        let query = `page=${pagination.page}&limit=${pagination.limit}&sort=${pagination.sort}`;
        clearStateData();
        handleCreateCouponClose();
        setLoading(false);
        const couponRes = await getCoupon(query);
        if (res.status === 200) {
          setCouponsArr([...couponRes.data.data]);
          setMeta(couponRes.data.meta.report);
        }
        return toast.success(msg);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {

        if (error.response) {
          setLoading(false);
          const msg: any = error.response.data;
          return toast.error(msg.error.message)
        }
      }
    }
  };

  const handleCouponDelete = async (id: string) => {
    try {
      let res = await deleteCoupon(id);
      if (res.status === 200) {
        let idx = -1;
        idx = couponsArr.findIndex((item: any) => {
          return item._id === id;
        });

        const msg = res.data.message;
        let query = `page=${pagination.page}&limit=${pagination.limit}&sort=${pagination.sort}`;
        const couponRes = await getCoupon(query);
        if (res.status === 200) {
          setCouponsArr([...couponRes.data.data]);
          setMeta(couponRes.data.meta.report);
        }

        return toast.success(res.data.message);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        return toast.error(err.data.error.message);
      }

      return console.log(error);
    }
  };

  const handleCouponSetAction = async (id: string) => {
    try {
      let res = await setCouponAction(id);
      if (res.status === 200) {
        if (res.status === 200) {
          let idx = -1;
          idx = couponsArr.findIndex((item: any) => {
            return item._id === id;
          });
          if (idx > -1) {
            couponsArr[idx].status = !couponsArr[idx].status;
            if (couponsArr[idx].status) {
              setMeta({
                ...meta,
                ["totalPublic"]: meta.totalPublic + 1,
                ["totalPrivate"]: meta.totalPrivate - 1,
              });
            } else {
              setMeta({
                ...meta,
                ["totalPublic"]: meta.totalPublic - 1,
                ["totalPrivate"]: meta.totalPrivate + 1,
              });
            }
            setCouponsArr([...couponsArr]);
          }
          return toast.success(res.data.message);
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        return toast.error(err.data.error.message);
      }

      return console.log(error);
    }
  };

  const handleEditCoupon = async () => {
    try {
      setLoading(true);
      let dataObj: ICoupon = {
        name: name,
        description: description,
        discount: +discount,
        type: type.toLowerCase(),
        max_uses: +limit,
      };

      if (code !== "") {
        dataObj.coupon_code = code;
      }

      const res = await updateCoupon(isEdit, dataObj);
      console.log(res);

      if (res.status === 200) {
        couponsArr[updateCouponIdx] = {
          ...res.data.data,
          ...dataObj,
        };

        clearStateData();
        handleCreateCouponClose();
        setLoading(false);
        return toast.success(res.data.message);
      }

      setCouponsArr(couponsArr);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        setLoading(false);
        return toast.error(err.data.error.message);
      }
      setLoading(false);
      return console.log(error);
    }
  };

  const clearStateData = () => {
    setName("");
    setDescription("");
    setCode("");
    setDiscount("");
    setLimit("");
    setType("");
    setIsEdit("");
    setCouponUpdateIdx(-1);
  };

  const handlePrev = () => {
    if (pagination.page === 1) {
      return toast.error("You are on first Page");
    }

    setPagination({
      ...pagination,
      page: pagination.page - 1,
    });
  };

  const handleNext = () => {
    if (pagination.page < Math.ceil(meta.total / pagination.limit)) {
      setPagination({
        ...pagination,
        page: pagination.page + 1,
      });
    } else {
      toast.error("No more pages");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (value) {
          if (value.length > 2) {
            let query = `page=${pagination.page}&limit=${pagination.limit}&sort=${pagination.sort}&keyword=${search}&show=true`;

            let res = await getCoupon(query);
            console.log(res);

            if (res.status === 200) {
              setCouponsArr([...res.data.data]);
              // setRenderCouponArr([...res.data.data]);
            }
          } else {
            toast.error("Please enter more than 2 characters");
          }
        } else {
          let query = `page=${pagination.page}&limit=${pagination.limit}&sort=${pagination.sort}`;

          let res = await getCoupon(query);
          console.log(res);

          if (res.status === 200) {
            setCouponsArr([...res.data.data]);
            // setRenderCouponArr([...res.data.data]);
          }

          return;
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return console.log(error.message);
        }

        return console.log(error);
      }
    };
    fetchData();
  }, [value]);

  return (
    <Layout>
      <h1 className="font-medium text-2xl pr-5 mb-3 opacity-80">Coupon</h1>
      <Hr />
      <div className="flex justify-end items-center px-5 py-2">
        {/* <div className="flex items-center gap-4">
          <select
            className="select max-w-xs bg-transparent text-sm font-normal pl-0 -ml-1 focus:outline-none"
            defaultValue={"none"}
            // onChange={(e) => dispatch(setBulkAction(e.target.value))}
          >
            <option value="none">Bulk Actions</option>
            <option value="public">Public</option>
            <option value="private">Private</option>
            <option value="restore">Restore</option>
            <option value="trash">Move to Trash</option>
            <option value="delete">Delete Forever</option>
          </select>
          <span
            // onClick={() => dispatch(setBulkActionCall(true))}
            className="bg-transparent py-3.5 px-6 text-sm cursor-pointer hover:bg-secondary-blue duration-100"
          >
            Apply
          </span>
        </div> */}
        <div className="flex gap-2 items-center">
          <SearchBar
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Button
            variant="text"
            onClick={() => {
              handleCreateCouponOpen();
            }}
            className="bg-primary-blue text-white hover:bg-primary-blue-dark duration-100"
          >
            + Add New
          </Button>
        </div>
      </div>
      <Hr />
      <div className="w-full">
        <div className="overflow-x-auto w-full">
          <table className="table w-full">
            <div className="w-full ">
              <div
                className={`w-full border-b flex ${darkMode ? "border-[#343434]" : "border-gray-200"
                  }`}
              >
                <th
                  className={`bg-transparent border-b w-[20%] ${darkMode ? "border-[#343434]" : "border-gray-200"
                    } capitalize text-base font-normal `}
                >
                  name
                </th>

                <th
                  className={`bg-transparent border-b w-[20%] ${darkMode ? "border-[#343434]" : "border-gray-200"
                    } capitalize text-base font-normal `}
                >
                  Coupon
                </th>
                <th
                  className={`bg-transparent border-b w-[20%] ${darkMode ? "border-[#343434]" : "border-gray-200"
                    } capitalize text-base font-normal`}
                >
                  Status
                </th>
                <th
                  className={`bg-transparent border-b w-[20%] ${darkMode ? "border-[#343434]" : "border-gray-200"
                    } capitalize text-base font-normal`}
                >
                  Uses
                </th>
                <th
                  className={`bg-transparent border-b w-[20%] ${darkMode ? "border-[#343434]" : "border-gray-200"
                    } capitalize text-base font-normal`}
                >
                  Limit
                </th>
                <th
                  className={`bg-transparent border-b w-[20%] flex justify-center ${darkMode ? "border-[#343434]" : "border-gray-200"
                    } capitalize text-base font-normal`}
                >
                  Actions
                </th>
              </div>
            </div>
            <tbody className="w-full">
              <>
                <div className="w-full">
                  {couponsArr.length > 0 ? (
                    couponsArr.map((item: any, index: number) => {
                      return (
                        <div key={item._id} className="w-full">
                          <div
                            className="w-full border-b border-[#343434] flex"
                            key={index}
                          >
                            <td
                              className={`border-b w-[20%] bg-transparent ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } capitalize text-base font-normal`}
                            >
                              <div className="flex items-center space-x-3">
                                <div className="font-semibold opacity-80 capitalize text-sm">
                                  {item.name || "N/A"}
                                </div>
                              </div>
                            </td>
                            <td
                              className={`bg-transparent border-b w-[20%] ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } capitalize text-base font-normal`}
                            >
                              <div className="flex items-center space-x-3">
                                <div className="font-semibold opacity-80 capitalize text-sm">
                                  {item.coupon_code || "N/A"}
                                </div>
                              </div>
                            </td>
                            <td
                              className={`bg-transparent border-b w-[20%] ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } capitalize text-base font-normal`}
                            >
                              <span>
                                {item.status ? (
                                  <div className="flex items-center space-x-2">
                                    <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                                    <div className="text-xs text-green-500">
                                      Public
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-center space-x-2">
                                    <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                                    <div className="text-xs text-red-500">
                                      Private
                                    </div>
                                  </div>
                                )}
                              </span>
                            </td>

                            <td
                              className={`bg-transparent border-b w-[20%] ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } capitalize text-base font-normal`}
                            >
                              <div className="flex items-center space-x-3 pl-4">
                                <div className="font-semibold opacity-80 capitalize text-sm">
                                  {item.uses || "N/A"}
                                </div>
                              </div>
                            </td>

                            <td
                              className={`bg-transparent border-b w-[20%] pl-2 ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } capitalize text-base font-normal`}
                            >
                              <div className="flex items-center space-x-3">
                                <div className="font-semibold opacity-80 capitalize text-sm">
                                  {item.max_uses || "N/A"}
                                </div>
                              </div>
                            </td>

                            <td
                              className={`bg-transparent border-b w-[20%] ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } `}
                            >
                              <div className="flex  bg-transparent  w-full">
                                <label
                                  // onClick={() => {
                                  //   setSingleSelect([
                                  //     item._id as never,
                                  //   ]);
                                  //   setStatus(
                                  //     item.status
                                  //       ? "private"
                                  //       : "public"
                                  //   );
                                  //   handleMuiDialogOpen();
                                  //   setMuiDialogContant({
                                  //     title: "Category Status",
                                  //     contentText:
                                  //       "Are you sure you want to change the status of this category?",
                                  //     buttonOneText: "Cancel",
                                  //     buttonTwoText: "Yes",
                                  //     actionKey: "statusCategory",
                                  //   });
                                  // }}
                                  className="cursor-pointer"
                                >
                                  {" "}
                                  {item.status ? (
                                    <div
                                      className="tooltip tooltip-accent"
                                      data-tip="Public"
                                    >
                                      <Button
                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                        variant="text"
                                        onClick={() => {
                                          handleCouponSetAction(item._id);
                                        }}
                                      >
                                        <VisibilityIcon />
                                      </Button>
                                    </div>
                                  ) : (
                                    <div
                                      className="tooltip tooltip-accent"
                                      data-tip="Private"
                                    >
                                      <Button
                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                        variant="text"
                                        onClick={() => {
                                          handleCouponSetAction(item._id);
                                        }}
                                      >
                                        <VisibilityOffIcon />
                                      </Button>
                                    </div>
                                  )}
                                </label>

                                <div
                                  className="tooltip tooltip-accent"
                                  data-tip="Edit"
                                >
                                  <Button
                                    className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                    variant="text"
                                    onClick={() => {
                                      setIsEdit(item._id);
                                      const coupon = couponsArr[index];
                                      console.log(coupon.type);
                                      setName(coupon.name);
                                      setDescription(coupon.description);
                                      setCode(coupon.coupon_code);
                                      setDiscount(coupon.discount + "");
                                      setLimit(coupon.max_uses + "");
                                      setType(coupon.type);
                                      handleCreateCouponOpen();
                                      // handleEditCoupon(index);
                                      setCouponUpdateIdx(index);
                                    }}
                                  >
                                    <EditIcon />
                                  </Button>
                                </div>

                                <div
                                  className="tooltip tooltip-accent"
                                  data-tip="Delete"
                                >
                                  <Button
                                    className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                    variant="text"
                                    onClick={() => {
                                      handleCouponDelete(item._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </div>
                              </div>
                            </td>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <tr className="w-full flex items-center justify-center">
                      <p className="text-center py-4">No data found</p>
                    </tr>
                  )}
                </div>
              </>
            </tbody>
          </table>
        </div>
      </div>

      <Hr />

      <div className="flex w-full justify-between">
        <div className="flex gap-8 items-center">
          <div className="flex gap-3.5">
            <div className="">Total Coupon</div>
            <div className="">{meta.total}</div>
          </div>
          <div className="flex gap-3.5">
            <div className="">Public</div>
            <div className="">{meta.totalPublic}</div>
          </div>
          <div className="flex gap-3.5">
            <div className="">Private</div>
            <div className="">{meta.totalPrivate}</div>
          </div>
        </div>

        <div className="btn-group">
          <button
            className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? "text-white" : "text-black"
              }`}
            onClick={handlePrev}
          >
            «
          </button>
          <button
            className={`btn bg-transparent hover:bg-transparent border-none capitalize text-sm ${darkMode ? "text-white" : "text-black"
              }`}
          >
            Page {pagination.page}
          </button>
          <button
            className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? "text-white" : "text-black"
              }`}
            onClick={handleNext}
          >
            »
          </button>
        </div>
      </div>

      <Hr />
      <Dialog
        open={createCouponOpen}
        onClose={() => {
          clearStateData();
          handleCreateCouponClose();
        }}
      >
        <DialogTitle>Create Coupon</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a Coupon, please enter the Coupon name here.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Coupon Name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="standard"
          />

          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Coupon Description"
            type="text"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="code"
            label="Coupon Code"
            type="text"
            fullWidth
            value={code}
            onChange={(e) => setCode(e.target.value)}
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="discount"
            label="Discount"
            type="text"
            placeholder="Discount Must be Number"

            fullWidth
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="limit"
            label="Coupon Limit"
            placeholder="Coupon Limit Must be Number"
            type="number"
            fullWidth
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            variant="standard"
          />

          <FormControl
            sx={{ m: 1, minWidth: 120 }}
            size="small"
            margin="none"
            className="w-full"
          >
            <InputLabel id="demo-select-small">Type</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={type}
              label="Type"
              onChange={handleChange}
            >
              <MenuItem className="capitalize" value={"percentage"}>
                Percentage
              </MenuItem>
              <MenuItem className="capitalize" value={"amount"}>
                Amount
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateCouponClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            onClick={() => {
              isEdit !== "" ? handleEditCoupon() : handleCreateCoupon();
            }}
            variant="text"
            color="primary"
          >
            {isEdit !== "" ? "Update" : "Create"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

export default Coupon;
