import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import SearchBar from "../../components/SearchBar";
import {
  CategoryTable,
  DeleteIcon,
  EditIcon,
  GenreTable,
  VisibilityIcon,
  VisibilityOffIcon,
} from "../../tailwind";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import {
  autoImportCountries,
  countCountry,
  createCategoryEndPoint,
  createGenreApi,
  deleteGeoBlockCountry,
  getGeoBlockCountries,
  postGeoBlockCountry,
  setActionGeoBlockCountry,
  updateGeoBlockCountry,
} from "../../http/Apis";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
import {
  fetchCategories,
  setBulkAction,
  setBulkActionCall,
} from "../../redux/slice​/categorySlice";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import qs from "qs";
import { Link } from "react-router-dom";
import axios from "axios";

const Country = () => {
  const { darkMode } = useSelector((state: RootState) => state.theme);

  const [search, setSearch] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [value] = useDebounce(search, 1000);
  const [countryArr, setCountryArr] = useState<any[]>([]);
  const [meta, setMeta] = useState({
    total: 0,
    totalPrivate: 0,
    totalPublic: 0,
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: process.env.REACT_APP_PAGE_SIZE
      ? parseInt(process.env.REACT_APP_PAGE_SIZE)
      : 25,
  });

  useEffect(() => {
    // dispatch(fetchCategories(fetchQueryStr) as any);
    let query = `page=${pagination.page}&limit=${pagination.limit}&order=asc`;
    const fetchData = async () => {
      try {
        let res = await getGeoBlockCountries(query);
        console.log(res)
        console.log(res.data.data)
        console.log(res.data.meta)
        if (res.status === 200) {
          setCountryArr(res.data.data);
          setMeta({
            total: res.data.meta.total,
            totalPrivate: res.data.meta.totalPrivate,
            totalPublic: res.data.meta.totalPublic,
          });
        }
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const err: any = error.response;
          return toast.error(err.data.error.message);
        }
        return console.log(error);
      }
    };

    fetchData();
  }, [pagination]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (value) {
          if (value.length > 2) {
            let query = `page=${pagination.page}&limit=${pagination.limit}&order=asc&keyword=${search}&isFromCountry=true`;

            let res = await getGeoBlockCountries(query);

            if (res.status === 200) {
              setCountryArr([...res.data.data]);
              // setRenderCouponArr([...res.data.data]);
            }
          } else {
            toast.error("Please enter more than 2 characters");
          }
        } else {
          let query = `page=${pagination.page}&limit=${pagination.limit}&order=asc&isFromCountry=true`;

          let res = await getGeoBlockCountries(query);

          if (res.status === 200) {
            setCountryArr([...res.data.data]);
            // setRenderCouponArr([...res.data.data]);
          }

          return;
        }
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          return console.log(error.message);
        }

        return console.log(error);
      }
    };
    fetchData();
  }, [value]);

  const headlePrev = () => {
    if (pagination.page > 1) {
      setPagination({
        ...pagination,
        page: pagination.page - 1,
      });
    } else {
      toast.error("You are on first page");
    }
  };

  const handleNext = () => {
    if (pagination.page < Math.ceil(meta.total / pagination.limit)) {
      setPagination({
        ...pagination,
        page: pagination.page + 1,
      });
    } else {
      toast.error("No more pages");
    }
  };

  // create category
  const [createCategory, setCreateCountryOpen] = useState(false);

  const handleClickCreateCountryOpen = () => {
    setCreateCountryOpen(true);
  };
  const handleCreateCountryClose = () => {
    setCreateCountryOpen(false);
  };

  // createCountrytion
  // createCountryFunction
  const [countryName, setCountryName] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState<string>("");
  const [editID, setEditID] = useState<string>("");
  const [isEdit, setIsEdit] = useState(false);

  const createCountryFunc = async () => {
    try {
      if (phoneCode === "" || countryCode === "" || countryName === "") {
        return toast.error("All fields required...");
      }

      let countryObj = {
        name: countryName.toLowerCase(),
        phoneCode: phoneCode,
        code: countryCode,
      };

      let res = await postGeoBlockCountry(countryObj);
      // console.log(res);

      if (res.status === 200 && res.data) {
        // countryArr.push(res.data.data ? (res.data.data as string) : {});
        setCountryArr([...countryArr, res.data.data]);
        handleCreateCountryClose();
        return toast.success(res.data.message);
      }
    } catch (error: any) {
      if (error && error.response) {
        return toast.error(error.response.data.error.message);
      }
      return console.log(error);
    }
  };


  const handleCountryUpdate = async () => {
    try {
      if (phoneCode === "" || countryCode === "" || countryName === "") {
        return toast.error("All fields required...");
      }

      let countryObj = {
        name: countryName,
        phoneCode: phoneCode,
        code: countryCode,
      };
      let res = await updateGeoBlockCountry(editID, countryObj);
      // console.log(res);

      if (res.status === 200) {
        let idx = -1;

        idx = countryArr.findIndex((item) => {
          return item._id === editID;
        });

        if (idx > -1) {
          countryArr[idx].name = countryName;
          countryArr[idx].phone_code = phoneCode;
          countryArr[idx].code = countryCode;
        }

        setCountryArr(countryArr);
        resetState()
        handleCreateCountryClose();
        return toast.success(res.data.message);
      }
    } catch (error) {
      return console.log(error);
    }
  };

  const handleCountryDelete = async (id: string) => {
    try {
      let res = await deleteGeoBlockCountry(id);
      if (res.status === 200) {
        let idx = -1;
        idx = countryArr.findIndex((item) => {
          return item._id === id;
        });

        console.log(idx);

        if (idx > -1) {
          countryArr.splice(idx, 1);
        }

        setCountryArr([...countryArr]);

        return toast.success(res.data.message);
      }
    } catch (error) {
      return console.log(error);
    }
  };

  const handleCountryAction = async (id: string) => {
    try {
      let res = await setActionGeoBlockCountry(id);

      if ((res.status = 200)) {
        let idx = -1;

        idx = countryArr.findIndex((item) => {
          return item._id === id;
        });

        if (idx > -1) {
          countryArr[idx].status = !countryArr[idx].status;
          if (countryArr[idx].status) {
            setMeta({
              ...meta,
              ["totalPublic"]: meta.totalPublic + 1,
              ["totalPrivate"]: meta.totalPrivate - 1,
            });
          } else {
            setMeta({
              ...meta,
              ["totalPublic"]: meta.totalPublic - 1,
              ["totalPrivate"]: meta.totalPrivate + 1,
            });
          }
        }

        setCountryArr([...countryArr]);

        return toast.success(res.data.message);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        return toast.error(err.data.error.message);
      }
      return console.log(error);
    }
  };

  const resetState = () => {
    setCountryCode("");
    setPhoneCode("");
    setCountryName("")
    setIsEdit(false);
    setEditID("")
  }
  const importCountries = async () => {
    try {
      setLoading(true);
      let res: any = await autoImportCountries();

      if (res.status === 200) {
        let query = "order=asc";
        res = await getGeoBlockCountries(query);
        console.log(res);
        if (res.status === 200) {
          setCountryArr(res.data.data);
          setMeta({
            total: res.data.meta.total,
            totalPrivate: res.data.meta.totalPrivate,
            totalPublic: res.data.meta.totalPublic,
          });
        }
        res = await countCountry();
        if (res.status === 200) {
          setCount(res.data.data);
        }

        setLoading(false);
        return toast.success("Countries Fetched Successfully...");
      }
    } catch (error: any) {
      return console.log(error);
    }
  };

  return (
    <>
      <Layout>
        <h1 className="font-medium text-2xl pr-5 mb-3 opacity-80">Countries</h1>
        <Hr />

        {countryArr.length === 0 && count === 0 ? (
          <>
            <div className="mt-40 flex justify-between flex-col items-center">
              <h1 className="text-center text-xl font-semibold mt-5 mb-5">
                No Countries Found
              </h1>
              <Button
                variant="contained"
                className="w-1/4 mx-auto"
                onClick={importCountries}
                disabled={loading}
              >
                {loading ? "Loading..." : "Import Countries"}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-end items-center px-5">
              <div className="flex gap-2 items-center">
                <SearchBar onChange={(e) => setSearch(e.target.value)} />
              </div>
            </div>
            <Hr />
            <div className="w-full">
              {countryArr.length > 0 ? (
                <>
                  <>
                    <div className="overflow-x-auto w-full">
                      <table className="table w-full">
                        <div className="w-full ">
                          <div
                            className={`w-full border-b flex ${darkMode ? "border-[#343434]" : "border-gray-200"
                              }`}
                          >
                            <th
                              className={`flex justify-center bg-transparent  w-[20%] ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } capitalize text-base font-normal`}
                            >
                              Name
                            </th>
                            <th
                              className={`flex justify-center bg-transparent  w-[20%] ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } capitalize text-base font-normal`}
                            >
                              Phone code
                            </th>
                            <th
                              className={`flex justify-center bg-transparent  w-[20%] ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } capitalize text-base font-normal`}
                            >
                              country code
                            </th>
                            <th
                              className={`flex justify-center bg-transparent  w-[20%] ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } capitalize text-base font-normal`}
                            >
                              Status
                            </th>
                            <th
                              className={` bg-transparent  flex justify-center w-[20%] ${darkMode
                                ? "border-[#343434]"
                                : "border-gray-200"
                                } capitalize text-base font-normal`}
                            >
                              Actions
                            </th>
                          </div>
                        </div>

                        <tbody className="w-full">
                          <>
                            <div className="w-full">
                              {countryArr.length > 0 ? (
                                countryArr.map((item: any, index: number) => {
                                  return (
                                    <div key={item._id} className="w-full">
                                      <div
                                        className="w-full border-b border-[#343434] flex"
                                        key={index}
                                      >
                                        <td
                                          className={`justify-center w-[20%] bg-transparent flex items-center ${darkMode
                                            ? "border-[#343434]"
                                            : "border-gray-200"
                                            } capitalize text-base font-normal`}
                                        >
                                          <div className="flex items-center space-x-3">
                                            <div className="font-semibold opacity-80 capitalize text-sm">
                                              {item.name || "N/A"}
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className={`justify-center w-[20%] bg-transparent flex items-center ${darkMode
                                            ? "border-[#343434]"
                                            : "border-gray-200"
                                            } capitalize text-base font-normal`}
                                        >
                                          <div className="flex items-center space-x-3">
                                            <div className="font-semibold opacity-80 capitalize text-sm">
                                              {item.phone_code || "N/A"}
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className={`justify-center w-[20%] bg-transparent flex items-center ${darkMode
                                            ? "border-[#343434]"
                                            : "border-gray-200"
                                            } capitalize text-base font-normal`}
                                        >
                                          <div className="flex items-center space-x-3">
                                            <div className="font-semibold opacity-80 capitalize text-sm">
                                              {item.code || "N/A"}
                                            </div>
                                          </div>
                                        </td>

                                        <td
                                          className={`justify-center bg-transparent flex items-center  w-[20%] ${darkMode
                                            ? "border-[#343434]"
                                            : "border-gray-200"
                                            } capitalize text-base font-normal`}
                                        >
                                          <span>
                                            {item.status ? (
                                              <div className="flex items-center space-x-2">
                                                <div className="w-2 h-2 bg-green-500 rounded-full"></div>
                                                <div className="text-xs text-green-500">
                                                  Public
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="flex items-center space-x-2">
                                                <div className="w-2 h-2 bg-red-500 rounded-full"></div>
                                                <div className="text-xs text-red-500">
                                                  Private
                                                </div>
                                              </div>
                                            )}
                                          </span>
                                        </td>

                                        <td
                                          className={`justify-center bg-transparent w-[20%]  ${darkMode
                                            ? "border-[#343434]"
                                            : "border-gray-200"
                                            } `}
                                        >
                                          <div className="flex justify-center  bg-transparent  w-full">
                                            <label className="cursor-pointer">
                                              {" "}
                                              {item.status ? (
                                                <div
                                                  className="tooltip tooltip-accent"
                                                  data-tip="Public"
                                                >
                                                  <Button
                                                    className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                    variant="text"
                                                    onClick={() => {
                                                      handleCountryAction(
                                                        item._id
                                                      );
                                                    }}
                                                  >
                                                    <VisibilityIcon />
                                                  </Button>
                                                </div>
                                              ) : (
                                                <div
                                                  className="tooltip tooltip-accent"
                                                  data-tip="Private"
                                                >
                                                  <Button
                                                    className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                    variant="text"
                                                    onClick={() => {
                                                      handleCountryAction(
                                                        item._id
                                                      );
                                                    }}
                                                  >
                                                    <VisibilityOffIcon />
                                                  </Button>
                                                </div>
                                              )}
                                            </label>
                                          </div>
                                        </td>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <tr className="w-full flex items-center justify-center">
                                  <p className="text-center py-4">
                                    No data found
                                  </p>
                                </tr>
                              )}
                            </div>
                          </>
                        </tbody>
                      </table>
                    </div>
                  </>
                  <div className="flex justify-between items-center my-1.5 pr-5">
                    <div className="flex gap-8 items-center">
                      <div className="flex gap-3.5">
                        <div className="">Total Country</div>
                        <div className="">{meta.total}</div>
                      </div>
                      <div className="flex gap-3.5">
                        <div className="">Public</div>
                        <div className="">{meta.totalPublic}</div>
                      </div>
                      <div className="flex gap-3.5">
                        <div className="">Private</div>
                        <div className="">{meta.totalPrivate}</div>
                      </div>
                    </div>

                    <div>
                      <div className="btn-group">
                        <button
                          className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? "text-white" : "text-black"
                            }`}
                          onClick={headlePrev}
                        >
                          «
                        </button>
                        <button
                          className={`btn bg-transparent hover:bg-transparent border-none capitalize text-sm ${darkMode ? "text-white" : "text-black"
                            }`}
                        >
                          Page {pagination.page}
                        </button>
                        <button
                          className={`btn bg-transparent hover:bg-transparent border-none ${darkMode ? "text-white" : "text-black"
                            }`}
                          onClick={handleNext}
                        >
                          »
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center my-8">No Countries found</div>
              )}
            </div>
            <Hr />
          </>
        )}
      </Layout>
    </>
  );
};

export default Country;
