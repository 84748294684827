import { useEffect, useState } from "react";
import { InputField } from "../../../../tailwind";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";
import { Checkbox, Switch } from "@mui/material";
import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";
import { updatePaymentGatewaySettingApi } from "../../../../http/settingshttp";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import TvIcon from '@mui/icons-material/Tv';

interface IProps {
    data: IPaymentGatewaySettings;
    darkMode: boolean;
    getPaymentGatewaysQuery: UseQueryResult
}

const UPIPaymentSettings = ({ data, darkMode, getPaymentGatewaysQuery }: IProps) => {
    const [viewKey, setViewKey] = useState<boolean>(false);
    const [viewSecret, setViewSecret] = useState<boolean>(false);
    const [UPIData, setUPIData] = useState<IPaymentGatewaySettings>(data);

    useEffect(() => {
        setUPIData({ ...data, name: "UPI" });
    }, [data]);

    const updatedPaymentGatewaysMutation = useMutation({
        mutationKey: ["updatePaymentGateways"],
        mutationFn: (data: { payment_gateways: IPaymentGatewaySettingsUpdatePayload[] }) => updatePaymentGatewaySettingApi(data, "UPI"),
        onSuccess(data, variables, context) {
            getPaymentGatewaysQuery.refetch();
            toast.success("Payment gateway updated successfully");
        },
        onError(err, variables, context) {
            toast.error("Error while updating payment gateways");
        }
    })

    const handleSave = async () => {
        // remove availablity key from payment gateways
        delete UPIData.availablity;
        delete UPIData.provider;
        delete UPIData.mode;
        delete UPIData.website
        // convert payment gateways to array
        const paymentGatewaysData = [UPIData];
        updatedPaymentGatewaysMutation.mutate({ payment_gateways: JSON.stringify(paymentGatewaysData) as any })
    };

    const getLogo = (provider: "net.one97.paytm" | "com.google.android.apps.nbu.paisa.user" | "com.phonepe.app") => {
        switch (provider) {
            case "net.one97.paytm":
                return "https://res.cloudinary.com/dm4uaqlio/image/upload/v1680759625/Paytm_gzd3hm.png";
            case "com.google.android.apps.nbu.paisa.user":
                return "https://res.cloudinary.com/dm4uaqlio/image/upload/v1680759992/Google_Play_1_o2tcnw.png";
            case "com.phonepe.app":
                return "https://res.cloudinary.com/dm4uaqlio/image/upload/v1680775711/Phone_Pe_js3pcv.png";
            default:
                return "https://res.cloudinary.com/dm4uaqlio/image/upload/v1680759625/Paytm_gzd3hm.png";
        }
    }

    return (
        <div>
            <div className="mt-5 pr-6">
                <div className="flex items-center gap-2">
                    <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                        {UPIData.name}
                    </h1>
                    <span>
                        {UPIData.status ? (
                            <span className="text-green-500">(Active)</span>
                        ) : (
                            <span className="text-red-500">(Inactive)</span>
                        )}
                    </span>
                </div>
                <div>
                    <p className="mt-2 opacity-70">
                        Update your UPI payment gateway settings here.
                    </p>
                </div>
                <div className="my-5 flex flex-col gap-8">
                    <InputField
                        inputType="password"
                        title={"UPI ID"}
                        fieldName={UPIData.provider + "_api_key"}
                        type={viewKey ? "text" : "password"}
                        placeholder={"Enter your UPI ID"}
                        value={UPIData.key}
                        onPasswordVisibilityClick={() => setViewKey(!viewKey)}
                        onChange={(e) => setUPIData({ ...UPIData, key: e.target.value })}
                    />
                    <InputField
                        inputType="password"
                        title={"Merchant Code"}
                        fieldName={UPIData.provider + "_secret_key"}
                        type={viewSecret ? "text" : "password"}
                        placeholder={"Enter your Merchant Code"}
                        value={UPIData.secret}
                        onPasswordVisibilityClick={() => setViewSecret(!viewSecret)}
                        onChange={(e) => setUPIData({ ...UPIData, secret: e.target.value })}
                    />
                    <InputField
                        title={"Merchant Name"}
                        fieldName={UPIData.provider + "_merchant_name"}
                        type={"text"}
                        placeholder={"Enter your Merchant Name"}
                        value={UPIData.merchant}
                        onChange={(e) => setUPIData({ ...UPIData, merchant: e.target.value })}
                    />
                </div>
                <div className="my-3.5">
                    <p className="mt-2 opacity-70">
                        Enable UPI for different platform. like web, android, ios etc.
                    </p>
                    <div className="my-3.5">
                        <div className="flex items-center gap-4">
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <PhoneAndroidIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        Android
                                    </h1>
                                    {
                                        UPIData.availablity?.android ? <Checkbox
                                            checked={UPIData.enabled_on?.android}
                                            onChange={(e: any) => setUPIData({ ...UPIData, enabled_on: { ...UPIData.enabled_on, android: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <PhoneIphoneIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        IOS
                                    </h1>
                                    {
                                        UPIData.availablity?.ios ? <Checkbox
                                            checked={UPIData.enabled_on?.ios}
                                            onChange={(e: any) => setUPIData({ ...UPIData, enabled_on: { ...UPIData.enabled_on, ios: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <LaptopMacIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        Website
                                    </h1>
                                    {
                                        UPIData.availablity?.web ? <Checkbox
                                            checked={UPIData.enabled_on?.web}
                                            onChange={(e: any) => setUPIData({ ...UPIData, enabled_on: { ...UPIData.enabled_on, web: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <TvIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        TV
                                    </h1>
                                    {
                                        UPIData.availablity?.android_tv ? <Checkbox
                                            checked={UPIData.enabled_on?.android_tv}
                                            onChange={(e: any) => setUPIData({ ...UPIData, enabled_on: { ...UPIData.enabled_on, android_tv: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-3.5">
                    <p className="mt-2 opacity-70">
                        Enable UPI Apps like Google Pay, PhonePe, Paytm etc.
                    </p>
                    <div className="my-3.5">
                        <div className="flex items-center gap-4">
                            {
                                UPIData.available_upi_apps && UPIData.available_upi_apps.length > 0 && UPIData.available_upi_apps.map((app, index: number) => {
                                    return <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                        <img src={getLogo(app.package_name)} alt={app.package_name} className="w-20 h-20 object-contain" />
                                        <Checkbox checked={app.status} onChange={(e: any) => {
                                            const available_upi_apps = UPIData.available_upi_apps;
                                            if (!available_upi_apps) return;
                                            available_upi_apps[index].status = e.target.checked;
                                            setUPIData({ ...UPIData, available_upi_apps })
                                        }
                                        } />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <h1 className="text-lg opacity-80 font-semibold tracking-wide">
                        {UPIData.name} Status
                    </h1>
                    <Switch
                        checked={UPIData.status}
                        onChange={(e: any) => setUPIData({ ...UPIData, status: e.target.checked })}
                        name="available"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </div>
            </div>
            <div className="pr-6">
                <div className="flex justify-end mb-20">
                    <LoadingButton
                        loading={updatedPaymentGatewaysMutation.isLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        onClick={() => handleSave()}>
                        Save
                    </LoadingButton>
                </div>
            </div>
        </div >
    );
};

export default UPIPaymentSettings;