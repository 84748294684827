import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteBetaSection,
  sectionDelete,
  sectionStatus,
  updateSectionOrder,
} from "../../http/Apis";
import {
  fetchSliders,
  setBulkActionCall,
} from "../../redux/slice​/sliderSlice";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../components/Droppable/StrictModeDroppable";
import {
  fetchSections,
  ISecctionInitialState,
} from "../../redux/slice​/sectionSlice";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RootState } from "../../redux/store";
import Checkbox from "@mui/material/Checkbox";
import { MuiDialog } from "../../components";
import { IMuiDialog } from "../../Pages/Content/EditContent";
import qs from "qs";

interface ISectionTable {
  data: ISecctionInitialState["data"];
}

const SectionTable = ({ data }: ISectionTable) => {
  const dispatch = useDispatch();
  const [allSelectArray, setallSelectArray] = useState([]);
  const { bulkAction, bulkActionCall } = useSelector(
    (state: RootState) => state.slider
  );
  const { activeSection } = useSelector((state: RootState) => state.section);
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const [singleSelect, setSingleSelect] = useState([]);
  //   const [deleteID, setDeleteID]
  const [status, setStatus] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  });
  const query = qs.stringify({
    page: 1,
    limit: 20,
    category: activeSection,
  });
  // handleStatus
  const handleStatus = async () => {
    const data = {
      isPublished: status,
      id: singleSelect,
    };
    try {
      const response = await sectionStatus(data);
      if (response.status === 200) {
        toast.success("Status updated successfully");
        dispatch(fetchSections() as any);
        handleMuiDialogClose();
        setMuiDialogContant({
          title: "",
          contentText: "",
          actionKey: "",
        });
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  // handle Delete
  const handleDelete = async () => {
    try {
      const response = await sectionDelete({ id: singleSelect });
      if (response.status === 200) {
        toast.success("Slider deleted successfully");
        dispatch(fetchSections(query) as any);

        await deleteBetaSection(singleSelect[0]);

        handleMuiDialogClose();
        setMuiDialogContant({
          title: "",
          contentText: "",
          actionKey: "",
        });
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  // headle checkbox
  const headleCheckbox = (e: any) => {
    const { name: id, checked } = e.target;
    if (id === "selectAll") {
      if (checked) {
        setallSelectArray(data.map((data) => data._id) as any);
      } else {
        setallSelectArray([]);
      }
    } else {
      setallSelectArray((prev: any) => {
        if (checked) {
          return [...prev, id];
        } else {
          return prev.filter((item: any) => item !== id);
        }
      });
    }
  };

  useEffect(() => {
    if (bulkActionCall) {
      headleBulkActions();
      dispatch(setBulkActionCall(false));
    }
  }, [bulkActionCall]);

  // headle bulk actions
  const headleBulkActions = async () => {
    const formData = new FormData();
    if (allSelectArray.length < 1) {
      formData.append("id", allSelectArray as any);
    } else {
      for (let i = 0; i < allSelectArray.length; i++) {
        formData.append("id", allSelectArray[i]);
      }
    }
    const newFormData =
      allSelectArray.length > 0 ? { id: allSelectArray } : formData;

    if (bulkAction === "none") {
      toast.error("Please select bulk action");
    }
    if (bulkAction === "Delete") {
      if (allSelectArray.length === 0) {
        toast.error("Please select at least one item");
      } else {
        try {
          const res = await sectionDelete(newFormData);
          if (res.status === 200) {
            dispatch(fetchSections(query) as any);
            toast.success("Slider deleted successfully");
          }
        } catch (e) {
          toast.error("Something went wrong");
        }
      }
    }
    if (bulkAction === "UnPublish") {
      if (allSelectArray.length === 0) {
        toast.error("Please select at least one item");
      } else {
        const data = {
          isPublished: false,
          id: allSelectArray,
        };
        try {
          const res = await sectionStatus(data);
          if (res.status === 200) {
            dispatch(fetchSections(query) as any);
            toast.success("Status updated");
          }
        } catch (e) {
          toast.error("Something went wrong");
        }
      }
    }
    if (bulkAction === "Publish") {
      if (allSelectArray.length === 0) {
        toast.error("Please select at least one item");
      } else {
        const data = {
          isPublished: true,
          id: allSelectArray,
        };
        try {
          const res = await sectionStatus(data);
          if (res.status === 200) {
            dispatch(fetchSections(query) as any);
            toast.success("Status updated");
          }
        } catch (e) {
          toast.error("Something went wrong");
        }
      }
    }
  };

  interface ISectionData {
    _id: string;
    title: string;
    isPublished: number;
  }

  const [sectionData, updateSectionData] = useState<ISectionData[]>([]);

  useEffect(() => {
    updateSectionData(data as any);
  }, [data]);

  // now update the index in database
  const updateSectionOrderFunc = async (newData: any) => {
    const formData = new FormData();
    for (let i = 0; i < newData.length; i++) {
      formData.append("id", newData[i]._id);
      // order must be string
      formData.append("order", i as any);
    }
    const res = await updateSectionOrder({ order: newData });
    if (res.status === 200) {
      dispatch(fetchSliders(pagination.page, pagination.limit) as any);
      toast.success("Order updated successfully");
    }
  };

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(sectionData as any);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateSectionData(items as any);
    // get item index
    const newData = items.map((item, index) => {
      return {
        ...(item as any),
        index: index + 1,
      };
    });
    console.log(newData);
    updateSectionOrderFunc(newData);
  }
  const [muiDialogContant, setMuiDialogContant] = useState<IMuiDialog>({
    title: "",
    contentText: "",
    buttonOneText: "",
    buttonTwoText: "",
    twoAction: false,
    actionKey: false,
    bulkDeleteData: false,
  });
  // status dialog
  const [muiDialogOpen, setMuiDialogOpen] = useState(false);
  const handleMuiDialogOpen = () => {
    setMuiDialogOpen(true);
  };
  const handleMuiDialogClose = () => {
    setMuiDialogOpen(false);
    setTimeout(() => {
      setMuiDialogContant({
        title: "",
        contentText: "",
      });
    }, 100);
  };

  return (
    <>
      <div className="overflow-x-auto w-full">
        <table className="table w-full">
          <div className="w-full">
            <div
              className={`w-full border-b ${
                darkMode ? "border-[#343434]" : "border-gray-200"
              }`}
            >
              <th className="bg-transparent  w-10 rounded-none">
                <div
                  className="tooltip tooltip-right -ml-2.5"
                  data-tip="Select All"
                >
                  <Checkbox
                    checked={allSelectArray.length === data.length}
                    onChange={headleCheckbox}
                    indeterminate={
                      allSelectArray.length > 0 &&
                      allSelectArray.length < data.length
                    }
                    color="primary"
                    name="selectAll"
                  />
                </div>
              </th>
              <th className="bg-transparent capitalize text-base font-normal">
                Name
              </th>
              <th className="bg-transparent capitalize text-base font-normal"></th>
            </div>
          </div>
          <tbody className="w-full">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <StrictModeDroppable droppableId="sections">
                {(provided: any) => (
                  <>
                    <div
                      className="w-full"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {sectionData.length > 0 ? (
                        sectionData.map((item, index) => {
                          return (
                            <div key={item._id}>
                              <Draggable draggableId={item._id} index={index}>
                                {(provided) => (
                                  <div
                                    className="w-full"
                                    key={index}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <th
                                      className={`bg-transparent border-b ${
                                        darkMode
                                          ? "border-[#343434]"
                                          : "border-gray-200"
                                      } w-10`}
                                    >
                                      <div
                                        className="tooltip tooltip-right -ml-2.5"
                                        data-tip="Select One"
                                      >
                                        <Checkbox
                                          checked={allSelectArray.includes(
                                            item._id as never
                                          )}
                                          onChange={headleCheckbox}
                                          color="primary"
                                          name={item._id}
                                        />
                                      </div>
                                    </th>
                                    <td
                                      className={`bg-transparent border-b ${
                                        darkMode
                                          ? "border-[#343434]"
                                          : "border-gray-200"
                                      } w-full`}
                                    >
                                      <div className="flex items-center space-x-3">
                                        <div>
                                          <div className="font-bold opacity-80 capitalize">
                                            {item.title}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <th
                                      className={`bg-transparent border-b ${
                                        darkMode
                                          ? "border-[#343434]"
                                          : "border-gray-200"
                                      } w-full`}
                                    >
                                      <div className="flex gap-4">
                                        <label
                                          onClick={() => {
                                            setSingleSelect([item._id] as any);
                                            setStatus(
                                              item.isPublished ? false : true
                                            );
                                            handleMuiDialogOpen();
                                            setMuiDialogContant({
                                              title: "Section Status",
                                              contentText:
                                                "Are you sure you want to change the status of this section?",
                                              buttonOneText: "Cancel",
                                              buttonTwoText: "Yes",
                                              actionKey: "statusSection",
                                            });
                                          }}
                                          className="cursor-pointer"
                                        >
                                          {" "}
                                          {item.isPublished ? (
                                            <div
                                              className="tooltip tooltip-accent"
                                              data-tip="Public"
                                            >
                                              <Button
                                                className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                variant="text"
                                              >
                                                <VisibilityIcon className="cursor-pointer" />
                                              </Button>
                                            </div>
                                          ) : (
                                            <div
                                              className="tooltip tooltip-accent"
                                              data-tip="Private"
                                            >
                                              <Button
                                                className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                variant="text"
                                              >
                                                <VisibilityOffIcon className="cursor-pointer" />
                                              </Button>
                                            </div>
                                          )}
                                        </label>

                                        <Link
                                          to={`/admin/sections/edit/${item._id}`}
                                          className="cursor-pointer"
                                        >
                                          <div
                                            className="tooltip tooltip-accent"
                                            data-tip="Edit"
                                          >
                                            <Button
                                              className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                              variant="text"
                                            >
                                              <EditIcon className="cursor-pointer" />
                                            </Button>
                                          </div>
                                        </Link>

                                        <label
                                          onClick={() => {
                                            setSingleSelect([item._id] as any);
                                            handleMuiDialogOpen();
                                            setMuiDialogContant({
                                              title: "Delete Section",
                                              contentText:
                                                "Are you sure you want to delete this section permanently?",
                                              buttonOneText: "Cancel",
                                              buttonTwoText: "Yes",
                                              actionKey: "deleteSection",
                                            });
                                          }}
                                          className="cursor-pointer"
                                        >
                                          <div
                                            className="tooltip tooltip-accent"
                                            data-tip="Delete"
                                          >
                                            <Button
                                              className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                              variant="text"
                                            >
                                              <DeleteIcon className="cursor-pointer" />
                                            </Button>
                                          </div>
                                        </label>
                                      </div>
                                    </th>
                                  </div>
                                )}
                              </Draggable>
                            </div>
                          );
                        })
                      ) : (
                        <tr className="w-full flex items-center justify-center">
                          <p className="text-center py-4">No data found</p>
                        </tr>
                      )}
                      {provided.placeholder}
                    </div>
                  </>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          </tbody>
        </table>
      </div>

      <MuiDialog
        open={muiDialogOpen}
        onClose={handleMuiDialogClose}
        title={muiDialogContant.title}
        contentText={muiDialogContant.contentText}
        onClickClose={handleMuiDialogClose}
        onClickYes={() => {
          if (muiDialogContant.actionKey === "deleteSection") {
            handleDelete();
          } else if (muiDialogContant.actionKey === "statusSection") {
            handleStatus();
          }
        }}
        buttonOneText={muiDialogContant.buttonOneText}
        buttonTwoText={muiDialogContant.buttonTwoText}
      />
    </>
  );
};

export default SectionTable;
