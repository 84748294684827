import { useMutation, useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { FiDelete } from 'react-icons/fi'
import { RootState } from '../../redux/store'
import { createImageSize, deleteImageSize, getImageSizes } from '../../http/contents.http'
import { Loader } from '../../components/smallComponents/loader'
import { Hr } from '../../components'
import Layout from '../../components/Layout/Layout'
import Dialogs from "../../components/Mui/Dialogs/Dialog"
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Dialog, DialogTitle, IconButton, styled, DialogActions, DialogContent, } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { InputField } from '../../tailwind'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

interface ICreatePayload {
    name: string
    width: number
    height: number
}

function ContentImagesSettings() {
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
    const [deleteId, setDeleteId] = useState<string>('')
    const [createDialog, setCreateDialog] = useState<boolean>(false)
    const [imageSize, setImageSize] = useState<ICreatePayload>({
        name: '',
        width: 0,
        height: 0,
    })
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const contentImagesQuery = useQuery<IContentImagesResponse>({
        queryKey: ['contentImagesSettings'],
        queryFn: () => getImageSizes()
    })

    // detele mutation
    const deleteMutation = useMutation({
        mutationKey: ['deleteImageSize'],
        mutationFn: (id: string) => deleteImageSize(id),
        onSuccess() {
            toast.success('Image Size Deleted Successfully')
            setDeleteDialog(false)
            contentImagesQuery.refetch()
        },
        onError(error: any, variables, context) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
        },
    })

    function triggerDelete() {
        deleteMutation.mutate(deleteId)
    }

    // create mutation
    const createMutation = useMutation({
        mutationKey: ['createImageSize'],
        mutationFn: (payload: {
            name: string
            width: number
            height: number
        }) => createImageSize(payload),
        onSuccess() {
            toast.success('Image Size Created Successfully')
            setCreateDialog(false)
            contentImagesQuery.refetch()
        },
        onError(error: any, variables, context) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
        },
    })

    function triggerCreate() {
        if (imageSize.name === '') return toast.error('Name is required')
        if (imageSize.width === 0) return toast.error('Width is required')
        if (imageSize.height === 0) return toast.error('Height is required')
        // height and width should be number
        if (isNaN(imageSize.width)) return toast.error('Width should be number')
        createMutation.mutate({
            name: imageSize.name,
            width: imageSize.width,
            height: imageSize.height,
        })
    }


    return (
        <Layout>
            <div className="pr-6">
                <div className='flex justify-between items-end'>
                    <div>
                        <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                            Images Sizes
                        </h1>
                        <p className="mt-2 opacity-70">
                            Update your Images Sizes here.
                        </p>
                    </div>
                    <div>
                        <div
                            onClick={() => setCreateDialog(true)}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer`}>
                            <h1 className='text-sm text-white'>
                                Create
                            </h1>
                        </div>
                    </div>
                </div>

            </div>
            <div className="my-5">
                <Hr />
            </div>

            {contentImagesQuery.isLoading && (
                <div className='w-full flex justify-center items-center min-h-[47rem]'>
                    <Loader smail />
                </div>
            )}
            {contentImagesQuery.isError && (
                <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                    <h1 className='text-xl font-medium opacity-90'>
                        Server Error. Please try again
                    </h1>
                    <div
                        onClick={() => contentImagesQuery.refetch()}
                        className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                        <h1 className='text-sm text-white'>
                            Retry
                        </h1>
                    </div>
                </div>
            )}
            {contentImagesQuery.isSuccess && (
                <>
                    {
                        contentImagesQuery.data.data.length === 0 && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                            <h1 className='text-xl font-medium opacity-90'>
                                No Images Sizes Found
                            </h1>
                            <div
                                onClick={() => setCreateDialog(true)}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                <h1 className='text-sm text-white'>
                                    Create
                                </h1>
                            </div>
                        </div>
                    }
                    {
                        contentImagesQuery.data.data.length > 0 && <div className='grid grid-cols-4 gap-5'>
                            {contentImagesQuery.data.data.map((imageSize, index) => {
                                return (
                                    <div key={index} className={`w-72 h-72 border-blue-500 border-4 relative`}>
                                        <div className='w-full h-full hover:bg-blue-500 hover:bg-opacity-50 duration-500 -z-10 cursor-pointer transition-all absolute'>
                                            <div className='opacity-100 hover:opacity-0 duration-100 absolute inset-0 z-10 flex items-center justify-center w-full h-full'>
                                                <p className='text-sm'>
                                                    {imageSize.name} ({imageSize.width}x{imageSize.height})
                                                </p>
                                            </div>
                                        </div>
                                        <div className='w-full h-full hover:bg-blue-500 duration-500 cursor-pointer transition-all absolute'>
                                            <div className='opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-50 flex items-center justify-center w-full h-full'>
                                                <FiDelete
                                                    onClick={() => {
                                                        setDeleteDialog(true)
                                                        setDeleteId(imageSize._id)
                                                    }}
                                                    className='text-red-500 text-3xl'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </>
            )}

            <Dialogs
                open={deleteDialog}
                title="Delete Image Size"
                onClose={() => setDeleteDialog(false)}
                onClickClose={() => setDeleteDialog(false)}
                buttonOneText='Cancel'
                buttonTwoText='Delete'
                loading={deleteMutation.isLoading}
                contentText='Are you sure you want to delete this image size?'
                onClickYes={triggerDelete}
            />

            <div>
                <BootstrapDialog
                    aria-labelledby="customized-dialog-title"
                    open={createDialog}>
                    <BootstrapDialogTitle id="customized-dialog-title"
                        onClose={() => setCreateDialog(false)}>
                        Create New Image Size
                    </BootstrapDialogTitle>
                    <DialogContent dividers className='w-full'>
                        <div className='w-[30rem] flex flex-col gap-4'>
                            <InputField
                                fieldName='Name'
                                label='Name'
                                placeholder='Name'
                                type='text'
                                required
                                value={imageSize.name}
                                onChange={(e) => setImageSize({ ...imageSize, name: e.target.value })}
                            />
                            <InputField
                                fieldName='width'
                                label='width'
                                placeholder='width'
                                type='number'
                                required
                                value={imageSize.width}
                                onChange={(e) => setImageSize({ ...imageSize, width: parseInt(e.target.value) })}
                            />
                            <InputField
                                fieldName='height'
                                label='height'
                                required
                                placeholder='height'
                                type='number'
                                value={imageSize.height}
                                onChange={(e) => setImageSize({ ...imageSize, height: parseInt(e.target.value) })}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            autoFocus
                            loading={createMutation.isLoading}
                            variant="contained"
                            disabled={createMutation.isLoading}
                            onClick={triggerCreate}>
                            Create
                        </LoadingButton>
                    </DialogActions>
                </BootstrapDialog>
            </div>

        </Layout>
    )
}

export default ContentImagesSettings