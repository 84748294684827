// Author: Pukhraj Dhamu
// Date Created: 24 June 2022 (Friday)
// Date Modified: Sunday 21 May 2023 at 7:32 PM
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getAppSettings, getSettings } from '../../http/Apis';
import { CustomLocalstorege } from '../../localstorege';

export interface IAPIStatus {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed'
}

export const STATUS: IAPIStatus = {
    IDLE: 'idle',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    LOADING: 'loading',
}

export interface IConfigInitialState {
    config: IWebSettings
    status: IAPIStatus[keyof IAPIStatus]
    appConfig: {
        _id: string
        splesh_screen: {
            background_color: string,
            screen_time: number,
            logoSize: number,
            splesh_logo: string
        },
        logo: string,
        data: {
            ipinfo_api_key: string
        }
        createdAt: string,
        updatedAt: string,
    }
}

const initialState: IConfigInitialState = {
    config: {} as IConfigInitialState['config'],
    status: STATUS.IDLE,
    // transcoderConfig: {} as IConfigInitialState['transcoderConfig'],
    appConfig: {
        _id: '',
        splesh_screen: {
            background_color: '',
            screen_time: 0,
            logoSize: 0,
            splesh_logo: ''
        },
        logo: '',
        data: {
            ipinfo_api_key: ''
        },
        createdAt: '',
        updatedAt: ''
    }
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfig: (state, action: PayloadAction<IConfigInitialState['config']>) => {
            state.config = action.payload
        },
        setAppConfig: (state, action: PayloadAction<IConfigInitialState['appConfig']>) => {
            state.appConfig = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        }
    }
});

export const { setConfig, setStatus, setAppConfig } = configSlice.actions;

export default configSlice.reducer;

// redux thunk api call
export function fetchConfig() {
    return async (dispatch: any) => {
        try {
            // get old data from localstorege and set to redux
            const olddata = CustomLocalstorege.getItem("web_settings")
            if (olddata) dispatch(setConfig(olddata))
            dispatch(setStatus({ status: STATUS.LOADING }))
            const { data } = await getSettings()
            dispatch(setConfig(data.data))
            // set new data to localstorege and set to redux
            CustomLocalstorege.setItem("web_settings", data.data)
            dispatch(setStatus({ status: STATUS.SUCCEEDED }))
        } catch (error) {
            // get old data from localstorege and set to redux
            const olddata = CustomLocalstorege.getItem("web_settings")
            if (olddata) dispatch(setConfig(olddata))
            dispatch(setStatus({ status: STATUS.FAILED }))
        }
    }
}

export const fetchAppConfig = () => async (dispatch: any) => {
    try {
        dispatch(setStatus({ status: STATUS.LOADING }))
        const { data } = await getAppSettings()
        dispatch(setAppConfig(data.data))
        dispatch(setStatus({ status: STATUS.SUCCEEDED }))
    } catch (error) {
        console.log(error)
        dispatch(setStatus({ status: STATUS.FAILED }))
    }
}
