// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import moment from 'moment';
import { useState } from 'react'
import { toast } from 'react-hot-toast';
import { updateAvatarEndpoint } from '../../../http';
import { Button } from '@mui/material';
import { ProfileIcon } from '../../../tailwind/Icon';
import { UseQueryResult } from '@tanstack/react-query';

interface IProps {
    id: string;
    user: IUsersResponse['data'][0]
    userQuery: UseQueryResult
}
function UserAvatar({ id, user }: IProps) {
    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState<{
        file: File | null;
        url: string;
      }>();

    const UpdateAvatar = async () => {
        const data = new FormData();
        if (!avatar?.file) return toast.error("Please select an image");
        data.append("avatar", avatar?.file as any);
        try {
          setLoading(true);
          const res = await updateAvatarEndpoint(id, data);
          if (res.status === 200) {
            setLoading(false);
            toast.success("Avatar updated successfully");
          }
        } catch (error) {
          setLoading(false);
          toast.error("Avatar update failed");
        }
      };

    return (
        <div className="flex flex-col w-full items-center">
            <h1 className="text-1xl font-semibold tracking-wider capitalize">
                {user?.name || "no name"}
            </h1>
            <span className="text-sm my-2">
                {user?.lastActivity
                    ? moment(user?.lastActivity).fromNow()
                    : "No activity yet"}{" "}
            </span>
            <label htmlFor="avatarImage" className="cursor-pointer">
                <div className="mt-6">
                    {avatar?.file ? (
                        <img
                            className="w-40 h-40 rounded-full border-2 object-contain"
                            src={avatar.url}
                            alt="avatar"
                        />
                    ) : user?.avatar ? (
                        <img
                            className="w-40 h-40 rounded-full border-2 object-contain"
                            src={user.avatar}
                            alt="avatar"
                        />
                    ) : (
                        <div className="w-40 h-40 rounded-full border-2 object-contain overflow-hidden">
                            <ProfileIcon width={160} height={160} />
                        </div>
                    )}
                </div>
                <input
                    onChange={(e: any) => {
                        let event = e.target.files[0];
                        let urlEvent = URL.createObjectURL(e.target.files[0]);
                        setAvatar({
                            file: event,
                            url: urlEvent,
                        });
                    }}
                    accept="image/*"
                    id="avatarImage"
                    type="file"
                    name="avatar"
                    className="hidden"
                />
            </label>
            <div className="mt-4">
                <Button
                    variant="contained"
                    color="success"
                    size="small"
                    className="w-40 h-10"
                    disabled={loading}
                    onClick={UpdateAvatar}
                >
                    {loading ? "Updating..." : "Update Avatar"}
                </Button>
            </div>
            <div className="w-full bg-dark-color-2 py-4 px-5 mt-4 rounded shadow border border-gray-400">
                <h1 className="text-center text-sm font-thin">
                    Upload a new avatar. Larger image will be resized
                    automatically.
                </h1>
                <h1 className="text-center text-sm font-thin mt-3">
                    Maximum upload size is 1MB
                </h1>
            </div>
            <h1 className="text-center text-sm font-thin mt-3">
                Member Since:{" "}
                <span className="font-bold">
                    {moment(user.createdAt).format("MMM Do YY")}
                </span>
            </h1>
        </div>
    )
}

export default UserAvatar
