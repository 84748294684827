import * as React from "react";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(
  event: React.MouseEvent<Element, MouseEvent>,
  setFolderPrefix: any,
  idxBreadArr: any,
  breadArr: string[],
  index: number
) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
  console.log(event.currentTarget.textContent);

  let x = event.currentTarget.textContent
    ? event.currentTarget.textContent
    : "";
  //   let idx = idxBreadArr.lastIndexOf(x);
  //   setFolderPrefix(breadArr[idx]);
  console.log(index);

  //   console.log(idxBreadArr);
  //   console.log(breadArr);

  //   while (idx-- > 0) {
  //     idxBreadArr.pop();
  //     breadArr.pop();
  //   }

  //   idxBreadArr.splice(idx);
  //   breadArr.splice(idx);

  let idx = idxBreadArr.findIndex((item: any) => {
    return index === item.id;
  });

  console.log(idx);

  console.log(idxBreadArr);
  console.log(breadArr);
  if (idx !== idxBreadArr.length - 1) {
    setFolderPrefix(breadArr[idx]);
    idxBreadArr.splice(idx + 1);
    breadArr.splice(idx + 1);
  }
  //   console.log(idxBreadArr);

  //   console.log(idxBreadArr.slice(idx));
  // console.log(breadArr.slice(idx));

  // now get onclick text on click
  //   console.log(event.currentTarget);
  //   // give only left side of click
  //   console.log(event.currentTarget.parentElement);
  //   // give all left side of click
  //   console.log(event.currentTarget.parentElement?.children);
}

interface IBreadcrumbsProps {
  data: string;
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  setFolderPrefix?: any;
  breadArr: string[];
  idxBreadArr: any;
}

export default function CustomizedBreadcrumbs({
  data,
  onClick,
  setFolderPrefix,
  idxBreadArr,
  breadArr,
}: IBreadcrumbsProps) {
  // prefix data split by '/' create array
  const prefix = data.split("/").filter((item) => item !== "");

  return (
    <div
      role="presentation"
      // onClick={handleClick}
    >
      <Breadcrumbs maxItems={6} aria-label="breadcrumb">
        <StyledBreadcrumb
          component="a"
          href="#"
          label="Home"
          icon={<HomeIcon fontSize="small" />}
          onClick={onClick}
        />

        {prefix.length > 0 &&
          prefix.map((item, index) => {
            return (
              <StyledBreadcrumb
                key={index}
                component="a"
                href="#"
                label={item}
                onClick={(e) => {
                  handleClick(e, setFolderPrefix, idxBreadArr, breadArr, index);
                }}
                //icon={<ExpandMoreIcon fontSize="small" />}
              />
            );
          })}
      </Breadcrumbs>
    </div>
  );
}
