import { BiRefresh, BiArrowBack } from 'react-icons/bi'
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import { useDebounce } from 'use-debounce';
import Layout from '../../components/Layout/Layout'
import { Loader } from '../../components/smallComponents/loader';
import { categorySearch, createBetaSection, createSection, getCategories, getContent } from '../../http/Apis';
import { SelectField, TextAreaField } from '../../tailwind';
import { HiOutlinePlusSm } from 'react-icons/hi'
import { Link } from 'react-router-dom';
import RouteStrings from "../../localData/routes.json";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';


export interface ISectionDataType {
  name: string;
  _id: string;
}
// normal => Card
export const sectionTypeData: ISectionDataType[] = [
  {
    name: "Card",
    _id: "normal",
  },
  {
    name: "Slider",
    _id: "slider",
  },
  {
    name: "Continue Watching",
    _id: "continue_watching",
  },
  {
    name: "New Release",
    _id: "new_releases",
  }
];

const CreateSection = () => {
  const [sectionList, setSectionList] = React.useState<any[]>([]);
  const [sectionListDrag, setSectionListDrag] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value] = useDebounce(search, 1000);
  const { darkMode } = useSelector((state: RootState) => state.theme);

  // fetch all the content from the database useing useQuery
  const { data: content, refetch, isLoading, isFetching, isError } = useQuery({
    queryKey: ['content'],
    queryFn: () => getContent(`${value ? `keyword=${value}` : ''}`),
  });

  // get categories from server
  const { data: categories, isLoading: categoryIsLoading, refetch: categoryRefetch } = useQuery<IGetCategoriesForAxios>({
    queryKey: ['categories'],
    queryFn: () => getCategories(),
  });

  useEffect(() => {
    if (value) refetch();
  }, [value])

  interface ISectionData {
    title: string;
    type: ISectionDataType;
    content: any[];
    category: any;
  }

  const [sectionData, setSectionData] = React.useState<ISectionData>({
    title: "",
    type: sectionTypeData[0],
    content: [],
    category: "",
  });

  const onCategorySearch = async (search: string) => {
    const { data } = await categorySearch(search);
    return data.data;
  };

  const categoryPromiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(onCategorySearch(inputValue));
      }, 1000);
    });

  function handleOnDragEnd(result: any) {
    //console.log("🚀 ~ file: AddNewSection.jsx ~ line 90 ~ handleOnDragEnd ~ result", result)
    if (!result.destination) return;

    // if sectionTypeData === continue_watching then return
    if (sectionData.type._id === "continue_watching") {
      toast.error("Continue watching section not allow to add content");
      return;
    }

    const { source, destination } = result;
    // result push to setSectionList if result.destination.droppableId === 'sectionList'
    if (destination.droppableId === "sectionList") {
      // if result.draggableId includes already in sectionList then return
      if (sectionList.includes(result.draggableId as never)) {
        toast.error("Already added in section list");
        return;
      }
      // console.log("🚀 ~ file: AddNewSection.tsx:148 ~ handleOnDragEnd ~ result.draggableId.length", {
      //     resultDraggableIdLength: result.draggableId.length,
      //     draggableId: result.draggableId
      // })

      // draggableId more then 25 character then return
      if (result.draggableId.length > 25) {
        // now reodering sectionList
        const items = Array.from(sectionList);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);

        setSectionList(items);

        // now reodering sectionListDrag
        const itemsDrag = Array.from(sectionListDrag);
        const [reorderedItemDrag] = itemsDrag.splice(source.index, 1);
        itemsDrag.splice(destination.index, 0, reorderedItemDrag);
        setSectionListDrag(itemsDrag);
        return;
      }
      // else unshift result.draggableId in sectionList
      setSectionList([result.draggableId, ...sectionList]);

      // setSectionList([...sectionList, result.draggableId])
      // find sectionDataDrag by result.draggableId and push to sectionListDrag
      const findSectionDataDrag = content.data.find((item: any) => {
        //console.log("🚀 ~ file: AddNewSection.jsx ~ line 96 ~ findSectionDataDrag ~ item", item)
        return item._id === result.draggableId;
      });
      // push new sectionListDrag in first index
      setSectionListDrag([findSectionDataDrag, ...sectionListDrag]);

      //setSectionListDrag([...sectionListDrag, findSectionDataDrag])
    }
    // if result.destination.droppableId === 'sectionContent' then remove from sectionList
    if (destination.droppableId === "sectionContent") {
      // remove from sectionList
      const newSectionList = sectionListDrag.filter((item) => {
        return item.thumbnail !== result.draggableId;
      });
      setSectionList(newSectionList);
      // remove from sectionListDrag
      const newSectionListDrag = sectionListDrag.filter((item) => {
        return item.thumbnail !== result.draggableId;
      });
      setSectionListDrag(newSectionListDrag);
    }

    // const items = Array.from(sectionDataDrag);
    // const [reorderedItem] = items.splice(result.source.index, 1);
    // items.splice(result.destination.index, 0, reorderedItem);
    // updateSectionDataDrag(items);
  }

  const handleSubmit = async () => {
    if (sectionData.title.length < 1) {
      toast.error("Section title is required");
      return;
    }

    const updateData = {
      title: sectionData.title,
      type: sectionData?.type?._id,
      category: sectionData.category?._id,
      content: sectionList,
    };

    setLoading(true);
    try {
      const res = await createSection(updateData);
      if (res.status === 201) {
        toast.success("Section created successfully");

        const betaObj = {
          sectionid: res.data.data._id,
          contentid: sectionList,
        };
        await createBetaSection(betaObj);
        setLoading(false);
      }
    } catch (error: any) {
      toast.error(error.response.data.error.message || "Something went wrong");
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className='pr-6 pb-10'>
        <div className='bg-opacity-25 rounded-xl overflow-hidden'>
          <div className='px-1 py-4 flex justify-between items-center'>
            <h1 className='text-xl font-medium opacity-90'>
              Add a Section
            </h1>
            <div className='flex gap-4 items-center'>
              <Link to={RouteStrings.OldSections.Link}>
                <div
                  className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                  <BiArrowBack className='text-black text-lg bg-white rounded-full' />
                  <h1 className='text-sm text-white'>
                    Back to Section
                  </h1>
                </div>
              </Link>
              <div
                onClick={() => {
                  categoryRefetch()
                  refetch()
                }}
                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                <BiRefresh className='text-black text-lg bg-white rounded-full' />
                <h1 className='text-sm text-white'>
                  Refresh
                </h1>
              </div>
              <div
                onClick={() => {
                  if (loading) return
                  handleSubmit()
                }}
                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                {loading ?
                  <div className={`w-full flex justify-center items-center`}>
                    <Loader
                      smail
                      color='border-white'
                    />
                  </div> : <>
                    <HiOutlinePlusSm className='text-black text-lg bg-white rounded-full' />
                    <h1 className='text-sm text-white'>
                      Create
                    </h1>
                  </>}
              </div>
            </div>
          </div>
          <div className='w-full min-h-[35rem]'>
            <div className="grid grid-cols-2 gap-4">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="sectionList" type="CONTENT">
                  {(provided: any) => (
                    <div className="pr-5">
                      <div className="flex flex-col gap-4">
                        <input
                          type="text"
                          placeholder="Section Title"
                          className={`input input-bordered input-md w-full bg-transparent border-gray-500 text-lg focus:outline-none rounded ${!darkMode ? "placeholder:text-black" : "placeholder:text-white"}`}
                          value={sectionData.title}
                          onChange={(e: any) => setSectionData({ ...sectionData, title: e.target.value })}
                        />
                        <SelectField
                          options={sectionTypeData}
                          onChange={(e) => {
                            console.log(e);
                            if (e._id === "continue_watching") {
                              setSectionList([]);
                              setSectionListDrag([]);
                            }
                            if (e.name === "Slider" && sectionList.length > 8) {
                              let splicedListArr = sectionList.splice(0, 8);
                              let splicedDragArr = sectionListDrag.splice(0, 8);
                              setSectionList([...splicedListArr]);
                              setSectionListDrag([...splicedDragArr]);
                            }
                            setSectionData({ ...sectionData, type: e });
                          }}
                          defaultValue={sectionData.type as any}
                          placeholder={"Select section type"}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) =>
                            option.id ? option.id : option._id
                          }
                          inputTypeColor="white"
                          borderColor='#6B7280'
                          borderColorFocus='#6B7280'
                          borderDarkMode='#6B7280'
                        />
                        <SelectField
                          options={categories?.data.data}
                          onChange={(e) => {
                            setSectionData({ ...sectionData, category: e });
                          }}
                          promiseOptions={categoryPromiseOptions}
                          defaultValue={sectionData.category as any}
                          placeholder={"Select category"}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) =>
                            option.id ? option.id : option._id
                          }
                          inputTypeColor="white"
                          borderColor='#6B7280'
                          borderColorFocus='#6B7280'
                          borderDarkMode='#6B7280'
                        />

                        <div className="w-full h-fit overflow-scroll rounded">
                          <div
                            className='flex flex-col gap-2 mt-2'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {sectionListDrag.map((item, index) => (
                              <Draggable
                                key={item.thumbnail}
                                draggableId={item.thumbnail}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className={`${darkMode ? 'bg-dark-color-box' : 'bg-[#dedede]'} px-2.5 rounded`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="flex gap-3 my-2.5">
                                      <img
                                        src={`${item.thumbnail}`}
                                        alt={item.name}
                                        loading="lazy"
                                        className={`w-44 aspect-video rounded overflow-hidden object-contain ${!darkMode ? 'bg-dark-color-box' : 'bg-[#dedede]'} `}
                                      />
                                      <div className='w-full'>
                                        <p className="line-clamp-1 capitalize">{
                                          item.name.length > 20 ? item.name.substring(0, 20) + "..." : item.name
                                        }</p>
                                        <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'} line-clamp-2 text-sm text-opacity-80`}>
                                          {item.description.length > 80
                                            ? item.description.substring(0, 80) +
                                            "..."
                                            : item.description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {sectionList.length === 0 && (
                              <div className="flex justify-center items-center h-[400px]">
                                <p className="w-[80%] text-center">
                                  Right side is the section content and left side is
                                  the content list to add in the section content. You
                                  can drag and drop the content from left side to
                                  right side.
                                </p>
                              </div>
                            )}
                            {provided.placeholder}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end mt-5">
                      </div>
                    </div>
                  )}
                </Droppable>
                <Droppable droppableId="sectionContent" type="CONTENT">
                  {(provided: any) => (
                    <div className="pr-5 w-full">
                      <div className="w-full min-h-screen overflow-auto">
                        <div className="flex justify-between items-center h-[47px] rounded border border-gray-500">
                          <input
                            type="text"
                            placeholder="Search Content by name, description, tags, slug, etc..."
                            className={`input input-md w-full bg-transparent text-lg focus:outline-none ${!darkMode ? "placeholder:text-black" : "placeholder:text-white"}`}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <div className="mr-5">
                            {isFetching && <Loader smail />}
                          </div>
                        </div>
                        <div className='flex flex-col gap-2 mt-2' {...provided.droppableProps} ref={provided.innerRef}>
                          {content && content.data.length > 0 ?
                            content.data.map((item: any, index: number) => (
                              <Draggable
                                key={index}
                                draggableId={item._id}
                                index={index}>
                                {(provided) => (
                                  <div
                                    className={`${darkMode ? 'bg-dark-color-box' : 'bg-[#dedede]'} px-2.5 rounded`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="flex gap-3 my-2.5">
                                      <img
                                        src={`${item.thumbnail}`}
                                        alt={item.name}
                                        loading="lazy"
                                        className={`w-44 aspect-video rounded overflow-hidden object-contain ${!darkMode ? 'bg-dark-color-box' : 'bg-[#dedede]'} `}
                                      />
                                      <div className='w-full'>
                                        <p className="line-clamp-1 capitalize">{
                                          item.name.length > 20 ? item.name.substring(0, 20) + "..." : item.name
                                        }</p>
                                        <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'} line-clamp-2 text-sm text-opacity-80`}>
                                          {item?.description && item.description.length > 80
                                            ? item?.description?.substring(0, 80) +
                                            "..."
                                            : item?.description}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            )) : <>
                              <div className="flex justify-center items-center h-[400px]">
                                <div className='min-h-[35rem] justify-center flex flex-col items-center'>
                                  <h1 className='text-xl font-medium opacity-90'>
                                    No Content Found!
                                  </h1>
                                  <div
                                    onClick={() => refetch()}
                                    className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                    <h1 className='text-sm text-white'>
                                      Refresh
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                          {provided.placeholder}
                        </div>
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CreateSection