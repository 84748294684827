// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import { useSelector } from "react-redux"
import { TailwindBox } from "../../../../tailwind"
import { RootState } from "../../../../redux/store"
import { useQuery } from "@tanstack/react-query"
import { BiRefresh } from "react-icons/bi"
import { Link } from "react-router-dom"
import moment from "moment"
import { IUsers } from "../../../../redux/slice​/userSlice"
import { Avatar } from "@mui/material"
import { blue } from "@mui/material/colors"
import { getUsers } from "../../../../http/users.http"
import qs from "qs"

const USERLIMIT = 5

const NewUsersCard = () => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    // fetch content query
    const userFetchQuery = qs.stringify({
        limit: USERLIMIT,
        order: "desc",
        fields: ["name", "email", "phone", "avatar", "createdAt"]
    }, { encodeValuesOnly: true })
    const getUsersQuery = useQuery<{ data: IUsers['data'] }>({
        queryKey: ["newUsers"],
        queryFn: () => getUsers(userFetchQuery)
    })
    return (
        <TailwindBox title="New Users">
            {
                getUsersQuery.isLoading && <div>
                    <p className="text-xs  opacity-80 mt-2">New {USERLIMIT} users:</p>
                    <div>
                        {new Array(5).fill("").map((user, index) => (
                            <div key={index} className="flex items-center justify-between mt-5">
                                <div className="flex items-center">
                                    <Avatar
                                        sx={{ bgcolor: blue[500], textTransform: "uppercase" }}
                                        alt={user.name || user.email || "N/A"}
                                        src={user.avatar}
                                    />
                                    <div className="ml-3">
                                        <div className={`h-3 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-20`} />
                                        <div className={`h-2 mt-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-28`} />
                                    </div>
                                </div>
                                <div className={`ml-3 h-2 mt-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-16`} />
                            </div>
                        ))}
                    </div>
                    <div className={`h-7 mt-7 mb-5 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-32`} />
                </div>
            }
            {
                getUsersQuery.isError && <div>
                    <div className="w-full h-full flex justify-center items-center mb-3">
                        <div className='min-h-[15rem] justify-center flex flex-col items-center'>
                            <h1 className='text-sm font-medium opacity-90'>
                                Server Error. Please try again
                            </h1>
                            <div
                                onClick={() => getUsersQuery.refetch()}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                <BiRefresh className={`text-black text-lg bg-white rounded-full ${getUsersQuery.isLoading || getUsersQuery.isFetching && "animate-spin"}`} />
                                <h1 className='text-sm text-white'>
                                    Refresh
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                getUsersQuery.isSuccess && <div>
                    <p className="text-xs  opacity-80 mt-2">New {USERLIMIT} users:</p>
                    <div>
                        {getUsersQuery.data?.data?.map((user, index) => (
                            <div key={index} className="flex items-center justify-between mt-5">
                                <div className="flex items-center">
                                    <Avatar
                                        sx={{ bgcolor: blue[500], textTransform: "uppercase" }}
                                        alt={user.name || user.email || "N/A"}
                                        src={user.avatar}
                                    />
                                    <div className="ml-3">
                                        <h1 className=" text-sm font-semibold opacity-90 tracking-wide">
                                            {user.name || "N/A"}
                                        </h1>
                                        <p className="text-xs  opacity-80">
                                            {user.email || user.phone}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="ml-3">
                                        <p className="text-xs  opacity-80">
                                            {moment(user.createdAt).format("MMM Do YY")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-7 mb-5">
                        <Link to="/admin/users">
                            <button className="uppercase text-blue-400 font-semibold ">
                                go to users
                            </button>
                        </Link>
                    </div>
                </div>
            }
        </TailwindBox>
    )
}

export default NewUsersCard