// Author: Pukhraj Dhamu
// Date Created: 24 June 2022 (Friday)
// Date Modified: Sunday 14 May 2023 at 7:26 PM
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import country from "../../localData/country.json";
import { InputField, SelectField, TagField, TextAreaField } from "../../tailwind";
import AsyncSelect from "react-select/async";
import { addContentForBock } from "../../http/Apis";
import toast from "react-hot-toast";
import {
  castSearch,
  categorySearch,
  contantCreateSeason,
  contantSeasonsDelete,
  genreSearch,
  getCastsEndPoint,
  getCategories,
  getGenres,
  getGeoBlockCountries,
  getLanguagesEndPoint,
  languageSearch,
} from "../../http/Apis";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ImageField } from "../../tailwind/Input/inputField";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import qs from "qs";
import { fetchAllContant } from "../../redux/slice​/contentSlice";
import {
  BasicTabs,
  ContentAnalytics,
  FormDialog,
  MuiDialog,
} from "../../components";
import moment from "moment";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { BuyOrRent, ContentSubtitle } from "./components";
import TVOD from "../../components/analytics/TVOD";
import ContentImages from "./components/ContentImages";

export interface IMuiDialog {
  title: string;
  contentText: string;
  buttonOneText?: string;
  buttonTwoText?: string;
  twoAction?: boolean;
  actionKey?: boolean | string;
  bulkDeleteData?: any;
  buttonTwoTitle?: string;
}

const EditContentSchema = Yup.object().shape({
  name: Yup.string()
    .required("Title is required")
    .min(2, "Title is too short")
    .max(100, "Title is too long"),
  description: Yup.string()
    .optional()
    .max(5000, "Description is too long"),
  uacertificate: Yup.string().optional(),
  genres: Yup.array().default([]),
  casts: Yup.array().default([]),
  rating: Yup.string().default("0"),
  duration: Yup.number().optional(),
  tags: Yup.array().default([]),
  thumbnail: Yup.mixed()
    .optional()
    .test("fileSize", "File too large limit is 1mb", (value: any) => {
      if (!value) return true;
      return value && value.size <= 1024 * 1024 * 1; // 1mb
    })
    .test(
      "fileFormat",
      "Unsupported Format. try JPEG, PNG, JPG, WEBP",
      (value: any) => {
        if (!value) return true;
        return (
          value &&
          ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(
            value.type
          )
        );
      }
    ),
  poster: Yup.mixed()
    .optional()
    .test("fileSize", "File too large limit is 1mb", (value: any) => {
      if (!value) return true;
      return value && value.size <= 1024 * 1024 * 1; // 1mb
    })
    .test(
      "fileFormat",
      "Unsupported Format. try JPEG, PNG, JPG, WEBP",
      (value: any) => {
        if (!value) return true;
        return (
          value &&
          ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(
            value.type
          )
        );
      }
    ),
  titleImage: Yup.mixed()
    .optional()
    .test("fileSize", "File too large limit is 1mb", (value: any) => {
      if (!value) return true;
      return value && value.size <= 1024 * 1024 * 1; // 1mb
    })
    .test(
      "fileFormat",
      "Unsupported Format. try JPEG, PNG, JPG, WEBP",
      (value: any) => {
        if (!value) return true;
        return (
          value &&
          ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(
            value.type
          )
        );
      }
    ),
});

const EditContent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { single } = useSelector((state: RootState) => state.content);
  const { darkMode } = useSelector((state: RootState) => state.theme);
  // const params = useParams();

  const queryString = qs.stringify({ id: id });
  useEffect(() => {
    dispatch(fetchAllContant(queryString, "single") as any);
  }, [id]);
  const [loading, setLoading] = React.useState(false);
  const [seasonName, setSeasonName] = React.useState<string>();
  const [singleSelect, setSingleSelect] = useState<string>();

  // delete handle remove season
  const handleRemoveSeason = async () => {
    if (!singleSelect) return toast.error("Something went wrong");
    try {
      setLoading(true);
      const response = await contantSeasonsDelete(singleSelect);
      if (response.status === 200) {
        setLoading(false);
        setMuiDialogOpen(false);
        toast.success("Season deleted successfully");
        setTimeout(() => {
          dispatch(fetchAllContant(queryString, "single") as any);
        }, 1000);
      }
    } catch (error: any) {
      setMuiDialogOpen(false);
      setLoading(false);
      toast.error(error.response.data.error.message || "Something went wrong");
    }
  };

  const [muiDialogContant, setMuiDialogContant] = useState<IMuiDialog>({
    title: "",
    contentText: "",
    buttonOneText: "",
    buttonTwoText: "",
    twoAction: false,
    actionKey: false,
    bulkDeleteData: false,
    buttonTwoTitle: "",
  });

  // status dialog
  const [muiDialogOpen, setMuiDialogOpen] = useState(false);
  const handleMuiDialogOpen = () => {
    setMuiDialogOpen(true);
  };
  const handleMuiDialogClose = () => {
    setMuiDialogOpen(false);
    setTimeout(() => {
      setMuiDialogContant({
        title: "",
        contentText: "",
      });
    }, 100);
  };

  // status dialog
  const [muiFromDialogOpen, setMuiFromDialogOpen] = useState(false);
  const handleMuiFromDialogOpen = () => {
    setMuiFromDialogOpen(true);
  };
  const handleMuiFromDialogClose = () => {
    setMuiFromDialogOpen(false);
    setTimeout(() => {
      setMuiDialogContant({
        title: "",
        contentText: "",
      });
    }, 100);
  };

  // handle add series season
  const addSeasonFunction = async () => {
    if (seasonName === "") return toast.error("Please enter season name");
    try {
      setLoading(true);
      const response = await contantCreateSeason(id, { name: seasonName });
      if (response.status === 201) {
        setLoading(false);
        toast.success("Season created successfully");
        setSeasonName("");
        handleMuiFromDialogClose();
        setTimeout(() => {
          dispatch(fetchAllContant(queryString, "single") as any);
        }, 1000);
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.error.data.message || "Something went wrong");
    }
  };

  // type
  const contentOfferingType: any = [
    { name: "Premium", _id: "PREMIUM" },
    { name: "Free", _id: "FREE" },
    { name: "Buy or rent", _id: "BUY_OR_RENT" },
  ];
  // content type
  const contentType = [
    { name: "Movie", _id: "movie" },
    { name: "Series", _id: "series" },
    { name: "Live Stream", _id: "live_stream" },
  ];

  const trailerSourceType = [
    { name: "HLS", _id: "HLS" },
    { name: "MP4", _id: "MP4" },
  ];

  const sourceType = [
    { name: "HLS", _id: "HLS" },
    { name: "MP4", _id: "MP4" },
    { name: "LIVE STREAM HLS", _id: "LIVE_STREAM_HLS" },
  ];

  interface IFormValues {
    name: string;
    slug: string;
    description: string;
    uacertificate: string;
    genres: any[];
    casts: any[];
    rating: number;
    duration: number | string;
    content_duration: string;
    tags: string[];
    thumbnail: any;
    thumbnailUrl: string;
    poster: any;
    posterUrl: string;
    titleImage: any;
    titleImageUrl: string;
    category: any;
    language: any;
    type: any;
    content_offering_type: any;
    trailer_source_link: any;
    trailer_source_type: any;
    source_link: any;
    source_type: any;
  }

  const initialValues: IFormValues = {
    name: single.name,
    slug: single.slug,
    description: single.description,
    uacertificate: single.u_age || "",
    genres: single.genres || [],
    casts: single.cast || [],
    rating: single.rating || 0,
    duration: single.duration || 0,
    content_duration: single.content_duration || "",
    tags: single.tags || [],
    thumbnail: "",
    thumbnailUrl: single.thumbnail,
    poster: "",
    posterUrl: single.poster,
    titleImage: "",
    titleImageUrl: single.title_image || "",
    category: single.category,
    language: single.language,
    type: contentType.find((item: any) => item._id === single.type) || "",
    content_offering_type: contentOfferingType.find(
      (item: any) => item._id === single.content_offering_type
    ),
    trailer_source_link: single.trailer_source_link,
    trailer_source_type: trailerSourceType.find(
      (item: any) => item._id === single.trailer_source_type
    ),
    source_link: single.source_link,
    source_type: sourceType.find(
      (item: any) => item._id === single.source_type
    ),
  };

  const handleSubmitFrom = async (values: any) => {
    // console.log("291---> values  ",values)

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("slug", values.slug);
    if (values.description) formData.append("description", values.description);
    if (values.uacertificate) formData.append("u_age", values.uacertificate);
    if (values.genres?.length > 0) {
      const genreIds = values.genres.map((item: any) => item.id || item._id);
      formData.append("genres", JSON.stringify(genreIds));
    }
    if (values.casts?.length > 0) {
      const castIds = values.casts.map((item: any) => item.id || item._id);
      formData.append("cast", JSON.stringify(castIds));
    }
    formData.append("rating", values.rating);
    formData.append("duration", values.duration);
    if (values.content_duration) formData.append("content_duration", values.content_duration);
    if (values.tags) formData.append("tags", JSON.stringify(values.tags));
    if (values.thumbnail) formData.append("thumbnail", values.thumbnail);
    if (values.poster) formData.append("poster", values.poster);
    if (values.titleImage) formData.append("title_image", values.titleImage);
    if (values.category) {
      if (values.category.length > 0) {
        formData.append(
          "category",
          values.category[0].id || values.category[0]._id
        );
      } else {
        if (values.category.id || values.category._id) {
          formData.append(
            "category",
            values.category.id || values.category._id
          );
        }
      }
    }
    if (values.language) {
      if (values.language.length > 0) {
        formData.append(
          "language",
          values.language[0].id || values.language[0]._id
        );
      } else {
        if (values.language.id || values.language._id) {
          formData.append(
            "language",
            values.language.id || values.language._id
          );
        }
      }
    }
    if (values.type) formData.append("type", values.type.id || values.type._id);
    if (values.content_offering_type)
      formData.append(
        "content_offering_type",
        values.content_offering_type.id || values.content_offering_type._id
      );
    if (values.trailer_source_link)
      formData.append("trailer_source_link", values.trailer_source_link);
    if (values.trailer_source_type)
      formData.append(
        "trailer_source_type",
        values.trailer_source_type.id || values.trailer_source_type._id
      );
    if (values.source_link) formData.append("source_link", values.source_link);
    if (values.source_type)
      formData.append(
        "source_type",
        values.source_type.id || values.source_type._id
      );
    if (!values.type) return toast.error("Please select type");

    // submit form
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/content/${id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Content updated successfully");
        dispatch(fetchAllContant(queryString, "single") as any);
        setLoading(false);
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.error?.message || "Something went wrong"
      );
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: EditContentSchema,
    onSubmit: (values, action) => {
      handleSubmitFrom(values);
      //action.resetForm();
    },
  });

  // fetching all the categories and languages, genres and subtitles from the server
  // innitializing the state with the fetched data
  const [categories, setCategories] = React.useState([]);
  const [genreData, setGenreData] = React.useState([]);
  const [languagesData, setLanguages] = React.useState([]);
  const [castData, setCastData] = React.useState([]);

  const fetchCategories = async () => {
    const { data } = await getCategories();
    setCategories(data.data);
  };
  const onCategorySearch = async (search: string) => {
    const { data } = await categorySearch(search);
    setCategories(data.data);
    return data.data;
  };

  const fetchGenres = async () => {
    const { data } = await getGenres();
    setGenreData(data.data);
  };
  const onGenreSearch = async (search: string) => {
    const { data } = await genreSearch(search);
    setGenreData(data.data);
    return data.data;
  };
  const fetchAllLanguages = async () => {
    const { data } = await getLanguagesEndPoint();
    setLanguages(data.data);
  };
  const onLanguageSearch = async (search: string) => {
    const { data } = await languageSearch(search);
    setLanguages(data.data);
    return data.data;
  };
  const fetchAllCast = async () => {
    const { data } = await getCastsEndPoint();
    setCastData(data.data);
  };
  const onCastSearch = async (search: string) => {
    const { data } = await castSearch(search);
    setCastData(data.data);
    return data.data;
  };

  useEffect(() => {
    fetchGenres();
    fetchCategories();
    fetchAllLanguages();
    fetchAllCast();
  }, []);

  const promiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(onGenreSearch(inputValue));
      }, 1000);
    });

  const castsPromiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(onCastSearch(inputValue));
      }, 1000);
    });

  const categoryPromiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(onCategorySearch(inputValue));
      }, 1000);
    });

  const languagePromiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(onLanguageSearch(inputValue));
      }, 1000);
    });

  const [countriesForBlock, setContriesForBlock] = useState<any[]>([]);
  const [banCountries, setBanCountries] = useState<any[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      if (single && single.ban_country) {
        let parsedCountry: any = country;

        let arr = single.ban_country.map((item: any) => {
          return {
            name: parsedCountry[item],
            value: parsedCountry[item],
            code: item,
          };
        });

        setBanCountries(arr);
        setContriesForBlock([
          ...arr.map((item: any) => {
            return item.code;
          }),
        ]);
      }
    };

    fetchData();
  }, [single]);

  // @ code for the geo blocking
  const handleCountrySearch = async (search: string) => {
    const query = `keyword=${search}&show=true`;
    const res = await getGeoBlockCountries(query);
    let countryArr = res.data.data.map((country: any) => {
      return {
        value: country.name,
        label: country.name,
        code: country.code,
        id: country._id,
      };
    });

    return countryArr;
  };

  const promiseCountryOptions = (inputValue: string) => {
    return new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(handleCountrySearch(inputValue));
      }, 1000);
    });
  };

  const handleGetSelectedCountry = (value: any) => {
    if (value) {
      setContriesForBlock([
        ...value.map((item: any) => {
          return item.code;
        }),
      ]);
    }
  };

  const handleSaveCountryInContent = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setLoading(true);

    try {
      const countryArr = [...countriesForBlock];

      const obj = {
        countryArr: countryArr,
      };

      if (id) {
        const res = await addContentForBock(id, obj);
        if (res.status === 200) {
          dispatch(fetchAllContant(queryString, "single") as any);
          setLoading(false);
          return toast.success(res.data.message);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return console.log(error.message);
      }

      return console.log(error);
    }
  };

  interface IContentForm {
    title?: string;
    name: string;
    slug?: boolean;
    placeholder: string;
    type: string;
    value?: any;
    error?: any;
    required?: boolean;
    maxLength?: number;
    limitValidation?: boolean;
    options?: any;
    isMulti?: boolean;
    promiseOptions?: any;
    urlName?: string;
    url?: string;
    className?: string;
    imageStyle?: string;
    placeholderStyle?: string;
    col?: string;
  }

  const contentForm: IContentForm[] = [
    {
      name: "name",
      placeholder: "Type contect name here",
      type: "text",
      value: values.name,
      error: errors.name,
      required: true,
      maxLength: 100,
      limitValidation: true,
      col: "col-span-2",
    },
    {
      name: "slug",
      placeholder: "Type slug name here",
      type: "text",
      value: values.slug,
      error: errors.name,
      required: true,
      maxLength: 150,
      limitValidation: true,
      col: "col-span-2",
    },
    {
      name: "description",
      placeholder: "Type contect description here",
      type: "textarea",
      value: values.description,
      error: errors.description,
      required: true,
      maxLength: 5000,
      limitValidation: true,
      col: "col-span-2",
    },
    {
      name: "genres",
      options: genreData,
      placeholder: "Select genres",
      isMulti: true,
      type: "select",
      value: values.genres,
      promiseOptions: promiseOptions,
      col: "col-span-2",
    },
    {
      name: "uacertificate",
      placeholder: "Type contect uacertificate here",
      type: "text",
      value: values.uacertificate,
      limitValidation: false,
      col: "col-span-2",
    },
    {
      name: "casts",
      options: castData,
      placeholder: "Select casts",
      isMulti: true,
      type: "select",
      value: values.casts,
      promiseOptions: castsPromiseOptions,
      col: "col-span-2",
    },
    {
      name: "rating",
      placeholder: "Type contect rating here",
      type: "text",
      value: values.rating,
      limitValidation: false,
      col: "col-span-2",
    },
    {
      name: "duration",
      placeholder: "Type contact duration here in seconds",
      type: "text",
      value: values.duration,
      limitValidation: false,
      col: "col-span-2",
    },
    {
      title: "Content Duration (beta)",
      name: "content_duration",
      placeholder: "Type contact duration here",
      type: "text",
      value: values.content_duration,
      limitValidation: false,
      col: "col-span-2",
    },
    {
      name: "tags",
      placeholder: "Type tag and press enter to add",
      type: "tags",
      value: values.tags,
      limitValidation: false,
      col: "col-span-2",
    },
  ];

  const contentFormTwo: IContentForm[] = [
    {
      name: "thumbnail",
      urlName: "thumbnailUrl",
      placeholder: "Please upload a thumbnail 1280x720px.",
      type: "imagewithPreview",
      value: values.thumbnail,
      url: values.thumbnailUrl,
      error: errors.thumbnail,
      limitValidation: false,
      className:
        "w-full aspect-video rounded overflow-hidden border cursor-pointer",
      col: "col-span-1",
    },
    {
      name: "poster",
      urlName: "posterUrl",
      placeholder: "upload poster. 1175x1763px.",
      type: "imagewithPreview",
      value: values.poster,
      url: values.posterUrl,
      error: errors.poster,
      limitValidation: false,
      className: "w-full overflow-hidden border rounded flex justify-center",
      imageStyle: "object-contain w-52",
      placeholderStyle: "cursor-pointer text-sm py-32",
      col: "col-span-1",
    },
    {
      title: "Title Image (beta)",
      name: "titleImage",
      urlName: "titleImageUrl",
      placeholder: "Please upload a title 480x270px.",
      type: "imagewithPreview",
      value: values.titleImage,
      url: values.titleImageUrl,
      error: errors.titleImage,
      limitValidation: false,
      className:
        "w-full aspect-video rounded overflow-hidden border cursor-pointer",
      col: "col-span-1",
    },
    {
      name: "category",
      options: categories,
      placeholder: "Select category",
      type: "select",
      isMulti: false,
      value: values.category,
      promiseOptions: categoryPromiseOptions,
      col: "col-span-1",
    },
    {
      name: "language",
      options: languagesData,
      placeholder: "Select language",
      type: "select",
      isMulti: false,
      value: values.language,
      promiseOptions: languagePromiseOptions,
      col: "col-span-1",
    },
    {
      name: "type",
      options: contentType,
      placeholder: "Select type",
      type: "select",
      isMulti: false,
      value: values.type,
      col: "col-span-1",
    },
    {
      name: "content_offering_type",
      options: contentOfferingType,
      placeholder: "Select content offering type",
      type: "select",
      isMulti: false,
      value: values.content_offering_type,
      col: "col-span-1",
    },
  ];

  const contentFormSourceMovie: IContentForm[] = [
    {
      name: "trailer_source_type",
      options: trailerSourceType,
      placeholder: "Select source type",
      type: "select",
      isMulti: false,
      value: values.trailer_source_type,
    },
    {
      name: "trailer_source_link",
      title: "Trailer Source Link",
      placeholder: "Type source link here",
      type: "text",
      value: values.trailer_source_link,
      limitValidation: false,
    },
    {
      name: "source_type",
      options: sourceType,
      placeholder: "Select source type",
      type: "select",
      isMulti: false,
      value: values.source_type,
    },
    {
      name: "source_link",
      title: "Source Link",
      placeholder: "Type source link here",
      type: "text",
      value: values.source_link,
      limitValidation: false,
    },
  ];
  const contentFormSourceSeries: IContentForm[] = [
    {
      name: "trailer_source_type",
      options: trailerSourceType,
      placeholder: "Select source type",
      type: "select",
      isMulti: false,
      value: values.trailer_source_type,
    },
    {
      name: "trailer_source_link",
      title: "Trailer Source Link",
      placeholder: "Type source link here",
      type: "text",
      value: values.trailer_source_link,
      limitValidation: false,
    },
  ];
  const contentFormSourceLive: IContentForm[] = [
    {
      name: "source_type",
      options: sourceType,
      placeholder: "Select source type",
      type: "select",
      isMulti: false,
      value: values.source_type,
    },
    {
      name: "source_link",
      title: "Source Link",
      placeholder: "Type source link here",
      type: "text",
      value: values.source_link,
      limitValidation: false,
    },
  ];

  const getSourceType = (type: string) => {
    switch (type) {
      case "movie":
        return contentFormSourceMovie;
      case "series":
        return contentFormSourceSeries;
      case "live_stream":
        return contentFormSourceLive;
      default:
        return [];
    }
  };

  return (
    <Layout>
      <h1 className="font-medium text-2xl mb-2 opacity-80">
        Content
      </h1>
      <form id="edit-content" onSubmit={handleSubmit}>
        <BasicTabs
          activeTab={0}
          tabs={[
            {
              label: "Details",
              hide: false,
            },
            {
              label: "Source",
              hide: false,
            },
            {
              label: "Analytics",
              hide: false,
            },
            {
              label: "TVOD Analytics",
              hide: false,
            },
            {
              label: "Seasons",
              hide: values.type?._id === "series" ? false : true,
            },
            {
              label: "Subtitles",
              hide: values.type?._id === "movie" ? false : true,
            },
            {
              label: "Geo Block",
              hide: false,
            },
            {
              label: "BUY OR RENT",
              hide: values?.content_offering_type?._id === "BUY_OR_RENT" ? false : true,
            },
            {
              label: "Images",
              hide: false,
            }
          ]}
          tabPanels={[
            {
              label: "Details",
              children: (
                <div className="mt-2">
                  <div className="grid grid-cols-3 gap-5 pr-5">
                    <div className="flex flex-col col-span-2 gap-4">
                      {contentForm.map((item) => {
                        switch (item.type) {
                          case "text":
                            return (
                              <InputField
                                title={item.title || item.name}
                                fieldName={item.name}
                                placeholder={item.placeholder}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={item.value}
                                type="text"
                                required={item.required}
                                maxLength={item.maxLength}
                                error={item.error}
                                limitValidation={item.limitValidation}
                                col={item.col}
                              />
                            );
                          case "slug":
                            return (
                              <InputField
                                fieldName={item.name}
                                placeholder={item.placeholder}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={item.value}
                                type="text"
                                required={item.required}
                                maxLength={item.maxLength}
                                error={item.error}
                                limitValidation={item.limitValidation}
                                col={item.col}
                              />
                            );
                          case "textarea":
                            return (
                              <TextAreaField
                                fieldName={item.name}
                                placeholder={item.placeholder}
                                textAreaOnBlur={handleBlur}
                                textAreaOnChange={handleChange}
                                value={item.value}
                                type="text"
                                //required
                                maxLength={item.maxLength}
                                error={item.error}
                                limitValidation={item.limitValidation}
                                col={item.col}
                              />
                            );
                          case "select":
                            return (
                              <SelectField
                                options={item.options}
                                onChange={(e) => {
                                  let event = {
                                    target: { name: item.name, value: e },
                                  };
                                  handleChange(event);
                                }}
                                defaultValue={item.value}
                                onBlur={handleBlur}
                                placeholder={item.placeholder}
                                isMulti={item.isMulti}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) =>
                                  option.id ? option.id : option._id
                                }
                                promiseOptions={item.promiseOptions}
                                col={item.col}
                              />
                            );
                          case "tags":
                            return (
                              <TagField
                                fieldName={item.name}
                                placeholder={item.placeholder}
                                col={item.col}
                                onKeyDown={(e) => {
                                  if (e.key !== "Enter") return;
                                  e.preventDefault();
                                  let event = {
                                    target: {
                                      name: item.name,
                                      value: [
                                        ...(item.value as any),
                                        e.target.value,
                                      ],
                                    },
                                  };
                                  handleChange(event);
                                  e.target.value = "";
                                }}
                                value={item.value}
                                onRemove={(index) => {
                                  let event = {
                                    target: {
                                      name: item.name,
                                      value: item.value.filter(
                                        (item: any, i: number) => i !== index
                                      ) as any,
                                    },
                                  };
                                  handleChange(event);
                                }}
                              />
                            );
                        }
                      })}
                    </div>
                    <div className="flex flex-col col-span-1 gap-4">
                      {contentFormTwo.map((item) => {
                        switch (item.type) {
                          case "select":
                            return (
                              <SelectField
                                options={item.options}
                                onChange={(e) => {
                                  let event = {
                                    target: { name: item.name, value: e },
                                  };
                                  handleChange(event);
                                }}
                                onBlur={handleBlur}
                                defaultValue={item.value}
                                placeholder={item.placeholder}
                                isMulti={item.isMulti}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) =>
                                  option.id ? option.id : option._id
                                }
                                promiseOptions={item.promiseOptions}
                                col={item.col}
                              />
                            );
                          case "imagewithPreview":
                            return (
                              <ImageField
                                fieldName={item.name}
                                placeholder={item.placeholder}
                                onChange={(e) => {
                                  // set image in thumbnail field and url in thumbnailUrl field
                                  let event = {
                                    target: {
                                      name: item.name,
                                      value: e.target.files[0],
                                    },
                                  };
                                  handleChange(event);
                                  let urlEvent = {
                                    target: {
                                      name: item.urlName,
                                      value: URL.createObjectURL(
                                        e.target.files[0]
                                      ),
                                    },
                                  };
                                  handleChange(urlEvent);
                                }}
                                onBlur={handleBlur}
                                url={item.url}
                                value={item.value}
                                className={item.className}
                                error={item.error}
                                imageStyle={item.imageStyle}
                                placeholderStyle={item.placeholderStyle}
                                col={item.col}
                              />
                            );
                        }
                      })}
                    </div>
                  </div>
                  <div className="flex justify-end pr-5 my-5">
                    <LoadingButton
                      id="edit-content"
                      type="submit"
                      loading={loading}
                      disabled={loading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                    >
                      Save
                    </LoadingButton>
                  </div>
                </div>
              ),
            },
            {
              label: "Source",
              children: (
                <>
                  <div className="pr-5 my-2 flex flex-col gap-4">
                    {getSourceType(values.type?._id).map((item) => {
                      switch (item.type) {
                        case "text":
                          return (
                            <InputField
                              title={item.title}
                              fieldName={item.name}
                              placeholder={item.placeholder}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={item.value}
                              type="text"
                              required={item.required}
                              maxLength={item.maxLength}
                              error={item.error}
                              limitValidation={item.limitValidation}
                              col={item.col}
                            />
                          );
                        case "select":
                          return (
                            <SelectField
                              options={item.options}
                              onChange={(e) => {
                                let event = {
                                  target: { name: item.name, value: e },
                                };
                                handleChange(event);
                              }}
                              defaultValue={item.value}
                              onBlur={handleBlur}
                              placeholder={item.placeholder}
                              isMulti={item.isMulti}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) =>
                                option.id ? option.id : option._id
                              }
                              promiseOptions={item.promiseOptions}
                              col={item.col}
                            />
                          );
                      }
                    })}
                  </div>
                  <div className="flex justify-end pr-5 my-5">
                    <LoadingButton
                      id="edit-content"
                      type="submit"
                      loading={loading}
                      disabled={loading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                    >
                      Save
                    </LoadingButton>
                  </div>
                </>
              ),
            },
            {
              label: "Analytics",
              children: <ContentAnalytics content_id={id} />,
            },
            {
              label: "TVOD Analytics",
              children: <TVOD content_id={id ? id : ""} />,
            },
            {
              label: "Seasons",
              children: (
                <>
                  {single.type === "series" && (
                    <div
                      onClick={() => {
                        handleMuiFromDialogOpen();
                        setMuiDialogContant({
                          title: "Add New Season",
                          contentText:
                            "To add new season, please fill the form below and click on save button to save the season.",
                          buttonOneText: "Cancel",
                          buttonTwoText: "Yes",
                        });
                      }}
                      className="pr-5 mt-3 flex justify-end gap-4"
                    >
                      <Button variant="contained" startIcon={<AddIcon />}>
                        Add Season
                      </Button>
                    </div>
                  )}

                  <div className="mb-2">
                    {single && single.seasons && single.seasons?.length > 0 && (
                      <div className="overflow-x-auto">
                        <table className="table w-full">
                          <thead>
                            <tr>
                              <th
                                className={`bg-transparent border-b ${darkMode
                                  ? "border-[#343434]"
                                  : "border-gray-200"
                                  }`}
                              >
                                No.
                              </th>
                              <th
                                className={`bg-transparent border-b ${darkMode
                                  ? "border-[#343434]"
                                  : "border-gray-200"
                                  }`}
                              >
                                Name
                              </th>
                              <th
                                className={`bg-transparent border-b ${darkMode
                                  ? "border-[#343434]"
                                  : "border-gray-200"
                                  }`}
                              >
                                release Date
                              </th>
                              <th
                                className={`bg-transparent border-b ${darkMode
                                  ? "border-[#343434]"
                                  : "border-gray-200"
                                  }`}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {single.seasons.map((season, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className={`bg-transparent border-b ${darkMode
                                      ? "border-[#343434]"
                                      : "border-gray-200"
                                      }`}
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    className={`bg-transparent border-b ${darkMode
                                      ? "border-[#343434]"
                                      : "border-gray-200"
                                      }`}
                                  >
                                    {season.name}
                                  </td>
                                  <td
                                    className={`bg-transparent border-b ${darkMode
                                      ? "border-[#343434]"
                                      : "border-gray-200"
                                      }`}
                                  >
                                    {moment(season.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td
                                    className={`bg-transparent border-b ${darkMode
                                      ? "border-[#343434]"
                                      : "border-gray-200"
                                      }`}
                                  >
                                    <div className="flex gap-4 items-center">
                                      <Link
                                        to={`/admin/content/season/${id}/${season._id}`}
                                        className="cursor-pointer"
                                      >
                                        <div
                                          className="tooltip tooltip-accent"
                                          data-tip="Edit"
                                        >
                                          <Button
                                            className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                            variant="text"
                                          >
                                            <EditIcon className="cursor-pointer" />
                                          </Button>
                                        </div>
                                      </Link>

                                      <div
                                        className="tooltip tooltip-accent"
                                        data-tip="Delete"
                                      >
                                        {" "}
                                        <Button
                                          onClick={() => {
                                            setSingleSelect(season._id);
                                            handleMuiDialogOpen();
                                            setMuiDialogContant({
                                              title: "Delete Season",
                                              contentText:
                                                "Are you sure you want to delete this season permanently?",
                                              buttonOneText: "Cancel",
                                              buttonTwoText: "Yes",
                                            });
                                          }}
                                          variant="text"
                                        >
                                          <DeleteIcon className="cursor-pointer" />
                                        </Button>
                                      </div>

                                      {/* <div onClick={() => {
                                                                                setSeasonId(season._id)
                                                                            }}>
                                                                                <label htmlFor='season-delete'>
                                                                                    <AiFillDelete
                                                                                        size={25}
                                                                                        className='cursor-pointer text-red-500'
                                                                                    />
                                                                                </label>
                                                                            </div> */}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <FormDialog
                    open={muiFromDialogOpen}
                    onClose={handleMuiFromDialogClose}
                    title={muiDialogContant.title}
                    contentText={muiDialogContant.contentText}
                    btnText={"Create"}
                    id="create-season"
                    label="Season Name"
                    onClick={addSeasonFunction}
                    value={seasonName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSeasonName(e.target.value)
                    }
                  />
                  <MuiDialog
                    open={muiDialogOpen}
                    onClose={handleMuiDialogClose}
                    title={muiDialogContant.title}
                    contentText={muiDialogContant.contentText}
                    onClickClose={handleMuiDialogClose}
                    onClickYes={handleRemoveSeason}
                    buttonOneText={muiDialogContant.buttonOneText}
                    buttonTwoText={muiDialogContant.buttonTwoText}
                  />
                </>
              ),
              hide: values.type?._id === "series" ? false : true,
            },
            {
              label: "Subtitles",
              children: (
                <ContentSubtitle
                  content_id={id}
                  subtitles={single.subtitles as any}
                />
              ),
              hide: values.type?._id === "movie" ? false : true,
            },
            {
              label: "Geo Block",
              children: (
                <div className="mt-4 py-2 px-4">
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    placeholder="Select countries for the ban.."
                    loadOptions={promiseCountryOptions}
                    onChange={handleGetSelectedCountry}
                    defaultValue={banCountries.map((item: any) => {
                      return {
                        value: item.name,
                        label: item.name,
                        code: item.code,
                      };
                    })}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: darkMode
                          ? "transparent"
                          : "transparent",
                        border: state.isFocused
                          ? "1px solid #3b82f6"
                          : darkMode
                            ? "1px solid #343434"
                            : "1px solid #e5e7eb",
                        boxShadow: state.isFocused
                          ? "0 0 0 1px #3b82f6"
                          : "none",
                        "&:hover": {
                          border: "1px solid #3b82f6",
                        },
                      }),
                      menuList(base, props) {
                        return {
                          ...base,
                          backgroundColor: darkMode ? "#2D2D2D" : "white",
                          color: darkMode ? "white" : "black",
                        };
                      },
                      option(base, props) {
                        return {
                          ...base,
                          backgroundColor: props.isFocused
                            ? "#3b82f6"
                            : darkMode
                              ? "#2D2D2D"
                              : "white",
                          color: props.isFocused
                            ? "white"
                            : darkMode
                              ? "white"
                              : "black",
                        };
                      },
                      multiValue(base, props) {
                        return {
                          ...base,
                          backgroundColor: darkMode ? "#3b82f6" : "#3b82f6",
                          color: darkMode ? "white" : "white",
                        };
                      },
                      multiValueLabel(base, props) {
                        return {
                          ...base,
                          color: darkMode ? "white" : "white",
                        };
                      },
                      input(base, props) {
                        return {
                          ...base,
                          color: darkMode ? "white" : "black",
                        };
                      },
                      singleValue(base, props) {
                        return {
                          ...base,
                          color: darkMode ? "white" : "black",
                        };
                      },
                    }}
                  />

                  <div className="absolute right-2 bottom-2">
                    <LoadingButton
                      id="add-country"
                      type="submit"
                      loading={loading}
                      disabled={loading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                        handleSaveCountryInContent(event)
                      }
                    >
                      Save
                    </LoadingButton>
                  </div>
                </div>
              ),
            },
            {
              label: "BUY OR RENT",
              children: <BuyOrRent content_id={id} />,
              hide: values?.content_offering_type?._id === "BUY_OR_RENT" ? false : true,
            },
            {
              label: "Images",
              children: <ContentImages id={id} />,
            }
          ]}
        />
      </form>
    </Layout>
  );
};

export default EditContent;
