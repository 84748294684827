// Author: Pukhraj Dhamu
// Date Created: Sunday 21 May 2023 at 7:04 PM
// Date Modified: Sunday 21 May 2023 at 7:04 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const AppSettingsApis = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
});

// list all endpoints
// init payment gateway settings if new provider added
export const initAppSettingsApi = () => AppSettingsApis.get(`/settings/app/initiate`, {
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
});
export const updateAppSettingsApi = <T>(payload: T, type: "app_splesh_screen" | "tv_splesh_screen" | "logo" | "login_poster", headers: any) => AppSettingsApis.put(`/settings/app?type=${type}`, payload, headers);
export const getAppSettingsApi = (query: string) => AppSettingsApis.get(`/settings/app${query ? '?' + query : ""}`, {
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
}).then((res) => res.data);
export const getAppThemeApi = (queryString?: string) => AppSettingsApis.get(`/service/app-color-scheme${queryString ? '?' + queryString : ""}`).then((res) => res.data);
export const initAppThemeApi = () => AppSettingsApis.get(`/service/app-color-scheme/initiate`).then((res) => res.data);
export const updateAppThemeApi = (payload: IAppThemeUpdatePayload, id: string) => AppSettingsApis.put(`/service/app-color-scheme/${id}`, payload);


AppSettingsApis.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

export default AppSettingsApis;