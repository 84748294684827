import moment from "moment";
import { IAnalytics } from "../redux/slice​/analyticsSlice";

export function bytesToSize(bytes: IBytesToSize) {
  if (bytes.bytes === 0) return "0 Byte";
  bytes.bytes = Number(bytes);
  let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  let i = Math.floor(Math.log(bytes.bytes) / Math.log(1024));
  return Math.round(bytes.bytes / Math.pow(1024, i)) + " " + sizes[i];
}

export function bytesToBand(bytes: number) {
  if (bytes === 0) return 0;
  let bits = Number(bytes) * 8;
  return Math.round(bits / Math.pow(1024, 2)) as any;
}

export function secondsToDhms(seconds: number) {
  seconds = Number(seconds);
  let d = Math.floor(seconds / (3600 * 24));
  let h = Math.floor((seconds % (3600 * 24)) / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let s = Math.floor(seconds % 60);

  let dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  let hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  let mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  let sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function secondsToDhmsSimple(seconds: number) {
  seconds = Number(seconds);
  let d = Math.floor(seconds / (3600 * 24));
  let h = Math.floor((seconds % (3600 * 24)) / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let s = Math.floor(seconds % 60);

  let dDisplay = d > 0 ? d + "d," : "";
  let hDisplay = h > 0 ? h + "h," : "";
  let mDisplay = m > 0 ? m + "m," : "";
  let sDisplay = s + "s";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function secondsToHms(d: number, type: boolean) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  if (type) {
    var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "";
  } else {
    var hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m, " : " m, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
  }

  return hDisplay + mDisplay + sDisplay;
}

export function isDay() {
  const hours = new Date().getHours();
  return hours >= 6 && hours < 18;
}

// get last 28 days, 7 days, 30 days, 90 days, 365 days and all time ot custom date range using moment
export function dateRange(days: IDateRange | String) {
  let startDate: string = moment().format("YYYY-MM-DD HH:mm:ss");
  let endDate: string = moment().format("YYYY-MM-DD HH:mm:ss");
  switch (days) {
    case "last28":
      startDate = moment()
        .subtract(28, "days")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      break;
    case "last7":
      startDate = moment()
        .subtract(7, "days")
        .startOf("days")
        .format("YYYY-MM-DD HH:mm:ss");
      break;
    case "last30":
      startDate = moment()
        .subtract(30, "days")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      break;
    case "last90":
      startDate = moment()
        .subtract(90, "days")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      break;
    case "last365":
      startDate = moment()
        .subtract(365, "days")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      break;
    default:
      startDate = moment()
        .subtract(28, "days")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      break;
  }
  return { startDate, endDate };
}
export const calculateViews = (views: IAnalytics["analytics"]["views"]) => {
  if (!views) return 0
  let total = 0;
  for (const view of views) {
    total += view.count;
  }
  return totalViewsCalculate(total);
};
// calculate the views
export const totalViewsCalculate = (views: number, plus?: boolean) => {
  // less than 1000
  if (views < 1000) {
    return views;
  }
  // if views > 1000 and convert to k and if like 1.2k then round to 1.2k
  if (views > 1000) {
    return plus
      ? (views / 1000).toFixed(1) + "k+"
      : (views / 1000).toFixed(1) + "k";
  }
  // if views > 1000000 then convert to m
  else if (views > 1000000) {
    return plus
      ? (views / 1000000).toFixed(1) + "m+"
      : (views / 1000000).toFixed(1) + "m";
  }
  // if views > 1000000000 then convert to b
  else if (views > 1000000000) {
    return plus
      ? (views / 1000000000).toFixed(1) + "b+"
      : (views / 1000000000).toFixed(1) + "b";
  }
};
export const calculateWatchTime = (
  watchTime: IAnalytics["analytics"]["watchTime"]
) => {
  let total = 0;
  for (const time of watchTime) {
    total += time.time;
  }

  // converting total time in hours
  total = total / 3600;
  return totalWatchTimeCalculate(total);
};
// calculate the watch time
export const totalWatchTimeCalculate = (time: number, plus?: boolean) => {
  // less than 1000
  if (time < 1000) {
    return time.toFixed(2);
  }
  // if views > 1000 and convert to k and if like 1.2k then round to 1.2k
  if (time > 1000) {
    return plus
      ? (time / 1000).toFixed(1) + "k+"
      : (time / 1000).toFixed(1) + "k";
  }
  // if views > 1000000 then convert to m
  else if (time > 1000000) {
    return plus
      ? (time / 1000000).toFixed(1) + "m+"
      : (time / 1000000).toFixed(1) + "m";
  }
  // if views > 1000000000 then convert to b
  else if (time > 1000000000) {
    return plus
      ? (time / 1000000000).toFixed(1) + "b+"
      : (time / 1000000000).toFixed(1) + "b";
  }
};

// sec to min and hour using moment
export const secToMin = (sec: number) => {
  // if the number is less than 60 seconds
  // if (sec < 60) {
  //   return sec + " sec";
  // }
  // // if the number is less than 60 minutes
  // else if (sec < 3600) {
  //   return Math.round(sec / 60) + " min";
  // }
  // // if the number is less than 24 hours
  // else if (sec < 86400) {
  //   return Math.round(sec / 3600) + " hours";
  // }
  return (sec / 3600).toFixed(2) + " hours";
};

// type of contant type
export const typeOfContant = (type: IContent["data"][0]["type"]) => {
  switch (type) {
    case "movie":
      return "Movie";
    case "series":
      return "Series";
    case "live_stream":
      return "Live Stream";
    default:
      return "None";
  }
};

// @Total Rent/Buy calculate
export const calculateBuyRentAmount = (data: number): string | number => {
  if (data > 1000000000) {
    if (Number.isInteger(data / 1000000000)) {
      return (data / 1000000000) + " B";
    }
    return (data / 1000000000).toFixed(2) + " B";
  } else if (data > 1000000) {
    if (Number.isInteger(data / 1000000)) {
      return (data / 1000000) + " M";
    }
    return (data / 1000000).toFixed(2) + " M";
  } else if (data > 1000) {
    if (Number.isInteger(data / 1000)) {
      return (data / 1000) + " K";
    }
    return (data / 1000).toFixed(2) + " K";
  }

  return data;
}

export const counter = (number: number, plus?: boolean) => {
  if(!number) return 0
  // less than 1000
  if (number < 1000) {
    return number;
  }
  // if views > 1000 and convert to k and if like 1.2k then round to 1.2k
  if (number > 1000) {
    return plus
      ? (number / 1000).toFixed(1) + "k+"
      : (number / 1000).toFixed(1) + "k";
  }
  // if views > 1000000 then convert to m
  else if (number > 1000000) {
    return plus
      ? (number / 1000000).toFixed(1) + "m+"
      : (number / 1000000).toFixed(1) + "m";
  }
  // if views > 1000000000 then convert to b
  else if (number > 1000000000) {
    return plus
      ? (number / 1000000000).toFixed(1) + "b+"
      : (number / 1000000000).toFixed(1) + "b";
  }
};
