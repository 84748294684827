// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import { TailwindBox } from '../../../../tailwind'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { useQuery } from '@tanstack/react-query'
import { getAnalytics, getContant } from '../../../../http/Apis'
import qs from 'qs'
import { BiRefresh } from 'react-icons/bi'
import { calculateViews, calculateWatchTime, counter } from '../../../../utils/Utils'
import moment from 'moment'

function ContentAnalytics() {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    // fetch content query
    const getContentQuery = useQuery<{ data: IContent }>({
        queryKey: ["LatestPerformanceContent"],
        queryFn: () => getContant(`page=1&limit=5`)
    })

    const query = qs.stringify({
        timePeriod: "monthly",
    });

    interface IGetContentAnalytics {
        data: {
            views: {
                _id: string;
                count: number;
            }[];
            watchTime: {
                _id: string;
                time: number;
            }[];
        }
    }

    const getContentAnalyticsQuery = useQuery<{ data: IGetContentAnalytics }>({
        queryKey: ["getContentAnalyticsQuery"],
        queryFn: () => getAnalytics(query),
        enabled: getContentQuery.data?.data?.data.length ? true : false
    })
    return (
        <TailwindBox title="Analytics">

            {
                getContentAnalyticsQuery.isLoading && <div>
                    <p className="text-xs  opacity-80 mt-2">Last 30 days:</p>
                    <div className="flex justify-between items-center mt-4 text-sm">
                        <h6>Views</h6>
                        <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
                    </div>
                    <div className="flex justify-between items-center mt-3 text-sm">
                        <h6>Watch time (hours)</h6>
                        <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
                    </div>
                    <div>
                        <h1 className=" text-base font-bold opacity-90 tracking-wide mt-7 mb-5">
                            Latest Content
                        </h1>
                        <div>
                            {new Array(5).fill("").map((item, index) => (
                                <div key={index} className="flex items-center justify-between mt-5">
                                    <div className="flex items-center">
                                        <div className="w-11 h-11 bg-secondary-color rounded overflow-hidden">
                                            <img
                                                className='object-cover w-full h-full'
                                                src={"/default-product.png"}
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <div className={`h-3 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-28`}></div>
                                            <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10 mt-2`}></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="ml-3">
                                            <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="mt-7 mb-5">
                        <div className={`h-7 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-32`}></div>
                    </div>
                </div>
            }
            {
                getContentAnalyticsQuery.isError && <div>
                    <div className="w-full h-full flex justify-center items-center mb-3">
                        <div className='min-h-[15rem] justify-center flex flex-col items-center'>
                            <h1 className='text-sm font-medium opacity-90'>
                                Server Error. Please try again
                            </h1>
                            <div
                                onClick={() => getContentAnalyticsQuery.refetch()}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                <BiRefresh className={`text-black text-lg bg-white rounded-full ${getContentAnalyticsQuery.isLoading || getContentAnalyticsQuery.isFetching && "animate-spin"}`} />
                                <h1 className='text-sm text-white'>
                                    Refresh
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                getContentAnalyticsQuery.isSuccess && <div>
                    <p className="text-xs  opacity-80 mt-2">Last 30 days:</p>
                    <div className="flex justify-between items-center mt-4 text-sm">
                        <h6>Views</h6>
                        <p>
                            {getContentAnalyticsQuery?.data.data.data.views && calculateViews(getContentAnalyticsQuery?.data.data.data.views || 0)}
                        </p>
                    </div>
                    <div className="flex justify-between items-center mt-3 text-sm">
                        <h6>Watch time (hours)</h6>
                        <p>
                            {getContentAnalyticsQuery?.data.data.data.watchTime && calculateWatchTime(getContentAnalyticsQuery?.data.data.data.watchTime || 0)}
                        </p>
                    </div>
                    <div>
                        <h1 className=" text-base font-bold opacity-90 tracking-wide mt-7 mb-5">
                            Latest Content
                        </h1>
                        <div>
                            {getContentQuery?.data?.data?.data?.map((item, index) => (
                                <div key={index} className="flex items-center justify-between mt-5">
                                    <div className="flex items-center">
                                        <div className="w-11 h-11 bg-secondary-color rounded overflow-hidden">
                                            <img
                                                className='object-cover w-full h-full'
                                                src={item.thumbnail || "/default-product.png"}
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <h1 className=" text-sm font-semibold opacity-90 tracking-wide">
                                                {item.name}
                                            </h1>
                                            <p className="text-xs  opacity-80 mt-0.5">
                                                {moment(item.createdAt).format("DD MMM YYYY")}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="ml-3">
                                            <p className="text-xs  opacity-80">
                                                {counter(item.views) + " views"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="mt-7 mb-5">
                        <Link to={`/admin/analytics`}>
                            <button className="uppercase text-blue-400 font-semibold ">
                                go to analytics
                            </button>
                        </Link>
                    </div>
                </div>
            }
        </TailwindBox>
    )
}

export default ContentAnalytics