import { LoadingButton } from '@mui/lab';
import { useMutation, useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { getAppSettingsApi, updateAppSettingsApi } from '../../../http/app.settings.http';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import SaveIcon from "@mui/icons-material/Save";
import { UploadIcon } from '../../../tailwind/Icon';


function LoginPoster() {
    const [loginPoster, setLoginPoster] = useState<AppSettingsApiResponse['data']['login_poster']>({} as AppSettingsApiResponse['data']['login_poster']);
    const [loginPosterSource, setLoginPosterSource] = useState<string | null>(null);
    const queryStrings = qs.stringify({
        fields: ["login_poster"],
    }, { encodeValuesOnly: true });
    // get app splash screen settings
    const getLoginPoster = useQuery<AppSettingsApiResponse>({
        queryKey: ["getAppLoginPoster"],
        queryFn: () => getAppSettingsApi(queryStrings),
        refetchOnWindowFocus: false,
        retryOnMount: false,
        onSuccess: (res) => {
            setLoginPoster(res.data.login_poster);
        }
    })

    const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { files } } = e
        // get object url and set it to logoUrl
        if (files && files.length > 0) {
            const splesh_logoUrl = URL.createObjectURL(files[0]);
            setLoginPoster(splesh_logoUrl);
            setLoginPosterSource(files[0] as any);
        }
    };

    // update the app splash screen settings using muteion
    const AppLoginPosterUpdateMutation = useMutation({
        mutationKey: ["updateAppLoginPoster"],
        mutationFn: (payload: any) => updateAppSettingsApi<any>(payload, "login_poster", {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }),
        onSuccess(data, variables, context) {
            toast.success("Login poster updated successfully");
        },
        onError(error: any, variables, context) {
            toast.error(error?.response?.error?.data?.message || "Error updating login poster");
        },
    })

    const handleAppLoginPoster = () => {
        const formData = new FormData();
        formData.append("login_poster", loginPosterSource as any);
        AppLoginPosterUpdateMutation.mutate(formData);
    }

    return (
        <div className='pr-6'>
            <h1 className="my-6 opacity-80 font-medium tracking-wide text-xl pr-5">
                Login Poster
            </h1>
            {
                getLoginPoster.isLoading && (
                    <div className="flex justify-center items-center">
                        <div className="w-10 h-10 border-t-2 border-primary rounded-full animate-spin"></div>
                    </div>
                )
            }
            {
                getLoginPoster.isError && (
                    <div className="flex justify-center items-center">
                        <div className="text-red-500">Error fetching data</div>
                    </div>
                )
            }
            {
                getLoginPoster.isSuccess && (
                    <>
                        <div className="w-full p-10 border border-gray-700 rounded">
                            <div className="flex flex-col justify-center items-center">
                                <input
                                    type="file"
                                    className="hidden"
                                    id="loginPoster"
                                    onChange={handleLogoChange}
                                />
                                <div className="relative">
                                    <label htmlFor="loginPoster" className="cursor-pointer">
                                        <img
                                            src={loginPoster}
                                            className="w-60 h-60 object-contain rounded-3xl shadow-sm bg-dark-color-box text-center p-5"
                                        />
                                    </label>
                                </div>
                                <div className="mt-8">
                                    <label
                                        htmlFor="loginPoster"
                                        className="cursor-pointer flex items-center gap-3">
                                        <div className="mt-[2px]">
                                            <UploadIcon width={22} height={22} />
                                        </div>
                                        <p className="opacity-70 text-lg font-medium cursor-pointer">
                                            Replace
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end my-10">
                            <LoadingButton
                                loading={AppLoginPosterUpdateMutation.isLoading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                onClick={handleAppLoginPoster}>
                                Save
                            </LoadingButton>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default LoginPoster
