import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IBetaState {
  isBeta: boolean
}

const initialState: IBetaState = {
    isBeta: false,
}

export const BetaSlice = createSlice({
  name: 'beta',
  initialState,
  reducers: {
    setBeta: (
        state,
        actions: PayloadAction<{ isBeta: boolean }>
      ) => {
          const isBeta = actions.payload
          state.isBeta = isBeta.isBeta
      },
  },
})

// Action creators are generated for each case reducer function
export const { setBeta } = BetaSlice.actions

export default BetaSlice.reducer