import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { DeleteIcon, InputField, Title } from "../../tailwind";
import { UploadIcon } from "../../tailwind/Icon";
import { useSelector } from "react-redux";
import {
  addSocialMediaEndpoint,
  SocialMediaEndpoint,
  updateSettingApi,
  updateSocialMediaEndpoint,
} from "../../http/Apis";
import toast from "react-hot-toast";
import { FullScreenDialog, Hr } from "../../components";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  fetchConfig,
  IConfigInitialState,
} from "../../redux/slice​/configSlice";
import { IFullScreenDialogConfig } from "../Content/EditContentSeason";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ImageField } from "../../tailwind/Input/inputField";
interface IAddSocial {
  id?: string;
  name: string;
  newTab: boolean;
  href: string;
  image: string;
  imageUrl: boolean;
}

interface IAddSocialLinkProps {
  id?: string;
  config: IConfigInitialState["config"];
  onClose: () => void;
}

const AddSocialLink = ({ config, onClose }: IAddSocialLinkProps) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const addSocialLinkFunc = async (values: any, action: any) => {
    const fromData = new FormData();
    fromData.append("name", values.name);
    fromData.append("href", values.href);
    fromData.append("newTab", values.newTab);
    if (values.image) fromData.append("icon", values.image);
    fromData.append("status", values.status);
    try {
      setLoading(true);
      const res = await addSocialMediaEndpoint(config._id, fromData);
      setLoading(false);
      if (res.status === 200) {
        toast.success("Social media link added successfully");
        onClose();
        dispatch(fetchConfig() as any);
        setLoading(false);
        action.resetForm();
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.error.message || "Something went wrong");
    }
  };

  const initialValues = {
    name: "",
    href: "",
    newTab: true,
    image: "",
    imageUrl: "",
    status: true,
  };

  const addSocialMediaSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(3, "Name is too short")
      .max(50, "Name is too long"),
    href: Yup.string().required("Href is required").min(3, "Href is too short"),
    newTab: Yup.boolean().optional(),
    image: Yup.mixed()
      .required("Icon is required")
      .test(
        "fileSize",
        "File too large limit is 1mb",
        (value) => value && value.size <= 1000000
      ) // 1mb
      .test(
        "fileFormat",
        "Unsupported Format. try JPEG, PNG, JPG, WEBP",
        (value) =>
          value &&
          ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(
            value.type
          )
      ),
    status: Yup.boolean().optional(),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addSocialMediaSchema,
    onSubmit: (values, action) => {
      addSocialLinkFunc(values, action);
    },
  });

  const contentForm = [
    {
      name: "name",
      placeholder: "Type name here",
      type: "text",
      value: values.name,
      error: errors.name,
      required: true,
      maxLength: 50,
      limitValidation: true,
    },
    {
      name: "href",
      title: "href link",
      placeholder: "Type href her",
      type: "text",
      value: values.href,
      limitValidation: false,
    },

    {
      name: "image",
      urlName: "imageUrl",
      placeholder: "Please upload a thumbnail 150x150px.",
      type: "imagewithPreview",
      value: values.image,
      url: values.imageUrl,
      error: errors.image,
      limitValidation: false,
      className:
        "w-full aspect-video rounded overflow-hidden border cursor-pointer",
    },
  ];
  return (
    <form id="add-social-link" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4">
        {contentForm.map((item) => {
          switch (item.type) {
            case "text":
              return (
                <InputField
                  title={item.title}
                  fieldName={item.name}
                  placeholder={item.placeholder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={item.value}
                  type="text"
                  required={item.required}
                  maxLength={item.maxLength}
                  error={item.error}
                  limitValidation={item.limitValidation}
                />
              );
            case "imagewithPreview":
              return (
                <ImageField
                  fieldName={item.name}
                  placeholder={item.placeholder}
                  onChange={(e) => {
                    // set image in thumbnail field and url in thumbnailUrl field
                    let event = {
                      target: { name: item.name, value: e.target.files[0] },
                    };
                    handleChange(event);
                    let urlEvent = {
                      target: {
                        name: item.urlName,
                        value: URL.createObjectURL(e.target.files[0]),
                      },
                    };
                    handleChange(urlEvent);
                  }}
                  onBlur={handleBlur}
                  url={item.url}
                  value={item.value}
                  className={item.className}
                  error={item.error}
                  // imageStyle={item.imageStyle}
                  // placeholderStyle={item.placeholderStyle}
                />
              );
          }
        })}
      </div>
      <div className="flex justify-end my-5">
        <LoadingButton
          id="add-social-link"
          type="submit"
          loading={loading}
          disabled={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </form>
  );
};

const UpdateSocialLink = ({ config, onClose, id }: IAddSocialLinkProps) => {
  const dispatch = useDispatch();

  // fiter the config to get the social link
  const socialLink = config?.social_links?.filter(
    (item: any) => item.id === id
  )[0];

  const [loading, setLoading] = useState(false);
  const updateSocialLinkFunc = async (values: any, action: any) => {
    const fromData = new FormData();
    fromData.append("name", values.name);
    fromData.append("href", values.href);
    fromData.append("newTab", values.newTab);
    if (values.image) fromData.append("icon", values.image);
    fromData.append("status", values.status);
    try {
      setLoading(true);
      const res = await updateSocialMediaEndpoint(
        config._id,
        `action=update&socialId=${id}`,
        fromData
      );
      setLoading(false);
      if (res.status === 200) {
        toast.success("Social media link added successfully");
        onClose();
        dispatch(fetchConfig() as any);
        setLoading(false);
        action.resetForm();
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.error.message || "Something went wrong");
    }
  };

  const initialValues = {
    name: socialLink.name,
    href: socialLink.href,
    newTab: socialLink.newTab,
    image: "",
    imageUrl: socialLink.icon,
    status: socialLink.status,
  };

  const updateSocialMediaSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(3, "Name is too short")
      .max(50, "Name is too long"),
    href: Yup.string().required("Href is required").min(3, "Href is too short"),
    newTab: Yup.boolean().required(),

    image: Yup.mixed()
      .optional()
      .test("fileSize", "File too large limit is 1mb", (value: any) => {
        if (!value) return true;
        return value && value.size <= 1024 * 1024 * 1; // 1mb
      })
      .test(
        "fileFormat",
        "Unsupported Format. try JPEG, PNG, JPG, WEBP",
        (value: any) => {
          if (!value) return true;
          return (
            value &&
            ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(
              value.type
            )
          );
        }
      ),
    status: Yup.boolean().required(),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: updateSocialMediaSchema,
    onSubmit: (values, action) => {
      updateSocialLinkFunc(values, action);
    },
  });

  const contentForm = [
    {
      name: "name",
      placeholder: "Type name here",
      type: "text",
      value: values.name,
      error: errors.name,
      required: true,
      maxLength: 50,
      limitValidation: true,
    },
    {
      name: "href",
      title: "href link",
      placeholder: "Type href her",
      type: "text",
      value: values.href,
      limitValidation: false,
    },

    {
      name: "image",
      urlName: "imageUrl",
      placeholder: "Please upload a thumbnail 1280x720px.",
      type: "imagewithPreview",
      value: values.image,
      url: values.imageUrl,
      error: errors.image,
      limitValidation: false,
      className:
        "w-full aspect-video rounded overflow-hidden border cursor-pointer",
    },
  ];
  return (
    <form id="update-social-link" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4">
        {contentForm.map((item) => {
          switch (item.type) {
            case "text":
              return (
                <InputField
                  title={item.title}
                  fieldName={item.name}
                  placeholder={item.placeholder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={item.value}
                  type="text"
                  required={item.required}
                  maxLength={item.maxLength}
                  error={item.error}
                  limitValidation={item.limitValidation}
                />
              );
            case "imagewithPreview":
              return (
                <ImageField
                  fieldName={item.name}
                  placeholder={item.placeholder}
                  onChange={(e) => {
                    // set image in thumbnail field and url in thumbnailUrl field
                    let event = {
                      target: { name: item.name, value: e.target.files[0] },
                    };
                    handleChange(event);
                    let urlEvent = {
                      target: {
                        name: item.urlName,
                        value: URL.createObjectURL(e.target.files[0]),
                      },
                    };
                    handleChange(urlEvent);
                  }}
                  onBlur={handleBlur}
                  url={item.url}
                  value={item.value}
                  className={item.className}
                  error={item.error}
                  // imageStyle={item.imageStyle}
                  // placeholderStyle={item.placeholderStyle}
                />
              );
          }
        })}
      </div>
      <div className="form-control mt-2">
        <label className="label cursor-pointer">
          <span>Publish</span>
          <Switch
            checked={values.status}
            onChange={(e) => {
              let event = {
                target: { name: "status", value: e.target.checked },
              };
              handleChange(event);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </label>
      </div>
      <div className="form-control mt-2">
        <label className="label cursor-pointer">
          <span>Open in new tab</span>
          <Switch
            checked={values.newTab}
            onChange={(e) => {
              let event = {
                target: { name: "newTab", value: e.target.checked },
              };
              handleChange(event);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </label>
      </div>
      <div className="flex justify-end my-5">
        <LoadingButton
          id="update-social-link"
          type="submit"
          loading={loading}
          disabled={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </form>
  );
};

const Settings = () => {
  const dispatch = useDispatch();
  const { config } = useSelector((state: RootState) => state.config);
  const { credentials } = config;
  const [loading, setLoading] = useState(false);
  const [fullScreenDialog, setFullScreenDialog] = useState(false);
  const fullScreenDialogOpen = () => {
    setFullScreenDialog(true);
  };
  const handleFullScreenDialogClose = () => {
    setFullScreenDialog(false);
  };
  const [fullScreenDialogConfig, setFullScreenDialogConfig] =
    useState<IFullScreenDialogConfig>({
      title: "",
      children: <></>,
    });

  const [settings, setSettings] = useState({
    name: config.name,
    description: config.description,
    siteUrl: config.siteUrl,
    seo_title: config.seo_title,
    meta_description: config.meta_description,
    google_teg_manager: config.google_teg_manager,
    copy_right_text: config.copy_right_text,
    logo: "",
    logoUrl: config.logo,
    favicon: "",
    faviconUrl: config.favicon,
    fcm: {
      server_key: credentials?.fcm?.server_key || "",
    },
  });

  const [addSocial, setAddSocial] = useState<IAddSocial>({
    name: "",
    newTab: false,
    href: "",
    image: "",
    imageUrl: false,
  });

  const [updateSocial, setUpdateSocial] = useState<IAddSocial>({
    id: "",
    name: "",
    newTab: false,
    href: "",
    image: "",
    imageUrl: false,
  });

  useEffect(() => {
    setSettings({
      name: config.name,
      description: config.description,
      siteUrl: config.siteUrl,
      seo_title: config.seo_title,
      meta_description: config.meta_description,
      google_teg_manager: config.google_teg_manager,
      copy_right_text: config.copy_right_text,
      logo: "",
      logoUrl: config.logo,
      favicon: "",
      faviconUrl: config.favicon,
      fcm: {
        server_key: credentials?.fcm?.server_key || "",
      },
    });
  }, [config]);

  const handleLogoChange = (e: any) => {
    // get object url and set it to logoUrl
    if (e.target.files[0]) {
      const logoUrl = URL.createObjectURL(e.target.files[0]);
      setSettings({ ...settings, logoUrl, logo: e.target.files[0] });
    }
  };

  const handleIconChange = (e: any) => {
    // get object url and set it to faviconUrl
    if (e.target.files[0]) {
      const faviconUrl = URL.createObjectURL(e.target.files[0]);
      setSettings({ ...settings, faviconUrl, favicon: e.target.files[0] });
    }
  };

  const updateSettings = async () => {
    if (!config._id) return toast.error("Config not found");
    setLoading(true);
    const formData = new FormData();
    formData.append("name", settings.name);
    formData.append("description", settings.description);
    formData.append("siteUrl", settings.siteUrl);
    formData.append("seo_title", settings.seo_title);
    formData.append("meta_description", settings.meta_description);
    formData.append("google_teg_manager", settings.google_teg_manager);
    formData.append("copy_right_text", settings.copy_right_text);
    // append fcm object
    formData.append("fcm", JSON.stringify(settings.fcm));

    if (settings.logo) {
      formData.append("logo", settings.logo);
    }
    if (settings.favicon) {
      formData.append("favicon", settings.favicon);
    }
    try {
      const response = await updateSettingApi(config._id, formData);
      if (response.status === 200) {
        toast.success("Settings updated successfully");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  // handledeleteSocial
  const handledeleteSocial = async (id: string) => {
    try {
      const response = await SocialMediaEndpoint(
        config._id,
        `action=delete&socialId=${id}`
      );
      if (response.status === 200) {
        toast.success("Social deleted successfully");
        dispatch(fetchConfig() as any);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout>
      <div className="pr-5">
        <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
          Site Configuration
        </h1>
        <p className="mt-2 opacity-70">
          Edit your site configuration here and save. You can change your site
          name, description, logo and icon.
        </p>
      </div>
      <div className="my-5">
        <Hr />
      </div>
      <h6 className="opacity-70 pr-5">
        * – Required fields. Enter all fields.
      </h6>
      <h1 className="my-6 opacity-80 font-medium tracking-wide text-xl pr-5">
        Site details
      </h1>
      <h6 className="opacity-70 pr-5">
        set your site name, description, logo and icon.
      </h6>
      <div className="my-14 pr-36 flex flex-col gap-8">
        <InputField
          title="Site Name"
          fieldName="site-name"
          type="text"
          placeholder="Enter your site name"
          value={settings.name}
          onChange={(e) => setSettings({ ...settings, name: e.target.value })}
        />
      </div>

      <h1 className="my-6 opacity-80 font-medium tracking-wide text-xl pr-5">
        Graphics
      </h1>
      <div className="my-16 pr-36 flex flex-col gap-8">
        <div className="flex w-full items-center">
          <Title required={true} className="w-1/3">
            Logo
          </Title>
          <div className="w-full p-10 border border-gray-700 rounded">
            <div className="flex flex-col justify-center items-center">
              <input
                type="file"
                className="hidden"
                id="logo"
                onChange={handleLogoChange}
              />
              <div className="relative">
                <label htmlFor="logo" className="cursor-pointer">
                  <img
                    src={settings.logoUrl}
                    className="w-60 h-60 object-contain rounded-3xl shadow-sm bg-dark-color-box text-center p-5"
                  />
                </label>
              </div>

              <div className="mt-8">
                <label
                  htmlFor="logo"
                  className="cursor-pointer flex items-center gap-3"
                >
                  <div className="mt-[2px]">
                    <UploadIcon width={22} height={22} />
                  </div>
                  <p className="opacity-70 text-lg font-medium cursor-pointer">
                    Replace
                  </p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center">
          <Title required={true} className="w-1/3">
            Favicon
          </Title>
          <div className="w-full p-10 border border-gray-700 rounded">
            <div className="flex flex-col justify-center items-center">
              <input
                type="file"
                className="hidden"
                id="icon"
                onChange={handleIconChange}
              />
              <div className="relative">
                <label htmlFor="icon" className="cursor-pointer">
                  <img
                    src={settings.faviconUrl}
                    className="w-60 h-60 object-contain rounded-3xl shadow-sm bg-dark-color-box text-center p-5"
                  />
                </label>
              </div>

              <div className="mt-8">
                <label
                  htmlFor="icon"
                  className="cursor-pointer flex items-center gap-3"
                >
                  <div className="mt-[2px]">
                    <UploadIcon width={22} height={22} />
                  </div>
                  <p className="opacity-70 text-lg font-medium cursor-pointer">
                    Replace
                  </p>
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-24 pr-5">
          <h1 className="text-xl opacity-80 font-semibold tracking-wide">
            Firebase Cloud Messaging
          </h1>
          <p className="mt-2 opacity-70">
            Firebase Cloud Messaging, formerly known as Google Cloud Messaging,
            is a cross-platform cloud solution for messages and notifications
            for Android, iOS, and web applications.
          </p>
        </div> */}

        {/* <div className="my-10 flex flex-col gap-8 pr-5">
          <InputField
            fieldName="server-key"
            type="text"
            placeholder="Enter your server key"
            value={settings?.fcm?.server_key || ""}
            onChange={(e) =>
              setSettings({
                ...settings,
                fcm: { ...settings.fcm, server_key: e.target.value },
              })
            }
          />
        </div> */}

        <div className="pr-5 mt-24">
          <h1 className="text-xl opacity-80 font-semibold tracking-wide">
            Copy Right Text
          </h1>
          <p className="mt-2 opacity-70">
            Copy right text will be shown in the footer of your site.
          </p>
        </div>

        <div className="mb-5 mt-2 flex flex-col gap-8 pr-5">
          <InputField
            title="Copy right text"
            fieldName="copy_right_text"
            type="text"
            placeholder="Enter your copy right text"
            value={settings.copy_right_text || ""}
            onChange={(e) =>
              setSettings({ ...settings, copy_right_text: e.target.value })
            }
          />
        </div>

        <div className="pr-5">
          <h1 className="text-xl opacity-80 font-semibold tracking-wide">
            Manage your social media links. recommended limit is 4.
          </h1>
          <p className="mt-2 opacity-70">
            You can add your social media links here. These links will be shown
            in the footer of your site.
          </p>
        </div>

        <div className="grid grid-cols-3 gap-5 mt-8 mb-10">
          {config &&
            config?.social_links &&
            config?.social_links.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className="bg-secondary-color-800 px-4 py-3 rounded flex justify-between items-center h-16"
                >
                  <div className="flex items-center gap-5">
                    <img
                      src={item.imageUrl || item.icon}
                      className="w-10 h-fit object-contain rounded shadow-sm"
                    />
                    <h1 className="text-white">{item.name}</h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <Button
                      onClick={() => {
                        fullScreenDialogOpen();
                        setFullScreenDialogConfig({
                          title: "Update Social Link",
                          children: (
                            <UpdateSocialLink
                              id={item.id}
                              config={config}
                              onClose={handleFullScreenDialogClose}
                            />
                          ),
                        });
                      }}
                    >
                      <EditIcon fontSize="medium" color="primary" />
                    </Button>
                    <Button
                      className="cursor-pointer"
                      onClick={() => {
                        handledeleteSocial(item.id);
                      }}
                    >
                      <DeleteOutlineIcon fontSize="medium" color="error" />
                    </Button>
                  </div>
                </div>
              );
            })}

          <div
            onClick={() => {
              fullScreenDialogOpen();
              setFullScreenDialogConfig({
                title: "Add Social Link",
                children: (
                  <AddSocialLink
                    config={config}
                    onClose={handleFullScreenDialogClose}
                  />
                ),
              });
            }}
            className="bg-secondary-color-800 px-4 py-3 rounded flex justify-center items-center btn border-none h-16"
          >
            <AddIcon fontSize="large" />
          </div>
        </div>

        <div className="flex justify-end">
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={updateSettings}
          >
            Save
          </LoadingButton>
        </div>
      </div>

      <FullScreenDialog
        title={fullScreenDialogConfig.title}
        open={fullScreenDialog}
        onClose={handleFullScreenDialogClose}
      >
        <div className="py-4 container m-auto max-w-3xl">
          {fullScreenDialogConfig.children}
        </div>
      </FullScreenDialog>
    </Layout>
  );
};

export default Settings;
