interface SearchBarProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    className?: string;
    value?: string;
}

const SearchBar = ({ onChange, placeholder = "Search", className, value }: SearchBarProps) => {
    return (
        <div className={`flex items-center ${className}`}>
            <input
                type="text"
                className="bg-transparent rounded px-4 py-2.5 focus:outline-none border-0 h-full font-medium w-full"
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>

    )
}

export default SearchBar;