// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import _ from "lodash";
import { TailwindBox } from "../../../../../tailwind";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useQuery } from "@tanstack/react-query";
import { IUserAnalytics } from "../Users/Users";
import { userAnalytics } from "../../../../../http/Apis";
import { BiRefresh } from 'react-icons/bi'
import { counter } from "../../../../../utils/Utils";

const UserAnalytics = () => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const UserAnalyticsQuery = useQuery<{ data: IUserAnalytics }>({
        queryKey: ['UserAnalyticsQuery'],
        queryFn: () => userAnalytics()
    })

    return (
        <TailwindBox title="Subscribers">
            {
                UserAnalyticsQuery.isLoading && <>
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full -mt-4">
                            <div className="">
                                <div className="flex justify-between items-center mt-5 text-sm">
                                    <h6>
                                        Paid Subscribers
                                    </h6>
                                    <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                UserAnalyticsQuery.isError && <div>
                    <div className="w-full h-full flex justify-center items-center mb-3">
                        <div className='min-h-[15rem] justify-center flex flex-col items-center'>
                            <h1 className='text-sm font-medium opacity-90'>
                                Server Error. Please try again
                            </h1>
                            <div
                                onClick={() => UserAnalyticsQuery.refetch()}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                <BiRefresh className={`text-black text-lg bg-white rounded-full ${UserAnalyticsQuery.isLoading || UserAnalyticsQuery.isFetching && "animate-spin"}`} />
                                <h1 className='text-sm text-white'>
                                    Refresh
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                UserAnalyticsQuery.isSuccess && <>
                    <div className="flex flex-wrap mt-4">
                        <div className="w-full -mt-4">
                            <div className="">
                                <div className="flex justify-between items-center mt-5 text-sm">
                                    <h6>
                                        Paid Subscribers
                                    </h6>
                                    <p>{counter(UserAnalyticsQuery.data.data.data.subscriber || 0)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </TailwindBox>
    )
}

export default UserAnalytics