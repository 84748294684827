import Title from "../SmallComponets/Title";
import Multiselect from 'multiselect-react-dropdown';
import { CloseIcon } from "../Icon";
import { useDispatch } from "react-redux";

const Input = ({
    placeholder = "Placeholder",
    type = "text",
    marginVertical = "",
    inputType = "input",
    cols = "",
    rows = "",
    value,
    onChange = () => { },
    defaultValue,
    titleStyle,
    title = false,
    titleText = "",
    requiredTitle = false,
    singleSelect = false,
    options,
    selectedValue,
    onSelect,
    onRemove,
    width = "w-1/3",
    inputWidth = "w-full",
    file = false,
    multiple = false,
    name = "",
    multipleFile = false,
    selectionLimit = 1,
    checked = false,
    titleWidth = "w-1/3",
    onKeyDown = () => { },
    nonvalue = false,
    tagvalue = [],
    productId = "",
    galleryUrlLive = [],
    style = 'border px-5 py-3 rounded border-gray-700 mt-3 w-full',
    style2,
    className = "",
}) => {
    const dispatch = useDispatch();
    return (
        <>
            {
                inputType === "input" && <div className={`${style2 ? '' : style}`}>
                    {title && <Title required={requiredTitle} className={`${titleWidth} ${titleStyle}`}>{title}</Title>}
                    <input
                        className={`${inputWidth} bg-transparent border-none focus:outline-none text-white font-medium mt-2 w-full ${className}`}
                        type={type}
                        placeholder={placeholder}
                        value={value || ''}
                        onChange={onChange || null}
                        onKeyDown={onKeyDown || null}
                    />
                </div>
            }
            {
                inputType === "nonvalue" && <> <div className='border px-5 py-3 rounded border-gray-700 items-center'>
                    {title && <Title required={requiredTitle} className={`${titleWidth}`}>{title}</Title>}
                    <input
                        className={`${inputWidth} bg-transparent border-none focus:outline-none text-white font-medium mt-2 `}
                        type={type}
                        placeholder={placeholder}
                        onKeyDown={onKeyDown || null}
                    />
                </div>
                </>
            }
            {
                inputType === "select" && <div className='flex items-center'>
                    {title && <Title required={requiredTitle} className='w-1/3'>{title}</Title>}
                    <div className="w-full">
                        <Multiselect
                            className="w-full bg-secondary-color-800 py-0.5 px-2 rounded-md -m-0.5"
                            placeholder={placeholder}
                            singleSelect={singleSelect}
                            options={options} // Options to display in the dropdown
                            selectedValues={selectedValue} // Preselected value to persist in dropdown
                            onSelect={onSelect} // Function will trigger on select event
                            onRemove={onRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            selectionLimit={selectionLimit}
                        />
                    </div>


                </div>
            }
            {
                inputType === "checkbox" && <div className='flex items-center'>
                    {title && <Title required={requiredTitle} className={`${width}`}>{title}</Title>}
                    <div className="w-full">
                        <input
                            type="checkbox"
                            className="toggle mt-1.5 toggle-accent bg-slate-900"
                            checked={checked}
                            onChange={onChange}
                        />
                    </div>
                </div>
            }


            {
                inputType === "textarea" &&
                <div className={`border px-5 py-3 rounded border-gray-700 mt-3 w-full`}>
                    {/* <p className={`'text-gray-300 text-xs`}>Description (required)</p> */}
                    {title && <Title required={requiredTitle} className='w-1/3'>{title}</Title>}
                    <textarea
                        className="bg-transparent border-none focus:outline-none text-white font-medium mt-2 w-full"
                        name="description"
                        id=""
                        cols="30"
                        rows="10"
                        placeholder={placeholder}
                        onChange={onChange}
                        defaultValue={defaultValue}
                        value={value}
                    >
                    
                    </textarea>
                </div>
            }


            {
                inputType === "file" && <> <div className='flex items-center'>
                    {title && <Title required={requiredTitle} className={`${width}`}>{title}</Title>}
                    <div className="w-full bg-secondary-color-800 rounded-md flex">
                        <div className="bg-slate-700 py-2 px-3 rounded-sm text-lg"><span>Browse</span></div>
                        <label className="py-2.5 px-3 " htmlFor="file_input">{selectedValue ? selectedValue : 'Choose file'}</label>
                    </div>
                    <input
                        id="file_input"
                        type="file"
                        name={name}
                        onChange={onChange}
                    />
                </div>
                </>
            }

            {
                inputType === "multipleFile" && <> <div className='flex items-center'>
                    {title && <Title required={requiredTitle} className={`${width}`}>{title}</Title>}
                    <div className="w-full bg-secondary-color-800 rounded-md flex">
                        <div className="bg-slate-700 py-2 px-3 rounded-sm text-lg"><span>Browse</span></div>
                        <label className="py-2.5 px-3 " htmlFor="file_input_multiple">{selectedValue ? selectedValue : 'Choose file'}</label>
                    </div>
                    <input
                        multiple
                        id="file_input_multiple"
                        type="file"
                        name={name}
                        onChange={onChange}
                    />
                </div>
                   
                </>
            }
        </>
    );
}

export default Input;