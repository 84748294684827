import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { login } from "../../../http";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../redux/slice​/authSlice";
import Logo from "../../../components/Logo/Logo";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ClientJS } from "clientjs";
import { publicIpv4 } from "public-ip";

const Login = () => {
  const { config } = useSelector((state: RootState) => state.config);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [fingerprint, setFingerprint] = useState<number>();
  const [browser, setBrowser] = useState<string>();
  const [ip, setIp] = useState<string>();
  const [os, setOs] = useState<string>();

  useEffect(() => {
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();
    const browser = client.getBrowser();
    const os = client.getOS();

    async function AllData() {
      setFingerprint(fingerprint);
      setBrowser(browser);
      setOs(os);
      setIp(await publicIpv4());
    }
    AllData();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Fingerprint: fingerprint || "",
      browser: browser || "",
      os: os || "",
      ipAddress: ip || "",
    };
    e.preventDefault();
    try {
      if (email === "" || email === null) {
        toast.error("Email is required");
        return;
      }
      // email validation using regex
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        toast.error("Invalid email address");
        return;
      }
      if (password === "" || password === null) {
        toast.error("Password is required");
        return;
      }
      if (fingerprint === undefined || fingerprint === null) {
        toast.error("Something went wrong");
        return;
      }
      setLoading(true);
      const response = await login({ email, password }, headers);
      if (response.status === 200) {
        setLoading(false);
        const { user, accessToken, refreshToken } = response.data.data;

        if (user.role === "admin") {
          dispatch(setAuth(user));
          toast.success("Login Successful");
          // window.location.href = "/admin/dashboard";
        } else {
          toast.error("Your email or password is incorrect!");
        }
      } else {
        setLoading(false);
        toast.error("Your email or password is incorrect!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Your email or password is incorrect!");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-[url('https://static.zohocdn.com/iam/v2/components/images/bg.49756b7c711696d95133fa95451f8e13.svg')] h-screen w-screen flex justify-center items-center relative">
        <div className="w-[500px] h-[410px] shadow-2xl px-10 py-8 bg-white">
          <Logo className="w-24 -ml-1" />
          <h1 className="text-black text-xl font-semibold opacity-95 mt-4">
            Sign in
          </h1>
          <p className="text-black opacity-80 tracking-wide mt-1">
            to access Admin Area
          </p>
          <div className="mt-8">
            <form onSubmit={onSubmit} className="flex flex-col">
              <input
                type="email"
                placeholder="Email"
                className="border w-full border-gray-300 px-3.5 py-3 focus:outline-none text-black font-medium opacity-80"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                placeholder="Password"
                className="border w-full  border-gray-300 px-3.5 py-3 focus:outline-none text-black font-medium opacity-80 mt-4"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                disabled={loading}
                type="submit"
                className="bg-[#159AFF] text-white uppercase font-semibold tracking-wide w-full py-3 mt-6 text-lg focus:outline-none"
              >
                {loading ? "Loading..." : "Login"}
              </button>
            </form>
          </div>
        </div>

        <p className="absolute bottom-8">
          © {new Date().getFullYear()} {config.name}. All rights reserved.
          Powered by{" "}
          <a
            className="text-blue-600"
            href="https://zezosoft.com"
            target="_blank"
            rel="noreferrer"
          >
            Zezo
          </a>
        </p>
      </div>
    </>
  );
};

export default Login;
