import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useMutation, useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { getAppSettingsApi, updateAppSettingsApi } from '../../../http/app.settings.http';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import SaveIcon from "@mui/icons-material/Save";
import { UploadIcon } from '../../../tailwind/Icon';


function AppLogo() {
    const [appLogo, setAppLogo] = useState<AppSettingsApiResponse['data']['logo']>({} as AppSettingsApiResponse['data']['logo']);
    const [logoSource, setLogoSource] = useState<string | null>(null);
    const queryStrings = qs.stringify({
        fields: ["logo"],
    }, { encodeValuesOnly: true });
    // get app splash screen settings
    const appSplashScreenQuery = useQuery<AppSettingsApiResponse>({
        queryKey: ["getAppLogo"],
        queryFn: () => getAppSettingsApi(queryStrings),
        refetchOnWindowFocus: false,
        retryOnMount: false,
        onSuccess: (res) => {
            setAppLogo(res.data.logo);
        }
    })

    const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { files } } = e
        // get object url and set it to logoUrl
        if (files && files.length > 0) {
            const splesh_logoUrl = URL.createObjectURL(files[0]);
            setLogoSource(splesh_logoUrl);
            setAppLogo(files[0] as any);
        }
    };

    // update the app splash screen settings using muteion
    const AppLogoUpdateMutation = useMutation({
        mutationKey: ["updateAppLogo"],
        mutationFn: (payload: any) => updateAppSettingsApi<any>(payload, "logo", {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }),
        onSuccess(data, variables, context) {
            toast.success("Logo updated successfully");
        },
        onError(error, variables, context) {
            console.log("🚀 ~ file: AppSplashScreen.tsx:105 ~ onError ~ error:", error)
            toast.error("Error updating app logo");
        },
    })

    const handleAppSpleshScreenUpdate = () => {
        const formData = new FormData();
        formData.append("source", appLogo);
        AppLogoUpdateMutation.mutate(formData);
    }

    return (
        <div className='pr-6'>
            <h1 className="my-6 opacity-80 font-medium tracking-wide text-xl pr-5">
                App Logo
            </h1>
            {
                appSplashScreenQuery.isLoading && (
                    <div className="flex justify-center items-center">
                        <div className="w-10 h-10 border-t-2 border-primary rounded-full animate-spin"></div>
                    </div>
                )
            }
            {
                appSplashScreenQuery.isError && (
                    <div className="flex justify-center items-center">
                        <div className="text-red-500">Error fetching data</div>
                    </div>
                )
            }
            {
                appSplashScreenQuery.isSuccess && (
                    <>
                        <div className="w-full p-10 border border-gray-700 rounded">
                            <div className="flex flex-col justify-center items-center">
                                <input
                                    type="file"
                                    className="hidden"
                                    id="logo"
                                    onChange={handleLogoChange}
                                />
                                <div className="relative">
                                    <label htmlFor="logo" className="cursor-pointer">
                                        <img
                                            src={logoSource ? logoSource : appLogo}
                                            className="w-60 h-60 object-contain rounded-3xl shadow-sm bg-dark-color-box text-center p-5"
                                        />
                                    </label>
                                </div>
                                <div className="mt-8">
                                    <label
                                        htmlFor="logo"
                                        className="cursor-pointer flex items-center gap-3">
                                        <div className="mt-[2px]">
                                            <UploadIcon width={22} height={22} />
                                        </div>
                                        <p className="opacity-70 text-lg font-medium cursor-pointer">
                                            Replace
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end my-10">
                            <LoadingButton
                                loading={AppLogoUpdateMutation.isLoading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                onClick={handleAppSpleshScreenUpdate}
                            >
                                Save
                            </LoadingButton>
                        </div>
                    </>

                )
            }
        </div>
    )
}

export default AppLogo
