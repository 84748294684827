// Author: Pukhraj Dhamu
// Date Created: Monday 12 July 2023 at 12:55 PM
// Date Modified: Monday 12 July 2023 at 12:55 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const blogsApis = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    }
});

// list all endpoints
export const initDefaultPagesApi = () => blogsApis.get("/blog/create-default");
export const getBlogs = (queryString?: string) => blogsApis.get(`/blog/blogs${queryString ? `?${queryString}` : ""}`);


blogsApis.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

export default blogsApis;