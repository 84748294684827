import { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { InputField, TextAreaField } from "../../tailwind";
import { useSelector } from "react-redux";
import {
  addAdUnits,
  addPublisherID,
  addPublisherIDS,
  deleteAdUnits,
  getAdUnits,
  getPublisherID,
  updateAdUnits,
  updatePublisherID,
  updateSettingApi,
} from "../../http/Apis";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import axios from "axios";

interface IAdunit {
  _id?: string;
  name: string;
  unit_id: string;
  formate: string;
}

const AdMobSettings = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { config } = useSelector((state: RootState) => state.config);
  //   const { darkMode } = useSelector((state: RootState) => state.theme);
  const { credentials } = config;
  const [publisherIdPop, setPublisherIdPop] = useState(false);
  const [adunitPop, setAdUnitPop] = useState(false);
  const [publisherID, setPublisherID] = useState("");

  const [actualPublisherID, setActualPublisherID] = useState({
    _id: "",
    publisher_id: "",
  });
  const [adUnitID, setAdUnitID] = useState("");
  const [formate, setFormate] = useState("Choose a Formate");
  const [name, setName] = useState("");
  const [adUnitArr, setAdUnitArr] = useState<IAdunit[]>([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState({
    status: false,
    id: "",
  });
  const [isEditPublisher, setIsEditPublisher] = useState(false);
  const [seo, setSeo] = useState({
    name: config.name,
    description: config.description,
    siteUrl: config.siteUrl,
    seo_title: config.seo_title,
    meta_description: config.meta_description,
    meta_keywords: config.meta_keywords || [],
    google_teg_manager: config.google_teg_manager,
    logo: "",
    logoUrl: config.logo,
    favicon: "",
    faviconUrl: config.favicon,
    fcm: {
      server_key: credentials?.fcm?.server_key || "",
    },
  });

  useEffect(() => {
    if (user) {
      const timer = setTimeout(() => {
        setSeo({
          name: config.name,
          description: config.description,
          siteUrl: config.siteUrl,
          seo_title: config.seo_title,
          meta_description: config.meta_description,
          meta_keywords: config.meta_keywords,
          google_teg_manager: config.google_teg_manager,
          logo: "",
          logoUrl: config.logo,
          favicon: "",
          faviconUrl: config.favicon,
          fcm: {
            server_key: credentials?.fcm?.server_key || "",
          },
        });
        // if config is empty object
        if (!Object.keys(config).length) {
          toast.error("Error to get config");
        }
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [config]);

  const updateSeoSettings = async () => {
    setLoading(true);
    try {
      // data validation
      if (
        !seo?.seo_title ||
        !seo?.meta_description ||
        !seo?.google_teg_manager
      ) {
        toast.error("Please fill all the fields");
        setLoading(false);
        return;
      }
      const formData = new FormData();
      if (!config._id) return toast.error("Config not found");
      formData.append("name", seo.name);
      formData.append("description", seo.description);
      formData.append("siteUrl", seo.siteUrl);
      formData.append("seo_title", seo.seo_title);
      formData.append("meta_description", seo.meta_description);
      // append fcm object
      formData.append("fcm", JSON.stringify(seo.fcm));
      // meta_keywords
      if (seo.meta_keywords.length > 0) {
        for (let key of seo.meta_keywords.entries() as any) {
          formData.append("meta_keywords", key[1]);
        }
      }
      formData.append("google_teg_manager", seo.google_teg_manager);
      const res = await updateSettingApi(config._id, formData);
      if (res.status === 200) {
        toast.success("Updated Successfully");
        setLoading(false);
      } else {
        toast.error("Something went wrong");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPublisherID();
        const adUnits = await getAdUnits();
        setAdUnitArr(adUnits.data.data);
        setActualPublisherID({
          _id: res.data.data.length > 0 ? res.data.data[0]._id : "",
          publisher_id:
            res.data.data.length > 0 ? res.data.data[0].publisher_id : "",
        });
      } catch (error) {
        return console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleAddPublisherID = async () => {
    try {
      if (publisherID === "") {
        return;
      }
      setLoading(true);

      let res = await addPublisherID({
        publisherID: publisherID,
      });
      if (res.status === 200) {
        setLoading(false);
        // setActualPublisherID(res.data.data.publisher_id);
        setActualPublisherID({
          _id: res.data.data._id,
          publisher_id: res.data.data.publisher_id,
        });

        setPublisherIdPop(false);
      }

      console.log(res);
    } catch (error) {
      setLoading(false);
      return console.log(error);
    }
  };

  const handleUpdatePublisherID = async () => {
    try {
      // let res = await updatePublisherID();
      if (publisherID === "") {
        return;
      }
      let obj = {
        newPublisherID: publisherID,
      };
      console.log(obj);
      console.log(actualPublisherID._id);

      let res = await updatePublisherID(actualPublisherID._id, obj);
      console.log(res);

      if (res.status === 200) {
        setPublisherIdPop(false);
        setIsEditPublisher(false);
        setActualPublisherID({
          ...actualPublisherID,
          ["publisher_id"]: publisherID,
        });
      }
    } catch (error) {
      return console.log(error);
    }
  };
  const handleAddUnitID = async () => {
    try {
      // console.log(adUnitID);
      // console.log(formate);
      // console.log(name);

      if (name === "" || adUnitID === "" || formate === "Choose a Formate") {
        return;
      }

      setLoading(true);
      let adunitObj = {
        name,
        unit_id: adUnitID,
        formate,
      };
      console.log(adunitObj);


      let res = await addAdUnits(adunitObj);

      console.log(res);

      if (res.status === 200) {
        setLoading(false);
        setAdUnitPop(false);
        setName("");
        setFormate("");
        setAdUnitID("");
        // let newArr = ;
        setAdUnitArr([...adUnitArr, res.data.data]);
      }
    } catch (error) {
      // setLoading(false);
      return console.log(error);
    }
  };

  const handleDeleteAdUnitID = async (id: string) => {
    try {
      let res = await deleteAdUnits(id);

      if (res.status === 200) {
        const idx = adUnitArr.findIndex((item: any) => {
          return item._id === id;
        });

        adUnitArr.splice(idx, 1);
        console.log(adUnitArr);
        setAdUnitArr([...adUnitArr]);
      }
      // console.log(res);
    } catch (error) {
      return console.log(error);
    }
  };

  const handleAdUnitEdit = async () => {
    try {
      if (name === "" || adUnitID === "" || formate === "Choose a Formate") {
        return;
      }
      setLoading(true);

      let adunitObj = {
        name,
        unit_id: adUnitID,
        formate,
      };
      console.log(adunitObj);

      let res = await updateAdUnits(isEdit.id, adunitObj);

      if (res.status === 200) {
        setName("");
        setAdUnitID("");
        setFormate("");
        setIsEdit({
          status: false,
          id: "",
        });
        setAdUnitPop(false);
        setLoading(false);

        const idx = adUnitArr.findIndex((item: any) => {
          return item._id === isEdit.id;
        });

        adUnitArr[idx].name = name;
        adUnitArr[idx].unit_id = adUnitID;
        adUnitArr[idx].formate = formate;
        console.log(adUnitArr);
        setAdUnitArr([...adUnitArr]);
      }

      console.log(res);
    } catch (error) {
      return console.log(error);
    }
  };

  return (
    <Layout>
      <div className="pr-5">
        <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
          Admob Settings
        </h1>
        <p className="mt-2 opacity-70">
          You can update your Admob settings here. You can get your Admob
          credentials from{" "}
          <a
            href="https://apps.admob.com/v2/home"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            here
          </a>
        </p>
        <p className="mt-1 text-red-700 text-sm">
          Note: admob is in beta version and will be available soon.
        </p>
      </div>

      <div className="my-5">
        <Hr />
      </div>

      <div className="mt-5 pr-5 flex w-full justify-evenly items-center">
        <div className="w-1/2">
          <h1 className="text-xl opacity-80 font-semibold tracking-wide">
            Admob Publisher ID
          </h1>
          <p className="mt-2 opacity-70">
            You can get your Admob Publisher ID from{" "}
            <a
              href="https://apps.admob.com/v2/home"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              here
            </a>
          </p>
        </div>

        <div className="w-1/2">
          {actualPublisherID.publisher_id === "" && (
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={() => {
                setPublisherIdPop(true);
              }}
            >
              Add publisher ID
            </button>
          )}
          {actualPublisherID.publisher_id !== "" && (
            <button
              type="button"
              // disabled={true}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={() => {
                console.log("clicked");
                setAdUnitPop(true);
              }}
            >
              ADD UNIT
            </button>
          )}
        </div>
      </div>

      {actualPublisherID.publisher_id !== "" && (
        <div>
          <p>
            Publisher ID: {actualPublisherID.publisher_id}
            <span
              className="material-symbols-outlined mx-2 cursor-pointer"
              onClick={() => {
                setPublisherIdPop(true);
                setIsEditPublisher(true);
                setPublisherID(actualPublisherID.publisher_id);
              }}
              style={{
                fontSize: "10px",
              }}
            >
              edit
            </span>
          </p>
        </div>
      )}

      {adUnitArr.length > 0 && (
        <div className="relative overflow-x-auto w-full flex justify-center">
          <table className="w-3/4 mt-2 rounded-lg text-sm text-left text-gray-500 bg-gray-700 dark:text-gray-400 bg-gray-700">
            <thead className="text-xs text-gray-50 bg-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 text-center">
                  Ad unit Name
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Ad unit ID
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Ad Formate
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {adUnitArr.map((item: any, index) => {
                return (
                  <tr
                    className="text-white border-b bg-gray-800 dark:bg-gray-800 dark:border-gray-700"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="text-center text-white font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.name}
                    </th>
                    <td className="text-center px-6 py-4">{item.unit_id}</td>
                    <td className="text-center px-6 py-4">{item.formate}</td>
                    <td className="text-center px-6 py-4">
                      {" "}
                      <span
                        className="material-symbols-outlined mx-2 cursor-pointer"
                        onClick={() => {
                          setAdUnitPop(true);
                          setIsEdit({
                            status: true,
                            id: item._id,
                          });
                          setName(item.name);
                          setAdUnitID(item.unit_id);
                          setFormate(item.formate);
                        }}
                      >
                        edit
                      </span>
                      <span
                        className="material-symbols-outlined cursor-pointer"
                        style={{
                          color: "red",
                        }}
                        onClick={() => {
                          handleDeleteAdUnitID(item._id);
                        }}
                      >
                        delete
                      </span>
                    </td>
                  </tr>
                );
              })}

              {/*  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Microsoft Surface Pro
              </th>
              <td className="px-6 py-4">White</td>
              <td className="px-6 py-4">Laptop PC</td>
              <td className="px-6 py-4">$1999</td>
            </tr>
            <tr className="bg-white dark:bg-gray-800">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Magic Mouse 2
              </th>
              <td className="px-6 py-4">Black</td>
              <td className="px-6 py-4">Accessories</td>
              <td className="px-6 py-4">$99</td>
            </tr> */}
            </tbody>
          </table>
        </div>
      )}

      {/* <div className="my-5 pr-36 flex flex-col gap-8">
                <InputField
                    title="Admob Publisher ID"
                    fieldName="admob_publisher_id"
                    type="text"
                    placeholder="Enter your Google Admob Publisher ID"
                    value={seo.google_teg_manager}
                    onChange={(e) => setSeo({ ...seo, google_teg_manager: e.target.value })}
                />
                <div className="flex justify-end">
                    <div className="flex justify-end">
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            onClick={() => {}}>
                            Save
                        </LoadingButton>
                    </div>
                </div>
          </div> */}

      {publisherIdPop && (
        <div
          className="absolute top-0 left-0 w-full h-screen "
          style={{
            backgroundColor: "rgba(40,40,40,1)",
          }}
        >
          <div className="absolute top-1/2 left-1/2 w-96 rounded-lg dark:bg-gray-500 border-2 border-sky-500 -translate-x-1/2 -translate-y-1/2 p-4">
            <InputField
              title="Admob Publisher ID"
              fieldName="admob_publisher_id"
              type="text"
              placeholder="Enter your Google Admob Publisher ID"
              value={publisherID}
              onChange={(e) => {
                // setSeo({ ...seo, google_teg_manager: e.target.value })
                console.log(e.target.value);
                setPublisherID(e.target.value);
              }}
            />
            <div className="flex justify-end mt-4">
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                onClick={() => {
                  isEditPublisher
                    ? handleUpdatePublisherID()
                    : handleAddPublisherID();
                }}
              >
                {isEditPublisher ? "Update" : "Save"}
              </LoadingButton>

              <div className="mx-2">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setPublisherIdPop(false);
                  }}
                >
                  Cancle
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {adunitPop && (
        <div
          className="absolute top-0 left-0 w-full h-screen "
          style={{
            backgroundColor: "rgba(40,40,40,1)",
          }}
        >
          <div className="absolute top-1/2 left-1/2 w-96 rounded-lg dark:bg-gray-500 border-2 border-sky-500 -translate-x-1/2 -translate-y-1/2 p-4">
            <InputField
              title="Admob Unit ID"
              fieldName="ad_unit_id"
              type="text"
              placeholder="Enter your Ad Unit ID"
              value={adUnitID}
              onChange={(e) => {
                // setSeo({ ...seo, google_teg_manager: e.target.value })
                setAdUnitID(e.target.value);
              }}
            />

            <div className="my-2">
              <InputField
                title="admob unit name"
                fieldName="ad_unit_name"
                type="text"
                placeholder="Enter your Ad Unit name"
                value={name}
                onChange={(e) => {
                  // setSeo({ ...seo, google_teg_manager: e.target.value })
                  setName(e.target.value);
                }}
              />
            </div>
            <select
              id="large"
              className="my-2 block w-full px-2 py-1 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => {
                setFormate(e.target.value);
              }}
              value={formate}
            >
              <option selected>Choose a Formate</option>
              <option value="Banner">Banner</option>
              <option value="Interstitial">Interstitial</option>
              <option value="Rewarded">Rewarded</option>
            </select>

            <div className="flex justify-end mt-4">
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                onClick={() => {
                  isEdit.status ? handleAdUnitEdit() : handleAddUnitID();
                }}
              >
                {isEdit.status ? "Update" : "Save"}
              </LoadingButton>

              <div className="mx-2">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setAdUnitPop(false);
                  }}
                >
                  Cancle
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default AdMobSettings;
