// Author: Pukhraj Dhamu
// Date Created: 24 June 2022 (Friday)
// Date Modified: Sunday 21 May 2023 at 9:53 AM
import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { InputField, SelectField } from "../../tailwind";
import { useSelector, useDispatch } from "react-redux";
import { getCategories, updateFooterMenuEndpoint, updateWebSettingApi } from "../../http/Apis";
import toast from "react-hot-toast";
import { BasicTabs } from "../../components";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { fetchAppConfig, fetchConfig } from "../../redux/slice​/configSlice";
import { v4 as uuidV4 } from "uuid";
import { Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { HeaderSettings, NewReleasePoster } from "./components";


// header and footer menu type
export const menuType = [
    { name: 'Category', _id: 'category' },
    { name: 'Custom', _id: 'custom' },
]


const WebSettings = () => {
    const dispatch = useDispatch()
    const { config } = useSelector((state: RootState) => state.config)
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([])

    const fetchCategories = async () => {
        const { data } = await getCategories();
        setCategories(data.data)
    };

    interface IWebSettings {
        header: {
            menu: {
                name: string;
                link: string;
                type: string;
                icon?: string;
                id: string;
            }[]
        },
    }
    const [MenuData, setMenuData] = useState<IWebSettings['header']['menu'][0]>({
        name: "",
        link: "",
        type: "",
        icon: "",
        id: ""
    });
    const [webSettings, setWebSettings] = useState<IWebSettings>({
        header: {
            menu: []
        }
    });

    const [footerUpdate, setFooterUpdate] = useState<boolean>(false)
    const [updateFooterMenuItemId, setUpdateFooterMenuItemId] = useState<string>()

    // delete menu item by id
    const deleteMenuItem = (id: string) => {
        setWebSettings({
            header: {
                menu: webSettings.header.menu.filter(item => item.id !== id)
            }
        } as any)
    }
    // update menu item by id
    const updateMenuItem = (id: string) => {
        const item = webSettings.header.menu.find(item => item.id === id);
        if (item) {
            setMenuData(item)
            deleteMenuItem(id)
        }
    }



    useEffect(() => {
        setWebSettings({
            header: {
                menu: config.webSettings?.header?.menu as never[]
            }
        })
    }, [config]);

    useEffect(() => {
        fetchCategories();
        dispatch(fetchAppConfig() as any);
    }, []);

    const saveMenu = async () => {
        if (!config._id) return toast.error("Config not found");
        setLoading(true);
        const data = {
            menu: webSettings.header.menu
        }

        try {
            const response = await updateWebSettingApi(config._id, data);
            if (response.status === 200) {
                toast.success("Settings updated successfully");
                setLoading(false);
                dispatch(fetchConfig() as any);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error("Something went wrong");
        }
    }

    const addNewMenu = async () => {
        if (!MenuData.name) return toast.error("Menu name is required");
        if (!MenuData.link) return toast.error("Menu link is required");
        if (!MenuData.type) return toast.error("Menu type is required");
        setWebSettings({
            header: {
                menu: [
                    ...webSettings.header.menu,
                    {
                        name: MenuData.name,
                        link: MenuData.link,
                        type: MenuData.type,
                        icon: '',
                        id: uuidV4()
                    }
                ]
            }
        } as any)
        setMenuData({
            name: "",
            link: "",
            type: "",
            icon: "",
            id: ""
        })
    }

    // delete footer menu item by id
    const deleteFooterMenuItem = async (id: string) => {
        try {
            await updateFooterMenuEndpoint(config._id, `action=delete`, {
                name: 'name',
                link: 'link',
                type: 'type',
                id: id
            })
            dispatch(fetchConfig() as any)
        } catch (error) {

        }
    }
    // add footer menu item
    const addFooterMenuItem = async () => {
        try {
            const { data, status } = await updateFooterMenuEndpoint(config._id, `action=create`, {
                name: MenuData.name,
                link: MenuData.link,
                type: MenuData.type,
            })
            if (status === 200) {
                setMenuData({
                    name: '',
                    id: '',
                    link: '',
                    type: '',
                    icon: ''
                })
                dispatch(fetchConfig() as any)
            }
        } catch (error) {

        }
    }

    // update footer item prepare
    const updateFooterMenuItemPrePare = (id: string) => {
        setUpdateFooterMenuItemId(id)
        const item = config.webSettings.footer.menu.find(item => item.id === id);
        if (item) {
            setMenuData(item as any)
        }
    }
    // update footer menu item
    const updateFooterMenuItem = async () => {
        try {
            const { data, status } = await updateFooterMenuEndpoint(config._id, `action=update`, {
                name: MenuData.name,
                link: MenuData.link,
                type: MenuData.type,
                id: updateFooterMenuItemId
            })
            if (status === 200) {
                setMenuData({
                    name: '',
                    id: '',
                    link: '',
                    type: '',
                    icon: ''
                })
                dispatch(fetchConfig() as any)
                setUpdateFooterMenuItemId(undefined)
            }
        } catch (error) {
        }
    }

    return (
        <Layout>
            <div className="pr-5">
                <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                    Website Settings
                </h1>
                <p className="mt-2 opacity-70">
                    Edit your website settings here. like header, footer, logo, favicon, etc.
                </p>
            </div>
            <div className="mt-5">
                <BasicTabs
                    onChange={(e, newValue) => { }}
                    tabs={[
                        {
                            label: "Header"
                        },
                        {
                            label: "Footer"
                        },
                        {
                            label: "Release Poster"
                        }
                    ]}
                    tabPanels={[
                        {
                            children: <>
                                {/* <HeaderSettings categories={categories} /> */}
                                <h1 className="my-5 opacity-80 font-medium tracking-wide text-xl pr-5">
                                    Header Settings
                                </h1>
                                <div className="my-10 flex flex-col  gap-8">
                                    <div className="flex w-full justify-between gap-20">
                                        <div className='w-1/2 flex flex-col gap-5'>
                                            <InputField
                                                fieldName="menu_name"
                                                title="Menu Name"
                                                placeholder="Menu Name"
                                                type="text"
                                                value={MenuData.name}
                                                onChange={(e) => setMenuData({ ...MenuData, name: e.target.value })}
                                            />

                                            <SelectField
                                                options={menuType}
                                                onChange={(e) => {
                                                    console.log(e);
                                                    setMenuData({ ...MenuData, type: e._id })
                                                }}
                                                placeholder={"Select Menu Type"}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option._id}
                                            />
                                            {
                                                MenuData.type === "custom" && <InputField
                                                    fieldName="menu_link"
                                                    title="Menu Link"
                                                    placeholder="Menu Link"
                                                    type="text"
                                                    value={MenuData.link}
                                                    onChange={(e) => setMenuData({ ...MenuData, link: e.target.value })}
                                                />
                                            }
                                            {
                                                MenuData.type === "category" && <SelectField
                                                    options={categories}
                                                    onChange={(e) => {
                                                        console.log(e);
                                                        setMenuData({ ...MenuData, link: e._id })
                                                    }}
                                                    placeholder={"Select Category"}
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option._id}
                                                />
                                            }

                                            <div className="">
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    startIcon={<SaveIcon />}
                                                    variant="contained"
                                                    onClick={addNewMenu}>
                                                    Add Menu
                                                </LoadingButton>
                                            </div>

                                        </div>
                                        <div className="w-full pr-10">
                                            <div className="w-full">
                                                <div className="flex justify-between mb-2 px-5">
                                                    <p>
                                                        NAME
                                                    </p>
                                                    <p>
                                                        ACTION
                                                    </p>
                                                </div>
                                                {
                                                    webSettings.header.menu.map((menu, index) => (
                                                        <div className={`${darkMode ? 'bg-dark-color-box border border-gray-700' : 'bg-white shadow border border-gray-200'} flex justify-between items-center px-5 mb-2 py-3`}>
                                                            <p>
                                                                {menu.name}
                                                            </p>
                                                            <p>
                                                                <div
                                                                    onClick={() => updateMenuItem(menu.id)}
                                                                    className="tooltip tooltip-accent" data-tip="Edit">
                                                                    <Button
                                                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                                        variant="text">
                                                                        <EditIcon />
                                                                    </Button>
                                                                </div>
                                                                <div
                                                                    onClick={() => deleteMenuItem(menu.id)}
                                                                    className="tooltip tooltip-accent" data-tip="Delete">
                                                                    <Button
                                                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                                        variant="text">
                                                                        <DeleteIcon />
                                                                    </Button>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-end my-20 pr-5">
                                    <LoadingButton
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        onClick={saveMenu}>
                                        Save
                                    </LoadingButton>
                                </div>

                            </>
                        },
                        {
                            children: <>
                                <h1 className="my-5 opacity-80 font-medium tracking-wide text-xl pr-5">
                                    Footer Settings
                                </h1>
                                <div className="my-10 flex flex-col  gap-8">
                                    <div className="flex w-full justify-between gap-20">
                                        <div className='w-1/2 flex flex-col gap-5'>
                                            <InputField
                                                fieldName="menu_name"
                                                title="Menu Name"
                                                placeholder="Menu Name"
                                                type="text"
                                                value={MenuData.name}
                                                onChange={(e) => setMenuData({ ...MenuData, name: e.target.value })}
                                            />

                                            <SelectField
                                                options={menuType}
                                                onChange={(e) => {
                                                    console.log(e);
                                                    setMenuData({ ...MenuData, type: e._id })
                                                }}
                                                placeholder={"Select Menu Type"}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option._id}
                                            />
                                            {
                                                MenuData.type === "custom" && <InputField
                                                    fieldName="menu_link"
                                                    title="Menu Link"
                                                    placeholder="Menu Link"
                                                    type="text"
                                                    value={MenuData.link}
                                                    onChange={(e) => setMenuData({ ...MenuData, link: e.target.value })}
                                                />
                                            }
                                            {
                                                MenuData.type === "category" && <SelectField
                                                    options={categories}
                                                    onChange={(e) => {
                                                        console.log(e);
                                                        setMenuData({ ...MenuData, link: e._id })
                                                    }}
                                                    placeholder={"Select Category"}
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option._id}
                                                />
                                            }

                                            <div className="">
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="start"
                                                    startIcon={<SaveIcon />}
                                                    variant="contained"
                                                    onClick={() => footerUpdate ? updateFooterMenuItem() : addFooterMenuItem()}>
                                                    {
                                                        footerUpdate ? 'Update Menu' : 'Add Menu'
                                                    }
                                                </LoadingButton>
                                            </div>

                                        </div>
                                        <div className="w-full pr-10">
                                            <div className="w-full">
                                                <div className="flex justify-between mb-2 px-5">
                                                    <p>
                                                        NAME
                                                    </p>
                                                    <p>
                                                        ACTION
                                                    </p>
                                                </div>
                                                {
                                                    config?.webSettings?.footer?.menu?.map((menu, index) => (
                                                        <div className={`${darkMode ? 'bg-dark-color-box border border-gray-700' : 'bg-white shadow border border-gray-200'} flex justify-between items-center px-5 mb-2 py-3`}>
                                                            <p>
                                                                {menu.name}
                                                            </p>
                                                            <p>
                                                                <div
                                                                    onClick={() => {
                                                                        updateFooterMenuItemPrePare(menu.id)
                                                                        setFooterUpdate(true)
                                                                    }}
                                                                    className="tooltip tooltip-accent" data-tip="Edit">
                                                                    <Button
                                                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                                        variant="text">
                                                                        <EditIcon />
                                                                    </Button>
                                                                </div>
                                                                <div
                                                                    onClick={() => deleteFooterMenuItem(menu.id)}
                                                                    className="tooltip tooltip-accent" data-tip="Delete">
                                                                    <Button
                                                                        className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                                        variant="text">
                                                                        <DeleteIcon />
                                                                    </Button>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end my-20 pr-5">
                                    <LoadingButton
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        onClick={saveMenu}>
                                        Save
                                    </LoadingButton>
                                </div>
                            </>
                        },
                        {
                            children: <NewReleasePoster />
                        }
                    ]}
                />
            </div>
        </Layout>

    );
}

export default WebSettings;
