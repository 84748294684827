// Author: Pukhraj Dhamu
// Created Date: Friday, July 14th 2023 at 6:43 PM
// Last Modified Date: Friday, July 14th 2023 at 6:43 PM
import { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { InputField, SelectField, TextAreaField } from "../../tailwind";
import { useSelector } from "react-redux";
import { searchUser } from "../../http/Apis";
import toast from "react-hot-toast";
import { Hr } from "../../components";
import { RootState } from "../../redux/store";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { Button as MuiButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getMarketingTemplates, sentEmailMarketing } from "../../http/marketing.http";
import routesString from "../../localData/routes.json";

const bodyTypeData = [
  {
    label: "Template",
    value: "template"
  },
  {
    label: "Text",
    value: "text"
  }
]

const groupType = [
  {
    label: "All Users",
    value: "all-user"
  },
  {
    label: "Premium Users",
    value: "premium-user"
  },
  {
    label: "Free Users",
    value: "free-user"
  },
  {
    label: "Select User",
    value: "custom-select"
  }
]

const EmailMarketing = () => {
  const navigate = useNavigate();
  const [emailTemplate, setEmailTemplate] = useState<IMarketingTemplateData>({} as IMarketingTemplateData);
  const [emails, setEmails] = useState<string[]>([]);
  const [body, setBody] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [bodyType, setBodyType] = useState<"template" | "text">("text");
  const [group, setGroup] = useState<"all-user" | "premium-user" | "free-user" | "custom-select" | "group-by-id">("all-user");

  // get all email templates query
  const getAllEmailTemplatesQuery = useQuery<{ data: IMarketingTemplate }>({
    queryKey: ["getAllEmailTemplates"],
    queryFn: () => getMarketingTemplates("page=1&limit=25&type=email"),
    onError: (error: any) => {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        return toast.error(err.data.error.message);
      }
      return toast.error("Something went wrong");
    }
  });

  const handleUserEmailSearch = async (search: string) => {
    const res = await searchUser(search);
    let emailArr = res.data.data.users.map((user: any) => {
      return {
        value: user.email,
        label: user.email,
        id: user._id,
      };
    });
    return emailArr;
  };

  const promiseUserEmailOptions = (inputValue: string) => {
    return new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(handleUserEmailSearch(inputValue));
      }, 1000);
    });
  };

  const handleGetSelectedEmail = (value: any) => {
    if (value) {
      console.log(value);
      let emails: any = value.map((item: any) => {
        return item.value;
      });

      if (emails) {
        setEmails([...emails]);
      }
    }
  };

  // useMutation for send email
  const sentEmailMutation = useMutation({
    mutationKey: ["sendEmail"],
    mutationFn: (data: ISentEmailPayload) => sentEmailMarketing(data),
    onSuccess: (data: any) => {
      toast.success("Email sent successfully");
    },
    onError: (error: any) => {
      return toast.error("Failed to send email");
    }
  })

  // handle send email
  const handleSendTemplate = () => {
    const payload: ISentEmailPayload = {
      subject: subject,
      to: JSON.stringify(emails) as any,
      group: group
    } as ISentEmailPayload;
    if (bodyType === "template") {
      payload.template = emailTemplate._id;
    }
    if (bodyType === "text") {
      payload.body = body;
    }
    sentEmailMutation.mutate(payload)
  };

  return (
    <Layout>
      <div>
        <div className="pr-5">
          <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
            Email Marketing
          </h1>
          <p className="mt-2 opacity-70">
            Send email to your users from here. You can send email to all
            users or to a specific user.
          </p>
        </div>
        <div className="my-5 mr-6">
          <Hr />
        </div>
        <div className="w-full flex justify-between items-center pr-4">
          <MuiButton
            variant="contained"
            onClick={() => navigate(`${routesString.Marketing.Children.MarketingTemplates.Link}`)}
            className="bg-primary-blue text-white hover:bg-primary-blue-dark duration-100">
            Templates
          </MuiButton>
        </div>
        <div className="my-5 flex flex-col gap-3.5 pr-5">
          <InputField
            title="Subject"
            required
            fieldName="subject"
            type="text"
            placeholder="Enter your subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <SelectField
            options={bodyTypeData}
            placeholder="Select Type"
            defaultValue={bodyTypeData[1] as any}
            onChange={(e) => setBodyType(e.value)}
          />
          {
            bodyType === "text" && <TextAreaField
              title="Body"
              fieldName="body"
              type="textarea"
              placeholder="Enter your body"
              value={body}
              textAreaOnChange={(e) => setBody(e.target.value)}
            />
          }
          {
            bodyType === "template" && <SelectField
              options={getAllEmailTemplatesQuery.data?.data.data || []}
              promiseOptions={promiseUserEmailOptions}
              placeholder="Choose Email Template"
              onChange={(e) => setEmailTemplate(e)}
              getOptionLabel={(option: IMarketingTemplateData) => option.name}
              getOptionValue={(option: IMarketingTemplateData) => option._id}
            />
          }

          <SelectField
            options={groupType}
            placeholder="Select Type"
            onChange={(e) => setGroup(e.value)}
          />

          {
            group === "custom-select" && <SelectField
              promiseOptions={promiseUserEmailOptions}
              placeholder="Choose Email"
              isMulti
              onChange={handleGetSelectedEmail}
            />
          }
        </div>
        <div className="my-10 pr-6 flex flex-col gap-8">
          <div className="flex justify-end">
            <LoadingButton
              loading={sentEmailMutation.isLoading}
              loadingPosition="end"
              endIcon={<SendIcon />}
              variant="outlined"
              color="primary"
              onClick={handleSendTemplate}>
              Send
            </LoadingButton>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EmailMarketing;