// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useMutation } from '@tanstack/react-query';
import { initDefaultPagesApi } from '../../../http/blogs.http';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { fetchConfig } from '../../../redux/slice​/configSlice';

function DashboardAlart() {
    const dispatch = useDispatch();
    const { config } = useSelector((state: RootState) => state.config);
    const pagesAlart = config?.warning?.pages || null;

    // create default pages useMutation
    const createDefaultPagesMutation = useMutation({
        mutationKey: ['createDefaultPages'],
        mutationFn: async () => initDefaultPagesApi(),
        onSuccess: (data) => {
            toast.success("Pages created successfully!");
            dispatch(fetchConfig() as any);
        },
        onError: (error) => {
            toast.error("Failed to create pages!");
        }
    });
    return (
        <div className='mt-4 pr-6'>
            {
                pagesAlart && pagesAlart.actionPerformed === "pending" && <div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative' role='alert'>
                    <strong className='font-bold'>
                        Pages action required!
                    </strong><br />
                    <span className='block sm:inline'>
                        Privacy policy and Terms And Conditions and more pages are not created yet. Please click on the button below to create them. <br />
                        <a
                            onClick={() => createDefaultPagesMutation.mutate()}
                            className='text-blue-500 hover:text-blue-700 mt-1 cursor-pointer'>
                            {createDefaultPagesMutation.isLoading ?
                                'Creating pages please wait...'
                                : 'Click here to create pages'}
                        </a>
                    </span>
                </div>
            }
        </div>
    )
}

export default DashboardAlart