// Author: Pukhraj Dhamu
// Date Created: Sunday 21 May 2023 at 11:25 AM
// Date Modified: Sunday 21 May 2023 at 11:31 AM
import { useState } from "react";
import { ImageField } from "../../../tailwind/Input/inputField";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { newReleasePosterApi } from "../../../http/settingshttp";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchConfig } from "../../../redux/slice​/configSlice";
import { useDispatch } from "react-redux";

const NewReleasePoster = (): JSX.Element => {
    const { config } = useSelector((state: RootState) => state.config);
    const dispatch = useDispatch();
    const [newReleasePoster, setNewReleasePoster] = useState<{
        newReleasePoster: File | null;
        newReleasePosterUrl: string;
    }>({
        newReleasePoster: null,
        newReleasePosterUrl: "",
    });

    // useMutation hook to update the new release poster
    const updateNewReleasePoster = async () => {
        if (newReleasePoster.newReleasePoster === null) throw new Error("New release poster is required");
        const { data, status } = await newReleasePosterApi({ poster: newReleasePoster.newReleasePoster });
        if (status === 200) return data;
        else throw new Error("Something went wrong");
    };

    const newReleasePosterMutation = useMutation({
        mutationFn: () => updateNewReleasePoster(),
        onSuccess: () => {
            dispatch(fetchConfig() as any);
            toast.success("New release poster updated successfully");
        },
        onError: (error: any) => toast.error(error?.response?.data?.error?.message || error?.message || "Something went wrong")
    });

    return (
        <>
            <h1 className="my-5 opacity-80 font-medium tracking-wide text-xl pr-5">
                New Release Poster
            </h1>
            <div>
                <ImageField
                    fieldName={"newReleasePoster"}
                    placeholder={"New Release Poster"}
                    onChange={(e) => {
                        setNewReleasePoster({
                            newReleasePoster: e.target.files[0],
                            newReleasePosterUrl: URL.createObjectURL(e.target.files[0]),
                        });
                    }}
                    url={newReleasePoster.newReleasePosterUrl || config.new_release_poster}
                    className="w-1/4 aspect-video rounded overflow-hidden border cursor-pointer"
                />
                <div className="flex justify-start my-5 pr-5">
                    <LoadingButton
                        loading={newReleasePosterMutation.isLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        onClick={() => newReleasePosterMutation.mutate()}>
                        Save
                    </LoadingButton>
                </div>
            </div>
        </>
    );
};

export default NewReleasePoster;