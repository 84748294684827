import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getSubscriptions } from '../../http/Apis'

export interface ISubscriptionState {
    subscriptions: {
        _id: string,
        name: string,
        description: string,
        price: number,
        points: [],
        duration: 'weekly' | 'monthly' | 'quarterly' | 'half-yearly' | 'yearly',
        currency: 'INR',
        status: boolean,
        maxVideoQuality: '144' | '240' | '360' | '480' | '720' | '1080' | '1440' | '2160'
        created_by: string,
        updated_by: string,
    }[],
    status: 'idle' | 'loading' | 'failed' | 'success',
    single: ISubscriptionState['subscriptions'][0],
}
const initialState: ISubscriptionState = {
    subscriptions: [],
    status: 'idle',
    single: {} as ISubscriptionState['subscriptions'][0],
}

export const SubscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setSubscription: (state, actions) => {
            const { data } = actions.payload
            state.subscriptions = data
        },
        setStatus: (state, actions) => {
            state.status = actions.payload
        },
        setSingle: (state, actions) => {
            const { data } = actions.payload
            state.single = data
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setSubscription,
    setStatus,
    setSingle,
} = SubscriptionSlice.actions

export default SubscriptionSlice.reducer

// Thunks
export interface IFetchAllSubscriptionType {
    type?: 'single' | 'all'
}
export function fetchSubscriptions(query?: string, type?: IFetchAllSubscriptionType['type']) {
    return async (dispatch: any) => {
        dispatch(setStatus('loading'))
        try {
            const { data } = await getSubscriptions(query);
            if (type === 'single') {
                dispatch(setSingle(data))
            } else {
                dispatch(setSubscription(data))
            }
            dispatch(setStatus('success'))
        } catch (error) {
            console.log(error)
            dispatch(setStatus('failed'))
        }
    }
}

// export function fetchSubscriptionReport() {
//     return async function fetchSubscriptionReportThunk(dispatch) {
//         dispatch(setStatus(STATUS.LOADING))
//         try {
//             const res = await subscriptionReport();
//             dispatch(setReport(res.data))
//             dispatch(setStatus(STATUS.SECCESS))
//         } catch (error) {
//             console.log(error)
//             dispatch(setStatus(STATUS.FAILED))
//         }
//     }
// }

// export function fetchSearchSubscription(search) {
//     return async function fetchSubscriptionThunk(dispatch) {
//         dispatch(setStatus(STATUS.LOADING))
//         try {
//             const res = await subscriptionSearch(search);
//             dispatch(setSubscription(res.data))
//             dispatch(setStatus(STATUS.SECCESS))
//         } catch (error) {
//             console.log(error)
//             dispatch(setStatus(STATUS.FAILED))
//         }
//     }
// }

// export function fetchSingleSubscription(id) {
//     return async function fetchSingleSubscriptionThunk(dispatch) {
//         dispatch(setStatus(STATUS.LOADING))
//         try {
//             const res = await getSubscription(id);
//             dispatch(setSingle(res.data))
//             dispatch(setStatus(STATUS.SECCESS))
//         } catch (error) {
//             console.log(error)
//             dispatch(setStatus(STATUS.FAILED))
//         }
//     }
// }

