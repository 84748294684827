// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import { useSelector } from "react-redux"
import { TailwindBox } from "../../../../tailwind"
import { RootState } from "../../../../redux/store"
import { useQuery } from "@tanstack/react-query"
import { getContant } from "../../../../http/Apis"
import { BiRefresh } from "react-icons/bi"
import { counter } from "../../../../utils/Utils"

const ContentReportAnalytics = () => {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    // fetch content query
    const getContentQuery = useQuery<{ data: IContent }>({
        queryKey: ["ContentReportAnalytics"],
        queryFn: () => getContant(`page=1&limit=1`)
    })
    return (
        <TailwindBox title="Content Report">
            {
                getContentQuery.isLoading && <div>
                    <p className="text-xs opacity-80 mt-2">All time:</p>
                    <div className="flex justify-between items-center mt-5 text-sm">
                        <h6>Total Content</h6>
                        <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
                    </div>
                    <div className="flex justify-between items-center mt-4 text-sm">
                        <h6>Total Public</h6>
                        <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
                    </div>
                    <div className="flex justify-between items-center mt-4 text-sm">
                        <h6>Total Private</h6>
                        <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
                    </div>
                </div>
            }
            {
                getContentQuery.isError && <div>
                    <div className="w-full h-full flex justify-center items-center mb-3">
                        <div className='min-h-[15rem] justify-center flex flex-col items-center'>
                            <h1 className='text-sm font-medium opacity-90'>
                                Server Error. Please try again
                            </h1>
                            <div
                                onClick={() => getContentQuery.refetch()}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                <BiRefresh className={`text-black text-lg bg-white rounded-full ${getContentQuery.isLoading || getContentQuery.isFetching && "animate-spin"}`} />
                                <h1 className='text-sm text-white'>
                                    Refresh
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                getContentQuery.isSuccess && <div>
                    <p className="text-xs opacity-80 mt-2">All time:</p>
                    <div className="flex justify-between items-center mt-5 text-sm">
                        <h6>Total Content</h6>
                        <p>{counter(getContentQuery.data.data.meta.report.total)}</p>
                    </div>
                    <div className="flex justify-between items-center mt-4 text-sm">
                        <h6>Total Public</h6>
                        <p>{counter(getContentQuery.data.data.meta.report.totalPublic)}</p>
                    </div>
                    <div className="flex justify-between items-center mt-4 text-sm">
                        <h6>Total Private</h6>
                        <p>{counter(getContentQuery.data.data.meta.report.totalPrivate)}</p>
                    </div>
                </div>
            }

        </TailwindBox>

    )
}

export default ContentReportAnalytics