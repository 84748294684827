import { createSlice } from '@reduxjs/toolkit'


export const STATUS = Object.freeze({
    SECCESS: 'secces',
    FAILED: 'failed',
    LOADING: 'loading',
})

const initialState = {
    isOpenSubMenu: false,
    status: STATUS.SECCESS,
}

export const StateSlice = createSlice({
    name: 'state',
    initialState,
    reducers: {
        setIsOpenSubMenu: (state, actions) => {
            const data = actions.payload
            state.isOpenSubMenu = data
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setIsOpenSubMenu
} = StateSlice.actions

export default StateSlice.reducer

// Thunks
// export function fetchProduct(id) {
//     return async function fetchProductThunk(dispatch) {
//         dispatch(setStatus(STATUS.LOADING))
//         try {
//             const res = await getProduct(id);
//             dispatch(setSingleProduct(res.data))
//             dispatch(setStatus(STATUS.SECCESS))
//         } catch (error) {
//             console.log(error)
//             dispatch(setStatus(STATUS.FAILED))
//         }
//     }
// }