// Author: Pukhraj Dhamu
// Date Created: Sunday 21 May 2023 at 7:04 PM
// Date Modified: Sunday 21 May 2023 at 7:04 PM
import axios from "axios";
import { publicIpv4 } from "public-ip";
const settingsApis = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
    }
});

// list all endpoints
export const getSettingsApi = (querystring?: IGetSettingsKeys) => settingsApis.get(`/settings${querystring ? `?which=${querystring}` : ""}`).then((res) => res.data);
export const newReleasePosterApi = (data: INewReleasePosterPayload) => settingsApis.post("/settings/new-release", data);
export const getAwsConfigSettingsApi = () => settingsApis.get("/settings/aws/config").then((res) => res.data);
export const updateAwsConfigSettingsApi = (payload: IUpdateAwsConfigPayload) => settingsApis.put("/settings/aws/config", payload)
export const updateTranscodeSettingApi = (id: string, payload: IUpdateTranscodeSettingApiPayload) => settingsApis.put(`/settings/transcoder/config/${id}`, payload);

// smtp settings
export const updateSMTPCredential = (payload: ISMTPServerSettings) => settingsApis.put("/settings/smtp/server", payload);

// payment gateway settings
export const updatePaymentGatewaySettingApi = (data: { payment_gateways: IPaymentGatewaySettingsUpdatePayload[] }, provider: IPaymentGatewaySettings['provider']) => settingsApis.put(`settings/payment/gateway?provider=${provider}`, data);

settingsApis.interceptors.request.use(async (config: any) => {
    const ipaddress = await publicIpv4();
    config.headers["ipaddress"] = ipaddress;
    return config;
});

export default settingsApis;