import React, { useState } from 'react'
import { SelectField } from '../../../tailwind'
import { useDebounce } from 'use-debounce';
import { useQuery } from '@tanstack/react-query';
import { getContant } from '../../../http/Apis';
import { getEpisodesByContentId } from '../../../http/Content.http';
import { handleSourceUpdate } from '../../../redux/slice​/toolSlice';
import { useDispatch } from 'react-redux';

export interface ISourceUpdateType {
    name: string;
    value: "trailer" | "source";
}

export const sourceUpdateType: ISourceUpdateType[] = [
    {
        name: "Trailer",
        value: "trailer"
    },
    {
        name: "Source",
        value: "source"
    }
];

function ContentSourceUpdate() {
    const dispatch = useDispatch();
    const [content, setContent] = useState<IContentData>();
    const [contentEpisodes, setContentEpisodes] = useState<IContentEpisodes>();
    const [sourceType, setSourceType] = useState<"trailer" | "source">();
    const [contentTypes, setContentTypes] = useState<IContentData['type']>();

    // use effect for source data update
    React.useEffect(() => {
        if (content && sourceType) {
            dispatch(handleSourceUpdate({
                content_id: content._id,
                episode_id: contentEpisodes?._id,
                sourceType: sourceType,
                source: undefined,
            }))
        }
    }, [content, contentEpisodes, sourceType])

    const [search, setSearch] = useState<string>("");

    // episode search
    const [episodeSearch, setEpisodeSearch] = useState<string>("");

    const contentQuery = useQuery<{ data: IContent }>({
        queryKey: ["content-list", search],
        queryFn: () => getContant(`${search ? `page=1&limit=25&sort=desc&keyword=${search}` : 'page=1&limit=25&sort=desc'}`),
    });
    // get episode by content id
    const contentEpisodesQuery = useQuery<{ data: IContentEpisodes[] }>({
        queryKey: ["content-episodes-list", episodeSearch],
        queryFn: () => getEpisodesByContentId(content?._id as string, `${episodeSearch && `?keyword=${episodeSearch}`}`),
        enabled: content?._id !== undefined,
    });


    const contentPromiseOptions = (inputValue: string) =>
        new Promise((resolve) => {
            setSearch(inputValue);
            contentQuery.refetch();
            // filter only series and movies content
            const data = contentQuery.data?.data?.data?.filter((item) => item.type === "series" || item.type === "movie") || [];
            resolve(data);
        });

    // content episode promise options
    const contentEpisodePromiseOptions = (inputValue: string) =>
        new Promise((resolve) => {
            setEpisodeSearch(inputValue);
            contentEpisodesQuery.refetch();
            resolve(contentEpisodesQuery.data?.data || []);
        });

    return (
        <div className='mx-5'>
            <div className='w-full py-2.5 flex flex-col gap-3'>
                <SelectField
                    onChange={(e: IContentData) => {
                        setContentTypes(e.type)
                        setContent(e)
                    }}
                    promiseOptions={contentPromiseOptions}
                    getOptionLabel={(option) => option.name}
                    placeholder='Select content'
                />
                {
                    contentTypes !== "live_stream" && content && <SelectField
                        onChange={(e: ISourceUpdateType) => setSourceType(e.value)}
                        options={sourceUpdateType}
                        getOptionLabel={(option) => option.name}
                        placeholder='Select source type'
                    />
                }
                {contentTypes === "series" && sourceType === "source" && <>
                    <SelectField
                        onChange={(e: IContentEpisodes) => setContentEpisodes(e)}
                        promiseOptions={contentEpisodePromiseOptions}
                        getOptionLabel={(option) => option.name}
                        placeholder='Select episode'
                    />
                </>}
            </div>
        </div>
    )
}

export default ContentSourceUpdate