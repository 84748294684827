const Title = ({
    children,
    required = false,
    className = '',
}) => {
    return (
        <div className={className}>
            {children}
            {required && <span className='text-red-800'> *</span>}
        </div>
    )
}

export default Title;