import { useQuery } from '@tanstack/react-query'
import { onlineUsers } from '../../http/Apis'
import { counter } from '../../utils/Utils'

function OnlineUser() {
    const { data } = useQuery({
        queryKey: ['onlineUser'],
        queryFn: onlineUsers,
        refetchInterval: 10000, // 10 sec
        // refetchInterval: 1000, // 1 sec
        retry: 1,
        enabled: process.env.REACT_APP_ENV === "development" ? false : true,
    })

    return (
        <div className='pr-2 flex items-center gap-1.5'>
            <div className='w-3 h-3 rounded-full bg-red-500 animate-pulse'/>
            <h2>
                {data ? data.data ? `Live: ${counter(data.data)}` : `Live: 0` : `Live: 0`}
            </h2>
        </div>
    )
}

export default OnlineUser