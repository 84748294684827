import Layout from "../../components/Layout/Layout";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  fetchSubscriptions,
  ISubscriptionState,
} from "../../redux/slice​/SubscriptionSlice";
import { Hr, MuiSwitch } from "../../components";
import { RootState } from "../../redux/store";
import { TailwindBox } from "../../tailwind";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button } from "@mui/material";
import { subscriptionInitEndpoint, subscriptionStatus } from "../../http/Apis";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const Subscription = () => {
  const { subscriptions } = useSelector(
    (state: RootState) => state.subscription
  );
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState({
    name: "",
    description: "",
    price: "",
    color: "",
    points: [],
    label: "",
    duration: "30",
    currency: "INR",
  });
  const [loading, setLoading] = useState(false);
  const [itemView, setItemView] = useState(5);
  const [search, setSearch] = useState();
  const [value] = useDebounce(search, 1000);

  useEffect(() => {
    dispatch(fetchSubscriptions() as any);
  }, []);

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const status = event.target.checked;
    try {
      const res = await subscriptionStatus({
        id,
        status,
      });
      if (res.status === 200) {
        dispatch(fetchSubscriptions() as any);
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.error?.message || "Something went wrong"
      );
    }
  };

  const importSubscriptions = async () => {
    try {
      setLoading(true);
      await subscriptionInitEndpoint();
      dispatch(fetchSubscriptions() as any);
      toast.success("Subscriptions imported successfully");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(
        error?.response?.data?.error?.message || "Something went wrong"
      );
    }
  };

  return (
    <Layout>
      <h1 className="font-medium text-2xl pr-5 mb-3">Subscriptions</h1>
      <Hr />
      <div className="w-full mt-5 pr-5 grid grid-cols-3 gap-8">
        {subscriptions &&
          subscriptions.length > 0 &&
          subscriptions.map(
            (item: ISubscriptionState["subscriptions"][0], index: number) => {
              return (
                <TailwindBox
                  key={index}
                  className="rounded p-4 overflow-hidden w-full h-fit"
                >
                  <div className="w-full overflow-hidden">
                    <h1 className="font-semibold text-xl">
                      ₹<span className="font-bold text-3xl">{item.price}</span>/
                      <span className="font-normal text-sm">
                        {item.duration}
                      </span>
                    </h1>
                    <h1 className="capitalize mt-4 text-xl">{item.name}</h1>
                    <p className="text-xs text-gray-500 line-clamp-2 mt-0.5 ">
                      {item.description}
                    </p>
                    <div className="mt-5 flex flex-col gap-2">
                      {item.points.length > 0 &&
                        item.points.map((point: string, index: number) => {
                          return (
                            <div
                              key={index}
                              className="flex items-center gap-2 "
                            >
                              <CheckCircleOutlineIcon
                                className="text-green-500"
                                fontSize="small"
                              />
                              <p className="text-sm text-gray-500 line-clamp-2 mt-0.5 ">
                                {point}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                    <div className="px-1 mt-4">
                      <MuiSwitch
                        variant="ios"
                        checked={item.status}
                        onChange={(event) =>
                          handleStatusChange(event, item._id)
                        }
                      />
                    </div>
                    <div className="mt-5 mb-1">
                      <Link to={`/admin/subscriptions/edit/${item._id}`}>
                        <Button
                          className="w-full"
                          variant="contained"
                          color="primary"
                        >
                          Edit
                        </Button>
                      </Link>
                    </div>
                  </div>
                </TailwindBox>
              );
            }
          )}
      </div>
      <div>
        {subscriptions && subscriptions.length === 0 && (
          <div className="mt-40 flex justify-between flex-col items-center">
            <h1 className="text-center text-xl font-semibold mt-5 mb-5">
              No Subscriptions Found
            </h1>
            <Button
              variant="contained"
              className="w-1/4 mx-auto"
              onClick={importSubscriptions}
              disabled={loading}
            >
              {loading ? "Loading..." : "Import Subscriptions"}
            </Button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Subscription;
