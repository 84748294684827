// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import React, { useEffect } from 'react'
import { SelectField } from '../../../tailwind'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { Button } from '@mui/material'
import { usersPermissionPolicy } from '../../../http/Apis'
import toast from 'react-hot-toast'
import { UseQueryResult } from '@tanstack/react-query'

interface IProps {
    id: string;
    user: IUsersResponse['data'][0]
    userQuery: UseQueryResult
}

function removeDups(items: string[]) {
    var i,
        setObj: any = {},
        setArray = [];
    for (i = 0; i < items.length; i += 1) {
        if (!setObj.hasOwnProperty(items[i])) {
            setArray.push(items[i]);
            setObj[items[i]] = true;
        }
    }
    return setArray;
}

const UserPermisionsOptions = [
    {
        label: 'Content Full Access',
        uniqueLabel: 'content:full',
        value: ['content:write', 'content:read']
    },
    {
        label: "Own Content Full Access",
        uniqueLabel: 'content:own:full',
        value: ['content:readOwn', 'content:writeOwn']
    },
    {
        label: 'Users Full Access',
        uniqueLabel: 'users:full',
        value: ['users:read', 'users:write']
    },
    {
        label: 'Own User Full Access',
        uniqueLabel: 'users:own:full',
        value: ['users:readOwn', 'users:writeOwn']

    },
    {
        label: 'Full GEO Access',
        uniqueLabel: 'geo:full',
        value: ['geo:read', 'geo:write']
    },
    {
        label: 'GEO Only Read And Own Write',
        uniqueLabel: 'geo:read:own:write',
        value: ['geo:read', 'geo:writeOwn']
    },
    // filemanager
    {
        label: 'File Manager Full Access',
        uniqueLabel: 'filemanager:full',
        value: ['filemanager:read', 'filemanager:write']
    },
    {
        label: 'File Manager Own Full Access',
        uniqueLabel: 'filemanager:own:full',
        value: ['filemanager:readOwn', 'filemanager:writeOwn']
    },
    // analytics
    {
        label: 'Analytics Full Access',
        uniqueLabel: 'analytics:full',
        value: ['analytics:read']
    },
    {
        label: 'Analytics Own Full Access',
        uniqueLabel: 'analytics:own:full',
        value: ['analytics:readOwn']
    }
]

const getLabelUserPermisionsUseingValue = (value: string[]) => {
    const labels: string[] = []
    value.forEach(item => {
        const label = UserPermisionsOptions.find(i => i.value.includes(item))?.label
        if (label) labels.push(label)
    })
    // remove dups
    return removeDups(labels)
}

const onRemoveAllValuerInLabel = (uniqueLabel: string) => {
    const values: string[] = []
    UserPermisionsOptions.forEach(item => {
        if (item.label === uniqueLabel) values.push(...item.value)
    })
    return values
}


const UserPermisionsPromiseOptions = (inputValue: string) =>
    new Promise(resolve => {
        setTimeout(() => {
            resolve(UserPermisionsOptions.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            ))
        }, 1000)
    })


function UserPermisions({ id, user, userQuery }: IProps): JSX.Element {
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const [userPermisions, setUserPermisions] = React.useState<string[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    useEffect(() => {
        setUserPermisions(user.permissionPolicy || [])
    }, [user])

    const onchange = (value: {
        label: string
        value: string[]
    }[]) => {
        const newValues = value.map(i => i.value).flat()
        const uniqueValues = removeDups([...userPermisions, ...newValues])
        // set user permisions with unique values
        setUserPermisions(uniqueValues)
    }

    const onRemove = (uniqueLabel: string) => {
        // const values = onRemoveAllValuerInLabel(uniqueLabel)
        // const newValues = userPermisions.filter(i => !values.includes(i))
        // setUserPermisions(newValues)
        const newValues = userPermisions.filter(i => i !== uniqueLabel)
        setUserPermisions(newValues)
        
    }

    const onSubmit = async () => {
        try {
            setLoading(true)
            const { status } = await usersPermissionPolicy({
                id: id!,
                permission: userPermisions
            })
            setLoading(false)
            if (status === 200) {
                toast.success("User Permisions Updated")
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.response?.data?.error?.message || "Something went wrong")
        }
    }



    return (
        <div className='py-5'>
            <h1 className='text-xl mb-4'>
                User Permisions
            </h1>
            <SelectField
                placeholder='Select User Permisions'
                onChange={onchange}
                isMulti
                defaultValue={[]}
                options={UserPermisionsOptions}
                promiseOptions={UserPermisionsPromiseOptions}
            />
            {
                userPermisions.length > 0 && (<div className={`bg-transparent border my-5 ${darkMode ? 'border-[#343434]' : 'border-gray-200'} rounded-md px-2 py-2.5 flex items-center flex-wrap gap-3`}>
                    {userPermisions.map((item, index) => (
                        <div key={index} className="bg-blue-500 py-1 px-3 rounded flex gap-2">
                            <div className="text-white">{item}</div>
                            <div className="text-white bg-gray-800 px-1 w-6 items-center justify-center flex rounded-full cursor-pointer"
                                onClick={onRemove ? () => onRemove(item) : undefined}
                            >&times;</div>
                        </div>
                    ))}

                </div>)
            }

            <div className="w-full my-5">
                <div className="flex gap-4 justify-end">
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="w-40 h-10"
                        disabled={loading}
                        onClick={() => onSubmit()}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default UserPermisions