// Author: Pukhraj Dhamu
// Created Date: Tuesday, July 11th 2023 at 11:00 AM
// Last Modified Date: Tuesday, July 11th 2023 at 11:00 AM
import React, { useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import { InputField, SelectField, TagField, TextAreaField } from '../../tailwind'
import toast from 'react-hot-toast'
import { categorySearch, getCategories } from '../../http/Apis'
import axios from 'axios'
import { useFormik } from "formik";
import * as Yup from "yup";
import { ImageField } from '../../tailwind/Input/inputField'
import slugify from 'slugify'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CustomMarkDownEditor } from '../../components'

const initialValues = {
    name: "",
    slug: "",
    blogContent: '',
    tags: [],
    thumbnail: "",
    thumbnailUrl: "",
    category: "",
};

const createBlogSchema = Yup.object().shape({
    name: Yup.string().required("Title is required").min(2, "Title is too short").max(200, "Title is too long"),
    slug: Yup.string().optional(),
    blogContent: Yup.string().optional(),
    tags: Yup.array().default([]),
    thumbnail: Yup.mixed().optional()
        .test("fileSize", "File too large limit is 1mb", (value: any) => {
            if (!value) return true
            return value && value.size <= 1024 * 1024 * 1 // 1mb
        })
        .test("fileFormat", "Unsupported Format. try JPEG, PNG, JPG, WEBP", (value: any) => {
            if (!value) return true
            return value && ["image/jpeg", "image/png", "image/jpg", "image/webp"].includes(value.type)
        }),
});


const CreateBlog = () => {
    const [loading, setLoading] = React.useState(false)

    const handleSubmitFrom = async (values: any) => {
        if (values.blogContent === '') return toast.error('Blog content is required')
        const formData: any = {
            title: values.name,
            blog_content: values.blogContent,
        }
        if (values.slug) formData.slug = slugify(values.slug, { lower: true, strict: true, trim: true })
        if (Array.isArray(values.tags)) formData.tags = JSON.stringify(values.tags)
        if (values.thumbnail) formData.thumbnail = values.thumbnail
        if (values.category) formData.category = values.category._id

        //Set up the config
        const config = {
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "multipart/form-data",
            },
        };
        // submit form
        try {
            setLoading(true)
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/blog/create`, formData, config)
            if (response.status === 201) {
                toast.success('Blog created successfully')
                setLoading(false)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || 'Something went wrong')
            setLoading(false)
        }

    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            initialValues,
            validationSchema: createBlogSchema,
            onSubmit: (values, action) => {
                handleSubmitFrom(values)
                //action.resetForm();
            },

        });
    // fetching all the categories and languages, genres and subtitles from the server
    // innitializing the state with the fetched data
    const [categories, setCategories] = React.useState([])

    const fetchCategories = async () => {
        const { data } = await getCategories();
        setCategories(data.data)
    };
    const onCategorySearch = async (search: string) => {
        const { data } = await categorySearch(search);
        setCategories(data.data)
        return data.data
    };

    useEffect(() => {
        fetchCategories();
    }, [])

    const categoryPromiseOptions = (inputValue: string) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(onCategorySearch(inputValue))
            }, 1000);
        });

    interface IContentForm {
        name: string;
        slug?: boolean;
        placeholder: string;
        type: string;
        value?: any;
        error?: any;
        required?: boolean;
        maxLength?: number;
        limitValidation?: boolean;
        options?: any;
        isMulti?: boolean;
        promiseOptions?: any;
        urlName?: string;
        url?: string;
        className?: string;
        imageStyle?: string;
        placeholderStyle?: string;
        col?: string;
    }

    const contentForm: IContentForm[] = [
        {
            "name": "name",
            "placeholder": "Type contact name here",
            "type": "text",
            "value": values.name,
            "error": errors.name,
            "required": false,
            "maxLength": 200,
            "limitValidation": true,
            "col": "col-span-2"
        },
        {
            "name": "slug",
            "placeholder": "Type blog slug here",
            "type": "text",
            "value": values.slug,
            "error": errors.slug,
            "required": true,
            "maxLength": 500,
            "limitValidation": true,
            "col": "col-span-2"
        },
        {
            "name": "blogContent",
            "placeholder": "Type blog content here",
            "type": "textarea",
            "value": values.blogContent,
            "error": errors.blogContent,
            "required": true,
            "maxLength": 5000,
            "limitValidation": true,
            "col": "col-span-2"
        },
        {
            "name": "tags",
            "placeholder": "Type tag and press enter to add",
            "type": "tags",
            "value": values.tags,
            "limitValidation": false,
            "col": "col-span-2"
        },
    ]

    const contentFormTwo: IContentForm[] = [
        {
            "name": "thumbnail",
            "urlName": "thumbnailUrl",
            "placeholder": "Please upload a thumbnail 1280x720px.",
            "type": "imagewithPreview",
            "value": values.thumbnail,
            "url": values.thumbnailUrl,
            "error": errors.thumbnail,
            "limitValidation": false,
            className: 'w-full aspect-video rounded overflow-hidden border cursor-pointer',
            "col": "col-span-1"
        },
        {
            "name": "category",
            "options": categories,
            "placeholder": "Select category",
            "type": "select",
            "isMulti": false,
            "value": values.category,
            "promiseOptions": categoryPromiseOptions,
            "col": "col-span-1"
        }
    ]

    return (
        <Layout>
            <h1 className="font-medium opacity-80 text-2xl mb-3.5">
                Create Blog Post
            </h1>
            <form id='create-blog' onSubmit={handleSubmit}>
                <div className='grid grid-cols-3 gap-5 pr-5'>
                    <div className='flex flex-col col-span-2 gap-4'>
                        {
                            contentForm.map((item) => {
                                switch (item.type) {
                                    case 'text':
                                        return (<InputField
                                            fieldName={item.name}
                                            placeholder={item.placeholder}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={item.value}
                                            type='text'
                                            required={item.required}
                                            maxLength={item.maxLength}
                                            error={item.error}
                                            limitValidation={item.limitValidation}
                                            col={item.col}
                                        />)
                                    case 'textarea':
                                        return (
                                            <div className='h-auto w-full'>
                                                {/* <CKEditor
                                                    editor={ClassicEditor}
                                                    data={item.value}
                                                    onChange={(event: any, editor: any) => {
                                                        const data = editor.getData();
                                                        let eventt = { target: { name: item.name, value: data } }
                                                        handleChange(eventt)
                                                    }}
                                                /> */}
                                                <CustomMarkDownEditor
                                                    value={item.value}
                                                    onChange={(e) => {
                                                        let event = { target: { name: item.name, value: e } }
                                                        handleChange(event)
                                                    }}
                                                />
                                            </div>
                                        )
                                    case 'select':
                                        return (
                                            <SelectField
                                                options={item.options}
                                                onChange={(e) => {
                                                    let event = { target: { name: item.name, value: e } }
                                                    handleChange(event)
                                                }}
                                                onBlur={handleBlur}
                                                placeholder={item.placeholder}
                                                isMulti={item.isMulti}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option._id}
                                                promiseOptions={item.promiseOptions}
                                                col={item.col}
                                            />
                                        )
                                    case 'tags':
                                        return (
                                            <TagField
                                                fieldName={item.name}
                                                placeholder={item.placeholder}
                                                col={item.col}
                                                onKeyDown={(e) => {
                                                    if (e.key !== 'Enter') return
                                                    e.preventDefault()
                                                    let event = {
                                                        target: {
                                                            name: item.name,
                                                            value: [...item.value as any, e.target.value]
                                                        }
                                                    }
                                                    handleChange(event)
                                                    e.target.value = ''
                                                }}
                                                value={item.value}
                                                onRemove={(index) => {
                                                    let event = {
                                                        target: {
                                                            name: item.name,
                                                            value: item.value.filter((item: any, i: number) => i !== index) as any
                                                        }
                                                    }
                                                    handleChange(event)
                                                }}
                                            />
                                        )
                                }
                            })
                        }
                    </div>
                    <div className='flex flex-col col-span-1 gap-4'>
                        {
                            contentFormTwo.map((item) => {
                                switch (item.type) {
                                    case 'select':
                                        return (
                                            <SelectField
                                                options={item.options}
                                                onChange={(e) => {
                                                    let event = { target: { name: item.name, value: e } }
                                                    handleChange(event)
                                                }}
                                                onBlur={handleBlur}
                                                placeholder={item.placeholder}
                                                isMulti={item.isMulti}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => option._id}
                                                promiseOptions={item.promiseOptions}
                                                col={item.col}
                                            />
                                        )
                                    case 'imagewithPreview':
                                        return (
                                            <ImageField
                                                fieldName={item.name}
                                                placeholder={item.placeholder}
                                                onChange={(e) => {
                                                    // set image in thumbnail field and url in thumbnailUrl field
                                                    let event = { target: { name: item.name, value: e.target.files[0] } }
                                                    handleChange(event)
                                                    let urlEvent = { target: { name: item.urlName, value: URL.createObjectURL(e.target.files[0]) } }
                                                    handleChange(urlEvent)
                                                }}
                                                onBlur={handleBlur}
                                                url={item.url}
                                                value={item.value}
                                                className={item.className}
                                                error={item.error}
                                                imageStyle={item.imageStyle}
                                                placeholderStyle={item.placeholderStyle}
                                                col={item.col}
                                            />
                                        )
                                }
                            })
                        }
                    </div>
                </div>
                <div className="flex justify-end pr-5 my-5">
                    <LoadingButton
                        id='create-blog'
                        type='submit'
                        loading={loading}
                        disabled={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant='contained'>
                        Create
                    </LoadingButton>
                </div>
            </form>
        </Layout>
    )
}

export default CreateBlog