// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import { Link } from "react-router-dom";
import { TailwindBox } from "../../../../../tailwind";
import { useQuery } from "@tanstack/react-query";
import { getAnalytics, getContant } from "../../../../../http/Apis";
import qs from "qs";
import { calculateViews, calculateWatchTime } from "../../../../../utils/Utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { BiRefresh } from 'react-icons/bi'

const LatestMoviePerformance = () => {
  const { darkMode } = useSelector((state: RootState) => state.theme)
  // fetch content query
  const getContentQuery = useQuery<{ data: IContent }>({
    queryKey: ["LatestMoviePerformanceContent"],
    queryFn: () => getContant(`page=1&limit=1`)
  })

  const queryLatestMoviePerformance = qs.stringify({
    timePeriod: "monthly",
    content_id: getContentQuery.data?.data?.data.length ? getContentQuery.data?.data?.data[0]._id : ""
  });

  interface ILatestMoviePerformance {
    data: {
      views: {
        _id: string;
        count: number;
      }[];
      watchTime: {
        _id: string;
        time: number;
      }[];
    }
  }

  const LatestMoviePerformanceQuery = useQuery<{ data: ILatestMoviePerformance }>({
    queryKey: ["LatestMoviePerformance"],
    queryFn: () => getAnalytics(queryLatestMoviePerformance),
    enabled: getContentQuery.data?.data?.data.length ? true : false
  })
  return (
    <TailwindBox title="Latest Content Performance">
      {
        LatestMoviePerformanceQuery.isLoading && <div>
          <div className="aspect-video w-full overflow-hidden bg-gray-900 border border-gray-700 rounded my-4 relative animate-pulse" />
          <p className="text-xs opacity-80 mt-2">Last 30 days:</p>
          <div className="flex justify-between items-center mt-4 text-sm">
            <h6>Views</h6>
            <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
          </div>
          <div className="flex justify-between items-center mt-3 text-sm">
            <h6>Watch time (hours)</h6>
            <div className={`h-2 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-10`}></div>
          </div>
          <div className="mt-7 mb-5">
            <div className={`h-7 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-44`}></div>
          </div>
          <div className="mt-4 mb-3">
            <div className={`h-7 animate-pulse ${darkMode ? "bg-dark-card-color" : "bg-white"} rounded-full w-32`}></div>
          </div>
        </div>
      }
      {
        LatestMoviePerformanceQuery.isError && <div>
          <div className="w-full h-full flex justify-center items-center mb-3">
            <div className='min-h-[15rem] justify-center flex flex-col items-center'>
              <h1 className='text-sm font-medium opacity-90'>
                Server Error. Please try again
              </h1>
              <div
                onClick={() => LatestMoviePerformanceQuery.refetch()}
                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                <BiRefresh className={`text-black text-lg bg-white rounded-full ${LatestMoviePerformanceQuery.isLoading || LatestMoviePerformanceQuery.isFetching && "animate-spin"}`} />
                <h1 className='text-sm text-white'>
                  Refresh
                </h1>
              </div>
            </div>
          </div>
        </div>
      }
      {
        LatestMoviePerformanceQuery.isSuccess && <>
          <div>
            <div className="aspect-video w-full overflow-hidden bg-gray-900 border border-gray-700 rounded my-4 relative">
              <img src={getContentQuery.data?.data.data[0].thumbnail} />
              <div className="absolute bottom-0 dashbord__card__content w-full p-3">
                <h1 className="mt-10 text-base font-bold tracking-wide opacity-90 line-clamp-1 text-white">
                  {getContentQuery.data?.data.data[0].name}
                </h1>
              </div>
            </div>
            <p className="text-xs opacity-80 mt-2">Last 30 days:</p>
            <div className="flex justify-between items-center mt-4 text-sm">
              <h6>Views</h6>
              <p>{calculateViews(LatestMoviePerformanceQuery.data.data.data.views) || 0}</p>
            </div>
            <div className="flex justify-between items-center mt-3 text-sm">
              <h6>Watch time (hours)</h6>
              <p>{calculateWatchTime(LatestMoviePerformanceQuery.data.data.data.watchTime) || 0}</p>
            </div>
            <div className="mt-7 mb-5">
              <Link to={`/admin/content/edit/${getContentQuery.data?.data.data[0]._id}`}>
                <button className="uppercase text-blue-400 font-semibold ">
                  go to content analytics
                </button>
              </Link>
            </div>
            <div className="mt-4 mb-3">
              <Link to={`/admin/content/edit/${getContentQuery.data?.data.data[0]._id}`}>
                <button className="uppercase text-blue-400 font-semibold ">
                  Edit content
                </button>
              </Link>
            </div>
          </div>
        </>
      }
    </TailwindBox>
  )
};

export default LatestMoviePerformance;
