import { useEffect, useState } from "react";
import { ImageIcon } from "../../../tailwind/Icon";
import { CreateSignUrl } from "../../../http/Apis";

const ImageIconWithPreview = ({ file }: IImageIconViewProps) => {
    const [image, setImage] = useState<string | null>(null);
    async function createSignedUrl(file: any) {
        try {
            const { data } = await CreateSignUrl({ file_key: file.name.split("/").splice(1).join("/"), })
            setImage(data.data)
        } catch (error) { }
    }

    useEffect(() => {
        createSignedUrl(file as any);
    }, [file]);

    return (
        <div className="w-fit h-20 bg-black rounded-md flex items-center justify-center">
            {image ? (<img
                src={image ? image : (createSignedUrl(file) as any)}
                className="w-full h-full object-cover rounded-md"
            />) : (<ImageIcon />)}
        </div>
    );
};

export default ImageIconWithPreview;