// Author: Pukhraj Dhamu
// Created Date: Saturday 8 July 2023 at 11:03 AM
// Last Modified Date: Saturday 8 July 2023 at 11:03 AM
import React from 'react'
import { BiArrowBack, BiTime, BiRefresh, BiError } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import RouteStrings from '../../../localData/routes.json'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import Base64 from 'base-64';
import { MdDateRange, MdOutlineDone, MdOutlineClose, MdSlowMotionVideo, MdInput } from 'react-icons/md'
import { BsCloudArrowUp } from 'react-icons/bs'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { MdDelete } from 'react-icons/md'
import { AiOutlineLink } from 'react-icons/ai'
import { getAwsConfigSettingsApi, getSettingsApi } from '../../../http/settingshttp'

function TranscoderJobView(): JSX.Element {
    const navigate = useNavigate()
    const { id } = useParams();
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [disabledRetryUpload, setDisabledRetryUpload] = React.useState<boolean>(false)
    const [textCopied, setTextCopied] = React.useState<boolean>(false)
    const [transcoderConfig, setTranscoderConfig] = React.useState<{ hostname: string; username: string; password: string; } | undefined>();

    // copyed text false after 2 seconds
    React.useEffect(() => {
        if (textCopied) setTimeout(() => setTextCopied(false), 2000)
    }, [textCopied])

    // useQuery for get transcoder settings
    useQuery<{ data: ITranscoderSetting[] }>({
        queryKey: ["getTranscoderQuery"],
        queryFn: () => getSettingsApi("transcoder"),
        onSuccess: (data) => {
            if (data && data.data.length > 0) {
                setTranscoderConfig({
                    hostname: data.data[0].hostname,
                    username: data.data[0].username,
                    password: data.data[0].password
                });
            }
        },
        onError: (error) => {
            toast.error("Failed to fetch transcoder settings")
        }
    });

    // fetch job details data
    const fetchjob = async () => {
        const res = await axios.get<IGetTranscoderJobs>(`${transcoderConfig?.hostname}/api/v1/jobs?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Basic " + Base64.encode(`${transcoderConfig?.username}:${transcoderConfig?.password}`),
            }
        })
        if (res.data.data.length === 0) toast.error("No jobs found")
        return res.data;
    }

    // delete job
    const deleteJob = async () => {
        try {
            await axios.delete<IGetTranscoderJobs>(`${transcoderConfig?.hostname}/api/v1/jobs/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + Base64.encode(`${transcoderConfig?.username}:${transcoderConfig?.password}`),
                }
            })
            toast.success("Job deleted successfully")
            navigate(RouteStrings.Transcoding.Children.Dashboard.Link)
        } catch (error) {
            toast.error("Job not deleted")
        }
    }

    const { data: JobsData, refetch, isFetching } = useQuery({
        queryKey: ["jobById", id],
        queryFn: fetchjob,
        enabled: transcoderConfig ? true : false,
        refetchInterval: 1000 * 60 * 1, // 1 minute
    })

    const getAwsConfigQuery = useQuery<IGetAwsConfigResponse>({
        queryKey: ['getAwsConfig'],
        queryFn: () => getAwsConfigSettingsApi(),
    })

    const decodeBase64Data = (data: string | any): IGetAwsConfigResponse['data'] => {
        const decodedData: IGetAwsConfigResponse['data'] = JSON.parse(atob(data))
        return decodedData
    }

    // timemark to time in hh:mm:ss format function timemark like the 00:00:07.03 
    const timemarkToTime = (timemark: string | undefined) => {
        if (timemark === undefined) return '00:00:00'
        const time = timemark.split(':');
        const hours = time[0];
        const minutes = time[1];
        const seconds = time[2]?.split('.')[0];
        const momentTime = moment(`${hours}:${minutes}:${seconds}`, 'HH:mm:ss');
        const duration = moment.duration(momentTime.diff(moment('00:00:00', 'HH:mm:ss')));
        const hours2 = duration.hours() < 10 ? `0${duration.hours()}` : duration.hours();
        const minutes2 = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
        const seconds2 = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds();
        // now convert to seconds and add to total
        return `${!isNaN(hours2 as number) ? hours2 : '00'}:${!isNaN(minutes2 as number) ? minutes2 : '00'}:${!isNaN(seconds2 as number) ? seconds2 : '00'}`;
    }

    const checkStatus = (status: "submitted" | "processing" | "completed" | "failed" | undefined) => {
        switch (status) {
            case 'submitted':
                return <div className='flex gap-2 items-center'>
                    <BiTime size={20} className='text-yellow-500' />
                    <span className='capitalize text-yellow-500'>
                        waiting
                    </span>
                </div>;
            case 'processing':
                return <div className='flex gap-2 items-center'>
                    <BiRefresh className='animate-spin' size={20} />
                    <span className='capitalize'>
                        processing
                    </span>
                </div>
            case 'completed':
                return <div className='flex gap-2 items-center'>
                    <MdOutlineDone size={22} className='text-green-500' />
                    <span className='capitalize text-green-500'>
                        completed
                    </span>
                </div>
            case 'failed':
                return <div className='flex gap-2 items-center'>
                    <MdOutlineClose size={20} className='text-red-500' />
                    <span className='capitalize text-red-500'>
                        failed
                    </span>
                </div>
            default:
                return <div>
                    unknown
                </div>
        }
    }

    const retryToUpload = async (id: string) => {
        try {
            const { data, status } = await axios.post(`${transcoderConfig?.hostname}/api/v1/jobs/retry/upload`, { id: id }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + Base64.encode(`${transcoderConfig?.username}:${transcoderConfig?.password}`),
                }
            })
            if (status === 200) {
                setDisabledRetryUpload(true)
                toast.success("Operation performed successfully")
            }
        } catch (error) {
            toast.error("Something went wrong")
        }
    }

    return (
        <div className='mb-36'>
            <div className='bg-opacity-25 rounded-xl overflow-hidden'>
                <div className='px-1 py-4 flex justify-between items-center'>
                    <h1 className='text-xl font-medium opacity-90'>View Job</h1>
                    <div className='flex gap-4 items-center'>
                        <div onClick={() => refetch()}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                            <BiRefresh className={`text-black text-lg bg-white rounded-full ${isFetching && 'animate-spin'}`} />
                            <h1 className='text-sm select-none text-white'>
                                Refresh
                            </h1>
                        </div>
                        <div
                            onClick={() => {
                                if (!JobsData?.data[0]._id) return
                                //confirm delete
                                if (window.confirm("Are you sure you want to delete this job?")) deleteJob()
                                else return
                            }}
                            className='bg-red-500 w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer'>
                            <MdDelete className='text-white text-lg rounded-full' />
                            <h1 className='text-sm select-none text-white'>Delete</h1>
                        </div>
                        <Link to={RouteStrings.Transcoding.Children.Dashboard.Link}>
                            <div
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                                <BiArrowBack className='text-black text-lg bg-white rounded-full' />
                                <h1 className='text-sm text-white'>Back to Jobs</h1>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className='w-full min-h-[35rem]'>
                    <div className='flex gap-4'>
                        <img
                            src="https://res.cloudinary.com/dm4uaqlio/image/upload/v1679132853/zezo-ott-app-logo/Group_174_lmnm4o.png"
                            className='w-14 h-14'
                        />
                        <div>
                            <h1 className='text-lg font-medium'>
                                {JobsData?.data[0]?.name && JobsData?.data[0]?.name.length > 100 ? JobsData?.data[0]?.name.slice(0, 100) + '...' : JobsData?.data[0]?.name}
                            </h1>
                            <h1 className='capitalize opacity-70 mt-0.5'>
                                Priority - {JobsData?.data[0].priority}
                            </h1>
                        </div>
                    </div>
                    <div>
                        <div className='flex flex-col gap-4 mt-8'>
                            <div className='flex justify-between items-center'>
                                <span className="text-sm">{JobsData?.data[0].progress}% Complete</span>
                                <span className="text-sm">
                                    Timemark - {timemarkToTime(JobsData?.data[0].timemark)}
                                </span>
                            </div>
                            <div className='relative w-full'>
                                <div className='w-full bg-gray-600 bg-opacity-50 h-2 rounded-full' />
                                <div
                                    style={{ width: `${JobsData?.data[0].progress}%` }}
                                    className={`bg-green-500 h-2 rounded-full absolute duration-1000 top-0 left-0`} />
                            </div>
                            <div className='flex justify-between items-center mt-2 gap-4'>
                                <div>
                                    <span className=''>
                                        {checkStatus(JobsData?.data[0].status)}
                                    </span>
                                </div>
                                <div className='flex items-center gap-4'>
                                    {
                                        JobsData?.data[0].startTime && (
                                            <div className='flex items-center gap-2'>
                                                <MdDateRange className='text-gray-300 text-lg' />
                                                <span>
                                                    {
                                                        moment(JobsData?.data[0].startTime).format('DD MMM YYYY hh:mm:ss A')
                                                    }
                                                </span>
                                            </div>
                                        )
                                    }
                                    {
                                        JobsData?.data[0].endTime && (<div className='flex items-center gap-2'>
                                            <img src="https://res.cloudinary.com/dm4uaqlio/image/upload/v1679221261/goal_vatauu.png"
                                                className='w-4 h-4 filter invert'
                                            />
                                            <span>
                                                {
                                                    moment(JobsData?.data[0].endTime).format('DD MMM YYYY hh:mm:ss A')
                                                }
                                            </span>
                                        </div>)
                                    }

                                </div>
                            </div>
                            <div className='flex mt-5 items-center'>
                                Resolutions  - {JobsData?.data[0]?.resolutions.map((res: any) => (
                                    <div className='bg-[#5B6B7D] text-white px-2.5 py-1 rounded-full ml-2 text-xs'>
                                        {res}
                                    </div>
                                ))}
                            </div>
                            <div className='flex mt-5 items-center'>
                                <div className='flex gap-2 items-center'>
                                    <BiTime size={20} />
                                    <span>
                                        Duration - {moment.duration(JobsData?.data[0].duration, 'seconds').humanize()}
                                    </span>
                                </div>
                            </div>
                            <div className='flex mt-2 items-center'>
                                <div className='flex gap-2 items-center'>
                                    <BsCloudArrowUp size={20} />
                                    <span>
                                        Cloud Storage - <span className='uppercase'>{JobsData?.data[0].storage}</span>
                                    </span>
                                </div>
                            </div>
                            <div className='flex mt-2 items-center'>
                                <div className='flex gap-2 items-center'>
                                    <MdSlowMotionVideo size={20} />
                                    <span>
                                        Template - <span className='uppercase'>{JobsData?.data[0].template}</span>
                                    </span>
                                </div>
                            </div>
                            <div className='flex mt-2 items-center'>
                                <div className='flex gap-2 items-center'>
                                    <MdInput size={20} />
                                    <span>
                                        Input - {JobsData?.data[0].input && JobsData?.data[0].input.length > 100 ? JobsData?.data[0].input.slice(0, 100) + '...' : JobsData?.data[0].input}
                                    </span>
                                </div>
                            </div>
                            {/* create at */}
                            <div className='flex mt-2 items-center'>
                                <div className='flex gap-2 items-center'>
                                    <BiTime size={20} />
                                    <span>
                                        Created At - {moment(JobsData?.data[0].createdAt).format('DD MMM YYYY hh:mm:ss A')}
                                    </span>
                                </div>
                            </div>
                            {/* updated at */}
                            <div className='flex mt-2 items-center'>
                                <div className='flex gap-2 items-center'>
                                    <BiTime size={20} />
                                    <span>
                                        Last Updated At - {moment(JobsData?.data[0].updatedAt).format('DD MMM YYYY hh:mm:ss A')}
                                    </span>
                                </div>
                            </div>
                            {/* error */}
                            <div className='flex mt-2 items-center'>
                                <div className='flex gap-2 items-center'>
                                    <BiError size={20} />
                                    <span>
                                        Error - {JobsData?.data[0].error && JobsData?.data[0].error?.length > 100 ? JobsData?.data[0].error?.slice(0, 100) + '...' : JobsData?.data[0].error}
                                    </span>
                                </div>
                            </div>
                            {/* error meassge */}
                            <div className='flex mt-2 items-center'>
                                <div className='flex gap-2 items-center'>
                                    <BiError size={20} />
                                    <span>
                                        Error Message - {JobsData?.data[0].error_message && JobsData?.data[0].error_message?.length > 100 ? JobsData?.data[0].error_message?.slice(0, 100) + '...' : JobsData?.data[0].error_message}
                                    </span>
                                    <span>
                                        {
                                            !disabledRetryUpload && JobsData?.data[0].error_message && JobsData?.data[0].error_message === "failed to upload on cloud" && (<button
                                                onClick={() => {
                                                    if (!JobsData?.data[0]._id) return
                                                    if (disabledRetryUpload) return
                                                    retryToUpload(JobsData?.data[0]._id)
                                                }}
                                                className='bg-[#5B6B7D] text-white px-2.5 py-1 rounded-full ml-2 text-xs'>
                                                Retry
                                            </button>)
                                        }
                                    </span>
                                </div>
                            </div>
                            {/* output */}
                            {
                                JobsData?.data[0].output && JobsData?.data[0].output?.length > 0 && getAwsConfigQuery.isSuccess && (<div className='flex mt-2 items-center'>
                                    <div className='flex gap-2 items-center'>
                                        <AiOutlineLink size={20} />
                                        <span>
                                            Output - {decodeBase64Data(getAwsConfigQuery.data?.data).cloudfront.url}{JobsData?.data[0].output && JobsData?.data[0].output?.length > 50 ? JobsData?.data[0].output?.slice(0, 50) + '...' : JobsData?.data[0].output}
                                        </span>
                                        { /* copy to clipboard */}
                                        <span>
                                            {
                                                JobsData?.data[0].output && (<button
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(decodeBase64Data(getAwsConfigQuery.data?.data).cloudfront.url + JobsData?.data[0].output || '')
                                                        toast.success('Copied to clipboard')
                                                        setTextCopied(true)
                                                    }}
                                                    className='bg-[#5B6B7D] text-white px-2.5 py-1 rounded-full ml-2 text-xs'>
                                                    {textCopied ? 'Copied' : 'Copy'}
                                                </button>)
                                            }
                                        </span>

                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TranscoderJobView

