// Author: Pukhraj Dhamu
// Created Date: Tuesday, July 11th 2023 at 11:00 AM
// Last Modified Date: Tuesday, July 11th 2023 at 11:00 AM
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
import { BlogTable, SelectField } from '../../tailwind'
import { useDispatch, useSelector } from 'react-redux'
import { setBulkAction, setBulkActionCall } from '../../redux/slice​/blogSlice'
import { RootState } from '../../redux/store'
import { BiRefresh } from 'react-icons/bi'
import RouteStrings from '../../localData/routes.json'
import { HiOutlinePlusSm } from 'react-icons/hi'
import { useQuery } from '@tanstack/react-query'
import { getBlogs } from '../../http/blogs.http'
import { useQueryParamsForPagination } from '../../hooks/useQueryParamsForPagination'
import { Loader } from '../../components/smallComponents/loader'
import { counter } from '../../utils/Utils'
import { toast } from 'react-hot-toast'
import { useDebounce } from 'use-debounce'
import qs from 'qs'

const BlogPage = (): JSX.Element => {
    const dispatch = useDispatch()
    const { darkMode } = useSelector((state: RootState) => state.theme)
    const [search, setSearch] = React.useState<string>('')
    // get query params
    const queryParams = useQueryParamsForPagination()
    const [value] = useDebounce(search, 1000);
    const queryString: string = qs.stringify(queryParams.query);

    // fetch content query
    const blogsQuery = useQuery<{ data: IBlogs }>({
        queryKey: ["blogs-list", queryString],
        queryFn: () => getBlogs(queryString),
        enabled: queryParams.query.page !== undefined || queryParams.query.limit !== undefined || queryParams.query.sort !== undefined,
    })

    useEffect(() => {
        if (value) queryParams.setQuery && queryParams.setQuery({ ...queryParams.query, keyword: value });
        if (!value) {
            delete queryParams.query.keyword
            queryParams.setQuery && queryParams.setQuery({ ...queryParams.query })
        }
        blogsQuery.refetch();
    }, [value]);

    useEffect(() => {
        blogsQuery.refetch();
    }, [queryParams.query]);

    const headlePrev = () => {
        if (queryParams.query.page > 1) {
            queryParams.setQuery && queryParams.setQuery({
                ...queryParams.query,
                page: queryParams.query.page - 1,
            });
        } else {
            toast.error("You are on first page");
        }
    };

    const handleNext = () => {
        if (!blogsQuery.data?.data.meta.pagination.page) return
        if (queryParams.query.page < blogsQuery.data.data.meta.pagination.pageCount) {
            queryParams.setQuery && queryParams.setQuery({
                ...queryParams.query,
                page: queryParams.query.page + 1,
            });
        } else {
            toast.error("No more pages");
        }
    };

    const headleFirst = () => {
        if (queryParams.query.page === 1) {
            toast.error("You are on first page");
        } else {
            queryParams.setQuery && queryParams.setQuery({
                ...queryParams.query,
                page: 1,
            });
        }
    }

    const headleLast = () => {
        if (!blogsQuery.data?.data.meta.pagination.pageCount) return
        const lastPage = blogsQuery.data?.data.meta.pagination.pageCount
        if (queryParams.query.page === lastPage) {
            toast.error("You are on last page");
        } else {
            queryParams.setQuery && queryParams.setQuery({
                ...queryParams.query,
                page: lastPage
            });
        }
    }

    return (
        <Layout>
            <div className="flex justify-between items-center pr-6">
                <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
                    List of Blogs
                </h1>
                <div className='flex gap-4 items-center'>
                    <div
                        onClick={() => { blogsQuery.refetch() }}
                        className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                        <BiRefresh className={`text-black text-lg bg-white rounded-full ${blogsQuery.isLoading || blogsQuery.isFetching && "animate-spin"}`} />
                        <h1 className='text-sm text-white select-none'>
                            Refresh
                        </h1>
                    </div>
                    <Link to={`${RouteStrings.Blogs.Children.CreateBlog.Link}`}>
                        <div className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                            <HiOutlinePlusSm className='text-black text-lg bg-white rounded-full' />
                            <h1 className='text-sm text-white'>
                                Add New
                            </h1>
                        </div>
                    </Link>
                    <div className='w-52'>
                        <SelectField
                            onChange={(e) => {
                                dispatch(setBulkAction(e.value))
                            }}
                            options={[
                                { value: "none", label: "Bulk Actions" },
                                { value: "public", label: "Public" },
                                { value: "private", label: "Private" },
                                { value: "restore", label: "Restore" },
                                { value: "trash", label: "Move to Trash" },
                                { value: "delete", label: "Delete Forever" },
                            ]}
                            getOptionLabel={(option) => option.label}
                            placeholder='Bulk Actions'
                        />
                    </div>
                    <div
                        onClick={() => dispatch(setBulkActionCall(true))}
                        className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                        <BiRefresh className={`text-black text-lg bg-white rounded-full ${blogsQuery.isLoading || blogsQuery.isFetching && "animate-spin"}`} />
                        <h1 className='text-sm text-white select-none'>
                            Apply
                        </h1>
                    </div>
                    <input
                        type="text"
                        placeholder="Search Content by name, tags, slug, etc..."
                        className={`input input-bordered input-md w-80 bg-transparent focus:outline-none h-[38px] rounded ${darkMode ? "border-gray-700" : "border-gray-300"}`}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                </div>
            </div>
            <div className="mt-5 w-full">
                {
                    blogsQuery.isLoading && <div className='w-full flex justify-center items-center min-h-[47rem]'>
                        <Loader smail />
                    </div>
                }
            </div>
            <div className="mt-5 w-full">
                {
                    blogsQuery.isError && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            Server Error. Please try again
                        </h1>
                        <div
                            onClick={() => blogsQuery.refetch()}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                            <h1 className='text-sm text-white'>
                                Retry
                            </h1>
                        </div>
                    </div>
                }
            </div>
            <div className="mt-5 w-full">
                {blogsQuery.isSuccess ? blogsQuery.data?.data?.data.length > 0 ? <>
                    <BlogTable blogsQuery={blogsQuery} />
                    <div className="flex justify-between items-center my-5 pr-6 mb-8">
                        <div className="flex gap-8 items-center">
                            <div className="flex gap-3.5">
                                <p>Total</p>
                                <p>{counter(blogsQuery.data.data.meta.pagination.total || 0)}</p>
                            </div>
                            <div className="flex gap-3.5">
                                <p>Public</p>
                                <p>{counter(blogsQuery.data.data.meta.report?.totalPublic || 0)}</p>
                            </div>
                            <div className="flex gap-3.5">
                                <p>Private</p>
                                <p>{counter(blogsQuery.data.data.meta.report?.totalPrivate || 0)}</p>
                            </div>
                            <div className="flex gap-3.5">
                                <p>Pages</p>
                                <p>{counter(blogsQuery.data.data.meta.pagination.pageCount || 0)}</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-2.5 mr-6">
                                <div
                                    onClick={headleFirst}
                                    className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 py-2 flex gap-2 items-center cursor-pointer`}>
                                    <h1 className='text-base text-white select-none'>
                                        First
                                    </h1>
                                </div>
                                <div
                                    onClick={headlePrev}
                                    className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 py-2 flex gap-2 items-center cursor-pointer`}>
                                    <h1 className='text-base text-white select-none'>
                                        Previous
                                    </h1>
                                </div>
                                <div className={`w-28 justify-center rounded px-5 flex gap-2 items-center cursor-pointer`}>
                                    <h1 className='text-base select-none'>
                                        Page {blogsQuery.data.data.meta.pagination.page || "N/A"}
                                    </h1>
                                </div>
                                <div
                                    onClick={handleNext}
                                    className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 flex gap-2 items-center cursor-pointer`}>
                                    <h1 className='text-base text-white select-none'>
                                        Next
                                    </h1>
                                </div>
                                <div
                                    onClick={headleLast}
                                    className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-24 justify-center rounded px-5 py-2 flex gap-2 items-center cursor-pointer`}>
                                    <h1 className='text-base text-white select-none'>
                                        Last
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <>
                    <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            No Blogs Found
                        </h1>
                        <div
                            onClick={() => blogsQuery.refetch()}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                            <h1 className='text-sm text-white'>
                                Retry
                            </h1>
                        </div>
                    </div>
                </> : null}
            </div>
        </Layout>
    )
}

export default BlogPage