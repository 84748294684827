import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAppThemeApi, initAppThemeApi, updateAppThemeApi } from '../../../http/app.settings.http';
import { toast } from 'react-hot-toast';
import { Loader } from '../../../components/smallComponents/loader';
import { FiEdit } from 'react-icons/fi'
import CodeEditor from '@uiw/react-textarea-code-editor';
import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


function AppThemes() {
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [open, setOpen] = useState<boolean>(false);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [editTheme, setEditTheme] = useState<IAppThemeState>();
    const [colors, setColors] = useState<IAppThemeState['colors']>();
    console.log("🚀 ~ file: AppThemes.tsx:63 ~ AppThemes ~ colors:", colors)
    // get the theme schema from server
    const getAppThemeQuery = useQuery<AppThemeApiResponse>({
        queryKey: ["getAppTheme"],
        queryFn: () => getAppThemeApi(),
        refetchOnWindowFocus: false,
        retryOnMount: false,
    })

    // update the app splash screen settings using muteion
    const AppThemeUpdateMutation = useMutation({
        mutationKey: ["updateAppTheme"],
        mutationFn: ({ payload, id }: { payload: IAppThemeUpdatePayload, id: string }) => updateAppThemeApi(payload, id),
        onSuccess(data, variables, context) {
            toast.success("Theme updated successfully");
            getAppThemeQuery.refetch();
            handleClose();
        },
        onError(error: any, variables, context) {
            toast.error(error?.response?.data?.error?.message || "Error updating theme");
        },
    })

    const handleAppThemeUpdate = () => {
        if (editTheme && colors) AppThemeUpdateMutation.mutate({
            payload: {
                colors: JSON.parse(colors as any),
                name: editTheme.name,
                status: editTheme.status,
                type: editTheme.type,
            },
            id: editTheme._id,
        });
        else toast.error("Error updating theme");
    }

    // init theme mutation
    const initThemeMutation = useMutation({
        mutationKey: ["initTheme"],
        mutationFn: () => initAppThemeApi(),
        onSuccess(data, variables, context) {
            getAppThemeQuery.refetch();
            toast.success("Theme initialized successfully");
        },
        onError(error: any, variables, context) {
            getAppThemeQuery.refetch();
            toast.error(error?.response?.data?.error?.message || "Error initializing theme");
        },
    })

    return (
        <>
            <div className='pr-6'>
                <h1 className="my-6 opacity-80 font-medium tracking-wide text-xl pr-5">
                    Themes
                </h1>
                {getAppThemeQuery.isLoading && (
                    <div className='w-full flex justify-center items-center min-h-[47rem]'>
                        <Loader smail />
                    </div>
                )}
                {getAppThemeQuery.isError && (
                    <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            Server Error. Please try again
                        </h1>
                        <div
                            onClick={() => getAppThemeQuery.refetch()}
                            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                            <h1 className='text-sm text-white'>
                                Retry
                            </h1>
                        </div>
                    </div>
                )}
                {getAppThemeQuery.isSuccess && (
                    getAppThemeQuery.data.data.length > 0 ? (
                        <div className='flex gap-4'>
                            {getAppThemeQuery.data.data.map((theme, index) => {
                                return (
                                    <div key={index}
                                        style={{ backgroundColor: theme.colors.background }}
                                        className={`w-72 h-72 border-blue-500 border-4 relative`}>
                                        <div className='w-full h-full hover:bg-blue-500 hover:bg-opacity-50 duration-500 cursor-pointer transition-all'>
                                            <div className='opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex items-center justify-center w-full h-full'>
                                                <FiEdit
                                                    onClick={() => {
                                                        handleClickOpen();
                                                        setEditTheme({
                                                            _id: theme._id,
                                                            colors: theme.colors,
                                                            name: theme.name,
                                                            status: theme.status,
                                                            type: theme.type,
                                                        });
                                                        setColors(JSON.stringify(theme.colors, null, 2) as any);
                                                    }}
                                                    className='text-blue-500 text-3xl' />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <div className='min-h-[47rem] justify-center flex flex-col items-center'>
                            <h1 className='text-xl font-medium opacity-90'>
                                No Themes Found. You can import default themes from here.
                            </h1>
                            <div
                                onClick={() => initThemeMutation.mutate()}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
                                <h1 className='text-sm text-white'>
                                    {initThemeMutation.isLoading ? "Importing..." : "Import"}
                                </h1>
                            </div>
                        </div>
                    )
                )}
            </div>

            <div >
                <BootstrapDialog
                    aria-labelledby="customized-dialog-title"
                    open={open}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Edit {editTheme?.name}
                    </BootstrapDialogTitle>
                    <DialogContent dividers className='w-full'>
                        {colors && <CodeEditor
                            value={colors as any}
                            language="json"
                            placeholder="You can edit the theme here."
                            onChange={(evn) => setColors(evn.target.value as any)}
                            padding={15}
                            style={{
                                fontSize: 15,
                                backgroundColor: "#282828",
                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                            }}
                        />
                        }
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            autoFocus
                            loading={AppThemeUpdateMutation.isLoading}
                            variant="contained"
                            disabled={AppThemeUpdateMutation.isLoading}
                            onClick={handleAppThemeUpdate}>
                            Save changes
                        </LoadingButton>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        </>
    )
}

export default AppThemes
