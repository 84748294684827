// Author: Pukhraj Dhamu
// Created Date: Wednesday, July 19th 2023 at 6:30 PM
// Last Modified Date: Wednesday, July 19th 2023 at 6:30 PM
import { Button } from '@mui/material'
import React from 'react'
import toast from 'react-hot-toast'
import { MuiDialog, MuiSwitch } from '../../../components'
import { userActionsApi } from '../../../http/Apis'
import { useNavigate } from "react-router-dom"
interface IProps {
    user: {
        isActivated: boolean | undefined
        trash: boolean | undefined
    } | null,
    setUser: (user: any) => void,
    id: string
}

const UserActions = ({ user, setUser, id }: IProps): JSX.Element => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const router = useNavigate()

    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleDelete = async (userId: string) => {
        try {
            setLoading(true)
            // delete user
            const { status } = await userActionsApi({
                id: [userId],
                action: 'delete',
            })
            if (status === 200) {
                setLoading(false)
                setOpen(false)
                toast.success('User deleted successfully')
                router('/admin/users')
            }
        } catch (error: any) {
            setLoading(false)
            setOpen(false)
            toast.error(error.response?.data?.error?.message || "User can't be deleted");
        }
    }

    return (
        <div>
            <div className="mt-10 pr-5">
                <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                    User Status
                </h1>
                <p className="mt-2 opacity-70">
                    You can activate or deactivate user here. if user is deactivated, user can't login to the system.
                </p>
            </div>
            <div className='flex items-center gap-10 mt-5'>
                <h1>
                    Status
                </h1>
                <MuiSwitch
                    variant="ios"
                    checked={user?.isActivated}
                    onChange={(e) => setUser({ ...user, isActivated: e.target.checked })}
                />
            </div>
            <div className="mt-10 pr-5">
                <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                    Move to Trash
                </h1>
                <p className="mt-2 opacity-70">
                    You can move user to trash here. if user is in trash, user can't login to the system.
                </p>
            </div>
            <div className='flex items-center gap-10 mt-5'>
                <h1>
                    Status
                </h1>
                <MuiSwitch
                    variant="ios"
                    checked={user?.trash}
                    onChange={(e) => setUser({ ...user, trash: e.target.checked })}
                />
            </div>
            <div className="mt-10 pr-5">
                <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                    Delete Permanently
                </h1>
                <p className="mt-2 opacity-70">
                    You can delete user permanently here. if user is deleted permanently, user can't login to the system.
                </p>
            </div>
            <div className='flex items-center gap-10 mt-5'>
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    disabled={loading}
                    onClick={handleClickOpen}>
                    {loading ? 'Deleting...' : 'Delete'}
                </Button>
            </div>
            <MuiDialog
                title="Delete User"
                contentText='Are you sure you want to permanently delete this user?'
                buttonOneText='Cancel'
                onClickYes={() => handleDelete(id)}
                onClose={handleClose}
                onClickClose={handleClose}
                loading={loading}
                open={open}
            />
        </div>
    )
}

export default UserActions