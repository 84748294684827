import Layout from "../../components/Layout/Layout";
import { LinkIcon, TsIcon, UnknownIcon, VideoFileIcon, } from "../../tailwind/Icon";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import { useDebounce } from "use-debounce";
import { useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { getDataForFilemanager, createDataForFilemanager, renameDataForFilemanager, deleteDataForFilemanager, syncDataForFilemanager, startTranscoding, CreateSignUrl, } from "../../http/Apis";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { checkFileExtension } from "./helpers/helper"
import DragAndDrop from "./components/DragAndDrop"
import FullScreenDialog from "./components/FullScreenDialog";
import ContextMenu from "./components/ContextMenu";
import Header from "./components/Header";
import DialogBox from "./components/DialogBox";
import ImageIconWithPreview from "./components/ImageIconWithPreview";
import { useQuery } from "@tanstack/react-query";
import { getAwsConfigSettingsApi } from "../../http/settingshttp";
const MENU_ID = "menu-id";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const fileTypes = ["JPG", "PNG", "GIF", "MP4", "MOV"];

const BUCKET_NAME = process.env.REACT_APP_AWS_PRIVATE_BUCKET;

// check file extension and return icon
interface IFileIconProps {
  name: string;
  key?: string;
}

const getFileIcon = (file: IFileIconProps) => {
  const fileName = file.name.split("/");
  const ext = fileName[fileName.length - 1].split(".").pop();
  switch (ext) {
    case "mp4":
      return <VideoFileIcon className="w-16" />;
    case "ts":
      return <TsIcon className="w-16" />;
    case "m3u8":
      return <LinkIcon className="w-16" />;
    case "jpg":
      return <ImageIconWithPreview file={file} />;
    case "jpeg":
      return <ImageIconWithPreview file={file} />;
    case "png":
      return <ImageIconWithPreview file={file} />;
    case "gif":
      return <ImageIconWithPreview file={file} />;
    case "webp":
      return <ImageIconWithPreview file={file} />;
    default:
      return <UnknownIcon />;
  }
};

const FileManager = () => {
  const getAwsConfigQuery = useQuery<IGetAwsConfigResponse>({
    queryKey: ['getAwsConfig'],
    queryFn: () => getAwsConfigSettingsApi(),
  })
  // @##### Array For the BreadCrumb Navigation #####@
  const [breadArr, setBreadArr] = useState<{
    name: string;
    key: string;
  }[]>([{
    name: "Home",
    key: "/",
  }]);
  const [currDir, setCurrDir] = useState("/");
  const [copied, setCopied] = useState<boolean>(false);
  const [files, setFiles] = useState([]);
  const [bucketName, setBucketName] = useState<string | undefined>(BUCKET_NAME);
  const { config } = useSelector((state: RootState) => state.config);
  const [isRename, setIsRename] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [value] = useDebounce(search, 1000);
  const { credentials } = config;
  interface ISingleFile {
    id: string;
    name: string;
    key: string;
    type: string;
    size: number;
  }
  // after 5 seconds, set copied to false
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    }
  }, [copied]);

  const [singleFile, setSingleFile] = useState<ISingleFile>({
    id: "",
    name: "",
    type: "",
    key: "",
    size: 0,
  });
  const [folderName, setFolderName] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [syncOpen, setSyncOpen] = useState(false);
  const [syncType, setSyncType] = useState("");
  const [fullScreenDialog, setFullScreenDialog] = useState(false);
  const [Resolution, setResolution] = useState([
    {
      id: 1,
      name: "144p",
      value: "144p",
      selected: true,
      disabled: true,
    },
    {
      id: 2,
      name: "240p",
      value: "240p",
      selected: true,
      disabled: true,
    },
    {
      id: 3,
      name: "360p",
      value: "360p",
      selected: true,
      disabled: true,
    },
    {
      id: 4,
      name: "480p",
      value: "480p",
      selected: true,
      disabled: true,
    },
    {
      id: 5,
      name: "720p",
      value: "720p",
      selected: true,
      disabled: true,
    },
    {
      id: 6,
      name: "1080p",
      value: "1080p",
      selected: true,
      disabled: true,
    },
    {
      id: 7,
      name: "4K",
      value: "2160p",
      selected: false,
      disabled: false,
    },
  ]);

  const [inputUrl, setInputUrl] = useState("");
  const [transcodingFileName, setTranscodingFileName] = useState("");
  const [isRootDir, setRootDir] = useState(false);
  const [submitData, setSubmitData] = useState({
    priority: "low",
    input: inputUrl,
    template: "hls",
    resolutions: Resolution.filter((res) => res.selected)
      .map((res) => res.value)
      .reverse(),
    storage: "s3",
  });

  // if resolution is selected then set it to true
  useEffect(() => {
    setSubmitData({
      ...submitData,
      input: inputUrl,
      resolutions: Resolution.filter((res) => res.selected)
        .map((res) => res.value)
        .reverse(),
    });
  }, [Resolution, inputUrl]);

  const onSubmitTranscoding = async () => {
    try {
      setLoading(true);
      // return;
      let newData = {
        "label": transcodingFileName || singleFile.name.split(".")[0],
        priority: submitData.priority,
        input: singleFile.key,
        template: submitData.template,
        resolutions: submitData.resolutions.reverse(),
        storage: submitData.storage,
      };
      await startTranscoding(newData);
      handleFullScreenDialogClose();
      toast.success("Transcoding started successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error starting transcode");
    }
  };

  // transcoding state
  const [transcodingProvider, setTranscodingProvider] = useState<string | null>(null);

  const fullScreenDialogOpen = () => setFullScreenDialog(true);

  const handleFullScreenDialogClose = () => {
    setFullScreenDialog(false);
    setTranscodingProvider(null);
  };

  // create folder
  const [createFolderOpen, setCreateFolderOpen] = useState(false);

  const handleClickCreateFolderOpen = () => setCreateFolderOpen(true);

  const handleCreateFolderClose = () => {
    setIsRename(false);
    setFolderName("");
    setCreateFolderOpen(false);
  };

  // open file details dialog
  const [detailsOpen, setDetailsOpen] = useState(false);
  const handleClickDetailsOpen = () => setDetailsOpen(true);

  const handleDetailsClose = () => setDetailsOpen(false);

  // handle double click on file
  const handleClick = (e: any, item: any) => {
    if (item.type === "folder") {
      setCurrDir(item.key);
      breadArr.push({
        name: item.name,
        key: item.key,
      });

      setBreadArr([...breadArr]);
    } else {
      handleClickDetailsOpen();
      setSingleFile(item);
    }
  };

  const decodeBase64Data = (data: string | any): IGetAwsConfigResponse['data'] => {
    const decodedData: IGetAwsConfigResponse['data'] = JSON.parse(atob(data))
    return decodedData
  }
  // fetch file and folders
  const fetchData = async () => {
    try {
      if (value) {
        if (value.length > 2) {
          const query = `?key=${currDir}&keyword=${search}&page=1&limit=10`;
          const res = await getDataForFilemanager(query);
          if (res.data.data) {
            let dataArr: any = [...res.data.data.data];
            setFiles(dataArr);
            setRootDir(true);
            return dataArr
          }
        } else {
          toast.error("Please enter more than 2 characters");
          const query = `?key=${currDir}&page=1&limit=10`;
          const res = await getDataForFilemanager(query);
          if (res.data.data) {
            let dataArr: any = [...res.data.data.folder, ...res.data.data.file];
            setFiles(dataArr);
            setRootDir(true);
            return dataArr
          }
        }
      } else {
        const query = `?key=${currDir}&page=1&limit=10`;
        const res = await getDataForFilemanager(query);
        if (res.data.data) {
          let dataArr: any = [...res.data.data.folder, ...res.data.data.file];
          setFiles(dataArr);
          setRootDir(true);
          return dataArr
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        if (err.status === 400 && currDir === "/") {
          setRootDir(false);
          return null
        }
        setRootDir(true);
        return toast.error(err.data.error.message);
      }
    }
  };
  const fetchFilesDataQuery = useQuery<any>({
    queryKey: ["files", currDir],
    queryFn: () => fetchData(),
    enabled: BUCKET_NAME ? true : false,
    onSuccess(data) {
      if (data) {
        // data must be an array
        if(!Array.isArray(data)) return
        setFiles(data as any)
      }
    },
    retry: false,
  })
  useEffect(() => {
    fetchFilesDataQuery.refetch()
  }, [currDir, bucketName, value]);

  // create folder
  const createFolder = async () => {
    if (folderName === "undefined" || folderName === "null" || folderName === "NaN" || folderName === "/")
      return toast.error("Folder name is required");
    if (folderName !== "") {
      setLoading(true)
      try {
        const folderObject = {
          name: folderName,
          key: folderName,
          type: "folder",
        };

        try {
          const res = await createDataForFilemanager(
            folderObject,
            `?parent_dir=${currDir}`
          );
          if (res.status === 200) {
            setFolderName("");
            handleCreateFolderClose();

            const query = `?key=${currDir}&page=1&limit=10`;
            const res = await getDataForFilemanager(query);
            if (res.data.data) {
              let dataArr: any = [
                ...res.data.data.folder,
                ...res.data.data.file,
              ];
              setFiles(dataArr);
              setRootDir(true);
              setLoading(false)
            }
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setLoading(false)

            const err: any = error.response;
            return toast.error(err.data.error.message);
          }
        }
        toast.success("Folder created successfully");
      } catch (error) {
        setLoading(false)
        toast.error("Error creating folder");
      }
    } else {
      toast.error("Folder name is required");
    }
  };

  // delete file
  const deleteFile = async (file: any) => {
    try {
      setLoading(true);
      let res = await deleteDataForFilemanager(file._id);
      if (res.status === 200) {
        let msg = res.data.message;
        const query = `?key=${currDir}&page=1&limit=10`;
        res = await getDataForFilemanager(query);
        if (res.data.data) {
          let dataArr: any = [...res.data.data.folder, ...res.data.data.file];
          setFiles(dataArr);
          setRootDir(true);
          setLoading(false);
          handleDeleteCloseDialog();
          toast.success(msg);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        setLoading(false);
        return toast.error(err.data.error.message);
      }
    }
  };

  // rename file
  const renameFile = async (file: any, newName: any) => {
    const renameFileObject = {
      id: file._id,
      name: newName,
    };
    try {
      setLoading(true);
      let res = await renameDataForFilemanager(
        renameFileObject,
        `?parent_dir=${currDir}`
      );
      if (res.status === 200) {
        let msg = res.data.message;
        const query = `?key=${currDir}&page=1&limit=10`;
        res = await getDataForFilemanager(query);
        if (res.data.data) {
          let dataArr: any = [...res.data.data.folder, ...res.data.data.file];
          setFiles(dataArr);
          setRootDir(true);
          handleCreateFolderClose();
          setLoading(false);

          toast.success(msg);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        setLoading(false);
        return toast.error(err.data.error.message);
      }
    }
  };

  // copy file url
  const copyFileUrl = async (file: any) => {
    try {
      const { data } = await CreateSignUrl({ file_key: file.key.split("/").splice(1).join("/"), })
      navigator.clipboard.writeText(data.data);
      toast.success("File url copied successfully");
    } catch (error) {
      toast.error("Error copying file url");
    }
  };

  // download file
  const downloadFile = async (file: any) => {
    const fileName = file.key.split("/");
    const { data } = await CreateSignUrl({ file_key: file.key.split("/").splice(1).join("/"), })
    // download file from s3 automatically
    window.open(data.data, "_blank");
  };

  // syncing folder
  const syncFolder = async () => {
    try {
      setLoading(true);
      const query = `?type=${syncType}&dir=${syncType === "global" ? "/" : currDir}`;
      let res = await syncDataForFilemanager(query);
      if (res.status === 200) {
        const msg = res.data.message;
        setTimeout(async () => {
          const query = `?key=${syncType === "global" ? "/" : currDir
            }&page=1&limit=10`;
          res = await getDataForFilemanager(query);
          if (res.data.data) {
            let dataArr: any = [...res.data.data.folder, ...res.data.data.file];
            setFiles(dataArr);
            setRootDir(true);
          }

          handleSyncCloseDialog();
          setLoading(false);
          if (syncType === "global") {
            setCurrDir("/");
            setBreadArr([
              {
                name: "Home",
                key: "/",
              },
            ]);
          }
          toast.success(msg);
        }, 2000);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err: any = error.response;
        setLoading(false);
        return toast.error(err.data.error.message);
      }
    }
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedImage, setSelectedImage] = useState<string>();
  // generate signed url
  const createSignedUrl = async (file: any) => {
    try {
      const { data } = await CreateSignUrl({ file_key: file.key.split("/").splice(1).join("/"), })
      window.navigator.clipboard.writeText(data.data);
      setSelectedImage(data.data);
    } catch (error) {
    }
  }

  useEffect(() => {
    if (singleFile && getAwsConfigQuery.data?.data) {
      createSignedUrl(singleFile);
      const cloudfrontUrl = decodeBase64Data(getAwsConfigQuery.data?.data).cloudfront.url
      if (!cloudfrontUrl) return
      const url = new URL(singleFile.key, cloudfrontUrl);
      setInputUrl(url.href);
    }
  }, [singleFile]);

  // 🔥 you can use this hook from everywhere. All you need is the menu id
  const { show } = useContextMenu({ id: MENU_ID });

  function displayMenu(e: any, item: any) {
    e.preventDefault();
    setSingleFile(item);
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show(e);
  }

  // start trascodeing
  const startTranscode = async (file: any) => {
    // remove file extension
    fullScreenDialogOpen();
    createSignedUrl(file);
  };

  const handleDeleteCloseDialog = () => setDeleteOpen(false);
  const handleDeleteOpenDialog = () => setDeleteOpen(true);
  const handleSyncCloseDialog = () => setSyncOpen(false);
  const handleSyncOpenDialog = () => setSyncOpen(true);

  return (
    <Layout>
      <div className="h-full">
        <Header
          files={files}
          loading={loading}
          isRootDir={isRootDir}
          setSearch={setSearch}
          setSyncType={setSyncType}
          handleSyncOpenDialog={handleSyncOpenDialog}
          handleClickCreateFolderOpen={handleClickCreateFolderOpen}
          handleClickOpen={handleClickOpen}
          handleClick={handleClick}
          breadArr={breadArr}
          setCurrDir={setCurrDir}
          setBreadArr={setBreadArr}
          displayMenu={displayMenu}
          getFileIcon={getFileIcon}
          bucketName={bucketName}
          fetchFilesDataQuery={fetchFilesDataQuery}
        />
        <div>
          <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}>
            <div className="flex flex-col justify-center items-center my-5">
              <p className="uppercase font-semibold text-xl text-gray-300">
                Upload Files
              </p>
              <p className="text-xs mt-1 w-72 text-center text-gray-400 tracking-wider">
                Upload files to your cloud storage.
              </p>
            </div>
            <div>
              <DragAndDrop currDir={currDir} fileTypes={fileTypes} setFiles={setFiles} setRootDir={setRootDir} handleClose={handleClose} submitData={submitData} />
            </div>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
        <div>
          <DialogBox
            createFolderOpen={createFolderOpen}
            deleteOpen={deleteOpen}
            syncOpen={syncOpen}
            detailsOpen={detailsOpen}
            handleDetailsClose={handleDetailsClose}
            handleSyncCloseDialog={handleSyncCloseDialog}
            handleCreateFolderClose={handleCreateFolderClose}
            handleDeleteCloseDialog={handleDeleteCloseDialog}
            singleFile={singleFile}
            folderName={folderName}
            setFolderName={setFolderName}
            isRename={isRename}
            loading={loading}
            renameFile={renameFile}
            createFolder={createFolder}
            deleteFile={deleteFile}
            syncFolder={syncFolder}
            checkFileExtension={checkFileExtension}
            selectedImage={selectedImage}
            bucketName={bucketName}
            copied={copied}
            setCopied={setCopied}
          />
        </div>
      </div>
      <ContextMenu
        MENU_ID={MENU_ID}
        singleFile={singleFile}
        setSingleFile={setSingleFile}
        handleClickDetailsOpen={handleClickDetailsOpen}
        handleDeleteOpenDialog={handleDeleteOpenDialog}
        setCurrDir={setCurrDir}
        breadArr={breadArr}
        setBreadArr={setBreadArr}
        setIsRename={setIsRename}
        setFolderName={setFolderName}
        handleClickCreateFolderOpen={handleClickCreateFolderOpen}
        checkFileExtension={checkFileExtension}
        copyFileUrl={copyFileUrl}
        downloadFile={downloadFile}
        startTranscode={startTranscode}
      />

      <FullScreenDialog
        inputUrl={inputUrl}
        setInputUrl={setInputUrl}
        setTranscodingFileName={setTranscodingFileName}
        transcodingFileName={transcodingFileName}
        fullScreenDialog={fullScreenDialog}
        handleFullScreenDialogClose={handleFullScreenDialogClose}
        transcodingProvider={transcodingProvider}
        setTranscodingProvider={setTranscodingProvider}
        Resolution={Resolution}
        setResolution={setResolution}
        submitData={submitData}
        credentials={credentials}
        loading={loading}
        onSubmitTranscoding={onSubmitTranscoding}
      />
    </Layout>
  );
};
export default FileManager;