import Layout from '../../components/Layout/Layout'
import { Hr } from '../../components';
import { InputField } from '../../tailwind';
import { LoadingButton } from '@mui/lab';
import SaveIcon from "@mui/icons-material/Save";
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAwsConfigSettingsApi, updateAwsConfigSettingsApi } from '../../http/settingshttp';
import { Loader } from '../../components/smallComponents/loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

function AwsConfig() {
  const { darkMode } = useSelector((state: RootState) => state.theme)
  const [VisibilityInputValue, setVisibilityInputValue] = useState<"awsAccessKey" | "awsSecretKey" | boolean>(false)
  const [awsAccessKey, setAwsAccessKey] = useState<string>()
  const [awsSecretKey, setAwsSecretKey] = useState<string>()
  const [awsBucket, setAwsBucket] = useState<string>()
  const [awsRegion, setAwsRegion] = useState<string>()
  const [awsPublicBucket, setAwsPublicBucket] = useState<string>()
  const [awsCloudfrontUrl, setAwsCloudfrontUrl] = useState<string>()

  const decodeBase64Data = (data: string | any): IGetAwsConfigResponse['data'] => {
    if (!data) return {
      s3: {
        accessKeyId: "",
        bucket: "",
        region: "",
        secretAccessKey: "",
        publicBucket: ""
      },
      cloudfront: {
        url: ""
      }
    }
    const decodedData: IGetAwsConfigResponse['data'] = JSON.parse(atob(data))
    setAwsAccessKey(decodedData.s3.accessKeyId)
    setAwsSecretKey(decodedData.s3.secretAccessKey)
    setAwsBucket(decodedData.s3.bucket)
    setAwsRegion(decodedData.s3.region)
    setAwsPublicBucket(decodedData.s3.publicBucket)
    setAwsCloudfrontUrl(decodedData.cloudfront.url)
    return decodedData
  }
  const getAwsConfigQuery = useQuery<IGetAwsConfigResponse>({
    queryKey: ['getAwsConfig'],
    queryFn: () => getAwsConfigSettingsApi(),
    onSuccess(data) {
      decodeBase64Data(data.data)
    },
  })

  // mutation the data update prosess
  const { mutate, isLoading } = useMutation({
    mutationKey: ["getAwsConfig"],
    mutationFn: () => updateAwsConfigSettingsApi({
      aws_access_key_id: awsAccessKey as string,
      aws_bucket: awsBucket as string,
      aws_cloudfront_url: awsCloudfrontUrl as string,
      aws_region: awsRegion as string,
      aws_secret_access_key: awsSecretKey as string,
      aws_public_bucket: awsPublicBucket as string
    }),
    onSuccess(data, variables, context) {
      getAwsConfigQuery.refetch()
      toast.success("Saved!")
    },
    onError(error: any, variables, context) {
      toast.error(error?.response?.data?.error?.message || "Something went wrong!")
    },
  })

  // onSave
  const onSave = () => {
    if (awsAccessKey || awsSecretKey || awsBucket || awsRegion || awsCloudfrontUrl || awsPublicBucket) {
      mutate()
    } else {
      toast.error("Please fill all the fields!")
    }
  }

  return (
    <Layout>
      <div className="pr-6">
        <h1 className="text-2xl opacity-80 font-semibold tracking-wide">
          Aws Config
        </h1>
        <p className="mt-2 opacity-70">
          Update your Aws Config here.
        </p>
      </div>
      <div className="my-5">
        <Hr />
      </div>

      {
        getAwsConfigQuery.isLoading && <div className='flex justify-center items-center h-[40rem]'>
          <Loader smail />
        </div>
      }

      {
        getAwsConfigQuery.isError && <div className='min-h-[47rem] justify-center flex flex-col items-center'>
          <h1 className='text-xl font-medium opacity-90'>
            Server Error. Please try again
          </h1>
          <div
            onClick={() => getAwsConfigQuery.refetch()}
            className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-5 py-2 flex gap-2 items-center cursor-pointer mt-4`}>
            <h1 className='text-sm text-white'>
              Retry
            </h1>
          </div>
        </div>
      }

      {
        getAwsConfigQuery.isSuccess && <>
          <div className="my-5 pr-36 flex flex-col gap-8">
            <div className="flex items-center gap-2">
              <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                S3 Config
              </h1>
            </div>
            <InputField
              title={"AWS Access Key"}
              fieldName={"aws-access-key"}
              type={VisibilityInputValue === "awsAccessKey" ? "text" : "password"}
              inputType='password'
              placeholder={"Enter AWS Access Key"}
              value={awsAccessKey || ""}
              onPasswordVisibilityClick={() => {
                if (VisibilityInputValue === "awsAccessKey") setVisibilityInputValue(false)
                else setVisibilityInputValue("awsAccessKey")
              }}
              onChange={({ target: { value } }) => setAwsAccessKey(value)}
            />
            <InputField
              title={"AWS Secret Key"}
              fieldName={"aws-secret-key"}
              type={VisibilityInputValue === "awsSecretKey" ? "text" : "password"}
              inputType='password'
              placeholder={"Enter AWS Secret Key"}
              value={awsSecretKey || ""}
              onPasswordVisibilityClick={() => {
                if (VisibilityInputValue === "awsSecretKey") setVisibilityInputValue(false)
                else setVisibilityInputValue("awsSecretKey")
              }}
              onChange={({ target: { value } }) => setAwsSecretKey(value)}
            />
            <InputField
              title={"AWS Bucket Name"}
              fieldName={"aws-bucket-name"}
              type="text"
              placeholder={"Enter AWS Bucket Name"}
              value={awsBucket || ""}
              onChange={({ target: { value } }) => setAwsBucket(value)}
            />
            <InputField
              title={"AWS Public Bucket Name"}
              fieldName={"aws-public-bucket-name"}
              type="text"
              placeholder={"Enter AWS Public Bucket Name"}
              value={awsPublicBucket || ""}
              onChange={({ target: { value } }) => setAwsPublicBucket(value)}
            />
            <InputField
              title={"AWS Region"}
              fieldName={"aws-region"}
              type="text"
              placeholder={"Enter AWS Region"}
              value={awsRegion || ""}
              onChange={({ target: { value } }) => setAwsRegion(value)}
            />
          </div>

          <div className="my-5 pr-36 flex flex-col gap-8">
            <div className="flex items-center gap-2">
              <h1 className="text-xl opacity-80 font-semibold tracking-wide">
                CloudFront Config
              </h1>
            </div>
            <InputField
              title={"CloudFront URL"}
              fieldName={"cloudfront-url"}
              type="text"
              placeholder={"Enter CloudFront URL"}
              value={awsCloudfrontUrl || ""}
              onChange={({ target: { value } }) => setAwsCloudfrontUrl(value)}
            />
          </div>

          <div className="pr-36">
            <div className="flex justify-end mb-20">
              <LoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                onClick={onSave}>
                Save
              </LoadingButton>
            </div>
          </div>
        </>
      }
    </Layout>
  )
}

export default AwsConfig