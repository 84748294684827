import { useEffect, useState } from "react";
import { InputField, SelectField } from "../../../../tailwind";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";
import { Checkbox, Switch } from "@mui/material";
import { UseQueryResult, useMutation } from "@tanstack/react-query";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import TvIcon from '@mui/icons-material/Tv';
import { IUpdateCCavenuePaymentGatewaySettingApiPayload, updateCCavenuePaymentGatewaySettingApi } from "../../../../http/paymentshttp";

interface IProps {
    data: IPaymentGatewaySettings;
    darkMode: boolean;
    getPaymentGatewaysQuery: UseQueryResult
}

function getOptionLabel(value: "test" | "live" | undefined) {
    if (!value) return "";
    switch (value) {
        case "test":
            return "Test";
        case "live":
            return "Live";
        default:
            return "";
    }
}

const CcAvenuePaymentSettings = ({ data, darkMode, getPaymentGatewaysQuery }: IProps) => {
    const [viewKey, setViewKey] = useState<boolean>(false);
    const [viewSecret, setViewSecret] = useState<boolean>(false);
    const [ccavenue, setCcavenueData] = useState<IPaymentGatewaySettings>(data);

    useEffect(() => {
        setCcavenueData({ ...data, name: "ccavenue" });
    }, [data]);

    const updatedPaymentGatewaysMutation = useMutation({
        mutationKey: ["updatePaymentGateways"],
        mutationFn: (data: IUpdateCCavenuePaymentGatewaySettingApiPayload) => updateCCavenuePaymentGatewaySettingApi(data),
        onSuccess(data, variables, context) {
            getPaymentGatewaysQuery.refetch();
            toast.success("Payment gateway updated successfully");
        },
        onError(err, variables, context) {
            toast.error("Error while updating payment gateways");
        }
    })

    const handleSave = async () => {
        // remove availablity key from payment gateways
        delete ccavenue.availablity;
        delete ccavenue.provider
        const updateData = {
            enabled_on: ccavenue.enabled_on,
            key: ccavenue.key,
            merchant: ccavenue.merchant as string,
            mode: ccavenue.mode as string,
            website: ccavenue.website,
            name: ccavenue.name,
            secret: ccavenue.secret,
            status: ccavenue.status
        } as IUpdateCCavenuePaymentGatewaySettingApiPayload;
        updatedPaymentGatewaysMutation.mutate(updateData);
    };
    return (
        <div>
            <div className="mt-5 pr-6">
                <div className="flex items-center gap-2">
                    <h1 className="text-xl opacity-80 font-semibold tracking-wide capitalize">
                        {ccavenue.name}
                    </h1>
                    <span>
                        {ccavenue.status ? (
                            <span className="text-green-500">(Active)</span>
                        ) : (
                            <span className="text-red-500">(Inactive)</span>
                        )}
                    </span>
                </div>
                <div>
                    <p className="mt-2 opacity-70">
                        Update your {ccavenue.provider} api key and secret key here. You can
                        get your {ccavenue.provider} api key and secret key from{" "}
                        <a
                            href={"https://www.ccavenue.com/payment_gateway.jsp"}
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>
                        .
                    </p>
                </div>
                <div className="my-5 flex flex-col gap-8">
                    <InputField
                        inputType="password"
                        title={ccavenue.name + " Access Code"}
                        fieldName={ccavenue.provider + "_access_code"}
                        type={viewKey ? "text" : "password"}
                        placeholder={"Enter your " + data.provider + " access code"}
                        value={ccavenue.key}
                        onPasswordVisibilityClick={() => setViewKey(!viewKey)}
                        onChange={(e) => setCcavenueData({ ...ccavenue, key: e.target.value })}
                    />
                    <InputField
                        inputType="password"
                        title={ccavenue.name + " Working Key"}
                        fieldName={ccavenue.provider + "_working_key"}
                        type={viewSecret ? "text" : "password"}
                        placeholder={"Enter your " + data.provider + " working key"}
                        value={ccavenue.secret}
                        onPasswordVisibilityClick={() => setViewSecret(!viewSecret)}
                        onChange={(e) => setCcavenueData({ ...ccavenue, secret: e.target.value })}
                    />
                    <InputField
                        inputType="text"
                        title={ccavenue.name + " Merchant ID"}
                        fieldName={ccavenue.provider + "_merchant_id"}
                        type="text"
                        placeholder={"Enter your " + data.provider + " merchant id"}
                        value={ccavenue.merchant}
                        onChange={(e) => setCcavenueData({ ...ccavenue, merchant: e.target.value })}
                    />
                    <InputField
                        inputType="text"
                        title={ccavenue.name + " Website Redirect URL"}
                        fieldName={ccavenue.provider + "_website"}
                        type="text"
                        placeholder={"Enter your website url"}
                        value={ccavenue.website}
                        onChange={(e) => setCcavenueData({ ...ccavenue, website: e.target.value })}
                    />
                    <SelectField
                        options={[
                            { value: "test", label: "Test" },
                            { value: "live", label: "Live" },
                        ]}
                        defaultValue={[
                            { value: ccavenue.mode, label: getOptionLabel(ccavenue.mode) },
                        ] as any}
                        placeholder="Select Mode"
                        onChange={(e) => setCcavenueData({ ...ccavenue, mode: e.value })}
                    />
                </div>
                <div className="my-3.5">
                    <p className="mt-2 opacity-70">
                        Enable this gateway for different platform. like web, android, ios etc.
                    </p>
                    <div className="my-3.5">
                        <div className="flex items-center gap-4">
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <PhoneAndroidIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        Android
                                    </h1>
                                    {
                                        ccavenue.availablity?.android ? <Checkbox
                                            checked={ccavenue.enabled_on?.android}
                                            onChange={(e: any) => setCcavenueData({ ...ccavenue, enabled_on: { ...ccavenue.enabled_on, android: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <PhoneIphoneIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        IOS
                                    </h1>
                                    {
                                        ccavenue.availablity?.ios ? <Checkbox
                                            checked={ccavenue.enabled_on?.ios}
                                            onChange={(e: any) => setCcavenueData({ ...ccavenue, enabled_on: { ...ccavenue.enabled_on, ios: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <LaptopMacIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        Website
                                    </h1>
                                    {
                                        ccavenue.availablity?.web ? <Checkbox
                                            checked={ccavenue.enabled_on?.web}
                                            onChange={(e: any) => setCcavenueData({ ...ccavenue, enabled_on: { ...ccavenue.enabled_on, web: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                            <div className={`px-8 py-3 border ${darkMode ? "border-slate-50" : "border-dark-mode"} rounded flex items-center gap-5`}>
                                <TvIcon sx={{
                                    width: '5rem',
                                    height: '5rem'
                                }} />
                                <div>
                                    <h1 className="text-base opacity-80 font-semibold tracking-wide">
                                        TV
                                    </h1>
                                    {
                                        ccavenue.availablity?.android_tv ? <Checkbox
                                            checked={ccavenue.enabled_on?.android_tv}
                                            onChange={(e: any) => setCcavenueData({ ...ccavenue, enabled_on: { ...ccavenue.enabled_on, android_tv: e.target.checked } })}
                                        /> : <Checkbox
                                            checked={false}
                                            disabled
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <h1 className="text-lg opacity-80 font-semibold tracking-wide capitalize">
                        {ccavenue.name} Status
                    </h1>
                    <Switch
                        checked={ccavenue.status}
                        onChange={(e: any) => setCcavenueData({ ...ccavenue, status: e.target.checked })}
                        name="available"
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </div>
            </div>
            <div className="pr-6">
                <div className="flex justify-end mb-20">
                    <LoadingButton
                        loading={updatedPaymentGatewaysMutation.isLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                        onClick={() => handleSave()}>
                        Save
                    </LoadingButton>
                </div>
            </div>
        </div >
    );
};

export default CcAvenuePaymentSettings;