// Author: Pukhraj Dhamu
// Date Created: 24 June 2022 (Friday)
// Date Modified: Sunday 14 May 2023 at 7:26 PM
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addRelease, contactAction, getRelease, updateRelease, deleteRelease } from "../../../http/Apis";
import { setBulkActionCall } from "../../../redux/slice​/contentSlice";
import moment from "moment";
import "react-circular-progressbar/dist/styles.css";
import { Hr, MuiDialog } from "../../../components";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import Button from "@mui/material/Button";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchIcon from "@mui/icons-material/Launch";
import Checkbox from "@mui/material/Checkbox";
import { UseQueryResult } from "@tanstack/react-query";
import { RootState } from "../../../redux/store";
import { counter } from "../../../utils/Utils";

interface IContentTableProps {
    contentQuery: UseQueryResult;
    query: string;
}

const ContentTable = ({ contentQuery, query }: IContentTableProps) => {
    const contentData: any = contentQuery
    const content: IContentData[] = contentData.data?.data.data
    const dispatch = useDispatch();
    const [allSelectArray, setallSelectArray] = useState<string[]>([]);
    const { bulkAction, bulkActionCall } = useSelector((state: RootState) => state.content);
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [singleSelect, setSingleSelect] = useState<string[]>([]);
    const [status, setStatus] = useState<any>(false);
    // @holds new release id
    const [releaseID, setReleaseID] = useState("");

    const [muiDialogContant, setMuiDialogContant] = useState<any>({
        title: "",
        contentText: "",
        buttonOneText: "",
        buttonTwoText: "",
        twoAction: false,
        actionKey: false,
        bulkDeleteData: false,
    });

    // status dialog
    const [muiDialogOpen, setMuiDialogOpen] = useState<boolean>(false);
    const handleMuiDialogOpen = () => {
        setMuiDialogOpen(true);
    };
    const handleMuiDialogClose = () => {
        setMuiDialogOpen(false);
        setTimeout(() => {
            setMuiDialogContant({
                title: "",
                contentText: "",
            });
        }, 100);
    };

    // handleStatus
    const handleAction = async () => {
        const data = {
            id: singleSelect,
            action: status,
        };
        try {
            const response = await contactAction(data);
            if (response.status === 200) {
                toast.success("Status updated successfully");
                contentQuery.refetch();
                handleMuiDialogClose();

                if (status === "private") {
                    setReleaseID("");
                }
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || "Something went wrong");
        }
    };

    const handleDeleteForeverdata = {
        id: singleSelect,
        action: "delete",
    };
    // handleDelete
    const handleDeleteForever = async (data: any) => {
        try {
            const response = await contactAction(data);
            if (response.status === 200) {
                toast.success("Status updated successfully");
                contentQuery.refetch();
                handleMuiDialogClose();
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error?.message || "Something went wrong");
            handleMuiDialogClose();
        }
    };

    // headle checkbox
    const headleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name: id, checked } = e.target;
        const contentData: any = contentQuery.data
        const newContentData: IContentData[] = contentData?.data.data
        if (id === "selectAll") {
            if (checked) {
                if (!newContentData) return toast.error("No data found! Please refresh the page")
                setallSelectArray(newContentData.map((data) => data._id));
            } else {
                setallSelectArray([]);
            }
        } else {
            setallSelectArray((prev) => {
                if (checked) {
                    return [...prev, id];
                } else {
                    return prev.filter((item) => item !== id);
                }
            });
        }
    };

    useEffect(() => {
        if (bulkActionCall) {
            headleBulkActions();
            dispatch(setBulkActionCall(false));
        }
    }, [bulkActionCall]);

    // headle bulk actions
    const headleBulkActions = async () => {
        const formData = new FormData();
        if (allSelectArray.length < 1) {
            formData.append("id", allSelectArray as any);
        } else {
            for (let i = 0; i < allSelectArray.length; i++) {
                formData.append("id", allSelectArray[i]);
            }
        }
        const newFormData =
            allSelectArray.length > 0
                ? { id: allSelectArray, action: bulkAction }
                : formData;

        if (bulkAction === "none") {
            toast.error("Please select bulk action");
        } else {
            if (bulkAction === "delete") {
                setStatus("restore");
                handleMuiDialogOpen();
                setMuiDialogContant({
                    title: "Restore from Trash",
                    contentText:
                        "Are you sure you want to restore this contant from trash?",
                    buttonOneText: "Delete Forever",
                    buttonTwoText: "Restore",
                    twoAction: true,
                    actionKey: "restore",
                    bulkDeleteData: newFormData,
                });
            } else {
                try {
                    const response = await contactAction(newFormData);
                    if (response.status === 200) {
                        toast.success("Bulk action performed successfully");
                        contentQuery.refetch();
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data.error.message || "Something went wrong");
                }
            }
        }
    };

    // type of contant type
    const typeOfContant = (type: IContentData['type']) => {
        switch (type) {
            case "movie":
                return "Movie";
            case "series":
                return "Series";
            case "live_stream":
                return "Live Stream";
            default:
                return "None";
        }
    };

    // @useEffect for the handling new Realeases
    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await getRelease();
                if (res.status === 200) {
                    setReleaseID(res.data.data.content_id._id);
                }
            } catch (error) {
                return console.log(error);
            }
        };

        fetchData();
    }, []);

    const handleNewReleaseAdd = async (id: string) => {
        try {
            if (!id) return console.log("All fields required..");
            let dataObj = {
                contentID: id,
            };
            let res = await addRelease(dataObj);
            if (res.status === 200) {
                setReleaseID(id);
                toast.success(res.data.message);
            }
        } catch (error) {
            toast.error("Something want wrong!")
        }
    };

    const handleUpdateRelease = async (id: string) => {
        try {
            if (!id) return console.log("All fields required..");
            let dataObj = {
                contentID: id,
            };
            let res = await updateRelease(dataObj);
            if (res.status === 200) {
                setReleaseID(id);
                toast.success("Pop updated");
            }
        } catch (error) {
            toast.error("Something want wrong!")
        }
    };

    const handleDeleteRelease = async () => {
        try {
            let res = await deleteRelease();
            if (res.status === 200) {
                setReleaseID("");
                toast.success(res.data.message);
            }
        } catch (error) {
            toast.error("Something want wrong!")
        }
    };

    return (
        <div className="w-full">
            <div className="w-full">
                <div className="flex items-center">
                    <div className="w-11 -mt-2.5 -ml-2.5">
                        <div className="tooltip tooltip-right" data-tip="Select All">
                            <Checkbox
                                checked={allSelectArray.length === content.length}
                                onChange={headleCheckbox}
                                name="selectAll"
                                color="primary"
                            />
                        </div>
                    </div>
                    <div className="grid grid-flow-row grid-cols-12 gap-3 pr-5 mb-2.5 w-full">
                        <div className="col-span-4">
                            <h1>Content</h1>
                        </div>
                        <div className="col-span-2">
                            <h1>Visibility</h1>
                        </div>
                        <div className="col-span-1">
                            <h1>Views</h1>
                        </div>
                        <div className="col-span-1">
                            <h1>Type</h1>
                        </div>
                        <div className="col-span-2">
                            <h1>Date</h1>
                        </div>
                        <div className="col-span-2">
                            <h1>Actions</h1>
                        </div>
                    </div>
                </div>
                <Hr />
                {content.map((item, index) => {
                    const lastIndex = content.length - 1;
                    return (
                        <div
                            key={index}
                            className={`flex my-3 ${lastIndex === index ? "" : "border-b"} ${darkMode ? "border-[#343434]" : "border-gray-200"}`}>
                            <div className="w-11 -mt-2.5 -ml-2.5">
                                <Checkbox
                                    checked={allSelectArray.includes(item._id)}
                                    onChange={headleCheckbox}
                                    name={item._id}
                                    color="primary"
                                />
                            </div>
                            <div className="grid grid-flow-row grid-cols-12 gap-3 pr-5 mb-2.5 w-full">
                                <div className="col-span-4">
                                    <div className="flex">
                                        <div className="w-32 h-[70px] aspect-video rounded overflow-hidden relative">
                                            <img
                                                src={item.thumbnail || "/default-product.png"}
                                                className={`${darkMode ? "bg-dark-color-box" : "bg-day-box-color"} w-full h-full`}
                                                alt={item.name}
                                            />
                                            <span className="absolute bottom-1.5 right-1.5 rounded bg-black opacity-80 w-fit px-2 text-sm text-white">
                                                {item.content_duration}
                                            </span>
                                        </div>
                                        <div className="ml-3 w-60">
                                            <h1 className="text-base line-clamp-1">
                                                {item.name}
                                            </h1>
                                            <p className="text-gray-400 text-xs mt-1 line-clamp-2">
                                                {item.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <div className="w-full cursor-pointer">
                                        <label
                                            onClick={() => {
                                                setSingleSelect([item._id]);
                                                setStatus(item.status === "PUBLIC" ? "private" : "public");
                                                handleMuiDialogOpen();
                                                setMuiDialogContant({
                                                    title: "Change Status",
                                                    contentText: "Are you sure you want to change the status of this contant?",
                                                    buttonOneText: "Cancel",
                                                    buttonTwoText: "Yes",
                                                });
                                            }}>
                                            <h1 className="cursor-pointer">
                                                {item.status === "PUBLIC" ? (
                                                    <div className="flex items-center gap-2">
                                                        <MdVisibility
                                                            size={20}
                                                            className="text-green-500"
                                                        />
                                                        <span className="text-sm">Public</span>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="flex items-center gap-2">
                                                            <MdVisibilityOff
                                                                size={20}
                                                                className="text-red-500"
                                                            />
                                                            <span className="text-sm">Private</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </h1>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <h1>{counter(item.views || 0)}</h1>
                                </div>
                                <div className="col-span-1">
                                    <h1>{typeOfContant(item.type)}</h1>
                                </div>
                                <div className="col-span-2">
                                    <h1 className="capitalize">
                                        {moment(item.createdAt).format("MMM DD, YYYY")}
                                    </h1>
                                    <p className="text-xs mt-0.5">Published</p>
                                </div>
                                <div className="col-span-2 flex">
                                    {item.trash ? (
                                        <div
                                            className="tooltip tooltip-accent"
                                            data-tip="Restore"
                                        >
                                            {" "}
                                            <Button
                                                onClick={() => {
                                                    setSingleSelect([item._id]);
                                                    setStatus("restore");
                                                    handleMuiDialogOpen();
                                                    setMuiDialogContant({
                                                        title: "Restore from Trash",
                                                        contentText:
                                                            "Are you sure you want to restore this contant from trash?",
                                                        buttonOneText: "Delete Forever",
                                                        buttonTwoText: "Restore",
                                                        twoAction: true,
                                                        actionKey: "restore",
                                                    });
                                                }}
                                                variant="text"
                                            >
                                                <RestoreFromTrashIcon className="cursor-pointer" />
                                            </Button>{" "}
                                        </div>
                                    ) : (
                                        <div
                                            className="tooltip tooltip-accent"
                                            data-tip="Delete"
                                        >
                                            {" "}
                                            <Button
                                                onClick={() => {
                                                    setSingleSelect([item._id]);
                                                    setStatus("trash");
                                                    handleMuiDialogOpen();
                                                    setMuiDialogContant({
                                                        title: "Move to Trash",
                                                        contentText:
                                                            "Are you sure you want to move this contant to trash?",
                                                        buttonOneText: "Cancel",
                                                        buttonTwoText: "Yes",
                                                    });
                                                }}
                                                variant="text"
                                            >
                                                <DeleteIcon className="cursor-pointer" />
                                            </Button>
                                        </div>
                                    )}
                                    <Link
                                        to={`/admin/content/edit/${item._id}`}
                                        //to={RoutesStrings.Content.Children.EditContent}

                                        className="cursor-pointer"
                                    >
                                        <div className="tooltip tooltip-accent" data-tip="Edit">
                                            <Button
                                                className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                                variant="text"
                                            >
                                                <EditIcon className="cursor-pointer" />
                                            </Button>
                                        </div>
                                    </Link>

                                    <div
                                        className="tooltip tooltip-accent"
                                        data-tip="Make PopUp"
                                    >
                                        <Button
                                            className="w-10 h-10 bg-gray-800 hover:bg-gray-700"
                                            variant="text"
                                            disabled={item.status === "PUBLIC" ? false : true}
                                            color={
                                                releaseID === item._id ? `secondary` : `primary`
                                            }
                                            onClick={() => {
                                                // console.log(item._id);

                                                if (releaseID === item._id) {
                                                    return handleDeleteRelease();
                                                }

                                                releaseID === ""
                                                    ? handleNewReleaseAdd(item._id)
                                                    : handleUpdateRelease(item._id);
                                            }}
                                        >
                                            <LaunchIcon className="cursor-pointer" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="-mt-3">
                    <Hr />
                </div>
            </div>
            {/* Dialog */}
            <MuiDialog
                open={muiDialogOpen}
                onClose={handleMuiDialogClose}
                title={muiDialogContant.title}
                contentText={muiDialogContant.contentText}
                onClickClose={() =>
                    muiDialogContant.twoAction
                        ? handleDeleteForever(
                            muiDialogContant.bulkDeleteData
                                ? muiDialogContant.bulkDeleteData
                                : handleDeleteForeverdata
                        )
                        : handleMuiDialogClose()
                }
                onClickYes={handleAction}
                buttonOneText={muiDialogContant.buttonOneText}
                buttonTwoText={muiDialogContant.buttonTwoText}
            // buttonTwoTitle="Yes"
            />
        </div>
    );
};

export default ContentTable;