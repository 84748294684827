// Author: Pukhraj Dhamu
// Created year: 2022
// Last Modified Date: Tuesday 27 Jun 2023 at 9:31 AM
import React from 'react'
import { Line } from 'react-chartjs-2';

function MovieAndSeries() {
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
            yAxes: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        elements: {
            line: {
                backgroundColor: "#D75483",
                borderRadius: 50,
                borderSkipped: "bottom",
            }
        },
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const data = {
        labels,
        datasets: [
            {
                label: 'Total Movies',
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Total Series',
                data: [28, 48, 40, 19, 86, 27, 90],
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
        ],
    };

    return (
        <div>
            <Line data={data} options={chartOptions} />
        </div>
    )
}

export default MovieAndSeries