// Author: Pukhraj Dhamu
// Created Date: Saturday 8 July 2023 at 11:03 AM
// Last Modified Date: Saturday 8 July 2023 at 11:03 AM
import React from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import RouteStrings from '../../../localData/routes.json'
import { HiOutlinePlusSm } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { fetchConfig } from '../../../redux/slice​/configSlice'
import axios from 'axios'
import Base64 from 'base-64';
import toast from 'react-hot-toast'
import { BasicTabs, Hr } from '../../../components'
import ReactJson from 'react-json-view'
import { Loader } from '../../../components/smallComponents/loader'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getSettingsApi } from '../../../http/settingshttp'

function TranscoderJobsCreate(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { darkMode } = useSelector((state: RootState) => state.theme);
    const [loading, setLoading] = React.useState<boolean>(false)
    const [transcoderConfig, setTranscoderConfig] = React.useState<{
        hostname: string;
        username: string;
        password: string;
    } | undefined>();
    const [priority, setPriority] = React.useState<"low" | "medium" | "high">("low")
    const [Resolution, setResolution] = React.useState([
        {
            id: 1,
            name: "144p",
            value: "144p",
            selected: true,
            disabled: true,
        },
        {
            id: 2,
            name: "240p",
            value: "240p",
            selected: true,
            disabled: true,
        },
        {
            id: 3,
            name: "360p",
            value: "360p",
            selected: true,
            disabled: true,
        },
        {
            id: 4,
            name: "480p",
            value: "480p",
            selected: true,
            disabled: true,
        },
        {
            id: 5,
            name: "720p",
            value: "720p",
            selected: true,
            disabled: true,
        },
        {
            id: 6,
            name: "1080p",
            value: "1080p",
            selected: true,
            disabled: true,
        },
        {
            id: 7,
            name: "4K",
            value: "2160p",
            selected: false,
            disabled: false,
        },
    ]);

    // useQuery for get transcoder settings
    useQuery<{ data: ITranscoderSetting[] }>({
        queryKey: ["getTranscoderQuery"],
        queryFn: () => getSettingsApi("transcoder"),
        onSuccess: (data) => {
            if (data && data.data.length > 0) {
                setTranscoderConfig({
                    hostname: data.data[0].hostname,
                    username: data.data[0].username,
                    password: data.data[0].password
                });
            }
        },
        onError: (error) => {
            toast.error("Failed to fetch transcoder settings")
        }
    });

    const [inputUrl, setInputUrl] = React.useState("");
    const [name, setName] = React.useState<string>("")
    const [submitData, setSubmitData] = React.useState({
        priority: priority,
        input: inputUrl,
        name: name,
        template: "hls",
        resolutions: Resolution.filter((res) => res.selected)
            .map((res) => res.value)
            .reverse(),
        storage: "s3",
    });

    // if resolution is selected then set it to true
    React.useEffect(() => {
        setSubmitData({
            ...submitData,
            input: inputUrl,
            name: name,
            priority: priority,
            resolutions: Resolution.filter((res) => res.selected)
                .map((res) => res.value)
                .reverse(),
        });
    }, [Resolution, inputUrl, priority, name]);

    React.useEffect(() => {
        dispatch(fetchConfig() as any);
    }, [])

    const onSubmitTranscoding = async (
        hostname: string,
        username: string,
        password: string
    ) => {
        try {
            if (!name) return toast.error("Name is required")
            if (!inputUrl) return toast.error("Input URL is required")
            let newData = {
                label: name,
                priority: submitData.priority,
                input: submitData.input,
                template: submitData.template,
                resolutions: submitData.resolutions.reverse(),
                storage: submitData.storage,
            };
            setLoading(true);
            const { data, status } = await axios.post(
                `${hostname}/api/v1/jobs/queue`,
                newData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic " + Base64.encode(`${username}:${password}`),
                    },
                }
            );
            toast.success("Transcoding started successfully");
            navigate(`${RouteStrings.Transcoding.Children.Dashboard.Link}`)
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.data?.error?.message || "Error starting transcode");
        }
    };

    return (
        <div className='mb-36'>
            <>
                <div className='bg-opacity-25 rounded-xl overflow-hidden'>
                    <div className='px-1 py-4 flex justify-between items-center'>
                        <h1 className='text-xl font-medium opacity-90'>
                            Create Job
                        </h1>
                        <div className='flex gap-4 items-center'>
                            <Link to={RouteStrings.Transcoding.Children.Dashboard.Link}>
                                <div
                                    className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-fit rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                                    <BiArrowBack className='text-black text-lg bg-white rounded-full' />
                                    <h1 className='text-sm text-white'>
                                        Back to Jobs
                                    </h1>
                                </div>
                            </Link>
                            <div
                                onClick={() => {
                                    if (loading) return
                                    if (transcoderConfig) {
                                        onSubmitTranscoding(transcoderConfig.hostname, transcoderConfig.username, transcoderConfig.password)
                                    } else {
                                        toast.error("Error starting transcode")
                                    }
                                }}
                                className={`${darkMode ? 'bg-dark-color-box' : "bg-blue-500"} w-28 rounded px-3 py-2.5 flex gap-2 items-center cursor-pointer`}>
                                {loading ?
                                    <div className='w-full flex justify-center items-center'>
                                        <Loader
                                            smail
                                            color='border-white'
                                        />
                                    </div> : <div className='flex justify-center items-center w-full gap-2'>
                                        <HiOutlinePlusSm className='text-black text-lg bg-white rounded-full' />
                                        <h1 className='text-sm text-white'>
                                            Create
                                        </h1>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className='w-full min-h-[35rem]'>
                        <h1 className="text-2xl mt-3 mb-5">Zezo Cloud Transcoding</h1>
                        <div className="">
                            <BasicTabs
                                tabs={[
                                    {
                                        label: "url",
                                    },
                                    {
                                        label: "upload",
                                    },
                                ]}
                                tabPanels={[
                                    {
                                        children: (
                                            <div className="pt-5">
                                                <input
                                                    type="text"
                                                    placeholder="Input Type Hare"
                                                    className="input w-full bg-transparent border border-gray-700"
                                                    value={inputUrl}
                                                    onChange={(e) => setInputUrl(e.target.value)}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Name"
                                                    className="input w-full bg-transparent mt-3 border-gray-700"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                                <div className="grid grid-cols-3 my-6">
                                                    <div className="col-span-1">
                                                        <div className="grid grid-cols-2 gap-5">
                                                            <div>
                                                                <h1 className="opacity-80 mb-1">
                                                                    Format
                                                                </h1>
                                                                <select className="select font-normal w-full max-w-xs bg-transparent border border-gray-600 rounded">
                                                                    <option selected>HLS</option>
                                                                    {/* <option>DASH</option> */}
                                                                </select>
                                                                <h1 className="opacity-80 mb-1 mt-4">
                                                                    Codec
                                                                </h1>
                                                                <select className="select font-normal w-full max-w-xs bg-transparent border border-gray-600 rounded">
                                                                    <option selected>H.264</option>
                                                                    {/* <option>DASH</option> */}
                                                                </select>
                                                                <h1 className="opacity-80 mb-1 mt-4">
                                                                    Priority
                                                                </h1>
                                                                <select
                                                                    onChange={(e) => {
                                                                        setPriority(e.target.value as any)
                                                                    }}
                                                                    className="select font-normal w-full max-w-xs bg-transparent border border-gray-600 rounded">
                                                                    <option value="low" selected>Low</option>
                                                                    <option value="medium" >Medium</option>
                                                                    <option value="high" >High</option>
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <h1 className="opacity-80 mb-1">
                                                                    Resolution
                                                                </h1>
                                                                <div className={`rounded w-fit bg-white bg-opacity-10`}>
                                                                    {Resolution &&
                                                                        Resolution.map(
                                                                            (singleResolution, index) => {
                                                                                return (
                                                                                    <div key={index} className="">
                                                                                        <div className="flex items-center py-1 px-3">
                                                                                            <div className="form-control">
                                                                                                <label className="cursor-pointer label">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={
                                                                                                            singleResolution.selected
                                                                                                        }
                                                                                                        disabled={
                                                                                                            singleResolution.disabled
                                                                                                        }
                                                                                                        onChange={(e) => {
                                                                                                            const newResolution =
                                                                                                                [...Resolution];
                                                                                                            newResolution[
                                                                                                                index
                                                                                                            ].selected =
                                                                                                                e.target.checked;
                                                                                                            setResolution(
                                                                                                                newResolution
                                                                                                            );
                                                                                                        }}
                                                                                                        className="checkbox checkbox-secondary"
                                                                                                    />
                                                                                                </label>
                                                                                            </div>
                                                                                            <span className="ml-2">
                                                                                                {singleResolution.name}
                                                                                            </span>
                                                                                        </div>
                                                                                        <Hr className='border-gray-300 border-opacity-20' />
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        ).reverse()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-span-2">
                                                        <h1 className="opacity-80 mb-1">
                                                            Your JSON
                                                        </h1>
                                                        <div
                                                            className={`bg-dark-color-box bg-opacity-10 overflow-auto rounded`}
                                                        >

                                                            <ReactJson
                                                                src={submitData}
                                                                theme="monokai"
                                                                style={{
                                                                    backgroundColor: `#282828`,
                                                                    padding: `1rem`,
                                                                }}
                                                                collapsed={false}
                                                                enableClipboard={false}
                                                                displayObjectSize={false}
                                                                displayDataTypes={false}
                                                            />
                                                        </div>

                                                        <div className="my-4">
                                                            {/* <Button
                                                                                        variant="contained"
                                                                                        className="w-full"
                                                                                        disabled={loading}
                                                                                        onClick={() => {
                                                                                            if (credentials.transcoder) {
                                                                                                const {
                                                                                                    hostname,
                                                                                                    username,
                                                                                                    password,
                                                                                                } = credentials.transcoder[0];
                                                                                                setTranscodingProvider("zezo");
                                                                                                onSubmitTranscoding(
                                                                                                    hostname,
                                                                                                    username,
                                                                                                    password
                                                                                                );
                                                                                            } else {
                                                                                                toast.error(
                                                                                                    "Please add your Zezo credentials first."
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {loading
                                                                                            ? "Loading..."
                                                                                            : "Start transcoding"}
                                                                                    </Button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    },
                                    {
                                        children: (
                                            <div className="flex justify-center items-center mt-10">
                                                Upload file service is not available yet.
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default TranscoderJobsCreate

